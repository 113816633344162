import { useState } from 'react';
import {
    Checkbox,
    Divider,
    Form,
    List,
    Modal,
    Typography,
    message,
} from 'antd';
import { startSearchManual } from '../../../api/express-delivery';

interface Props {
    items: {
        _id: string;
        name: string;
        total: number;
    }[],
    open: boolean;
    onClose: () => void;
}

const { Text } = Typography;

const StartSearch = ({ items, open, onClose }: Props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const handleFinish = () => {
        setLoading(true);
        const businesses = form.getFieldValue('businesses');
        startSearchManual(businesses)
            .then(() => {
                message.success('Search started successfully');
            })
            .catch((error) => {
                const description = error.response?.data?.message || 'An error occurred';
                message.error(description);
            })
            .finally(() => {
                form.resetFields();
                setLoading(false);
                onClose();
            });
    };

    return (
        <Modal
            open={open}
            title="Start Search"
            onCancel={onClose}
            onOk={() => form.submit()}
            confirmLoading={loading}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={() => handleFinish()}
            >
                <Text>
                    Select the stores with which you want to begin the search. Remember, all express deliveries from the selected store will be grouped into one route and the search will begin.
                </Text>

                <Divider
                    plain
                    orientation="left"
                    style={{ marginTop: 20 }}
                >
                    <Text strong>
                        {`Stores to start search (${items?.length || 0})`}
                    </Text>
                </Divider>

                <div style={{ maxHeight: 300, overflowY: 'scroll' }}>
                    <Form.Item name="businesses">
                        <Checkbox.Group style={{ width: '100%' }}>
                            {
                                items?.map((item) => (
                                    <List.Item key={item._id}>
                                        <Checkbox value={item._id} />
                                        <List.Item.Meta
                                            title={item.name}
                                            description={`Total: ${item.total} express deliver${item.total > 1 ? 'ies' : 'y'}`}
                                            style={{ marginLeft: 12 }}
                                        />
                                    </List.Item>
                                ))
                            }
                        </Checkbox.Group>
                    </Form.Item>
                </div>

            </Form>
        </Modal>
    );
};

export default StartSearch;
