export const getStripeProducts = async (query: string) => {
    const products = await fetch(`https://api.stripe.com/v1/products?limit=100&active=true&${query}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
        },
    });
    const productsJson = await products.json();
    return productsJson;
};

export const createInvoice = async (customer: string) => {
    const invoice = await fetch('https://api.stripe.com/v1/invoices', {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `customer=${customer}&auto_advance=true&collection_method=charge_automatically`,
    });
    const invoiceJson = await invoice.json();
    return invoiceJson;
};
