import axios from 'axios';
import { OCTOPUS_BASE_URL } from '.';
import { IUpdateCertificationDto } from '../components/WhatsappCertifications/context/certifications.interface';

export const getCertifications = ({
    limit,
    page,
    status,
    startDate,
    endDate,
    search,
}: any) => {
    const params = new URLSearchParams({
        limit: limit.toString(),
        page: page.toString(),
    });

    (status && status.length) && params.append('status', JSON.stringify(status));
    (startDate && startDate.length) && params.append('startDate', startDate.toString());
    (endDate && endDate.length) && params.append('endDate', endDate.toString());
    (search && search.length) && params.append('search', search);

    const url = `${OCTOPUS_BASE_URL}/whatsapp-certifications`;
    const response = axios.get(url, { params });
    return response;
};

export const getCertification = (id: string) => {
    const url = `${OCTOPUS_BASE_URL}/whatsapp-certifications/${id}`;
    const response = axios.get(url);
    return response;
};

export const updateCertification = (id: string, data: IUpdateCertificationDto) => {
    const url = `${OCTOPUS_BASE_URL}/whatsapp-certifications/${id}`;
    const response = axios.put(url, data);
    return response;
};
