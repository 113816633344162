import { Form, Input } from 'antd';
import { IDispersion } from '../../../interfaces/dispersion.interface';

interface Props {
    form: any;
    dispersion: IDispersion;
}

const { TextArea } = Input;

const DeleteDispersion = ({ form, dispersion }: Props) => (
    <Form
        form={form}
        layout="vertical"
        onFinish={() => {}}
        initialValues={{ status: dispersion.status }}
    >
        <Form.Item
            name="reason"
            label="Reason for deletion"
            rules={[{ required: true, message: 'Please add a reason for deletion' }]}
        >
            <TextArea
                placeholder="Add a reason for deletion"
                autoSize={{ minRows: 2, maxRows: 4 }}
            />
        </Form.Item>
    </Form>
);

export default DeleteDispersion;
