export const IMAGES = {
    logo: {
        box: 'https://picker-dashboard.s3.amazonaws.com/logos/logo.svg',
        iso: 'https://picker-dashboard.s3.amazonaws.com/logos/Isotipo.svg',
        horizontal: 'https://picker-dashboard.s3.amazonaws.com/logos/logo_horizontal.svg',
    },
    providers: {
        picker: 'https://picker-dashboard.s3.amazonaws.com/providers-iso/picker.svg',
        pedidosya: 'https://picker-dashboard.s3.amazonaws.com/providers-iso/pedidos_ya.svg',
        gacela: 'https://picker-dashboard.s3.amazonaws.com/providers-iso/gacela.svg',
        delivereo: 'https://picker-dashboard.s3.amazonaws.com/providers-iso/delivereo.svg',
        uber: 'https://picker-dashboard.s3.amazonaws.com/providers-iso/uber.svg',
        faster: 'https://picker-dashboard.s3.amazonaws.com/providers/faster.png',
        star_delivery: 'https://picker-dashboard.s3.amazonaws.com/providers/star_delivery.png',
        kanguro: 'https://picker-dashboard.s3.amazonaws.com/providers/kanguro.png',
        own_fleet: 'https://picker-dashboard.s3.amazonaws.com/providers-iso/my_fleet.svg',
        rappi: 'https://picker-dashboard.s3.amazonaws.com/providers-iso/rappi.svg',
        lalamove: 'https://picker-dashboard.s3.amazonaws.com/providers-iso/lalamove.svg',
        ivoy: 'https://picker-dashboard.s3.amazonaws.com/providers-iso/ivoy.svg',
        minutos99: 'https://picker-dashboard.s3.amazonaws.com/providers-iso/99min.svg',
        small_fleet: 'https://picker-dashboard.s3.amazonaws.com/providers-iso/small_fleets.svg',
        mensajeros_urbanos: 'https://picker-dashboard.s3.amazonaws.com/providers-iso/mu.svg',
        borzo: 'https://picker-dashboard.s3.amazonaws.com/providers-iso/borzo.svg',
        chazki: 'https://picker-dashboard.s3.amazonaws.com/providers-iso/chazki.svg',
        pibox: 'https://picker-dashboard.s3.amazonaws.com/providers-iso/pibox.svg',
    },
    icons: {
        pinCyan: 'https://picker-dashboard.s3.amazonaws.com/icons/pin-cyan.svg',
        pinGreen: 'https://picker-dashboard.s3.amazonaws.com/icons/pin-green.svg',
        location: 'https://picker-dashboard.s3.amazonaws.com/icons/search-location.svg',
        store_location: 'https://picker-dashboard.s3.amazonaws.com/icons/store_location.svg',
        batch: 'https://picker-dashboard.s3.amazonaws.com/icons/batch.svg',
        point: 'https://picker-dashboard.s3.amazonaws.com/icons/point.svg',
    },
};
