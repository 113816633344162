export enum RegionType {
    Polygon = 'Polygon',
}
export enum RegionStatus {
    Green = 'GREEN',
    Yellow = 'YELLOW',
    Red = 'RED',
}

export enum CarType {
    LITE = 0,
    BIKE = 1,
    BOTH = 3,
}

export enum PaymentType {
    CARD = 1,
    CASH = 2,
    BOTH = 4,
}

export enum RideType {
    RIDE_NOW = 1,
    RIDE_LATER = 2,
    BOTH = 3,
}

export enum SpecialFeesActive {
    RAIN_FEE = 'RAIN_FEE',
    RUSH_HOUR_FEE = 'RUSH_HOUR_FEE'
}
export interface GeoLongLat {
    coordinates: Array<Array<number[]>>;
    type: RegionType;
}
export interface Region {
    _id: string;
    updatedAt: Date;
    createdAt: Date;
    regionName: string;
    carType: CarType;
    paymentType: PaymentType;
    rideType: RideType;
    specialFeesActive: any[];
    regionStatus: string;
    isDeleted: boolean;
    isBlocked: boolean;
    geoLongLat: GeoLongLat;
    highDemandThreshold?: number;
}
