import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import WhatsappCertificationsScreen from '../screens/WhatsappCertifications';
import { CertificationsProvider } from '../components/WhatsappCertifications/context/certifications.context';

const WhatsappCertifications: FC = () => (
    <Routes>
        <Route
            path=""
            element={(
                <CertificationsProvider>
                    <WhatsappCertificationsScreen />
                </CertificationsProvider>
            )}
        />
    </Routes>
);

export default WhatsappCertifications;
