import cs from 'classnames';
import {
    Button, Card, Divider, List, PageHeader, Spin, Typography,
} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';
import {
    useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { FaPlus } from 'react-icons/fa';
import { IoMdRefresh } from 'react-icons/io';
import { AlertsContext } from '../context/alerts.context';
import { IAlert } from '../../../interfaces/workspace-alert';
import { CreateAlert, FiltersMenu } from '.';

const { Text } = Typography;

const AlertsList = () => {
    const [status, setStatus] = useState<any>();
    const [type, setType] = useState<any>();

    const {
        loading,
        alerts,
        alert,
        showCreateAlert,
        showFilters,
        maxAlerts,
        fetchAlerts,
        setAlert,
        setShowCreateAlert,
        setNewAlert,
        refreshAlerts,
        setShowFilters,
        handleType,
        handleIsActive,
    } = useContext(AlertsContext);

    const scrollableAlertsRef = useRef<HTMLDivElement | null>(null);
    const fetchDebounced = useCallback<any>(debounce(fetchAlerts, 700), []);

    const handleStatus = (value: string) => {
        setStatus(value);
        handleIsActive(value);
        fetchDebounced(type, value);
    };

    const handleAlertType = (value: string) => {
        setType(value);
        handleType(value);
        fetchDebounced(value, status);
    };

    const resetFilters = () => {
        setStatus(undefined);
        setType(undefined);
        handleIsActive(undefined);
        handleType(undefined);
        fetchDebounced(undefined, undefined);
    };

    const extra = [
        <FiltersMenu
            showFilters={showFilters}
            setShowFilters={setShowFilters}
            handleAlertType={handleAlertType}
            handleStatus={handleStatus}
            resetFilters={resetFilters}
        />,
        <Button
            type="ghost"
            icon={(
                <IoMdRefresh
                    size={16}
                    style={{
                        verticalAlign: 'middle',
                    }}
                />
            )}
            className="flex-row center-row-center"
            onClick={() => refreshAlerts()}
        />,
        <Button
            type="primary"
            className="flex-row center-row-center"
            icon={(
                <FaPlus
                    size={14}
                    style={{
                        verticalAlign: 'middle',
                    }}
                />
            )}
            onClick={() => setShowCreateAlert(true)}
        />,
    ];

    useEffect(() => {
        fetchAlerts();
    }, []);

    return (
        <Card bordered>
            <PageHeader
                title="Alerts"
                style={{ padding: 0 }}
                extra={extra}
            />
            {
                showCreateAlert && (
                    <CreateAlert
                        visible={showCreateAlert}
                        setVisible={setShowCreateAlert}
                        setNewAlert={setNewAlert}
                    />
                )
            }
            {
                loading ? (
                    <div style={{ textAlign: 'center' }}>
                        <Spin size="small" />
                    </div>
                ) : (

                    <div
                        id="scrollableAlerts"
                        ref={scrollableAlertsRef}
                        style={{
                            padding: 24,
                            paddingLeft: 0,
                            width: '100%',
                            overflow: 'scroll',
                            height: '75vh',
                        }}
                    >
                        <InfiniteScroll
                            dataLength={alerts.length}
                            next={fetchAlerts}
                            hasMore={alerts.length < maxAlerts}
                            loader={loading && <Spin size="small" />}
                            endMessage={
                                !loading && (
                                    <Divider plain>
                                        No more alerts
                                    </Divider>
                                )
                            }
                            scrollableTarget="scrollableAlerts"
                        >
                            <List
                                dataSource={alerts}
                                renderItem={(item: IAlert, index: number) => (
                                    <List.Item
                                        key={item._id}
                                        className={cs(
                                            'issue',
                                            { selected: alert ? alert?._id === item?._id : index === 0 },
                                        )}
                                        onClick={() => setAlert(item)}
                                    >
                                        <List.Item.Meta
                                            title={(
                                                <strong>
                                                    {`Alert ${index + 1}`}
                                                </strong>
                                            )}
                                            description={(
                                                <span style={{
                                                    whiteSpace: 'pre-wrap',
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    display: 'inline-block',
                                                    maxWidth: '100%',
                                                    paddingRight: 24,
                                                }}
                                                >
                                                    {item.description.en}
                                                </span>
                                            )}
                                        />
                                        <Text className={cs(item?.type, 'status')}>
                                            {item?.type}
                                        </Text>
                                    </List.Item>
                                )}
                            />
                        </InfiniteScroll>
                    </div>
                )
            }
        </Card>
    );
};

export default AlertsList;
