import { Modal } from 'antd';
import { getBookingRequests } from '../../api/booking';
import { IBookingRequests } from '../../types/bookingRequests';

export const showBookingRequestsModal = async (bookingId: string) => {
    let bookingRequests = {} as IBookingRequests;
    try {
        const { data } = await getBookingRequests(bookingId as string);
        bookingRequests = data.data;
    } catch (error) {
        console.log(error);
    }

    return Modal.info({
        title: 'Booking Requests',
        width: '80%',
        icon: null,
        content: (
            <pre>
                {JSON.stringify(bookingRequests, null, 2)}
            </pre>
        ),
        onOk() {},
    });
};
