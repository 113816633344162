import { useState, useEffect } from 'react';
import {
    Modal, notification, Input, Form,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { disableWorkspace } from '../../../api/workspaces';
import { setDeactivateWorkspace } from '../../../actions/modals';

const { TextArea } = Input;

interface DeactivateWorkspaceProps {
setReload: React.Dispatch<React.SetStateAction<boolean>>
}

interface handleOkProps {
isSuspended: string,
suspendedMessage: string,
}

export const DeactivateWorkspace = ({ setReload }: DeactivateWorkspaceProps) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { deactivateWorkspace } = useSelector((state: any) => state.modals);

    const handleOk = async (values: handleOkProps) => {
        setLoading(true);
        try {
            const { data } = await disableWorkspace(deactivateWorkspace._id, values);
            notification.success({
                message: data.message,
            });
        } catch (error: any) {
            notification.error({ message: error.response?.data?.message });
            console.log(error);
        } finally {
            dispatch(setDeactivateWorkspace(null));
            setReload((state: boolean) => !state);
            setLoading(false);
            form.resetFields();
        }
    };

    useEffect(() => {
        if (!deactivateWorkspace) return;
        form.setFieldsValue({ isSuspended: !deactivateWorkspace?.isSuspended, suspendedMessage: '' });
    }, [deactivateWorkspace]);

    const handleCancel = () => {
        dispatch(setDeactivateWorkspace(null));
    };

    return (
        <Modal
            title={`${deactivateWorkspace?.isSuspended ? 'Activate' : 'Deactivate'} Workspace`}
            visible={deactivateWorkspace}
            onOk={() => form.submit()}
            onCancel={handleCancel}
            confirmLoading={loading}
        >
            <p>
                Are you sure you want to
                {' '}
                {deactivateWorkspace?.isSuspended ? 'activate' : 'deactivate'}
                {' '}
                {deactivateWorkspace?.name}
                {' '}
                workspace?
            </p>
            <Form
                form={form}
                initialValues={{ isSuspended: !deactivateWorkspace?.isSuspended, suspendedMessage: '' }}
                requiredMark={false}
                layout="vertical"
                onFinish={handleOk}
            >
                <Form.Item name="isSuspended" hidden>
                    <input />
                </Form.Item>
                <Form.Item
                    label="Deactivated Message"
                    name="suspendedMessage"
                    hidden={deactivateWorkspace?.isSuspended}
                >
                    <TextArea rows={4} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
