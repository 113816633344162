import { isUndefined, uniq } from 'lodash';

/**
 * Reorder
 */
const reorder = (list:any, startIndex:any, endIndex:any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
   * With new task ids
   */
const withNewProviderIds = (column:any, providerIds:any) => ({
    id: column.id,
    name: column.name,
    providerIds,
});

/**
   * Reorder single drag
   */
const reorderSingleDrag = ({
    entities,
    selectedProviderIds,
    source,
    destination,
}:{
    entities:any,
    selectedProviderIds:any,
    source:any,
    destination:any

  }) => {
    // moving in the same list
    if (source.droppableId === destination.droppableId) {
        const column = entities.columns[source.droppableId];

        const reordered = reorder(column.providerIds, source.index, destination.index);

        const updated = {
            ...entities,
            columns: {
                ...entities.columns,
                [column.id]: withNewProviderIds(column, reordered),
            },
        };

        return {
            entities: updated,
            selectedProviderIds,
        };
    }

    // moving to a new list
    const home = entities.columns[source.droppableId];
    const foreign = entities.columns[destination.droppableId];

    // the id of the task to be moved
    const providerId = home.providerIds[source.index];

    // remove from home column
    const newHomeProviderIds = [...home.providerIds];
    newHomeProviderIds.splice(source.index, 1);

    // add to foreign column
    const newForeignProviderIds = [...foreign.providerIds];
    newForeignProviderIds.splice(destination.index, 0, providerId);

    const updated = {
        ...entities,
        columns: {
            ...entities.columns,
            [home.id]: withNewProviderIds(home, newHomeProviderIds),
            [foreign.id]: withNewProviderIds(foreign, newForeignProviderIds),
        },
    };

    return {
        entities: updated,
        selectedProviderIds,
    };
};

/**
   * Get home column
   */
export const getHomeColumn = (entities:any, providerId:any) => {
    const columnId = entities.columnIds.find((id:any) => {
        const column = entities.columns[id];
        return column.providerIds.includes(providerId);
    });

    return entities.columns[columnId];
};

/**
   * Reorder multi drag
   */
const reorderMultiDrag = ({
    entities,
    selectedProviderIds,
    source,
    destination,
}:{
    entities:any,
    selectedProviderIds:any,
    source:any,
    destination:any

  }) => {
    const start = entities.columns[source.droppableId];
    const dragged = start.providerIds[source.index];

    const insertAtIndex = (() => {
        const destinationIndexOffset = selectedProviderIds.reduce(
            (previous:any, current:any) => {
                if (current === dragged) {
                    return previous;
                }

                const final = entities.columns[destination.droppableId];
                const column = getHomeColumn(entities, current);

                if (column !== final) {
                    return previous;
                }

                const index = column.providerIds.indexOf(current);

                if (index >= destination.index) {
                    return previous;
                }

                // the selected item is before the destination index
                // we need to account for this when inserting into the new location
                return previous + 1;
            },
            0,
        );

        const result = destination.index - destinationIndexOffset;
        return result;
    })();

    // doing the ordering now as we are required to look up columns
    // and know original ordering
    const orderedSelectedProviderIds = [...selectedProviderIds];

    orderedSelectedProviderIds.sort((a, b) => {
        // moving the dragged item to the top of the list
        if (a === dragged) {
            return -1;
        }

        if (b === dragged) {
            return 1;
        }

        // sorting by their natural indexes
        const columnForA = getHomeColumn(entities, a);
        const indexOfA = columnForA.providerIds.indexOf(a);
        const columnForB = getHomeColumn(entities, b);
        const indexOfB = columnForB.providerIds.indexOf(b);

        if (indexOfA !== indexOfB) {
            return indexOfA - indexOfB;
        }

        // sorting by their order in the selectedTaskIds list
        return -1;
    });

    // we need to remove all of the selected tasks from their columns
    const withRemovedProviders = entities.columnIds.reduce((previous:any, columnId:any) => {
        const column = entities.columns[columnId];

        // remove the id's of the items that are selected
        const remainingProviderIds = column.providerIds.filter(
            (id:any) => !selectedProviderIds.includes(id),
        );

        previous[column.id] = withNewProviderIds(column, remainingProviderIds);
        return previous;
    }, entities.columns);

    const final = withRemovedProviders[destination.droppableId];

    const withInserted = (() => {
        const base = [...final.providerIds];
        base.splice(insertAtIndex, 0, ...orderedSelectedProviderIds);
        return base;
    })();

    // insert all selected tasks into final column
    const withAddedProviders = {
        ...withRemovedProviders,
        [final.id]: withNewProviderIds(final, withInserted),
    };

    const updated = {
        ...entities,
        columns: withAddedProviders,
    };

    return {
        entities: updated,
        selectedProviderIds: orderedSelectedProviderIds,
    };
};

/**
   * Mutli drag aware reorder
   */
export const mutliDragAwareReorder = (args:any) => {
    if (args.selectedProviderIds.length > 1) {
        return reorderMultiDrag(args);
    }

    return reorderSingleDrag(args);
};

/**
   * Multi select to
   */
export const multiSelectTo = (entities:any, selectedProviderIds:any, newProviderId:any) => {
    // Nothing already selected
    if (!selectedProviderIds.length) {
        return [newProviderId];
    }

    const columnOfNew = getHomeColumn(entities, newProviderId);
    const indexOfNew = columnOfNew.providerIds.indexOf(newProviderId);

    const lastSelected = selectedProviderIds[selectedProviderIds.length - 1];
    const columnOfLast = getHomeColumn(entities, lastSelected);
    const indexOfLast = columnOfLast.providerIds.indexOf(lastSelected);

    // multi selecting to another column
    // select everything up to the index of the current item
    if (columnOfNew !== columnOfLast) {
        return columnOfNew.providerIds.slice(0, indexOfNew + 1);
    }

    // multi selecting in the same column
    // need to select everything between the last index and the current index inclusive

    // nothing to do here
    if (indexOfNew === indexOfLast) {
        return null;
    }

    const isSelectingForwards = indexOfNew > indexOfLast;
    const start = isSelectingForwards ? indexOfLast : indexOfNew;
    const end = isSelectingForwards ? indexOfNew : indexOfLast;

    const inBetween = columnOfNew.providerIds.slice(start, end + 1);

    // everything inbetween needs to have it's selection toggled.
    // with the exception of the start and end values which will always be selected

    const toAdd = inBetween.filter((providerId :any) => {
        // if already selected: then no need to select it again
        if (selectedProviderIds.includes(providerId)) {
            return false;
        }
        return true;
    });

    const sorted = isSelectingForwards ? toAdd : [...toAdd].reverse();
    const combined = [...selectedProviderIds, ...sorted];

    return combined;
};

export const formatStore = (store: any) => ({
    ...store,
    providers: store.providers.filter((provider: any) => provider.isActive).map((provider: any) => provider.name),
});

export const getCurrentDeliveries = (allDeliveries: any[], initialStores: any[]) => {
    let deliveriesAvailables = initialStores
        .map((store: any) => store.providers)
        .reduce((allDeliveries: string[], deliveriesStore: any) => {
            const deliveries = deliveriesStore.map((delivery: any) => delivery.name);
            return [...allDeliveries, ...deliveries];
        }, []);
    deliveriesAvailables = uniq(deliveriesAvailables);
    const deliveries = allDeliveries.filter((delivery: any) => deliveriesAvailables.includes(delivery.keyName));

    const stores = initialStores.map(formatStore);

    const deliveriesDefault = deliveries.map((delivery: any) => (
        {
            ...delivery, isActive: !deliveries.length, stores: [], allStores: true,
        }
    ));
    const deliveriesWithActive: any[] = [];
    deliveriesDefault.forEach((deliveries: any) => {
        const delivery = { ...deliveries };
        stores.forEach((store: any) => {
            const providerActive = store.providers.includes(delivery.keyName);
            if (!delivery.isActive) {
                delivery.isActive = providerActive;
            }
            if (providerActive) {
                delivery.stores.push(store.companyName);
            }
        });
        delivery.allStores = (delivery.stores.length === 0 || delivery.stores.length === stores.length);
        deliveriesWithActive.push(delivery);
    });
    const deliveriesOrderByCoverage = deliveriesWithActive.sort((a: any, b: any) => b.coverageDistance - a.coverageDistance);
    const visibleDeliveries = deliveriesOrderByCoverage.filter(
        (delivery: any) => !(!(isUndefined(delivery.isVisible) || delivery.isVisible) && !delivery.isActive),
    );
    return visibleDeliveries;
};
