import { Dropdown } from 'antd';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import TableMenu from './TableMenu';
import { IExpressDelivery } from '../../../interfaces/express-delivery.interface';

interface Props {
    expressDelivery: IExpressDelivery;
    setExpressSelected: (expressDelivery: IExpressDelivery) => void;
}

const Actions = ({ expressDelivery, setExpressSelected }: Props) => (
    <Dropdown
        placement="bottomRight"
        trigger={['click']}
        overlay={(
            <TableMenu
                expressDelivery={expressDelivery}
                setExpressSelected={setExpressSelected}
            />
        )}
        // @ts-ignore
        onClick={(e: any) => { e.stopPropagation(); }}
    >
        <BiDotsVerticalRounded size={24} />
    </Dropdown>
);

export default Actions;
