import { IUser } from '../../../interfaces/user.interface';
import { IContext } from './users.interface';

export const initialContext: IContext = {
    loading: false,
    loadingTab: false,
    users: [] as IUser[],
    user: {} as IUser,
    activeTab: '1',
    total: 0,
    paginator: {
        page: 1,
        limit: 10,
    },
    searchParams: {},
    setPaginator: () => {},
    setActiveTab: () => {},
    handlePaginator: () => {},
    fetchUsers: () => {},
    fetchUser: () => {},
    fetchWorkspacesRelated: () => {},
    fetchWorkspaceInvitations: () => {},
};
