import { message } from 'antd';
import { createContext, FC, useState } from 'react';
import { createNewChat, getChatData, getMessages } from '../../../api/issues';
import { EChatType } from '../../../enums/chat.enum';
import { IChat, ICreateChatPayload, IMessage } from '../../../types/chat';
import { IContext } from './chat.interface';
import { initialContext } from './chat.initial';

export const ChatContext = createContext<IContext>(initialContext);

export const ChatProvider: FC = ({ children }) => {
    const [chat, setChat] = useState<IChat>(initialContext.chat);
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [loadingMessages, setLoadingMessages] = useState<boolean>(initialContext.loadingMessages);
    const [messages, setMessages] = useState<IMessage[]>(initialContext.messages);
    const [lastMessage, setLastMessage] = useState<IMessage>(initialContext.lastMessage);
    const [selectedMessage, setSelectedMessage] = useState<IMessage | null>(initialContext.selectedMessage);

    const fetchChat = (externalID: string) => {
        setLoading(true);
        const type = EChatType.ISSUE;
        getChatData(externalID, type)
            .then((response: any) => {
                const { data: { data } } = response;
                setChat(data[0]);
            })
            .catch((error: any) => {
                const content = error?.response?.data?.message;
                message.error({ content });
            })
            .finally(() => setLoading(false));
    };

    const createChat = (payload: ICreateChatPayload) => {
        setLoading(true);
        createNewChat(payload)
            .then(({ data: { data } }: any) => setChat(data))
            .catch((error: any) => {
                const content = error.response.data.message;
                message.error({ content });
            })
            .finally(() => setLoading(false));
    };

    const fetchMessages = (chatID: string, lastMessageID?: string) => {
        setLoadingMessages(true);
        getMessages(chatID, lastMessageID)
            .then(({ data: { data } }: any) => {
                setMessages(data);
                setLastMessage(data[data.length - 1]);
            })
            .catch((error: any) => {
                const message = error?.response?.data?.message;
                message.error({ content: message });
            })
            .finally(() => setLoadingMessages(false));
    };

    const addedMessage = (message: Partial<IMessage>) => {
        setMessages((messages) => [message as IMessage, ...messages]);
    };

    const setReadMessages = (messageIds: string[], readBy: string) => {
        setMessages((messages) => messages.map((message) => {
            if (messageIds.includes(message._id)) {
                message.readBy = [...message.readBy, readBy];
            }
            return message;
        }));
    };

    return (
        <ChatContext.Provider
            value={{
                loading,
                loadingMessages,
                chat,
                messages,
                lastMessage,
                selectedMessage,
                fetchChat,
                createChat,
                setMessages,
                fetchMessages,
                setLastMessage,
                addedMessage,
                setSelectedMessage,
                setReadMessages,
            }}
        >
            {children}
        </ChatContext.Provider>
    );
};
