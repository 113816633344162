import axios from 'axios';
import { OctopusPaginatorQuery } from '../types/paginator';
import { TGetAllAvailableDriver } from '../types/driver';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const OCTOPUS_BASE_URL = process.env.REACT_APP_OCTOPUS_BASE_URL;
const DASHBOARD_BASE_URL = process.env.REACT_APP_DASHBOARD_BASE_URL;

export const getPickerPay = ({ startDate, endDate, pickerID }: any) => {
    const query = {
        startDate,
        endDate,
        pickerID,
    };
    const urlParams = new URLSearchParams(query);
    return axios.get(`${BASE_URL}/admin/getPickerPay?${urlParams.toString()}`);
};

export const getDriverById = (id: string) => (
    axios.get(`${OCTOPUS_BASE_URL}/drivers/${id}`)
);

export const addDriver = (payload: any) => axios.post(`${DASHBOARD_BASE_URL}/admin/addDriver`, payload);

export const editDriver = (payload: any) => (
    axios.post(`${DASHBOARD_BASE_URL}/admin/editDriver`, payload)
);

export const getAllAvailableDriver = ({
    limit,
    skip,
    carType,
    latitude,
    longitude,
    bookingID,
}: TGetAllAvailableDriver) => {
    const query: any = {
        limit,
        skip,
        carType,
        latitude,
        longitude,
        bookingID,
    };

    const urlParams = new URLSearchParams(query);

    return axios.get(`${BASE_URL}/driver/getAllAvailableDriver?${urlParams.toString()}`);
};

export const assignManualRequest = ({
    bookingID,
    userID,
}:{bookingID: string, userID: string}) => axios.put(`${BASE_URL}/booking/assignManualRequest`, { bookingID, userID });

export const findDriver = (paginator: OctopusPaginatorQuery) => {
    const query: any = { ...paginator };
    const urlParams = new URLSearchParams(query);
    return axios.get(`${OCTOPUS_BASE_URL}/drivers?${urlParams.toString()}`);
};

export const editDriverOctopus = (driverId: string, payload: any) => (
    axios.put(`${OCTOPUS_BASE_URL}/drivers/${driverId}/picker`, payload)
);

export const getAssignReasons = () => (
    axios.get(`${BASE_URL}/admin/assignReasons`)
);
