import { Col, Row, Typography } from 'antd';
import CardDataBase from '../../../CardDataBase';

const { Text } = Typography;

interface IContactDetail {
    email?: string;
    name?: string;
    lastName?: string;
    mobile?: string;
    countryCode?: string;
}

const ContactDetail = ({
    email,
    name,
    lastName,
    mobile,
    countryCode,
} : IContactDetail) => {
    const CARD_CONTENT = [
        {
            title: 'Name',
            content: name && lastName ? `${name} ${lastName}` : 'No information',
        },
        {
            title: 'Email',
            content: email || 'No information',
        },
        {
            title: 'Phone number',
            content: countryCode && mobile ? `${countryCode} ${mobile}` : 'No information',
        },

    ];

    return (
        <CardDataBase title="Contact details" style={{ minHeight: 345 }}>
            {
                CARD_CONTENT.map((data) => (
                    <Row align="middle" justify="space-between" style={{ margin: '16px 0' }}>
                        <Col>
                            <Text strong>
                                {data.title}
                                :
                            </Text>
                        </Col>
                        <Col>{data.content}</Col>
                    </Row>
                ))
            }
        </CardDataBase>
    );
};

export default ContactDetail;
