import {
    Card, Divider, List, Typography,
} from 'antd';
import { useEffect, useState } from 'react';

import { getPickerDetails } from '../../api/customer';

const { Text } = Typography;

interface DeliveryRating {
  averageRating: number
  bookingsData: {
    completed: number
    cancelled: number
    cancelledDriver: number
  }
  califications: {
    efficient: number
    fast: number
    inefficient: number
    kind: number
    late: number
    rude: number
  }
}

interface DriverBookingsStatsProps {
  driverId?: string
}

export const DriverBookingsStats = ({ driverId }: DriverBookingsStatsProps) => {
    const [rating, setRating] = useState<DeliveryRating>();

    useEffect(() => {
        if (!driverId) return;
        getPickerDetails(driverId)
            .then(({ data }: any) => {
                setRating(data.data);
            })
            .catch();
    }, [driverId]);

    const AverageRating = rating?.averageRating || 0;
    const completed = rating?.bookingsData?.completed || 0;
    const cancelled = rating?.bookingsData?.cancelled || 0;
    const cancelledDriver = rating?.bookingsData?.cancelledDriver || 0;

    const kind = rating?.califications?.kind || 0;
    const rude = rating?.califications?.rude || 0;
    const fast = rating?.califications?.fast || 0;
    const late = rating?.califications?.late || 0;
    const efficient = rating?.califications?.efficient || 0;
    const inefficient = rating?.califications?.inefficient || 0;

    return (
        <Card style={{ height: '600px' }} title="Rating & Bookings Stats" bordered>
            <List>
                <Text style={{ fontSize: 13 }}>
                    {'Average Rating: '}
                    <Text type="secondary" style={{ fontSize: 12 }}>
                        {AverageRating}
                    </Text>
                </Text>
                <div />
            </List>
            <List>
                <Text style={{ fontSize: 13 }}>
                    {'Bookings Completed: '}
                    <Text type="secondary" style={{ fontSize: 12 }}>
                        {completed}
                    </Text>
                </Text>
                <div />
            </List>
            <List>
                <Text style={{ fontSize: 13 }}>
                    {'Bookings Cancelled: '}
                    <Text type="secondary" style={{ fontSize: 12 }}>
                        {cancelled}
                    </Text>
                </Text>
                <div />
            </List>
            <List>
                <Text style={{ fontSize: 13 }}>
                    {'Bookings Cancelled by Driver: '}
                    <Text type="secondary" style={{ fontSize: 12 }}>
                        {cancelledDriver}
                    </Text>
                </Text>
                <div />
            </List>
            <Divider />
            <List>
                <List.Item>
                    <List.Item.Meta
                        title={(
                            <Text style={{ fontSize: 13 }}>
                                {'Kind: '}
                                <Text type="secondary" style={{ fontSize: 12 }}>
                                    {kind}
                                </Text>
                            </Text>
                        )}
                    />
                    <Text style={{ fontSize: 13 }}>
                        {'Rude: '}
                        <Text type="secondary" style={{ fontSize: 12 }}>
                            {rude}
                        </Text>
                    </Text>
                </List.Item>
                <div />
            </List>
            <List>
                <List.Item>
                    <List.Item.Meta
                        title={(
                            <Text style={{ fontSize: 13 }}>
                                {'Fast: '}
                                <Text type="secondary" style={{ fontSize: 12 }}>
                                    {fast}
                                </Text>
                            </Text>
                        )}
                    />
                    <Text style={{ fontSize: 13 }}>
                        {'Late: '}
                        <Text type="secondary" style={{ fontSize: 12 }}>
                            {late}
                        </Text>
                    </Text>
                </List.Item>
                <div />
            </List>
            <List>
                <List.Item>
                    <List.Item.Meta
                        title={(
                            <Text style={{ fontSize: 13 }}>
                                {'Efficient: '}
                                <Text type="secondary" style={{ fontSize: 12 }}>
                                    {efficient}
                                </Text>
                            </Text>
                        )}
                    />
                    <Text style={{ fontSize: 13 }}>
                        {'Inefficient: '}
                        <Text type="secondary" style={{ fontSize: 12 }}>
                            {inefficient}
                        </Text>
                    </Text>
                </List.Item>
                <div />
            </List>
        </Card>
    );
};
