import moment from 'moment';
import {
    Button, Divider, Drawer, Space, Spin, Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { IoIosCloseCircle } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { MdOpenInNew } from 'react-icons/md';
import { getOneWorkspace } from '../../api/workspaces';
import { IWorkspace } from '../../types/workspaces';
import ItemData from '../../widgets/ItemData';

interface Props {
    workspaceID: string,
    open: boolean;
    onClose: () => void;
}

const { Title, Text } = Typography;

const WorkspaceDrawer = ({
    workspaceID, open, onClose,
}: Props) => {
    const navigate = useNavigate();

    const [workspace, setWorkspace] = useState<IWorkspace | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [showMasterKey, setShowMasterKey] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const general = [
        {
            label: 'Created at',
            value: moment(workspace?.createdAt).format('DD MMMM, YY'),
        },
        {
            label: 'Workspace Name',
            value: workspace?.name,
        },
        {
            label: 'Workspace Unique ID',
            value: workspace?.workspaceUniqueID,
        },
        {
            label: 'Owner Email',
            value: workspace?.ownerEmail,
        },
        {
            label: 'Country',
            value: workspace?.country,
        },
        {
            label: 'Currency',
            value: workspace?.currency,
        },
        {
            label: 'Master Key',
            value: (
                <Space size={4}>
                    {showMasterKey ? workspace?.masterKey : `********${workspace?.masterKey.slice(-4)}`}
                    <Button
                        icon={
                            !showMasterKey ? (
                                <FaRegEye
                                    style={{ verticalAlign: 'middle' }}
                                    size={16}
                                />
                            ) : (
                                <FaRegEyeSlash
                                    style={{ verticalAlign: 'middle' }}
                                    size={16}
                                />
                            )
                        }
                        type="link"
                        onClick={() => setShowMasterKey(!showMasterKey)}
                        style={{ padding: 0 }}
                        size="small"
                    />
                </Space>
            ),
        },
    ];

    const payment = [
        {
            label: 'Payment Method',
            value: workspace?.payment?.paymentMethod,
        },
        {
            label: 'Payment Gateway',
            value: workspace?.payment?.paymentGateway || '-',
        },
    ];

    const proofOfDelivery = [
        {
            label: 'Require Proof Of Delivery',
            value: workspace?.requiresProofOfDelivery ? 'Yes' : 'No',
        },
        {
            label: 'Proof Of Delivery Method',
            value: workspace?.proofOfDelivery,
        },
    ];

    const fetchWorkspace = () => {
        setLoading(true);

        getOneWorkspace(workspaceID)
            .then(({ data: { data } }: any) => setWorkspace(data))
            .catch((err) => {
                const message = err?.response?.data?.message || 'An error occurred';
                setError(message);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!open && !workspaceID) return;
        fetchWorkspace();
    }, [workspaceID]);

    return (
        <Drawer
            open={open}
            onClose={onClose}
            closable={false}
            placement="right"
            width={650}
            title={`Workspace: ${workspace?.workspaceUniqueID || '000000'}`}
            extra={[
                <Button
                    type="link"
                    icon={(
                        <MdOpenInNew
                            style={{ verticalAlign: 'middle' }}
                            size={20}
                        />
                    )}
                    onClick={() => navigate(`/v2/workspaces/${workspaceID}`)}
                />,
                <Button
                    type="link"
                    icon={(
                        <IoIosCloseCircle
                            style={{ verticalAlign: 'middle' }}
                            size={20}
                        />
                    )}
                    onClick={() => onClose()}
                />,
            ]}
        >
            {
                error ? (
                    <Text type="danger">{error}</Text>
                ) : loading ? (
                    <Text type="secondary">
                        <Spin size="small" />
                        {' '}
                        Loading...
                    </Text>
                ) : (
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <Title level={4}>
                            General Information
                        </Title>

                        {
                            general.map((item, index) => (
                                <ItemData
                                    label={item.label}
                                    value={item.value}
                                    key={index}
                                />
                            ))
                        }

                        <Divider />

                        <Title level={4}>
                            Payment
                        </Title>

                        {
                            payment.map((item, index) => (
                                <ItemData
                                    label={item.label}
                                    value={item.value}
                                    key={index}
                                />
                            ))
                        }

                        <Divider />
                        <Title level={4}>
                            Proof of Delivery
                        </Title>

                        {
                            proofOfDelivery.map((item, index) => (
                                <ItemData
                                    label={item.label}
                                    value={item.value}
                                    key={index}
                                />
                            ))
                        }

                        <Divider />
                    </Space>
                )
            }
        </Drawer>

    );
};

export default WorkspaceDrawer;
