import { Dispatch, SetStateAction, useState } from 'react';
import { Modal, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { setDeleteWorkspace } from '../../../actions/modals';
import { updateWorkspaceStatus } from '../../../api/workspaces';

interface RestoreWorkspaceProps {
setReload: Dispatch<SetStateAction<boolean>>
}

export const RestoreWorkspace = ({ setReload }: RestoreWorkspaceProps) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { removeWorkspace } = useSelector((state: any) => state.modals);

    const handleOk = () => {
        setLoading(true);
        updateWorkspaceStatus(removeWorkspace._id, !removeWorkspace.isDeleted)
            .then(({ data }) => notification.success({
                message: data.message,
            }))
            .catch((err) => {
                notification.error({ message: err.response?.data?.message });
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
                dispatch(setDeleteWorkspace(null));
                setReload((state: boolean) => !state);
            });
    };
    const handleCancel = () => {
        dispatch(setDeleteWorkspace(null));
    };

    return (
        <Modal
            title={`${removeWorkspace?.isDeleted ? 'Restore' : 'Deactivate'} Workspace`}
            visible={removeWorkspace}
            onOk={handleOk}
            onCancel={handleCancel}
            confirmLoading={loading}
        >
            <p>{`Are you sure you want to ${removeWorkspace?.isDeleted ? 'restore' : 'delete'} this Workspace?`}</p>
            <p>{removeWorkspace?.name}</p>
        </Modal>
    );
};
