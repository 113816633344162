import { Select } from 'antd';
import { useContext } from 'react';
import { CommandCenterContext } from '../../context/commandCenterContext';
import { internalDeliveryProvider } from '../../utils/deliveryProviders';

type SelectFilterT = {
    style?: {
        [x: string]: string;
    };
}

const { Option } = Select;

const DeliveryProviderSelect = ({
    style,
}: SelectFilterT) => {
    const { updateDriversFilter } = useContext(CommandCenterContext);

    const handleChange = (
        value: ['PICKER' | 'PICKER_BASE' | 'SMALL_FLEETS' | 'MY_FLEET' | ''],
    ) => {
        updateDriversFilter({
            deliveryProvider: value,
        });
    };

    const children = internalDeliveryProvider.map((dp, index) => (
        <Option value={dp.value} key={index}>{dp.text}</Option>
    ));

    return (
        <Select
            mode="multiple"
            style={style}
            placeholder="Delivery Providers"
            onChange={handleChange}
            maxTagCount="responsive"
        >
            {children}
        </Select>
    );
};

export default DeliveryProviderSelect;
