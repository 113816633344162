import { Form, Upload } from 'antd';
// @ts-ignore
import csvToObj from 'csv-to-js-parser';
import { BsInbox } from 'react-icons/bs';
import { dummyRequest } from '../../../utils';

interface UploadInputProps {
  handleFileUpload: (data: any) => void
}

const UploadInput = ({ handleFileUpload }: UploadInputProps) => {
    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    const handleFile = (info: any) => {
        if (info.file.status !== 'done') return;
        const fileReader = new FileReader();
        fileReader.onload = (event: any) => {
            const csvOutput = event.target.result;
            const columns = {
                Workspace: { type: 'string', group: 1 },
                Business: { type: 'string', group: 1 },
                Booking: { type: 'string', group: 1 },
                Date: { type: 'string', group: 1 },
                Description: { type: 'string', group: 1 },
                Amount: { type: 'string', group: 1 },
            };
            const obj = csvToObj.csvToObj(csvOutput, ',', columns);
            handleFileUpload(obj);
        };

        fileReader.readAsText(info.file.originFileObj);
    };

    return (
        <div>
            <Form layout="vertical">
                <Form.Item label="CSV File">
                    <Form.Item name="storeList" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                        {/* @ts-ignore */}
                        <Upload.Dragger name="files" customRequest={dummyRequest} onChange={handleFile}>
                            <p className="ant-upload-drag-icon">
                                <BsInbox size={30} />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>
            </Form>
        </div>
    );
};

export default UploadInput;
