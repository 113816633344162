import {
    createContext, FC, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { deleteConcept, getConcepts } from '../../../api/payments';
import { IConcept } from '../../../interfaces/plan';

interface IConceptContext {
    concepts: any[],
    conceptsLoading: boolean,
    fetchConcepts: () => void,
    addConcept: (concept: any) => void,
    removeConcept: (planId: string, conceptId: string) => void,
}

const initialContext = {
    concepts: [],
    conceptsLoading: false,
    variablesLoading: false,
    fetchConcepts: () => {},
    addConcept: (concept: any) => {},
    removeConcept: (planId: string, conceptId: string) => {},
};

export const ConceptContext = createContext<IConceptContext>(initialContext);

export const ConceptProvider: FC = ({ children }) => {
    const [conceptsLoading, setConceptsLoading] = useState<boolean>(initialContext.conceptsLoading);
    const [concepts, setConcepts] = useState<IConcept[]>(initialContext.concepts);
    const { id } = useParams();

    const fetchConcepts = () => {
        if (!id) return;
        setConceptsLoading(true);
        getConcepts(id)
            .then(({ data }) => {
                setConcepts(data.data.data);
            })
            .catch()
            .finally(() => setConceptsLoading(false));
    };

    const addConcept = (concept: any) => {
        const exists = concepts.find((c: any) => c._id === concept._id);
        if (exists) {
            setConcepts((concepts: any) => concepts.map((c: any) => {
                if (c._id === concept._id) return concept;
                return c;
            }));
        } else {
            setConcepts((concepts: any) => [...concepts, concept]);
        }
    };

    const removeConcept = async (planId: string, conceptId: string) => {
        setConceptsLoading(true);
        deleteConcept(planId, conceptId)
            .then(() => {
                setConcepts((concepts: any) => concepts.filter((c: any) => c._id !== conceptId));
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => setConceptsLoading(false));
    };

    return (
        <ConceptContext.Provider
            value={{
                concepts,
                conceptsLoading,
                fetchConcepts,
                addConcept,
                removeConcept,
            }}
        >
            {children}
        </ConceptContext.Provider>
    );
};
