import {
    Modal, Form, Input, Select, notification,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../actions/modals';
import { STATUSES_ISSUE } from '../../../const/issue';
import { ModalTypes } from '../../../types/modals';
import { updateIssue } from '../../../api/issues';
import { ShieldContext } from '../../Shield/context/shieldContext';
import { Responsable } from '../../../types/responsable';
import { getAllAdmin } from '../../../api/admin';

interface Props {
    onSuccess?: () => void
}

const { Item } = Form;
const { Option } = Select;

export const UpdateIssueModal = ({ onSuccess }: Props) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [responsableId, setResponsableId] = useState<string>();
    const [responsables, setResponsables] = useState<Responsable[]>([]);

    const requiredRule = { required: true, message: 'Required' };

    const { activeModal, modalData } = useSelector((state: any) => state.modals);
    const isOpen = activeModal === ModalTypes.UpdateIssue;
    const options = Object.values(STATUSES_ISSUE).map((status: any) => ({ value: status }));
    const { fetchIssues } = useContext(ShieldContext);

    const dispatch = useDispatch();

    const handleCancel = () => dispatch(closeModal());

    const onChangeResponsable = (responsableId: string) => {
        setResponsableId(responsableId);
        const findResponsable = responsables.find((responsable: Responsable) => responsable._id === responsableId);
        form.setFieldsValue({
            responsableID: findResponsable?._id,
            responsableName: findResponsable?.name,
        });
    };

    const fetchGetAdmin = () => getAllAdmin()
        .then(({ data: { data } }) => {
            setResponsables(data.users);
            modalData?.responsableID && onChangeResponsable(modalData?.responsableID);
        })
        .catch((error) => console.log(error));

    const optionsResponsable = responsables?.map((responsable: Responsable) => (
        <Option key={responsable._id} value={responsable._id}>
            {responsable.name}
        </Option>
    ));

    useEffect(() => {
        fetchGetAdmin();
    }, []);

    useEffect(() => {
        if (isOpen) {
            form.setFieldsValue({
                responsableID: modalData?.responsableID,
                responsableName: modalData?.responsableName,
                status: modalData?.status,
                description: modalData?.description,
            });
        }
    }, [isOpen]);

    const onFinish = (values: any) => {
        setLoading(true);

        const payload = {
            ...values,
        };

        const issueID = modalData?._id;

        updateIssue(issueID, payload)
            .then(() => {
                notification.success({
                    message: 'Issue updated successfully!',
                });
                onSuccess && onSuccess();
                dispatch(closeModal());
                fetchIssues();
            })
            .catch((error: any) => {
                const description = error?.response?.data?.message || 'Error';
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    return (
        <Modal
            title="Update Issue"
            visible={isOpen}
            onCancel={handleCancel}
            onOk={() => form.submit()}
            bodyStyle={{ padding: '32px 24px' }}
            confirmLoading={loading}
        >
            <Form
                requiredMark={false}
                layout="vertical"
                form={form}
                onFinish={onFinish}
            >
                <Item name="description" hidden>
                    <Input />
                </Item>

                <Item name="responsableID" hidden>
                    <Input />
                </Item>

                <Item name="responsableName" label="Responsable Name">
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder="Responsable Name"
                        optionFilterProp="children"
                        onChange={onChangeResponsable}
                        filterOption={(input, option) => (option!.children as unknown as string || '').toLowerCase().startsWith(input)}
                        allowClear
                    >
                        {optionsResponsable}
                    </Select>
                </Item>

                <Item name="status" label="Status" rules={[requiredRule]}>
                    <Select style={{ width: '100%' }} options={options} />
                </Item>
            </Form>
        </Modal>
    );
};
