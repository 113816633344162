export const validateTypeEvent = (type: string) => {
    const warningEvents: string[] = ['RESTART_SEARCH', 'MANUAL_HACK', 'MONITOR_ETA'];
    const alertEvents: string[] = ['PICKER_INSIGHT'];

    if (warningEvents.includes(type)) {
        return 'RESOLVING';
    }

    if (alertEvents.includes(type)) {
        return 'FAILED';
    }
};
