import { MdRestartAlt } from 'react-icons/md';

interface Props {
    resetPolygon: () => void;
}

const CoverageActions = ({ resetPolygon }: Props) => (
    <div
        style={{
            position: 'absolute',
            top: 40,
            left: 6,
            padding: 4,
            zIndex: 999,
            boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px',
            backgroundColor: '#FFF',
        }}
    >
        <MdRestartAlt
            size={24}
            style={{
                cursor: 'pointer',
                verticalAlign: 'middle',
                color: '#000',
            }}
            onClick={resetPolygon}
        />
    </div>
);

export default CoverageActions;
