import EditFareSettingsModal from './EditFareSettingsModal';
import EditPriceBreakUpModal from './EditPriceBreakUpModal';
import { ReportIssue } from './ReportIssue/ReportIssue';
import ManualRequest from './ManualRequest';
import { EditDeliveryProviderModal } from './DeliveryProviderModal/EditDeliveryProviderModal';
import { EditDriverModal } from './EditDriver/editDrivers';
import { UpdateIssueModal } from './UpdateIssue';
import EditMaxDistanceModal from './EditMaxDistanceModal';

export const Modals = () => (
    <>
        <EditPriceBreakUpModal />
        <EditFareSettingsModal />
        <EditDeliveryProviderModal />
        <ReportIssue />
        <ManualRequest />
        <EditDriverModal />
        <UpdateIssueModal />
        <EditMaxDistanceModal />
    </>
);
