import { PageHeader } from 'antd';
import { Issues } from '../components/Shield';
import { ChatProvider } from '../components/Shield/context/chatContext';
import { ShieldProvider } from '../components/Shield/context/shieldContext';

const Shield = () => (
    <div className="screen__issues">
        <PageHeader title="Booking Issues" style={{ padding: '32px 24px 24px 0' }} />
        <ShieldProvider>
            <ChatProvider>
                <Issues />
            </ChatProvider>
        </ShieldProvider>
    </div>
);

export default Shield;
