import { types } from '../types/types';

interface ActionProps {
  type: string;
  payload: any;
}

export const authReducer = (state = {}, action: ActionProps) => {
    switch (action.type) {
    case types.login:
        return {
            ...state,
            ...action.payload,
        };
    default:
        return state;
    }
};
