/* eslint-disable no-unused-vars */
import {
    createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import thunk from 'redux-thunk';
import { deliveriesReducer } from '../reducers/deliveriesReducer';
import { planReducer } from '../reducers/planReducer';
import { modalsReducer } from '../reducers/modalsReducer';

import { rideReducer } from '../reducers/rideReducer';
import { uiReducer } from '../reducers/uiReducer';
import { authReducer } from '../reducers/loginReducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const reducers = combineReducers({
    modals: modalsReducer,
    ride: rideReducer,
    ui: uiReducer,
    deliveries: deliveriesReducer,
    plans: planReducer,
    auth: authReducer,
});

export const store = createStore(
    reducers,
    composeEnhancers(
        applyMiddleware(thunk),
    ),
);
