import { useState } from 'react';
import { Drawer, Layout as LayoutAntd } from 'antd';

import { useLocation, useNavigate } from 'react-router-dom';
import { SideMenu } from './SideMenu';
import { Modals } from '../Modals';
import { SocketClientProvider } from '../../context/SocketClientContext';
import InvalidSession from '../InvalidSession';
import Header from './Header';
import { ActivityNotification } from './ActivityNotification';
import { SocketsProvider } from '../../context/SocketContext';

const { Content } = LayoutAntd;

export const Layout = ({ children }: any) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const openMenu = () => setIsMenuOpen(true);
    const closeMenu = () => setIsMenuOpen(false);
    const token = localStorage.getItem('token');
    const path = useLocation().pathname;
    const navigate = useNavigate();

    if (!token && !path.includes('login')) return <InvalidSession />;

    return (
        <SocketClientProvider>
            <SocketsProvider>
                <LayoutAntd style={{ minHeight: '100vh' }}>
                    <Drawer
                        placement="left"
                        bodyStyle={{ padding: 0 }}
                        closable={false}
                        width={250}
                        onClose={closeMenu}
                        visible={isMenuOpen}
                    >
                        <SideMenu closeMenu={closeMenu} isMenuOpen={isMenuOpen} />
                    </Drawer>
                    <ActivityNotification />
                    <LayoutAntd className="site-layout">
                        {token && (
                            <Header
                                openMenu={openMenu}
                                isMenuOpen={isMenuOpen}
                                goHome={() => navigate('/v2')}
                            />
                        )}
                        <Content style={{ padding: '0 20px' }}>
                            <Modals />
                            {children}
                        </Content>
                    </LayoutAntd>
                </LayoutAntd>
            </SocketsProvider>
        </SocketClientProvider>
    );
};
