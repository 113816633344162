import { types } from '../types/types';

interface ActionProps {
  type: string;
  payload: any;
}

export const deliveriesReducer = (state = {}, action: ActionProps) => {
    switch (action.type) {
    case types.deliveriesStartLoading:
        return {
            ...state,
            loading: true,
        };

    case types.deliveriesFinishLoading:
        return {
            ...state,
            loading: false,
        };

    case types.deliveriesLoad:
        return {
            ...state,
            deliveries: [...action.payload],
        };

    default:
        return state;
    }
};
