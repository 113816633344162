import {
    Button, Form, Input, Modal, notification,
} from 'antd';
import { useState, useContext } from 'react';
import { createIssue } from '../../../api/issues';
import { ShieldContext } from '../../Shield/context/shieldContext';

export const CreateIssueModal = ({ isModalVisible, setIsModalVisible }: any) => {
    const [newIssueForm] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const { addIssue } = useContext(ShieldContext);

    const handleModalCancel = () => {
        setIsModalVisible(false);
        newIssueForm.resetFields();
    };

    const handleCreateIssue = (values: any) => {
        setLoading(true);
        createIssue(values)
            .then((data) => {
                notification.success({
                    message: 'Issue Created',
                });
                addIssue(data.data.data.issue);
            })
            .catch((error: any) => {
                const description = error?.response?.data?.message || error.message;
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(
                () => {
                    setLoading(false);
                    handleModalCancel();
                },
            );
    };

    return (
        <Modal
            title="Create New Issue"
            open={isModalVisible}
            onCancel={handleModalCancel}
            footer={[
                <Button key="cancel" onClick={handleModalCancel}>
                    Cancel
                </Button>,
                <Button key="create" type="primary" loading={loading} onClick={() => newIssueForm.submit()}>
                    Create Issue
                </Button>,
            ]}
        >
            <Form form={newIssueForm} onFinish={handleCreateIssue} layout="vertical">
                <Form.Item
                    label="Booking Numeric ID"
                    name="bookingNumericId"
                    rules={[
                        { required: true, message: 'Please enter the Booking Numeric ID' },
                    ]}
                >
                    <Input type="number" />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        { required: true, message: 'Please enter a description' },
                    ]}
                >
                    <Input.TextArea rows={4} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
