import { useSearchParams } from 'react-router-dom';
import { Certifications } from '../components/WhatsappCertifications';

const CertificationsScreen = () => {
    const [searchParams] = useSearchParams();
    return (
        <Certifications params={searchParams} />
    );
};

export default CertificationsScreen;
