import { AutoComplete, Input } from 'antd';
import { useContext, useMemo } from 'react';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete';
import { IoLocationSharp } from 'react-icons/io5';
import cs from 'classnames';
import { CommandCenterContext } from '../../context/commandCenterContext';

const { Search } = Input;

const SearchLocation = () => {
    const { updateLocation } = useContext(CommandCenterContext);

    const {
        suggestions: { data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        debounce: 300,
    });

    const handleSearch = (newValue: string) => {
        setValue(newValue);
    };

    const handleSelect = (description: string) => {
        setValue(description, false);
        clearSuggestions();

        getGeocode({ address: description }).then((results) => {
            const { lat, lng } = getLatLng(results[0]);
            updateLocation({
                center: {
                    lat,
                    lng,
                },
                zoom: 8,
            });
        });
    };

    const searchResult = useMemo(() => data.map((place) => ({
        value: place.description,
        label: (
            <div className={cs('flex-row start-row-center')}>
                <IoLocationSharp style={{ marginRight: '8px' }} scale={10} />
                {place.description}
            </div>
        ),
    })), [data]);

    return (
        <AutoComplete
            options={searchResult}
            onSelect={handleSelect}
            style={{ width: '100%' }}
            defaultActiveFirstOption
            onSearch={handleSearch}
        >
            <Search placeholder="Search Location..." enterButton />
        </AutoComplete>
    );
};
export default SearchLocation;
