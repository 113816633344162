import axios from 'axios';
import { OCTOPUS_BASE_URL } from '.';
import { OctopusPaginatorQuery } from '../types/paginator';

export const SEARCH_ENGINE_URL = process.env.REACT_APP_SEARCH_ENGINE_URL;
export const DRIVER_SEARCH_URL = process.env.REACT_APP_DRIVER_SEARCH_URL;
export const PROVIDER_CONFIGURATION_SERVICE_URL = process.env.REACT_APP_BASE_PROVIDER_CONFIGURATION_SERVICE_URL;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const DASHBOARD_BASE_URL = process.env.REACT_APP_DASHBOARD_BASE_URL;

export const getDeliveryProviders = () => {
    const url = `${PROVIDER_CONFIGURATION_SERVICE_URL}/provider-configuration/getAllActiveProviders`;
    return axios.get(url);
};

export const getDeliveryProvidersByCity = (payload : { city: string, country: string}) => {
    const url = `${PROVIDER_CONFIGURATION_SERVICE_URL}/provider-configuration/getActiveProviderInformationByCity`;
    return axios.post(url, payload);
};

export const getSmallFleets = (limit = 10) => axios.get(`${DASHBOARD_BASE_URL}/admin/getSmallFleet?limit=${limit}`);

export const endSearch = (id: string, payload: any) => axios.post(`${BASE_URL}/admin/endSearch`, {
    bookingID: id,
    ...payload,
});

export const getResetDeliveryProviders = (data: any) => axios.post(`${BASE_URL}/admin/restartSearch`, data);

export const commentBooking = (data: any) => axios.post(`${BASE_URL}/admin/commentBooking`, data);

export const getUpdateStatus = (data: any) => axios.put(`${BASE_URL}/driver/updateBookingStatus`, data);

export const getAllUsers = (paginator: any, isAdminVerified: boolean = true) => {
    const urlParams = new URLSearchParams({
        role: 'driver',
        limit: paginator.limit,
        skip: paginator.skip,
        isAdminVerified: isAdminVerified.toString(),
        isDeleted: 'false',
        isBlocked: 'false',
    });
    return axios.get(`${BASE_URL}/admin/getAllUsers?${urlParams.toString()}`);
};

export const getAllAvailableDriver = (booking: any, paginator: any) => {
    const urlParams = new URLSearchParams({
        latitude: booking?.pickupCoordinates?.coordinates[1],
        longitude: booking?.pickupCoordinates?.coordinates[0],
        bookingID: booking._id,
        limit: paginator.limit,
        skip: paginator.skip,
        carType: '1',
    });
    return axios.get(`${BASE_URL}/driver/getAllAvailableDriver?${urlParams.toString()}`);
};

interface IAssignBookingManually {
  userID: string;
  bookingID: string;
  manualAssignReason: string;
  assignConfirm: boolean;
}

export const assignBookingManually = (payload: IAssignBookingManually) => axios.put(`${BASE_URL}/booking/assignBookingManually`, {
    role: 'driver',
    ...payload,
});

export const allProviders = (paginator: OctopusPaginatorQuery, search : string) => {
    const query: any = {
        ...paginator,
    };
    if (search) {
        query.search = search;
    }
    const urlParams = new URLSearchParams(query);

    return axios.get(`${OCTOPUS_BASE_URL}/providers-configuration?${urlParams.toString()}`);
};

export const disableDeliveryProvider = (deliveryProviderID: string, payload: any) => (
    axios.put(`${OCTOPUS_BASE_URL}/providers-configuration/${deliveryProviderID}`, payload)
);

export const editTier = (deliveryProviderID: string, payload: any) => (
    axios.put(`${OCTOPUS_BASE_URL}/providers-configuration/${deliveryProviderID}`, payload)
);

export const editProviderFareSettings = (providersID: string, payload: any) => (
    axios.put(
        `${OCTOPUS_BASE_URL}/providers-configuration/${providersID}/fare-settings`,
        { ...payload },
    )
);

export const editDeliveryProvider = (deliveryProviderID: string, payload: any) => (
    axios.put(`${OCTOPUS_BASE_URL}/providers-configuration/${deliveryProviderID}`, payload)
);

export const getCoverage = (provider: string) => (
    axios.get(`${OCTOPUS_BASE_URL}/coverage/${provider}`)
);

export const addCoverage = (provider: string, polygon: number[][]) => (
    axios.post(`${OCTOPUS_BASE_URL}/coverage`, { keyName: provider, polygon })
);

export const deleteCoverage = (id: string) => (
    axios.delete(`${OCTOPUS_BASE_URL}/coverage/${id}`)
);

export const updateCoverage = (id: string, payload: any) => (
    axios.patch(`${OCTOPUS_BASE_URL}/coverage/${id}`, payload)
);

export const getProvidersByCoordinates = (coordinates: string[]) => (
    axios.get(`${OCTOPUS_BASE_URL}/coverage?coverage=[${coordinates.join(',')}]`)
);
