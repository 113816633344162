import {
    Avatar, Button, Card, Col, Divider, List, PageHeader, Row, Space, Table, Tag, Tooltip, Typography,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';

import { getUserBooking } from '../api/booking';
import { getDriverFromSmallFleet } from '../api/microFleet';
import { UpdateDriverModal } from '../components/Modals';
import { getStatusChipClass } from '../utils/bookings';

const { Text } = Typography;

export const FleetDetails = () => {
    const [showUpdateDriverModal, setShowUpdateDriverModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fleet, setFleet] = useState<any>();
    const [bookings, setBookings] = useState<any>();
    const [paginator, setPaginator] = useState({ limit: 10, skip: 0 });
    const [total, setTotal] = useState(0);

    const { id, smallFleetId } = useParams();
    const navigate = useNavigate();

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({ limit: pageSize, skip: pageSize * (page - 1) });
    };

    const worksBalance = fleet?.driverID.workBalance;
    const fullMobile = fleet?.fullMobile;
    const earnings = fleet?.driverID?.earnings;
    const wallet = fleet?.driverID?.wallet;
    const vehicleType = fleet?.driverID?.vehicleType;
    const vehicleYear = fleet?.driverID?.vehicleYear;
    const vehicleColor = fleet?.driverID?.vehicleColor;
    const MCNumber = fleet?.driverID?.MCNumber;

    useEffect(() => {
        if (!fleet) return;
        setLoading(true);
        getUserBooking(paginator, 'driver', fleet?.driverID._id)
            .then(({ data }) => {
                setBookings(data.data.bookingData);
                setTotal(data.data.dataCount);
            })
            .catch()
            .finally(() => setLoading(false));
    }, [paginator, fleet]);

    useEffect(() => {
        getDriverFromSmallFleet({ id, smallFleetId })
            .then(({ data }) => {
                setFleet(data.body);
            })
            .catch((err) => console.log(err));
    }, [showUpdateDriverModal]);

    const column = [
        {
            title: 'Service ID',
            dataIndex: 'bookingNumericId',
            key: 'bookingNumericId',
        },
        {
            title: 'Service Date/time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string) => (
                <div style={{ fontSize: 12, textAlign: 'center' }}>
                    <div>
                        {moment(createdAt).format('DD/MM/YY')}
                    </div>
                    <div>
                        {moment(createdAt).format('HH:mm')}
                    </div>
                </div>
            ),
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
        },
        {
            title: 'Amount Accepted',
            dataIndex: 'orderAmount',
            key: 'orderAmount',
            render: (amount: number) => `$${amount.toFixed(2)}`,
        },
        {
            title: 'Delivery Fee',
            dataIndex: 'ServiceAmount',
            key: 'ServiceAmount',
            render: (amount: number) => `$${amount.toFixed(2)}`,
        },
        {
            title: 'Status',
            dataIndex: 'statusText',
            key: 'statusText',
            render: (status: string) => (
                <Tag {...getStatusChipClass(status)}>{status}</Tag>
            ),
        },
    ];

    return (
        <div className="site-card-wrapper">
            <PageHeader title="Picker Details" onBack={() => navigate(-1)} />
            <Card bordered={false}>
                <Row gutter={[16, 24]}>
                    <Col span={24} lg={8}>
                        <Card style={{ height: '600px' }} title="Rating & Bookings Stats" bordered>
                            <List>
                                <Space direction="vertical">
                                    <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                        <Text style={{ fontSize: 13 }}>
                                            {'Average Rating: '}
                                            <Text type="secondary" style={{ fontSize: 12 }}>
                                                N/A
                                            </Text>
                                        </Text>
                                    </Space>
                                </Space>
                                <div />
                            </List>
                            <List>
                                <Space direction="vertical">
                                    <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                        <Text style={{ fontSize: 13 }}>
                                            {'Bookings Completed: '}
                                            <Text type="secondary" style={{ fontSize: 12 }}>
                                                N/A
                                            </Text>
                                        </Text>
                                    </Space>
                                </Space>
                                <div />
                            </List>
                            <List>
                                <Space direction="vertical">
                                    <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                        <Text style={{ fontSize: 13 }}>
                                            {'Bookings Cancelled: '}
                                            <Text type="secondary" style={{ fontSize: 12 }}>
                                                N/A
                                            </Text>
                                        </Text>
                                    </Space>
                                </Space>
                                <div />
                            </List>
                            <List>
                                <Space direction="vertical">
                                    <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                        <Text style={{ fontSize: 13 }}>
                                            {'Bookings Cancelled by Driver: '}
                                            <Text type="secondary" style={{ fontSize: 12 }}>
                                                N/A
                                            </Text>
                                        </Text>
                                    </Space>
                                </Space>
                                <div />
                            </List>
                            <Divider />
                            <List>
                                <List.Item>
                                    <List.Item.Meta
                                        title={(
                                            <Text style={{ fontSize: 13 }}>
                                                {'Kind: '}
                                                <Text type="secondary" style={{ fontSize: 12 }}>
                                                    N/A
                                                </Text>
                                            </Text>
                                        )}
                                    />
                                    <Space>
                                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                            <Text style={{ fontSize: 13 }}>
                                                {'Rude: '}
                                                <Text type="secondary" style={{ fontSize: 12 }}>
                                                    N/A
                                                </Text>
                                            </Text>
                                        </Space>
                                    </Space>
                                </List.Item>
                                <div />
                            </List>
                            <List>
                                <List.Item>
                                    <List.Item.Meta
                                        title={(
                                            <Text style={{ fontSize: 13 }}>
                                                {'Fast: '}
                                                <Text type="secondary" style={{ fontSize: 12 }}>
                                                    N/A
                                                </Text>
                                            </Text>
                                        )}
                                    />
                                    <Space>
                                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                            <Text style={{ fontSize: 13 }}>
                                                {'Late: '}
                                                <Text type="secondary" style={{ fontSize: 12 }}>
                                                    N/A
                                                </Text>
                                            </Text>
                                        </Space>
                                    </Space>
                                </List.Item>
                                <div />
                            </List>
                            <List>
                                <List.Item>
                                    <List.Item.Meta
                                        title={(
                                            <Text style={{ fontSize: 13 }}>
                                                {'Efficient: '}
                                                <Text type="secondary" style={{ fontSize: 12 }}>
                                                    N/A
                                                </Text>
                                            </Text>
                                        )}
                                    />
                                    <Space>
                                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                            <Text style={{ fontSize: 13 }}>
                                                {'Inefficient: '}
                                                <Text type="secondary" style={{ fontSize: 12 }}>
                                                    N/A
                                                </Text>
                                            </Text>
                                        </Space>
                                    </Space>
                                </List.Item>
                                <div />
                            </List>
                        </Card>
                    </Col>
                    {/* Basic Details */}
                    <Col span={24} lg={8}>
                        <Card
                            style={{ height: '600px' }}
                            title="Basic Details"
                            bordered
                            extra={(
                                <Tooltip title="Edit">
                                    <Button type="primary" shape="circle" icon={<FaPencilAlt />} onClick={() => setShowUpdateDriverModal(true)} />
                                </Tooltip>
                            )}
                        >
                            <List>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Avatar size={100} src="https://dev-admin.pickerexpress.com/assets/img/default.png" />
                                </div>
                            </List>
                            <Divider />
                            <List>
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Text style={{ fontSize: 13 }}>{'Name: '}</Text>
                                        }
                                    />
                                    <Space>
                                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                            <Text type="secondary" style={{ fontSize: 12 }}>
                                                {`${fleet?.name} ${fleet?.lastName}`}
                                            </Text>
                                        </Space>
                                    </Space>
                                </List.Item>
                                <div />
                            </List>
                            <List>
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Text style={{ fontSize: 13 }}>{'Email: '}</Text>
                                        }
                                    />
                                    <Space>
                                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                            <Text type="secondary" style={{ fontSize: 12 }}>
                                                {fleet?.email || 'N/A'}
                                            </Text>
                                        </Space>
                                    </Space>
                                </List.Item>
                                <div />
                            </List>
                            <List>
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Text style={{ fontSize: 13 }}>{'Phone No.: '}</Text>
                                        }
                                    />
                                    <Space>
                                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                            <Text type="secondary" style={{ fontSize: 12 }}>
                                                {fullMobile || 'N/A'}
                                            </Text>
                                        </Space>
                                    </Space>
                                </List.Item>
                                <div />
                            </List>
                            <List>
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Text style={{ fontSize: 13 }}>{'OTP Code: '}</Text>
                                        }
                                    />
                                    <Space>
                                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                            <Text type="secondary" style={{ fontSize: 12 }}>
                                                N/A
                                            </Text>
                                        </Space>
                                    </Space>
                                </List.Item>
                                <div />
                            </List>
                            <List>
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Text style={{ fontSize: 13 }}>{'Work Balance: '}</Text>
                                        }
                                    />
                                    <Space>
                                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                            <Text type="secondary" style={{ fontSize: 12 }}>
                                                {worksBalance >= 0 ? `$${worksBalance.toFixed(2)}` : 'N/A'}
                                            </Text>
                                        </Space>
                                    </Space>
                                </List.Item>
                                <div />
                            </List>
                            <List>
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Text style={{ fontSize: 13 }}>{'Earnings: '}</Text>
                                        }
                                    />
                                    <Space>
                                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                            <Text type="secondary" style={{ fontSize: 12 }}>
                                                {earnings >= 0 ? `$${earnings.toFixed(2)}` : 'N/A'}
                                            </Text>
                                        </Space>
                                    </Space>
                                </List.Item>
                                <div />
                            </List>
                            <List>
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Text style={{ fontSize: 13 }}>{'Wallet: '}</Text>
                                        }
                                    />
                                    <Space>
                                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                            <Text type="secondary" style={{ fontSize: 12 }}>
                                                {wallet >= 0 ? `$${wallet.toFixed(2)}` : 'N/A'}
                                            </Text>
                                        </Space>
                                    </Space>
                                </List.Item>
                                <div />
                            </List>
                        </Card>
                    </Col>
                    {/* Vehicle Information */}
                    <Col span={24} lg={8}>
                        <Card
                            style={{ height: '600px' }}
                            title="Vehicle Information"
                            bordered
                            extra={(
                                <Tooltip title="Edit">
                                    <Button type="primary" shape="circle" icon={<FaPencilAlt />} onClick={() => setShowUpdateDriverModal(true)} />
                                </Tooltip>
                            )}
                        >
                            <List>
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Text style={{ fontSize: 13 }}>{'Type: '}</Text>
                                        }
                                    />
                                    <Space>
                                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                            <Text type="secondary" style={{ fontSize: 12 }}>
                                                {vehicleType || 'N/A'}
                                            </Text>
                                        </Space>
                                    </Space>
                                </List.Item>
                                <div />
                            </List>
                            <List>
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Text style={{ fontSize: 13 }}>{'Model Name: '}</Text>
                                        }
                                    />
                                    <Space>
                                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                            <Text type="secondary" style={{ fontSize: 12 }}>
                                                {vehicleType || 'N/A'}
                                            </Text>
                                        </Space>
                                    </Space>
                                </List.Item>
                                <div />
                            </List>
                            <List>
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Text style={{ fontSize: 13 }}>{'Year : '}</Text>
                                        }
                                    />
                                    <Space>
                                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                            <Text type="secondary" style={{ fontSize: 12 }}>
                                                {vehicleYear || 'N/A'}
                                            </Text>
                                        </Space>
                                    </Space>
                                </List.Item>
                                <div />
                            </List>
                            <List>
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Text style={{ fontSize: 13 }}>{'Color: '}</Text>
                                        }
                                    />
                                    <Space>
                                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                            <Text type="secondary" style={{ fontSize: 12 }}>
                                                {vehicleColor || 'N/A'}
                                            </Text>
                                        </Space>
                                    </Space>
                                </List.Item>
                                <div />
                            </List>
                            <List>
                                <List.Item>
                                    <List.Item.Meta
                                        title={
                                            <Text style={{ fontSize: 13 }}>{'Number: '}</Text>
                                        }
                                    />
                                    <Space>
                                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                                            <Text type="secondary" style={{ fontSize: 12 }}>
                                                {MCNumber || 'N/A'}
                                            </Text>
                                        </Space>
                                    </Space>
                                </List.Item>
                                <div />
                            </List>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Table
                            rowKey="_id"
                            loading={loading}
                            columns={column}
                            dataSource={bookings}
                            pagination={{
                                total,
                                current: (paginator.skip / paginator.limit) + 1,
                                pageSize: paginator.limit,
                                onChange: onPageChange,
                                showSizeChanger: true,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
            {showUpdateDriverModal && (
                <UpdateDriverModal
                    showUpdateDriverModal={showUpdateDriverModal}
                    setShowUpdateDriverModal={setShowUpdateDriverModal}
                    id={id}
                    smallFleetId={smallFleetId}
                />
            )}
        </div>
    );
};
