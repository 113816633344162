import moment from 'moment';
import cs from 'classnames';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Space, Typography } from 'antd';
import { IInvoice } from '../context/invoices.interface';
import Actions from './Actions';
import { InvoicesContext } from '../context/invoices.context';

export interface ColumnProps {
    title: string;
    dataIndex: string;
    key: string;
    align?: 'center' | 'left' | 'right';
    render?: (value: any, record: any) => React.ReactNode;
}

const { Text } = Typography;

const InitialColumns: () => ColumnProps[] = () => {
    const { setItem, fetchData } = useContext(InvoicesContext);

    const INITIAL_COLUMNS = [
        {
            title: 'Invoice ID',
            dataIndex: 'invoiceId',
            key: 'invoiceId',
            render: (text: string) => (
                <Text>
                    {text}
                </Text>
            ),
        },
        {
            title: 'From',
            dataIndex: 'from',
            key: 'from',
            render: (text: string) => (text ? moment(text).format('DD/MM/YY') : '-'),
        },
        {
            title: 'To',
            dataIndex: 'to',
            key: 'to',
            render: (text: string) => (text ? moment(text).format('DD/MM/YY') : '-'),
        },
        {
            title: 'Date',
            dataIndex: 'payDate',
            key: 'payDate',
            render: (text: string) => moment(text).format('DD/MM/YY'),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            align: 'center' as 'center',
            render: (text: number, invoice: IInvoice) => (
                <Space size={4} align="center">
                    <Text>
                        {text.toFixed(2)}
                    </Text>
                    <Text type="secondary">
                        {invoice.currency}
                    </Text>
                </Space>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center' as 'center',
            render: (text: string) => (
                <Text className={cs(text.toLowerCase(), 'status')}>
                    {text}
                </Text>
            ),
        },
        {
            title: 'Workspace',
            dataIndex: 'workspaceName',
            key: 'workspaceID',
            render: (text: string, invoice: IInvoice) => (
                <Link to={`/v2/workspaces/${invoice.workspaceID}`}>
                    <Space direction="vertical">
                        <Text style={{ color: '#177ddc' }}>
                            {`${text} (${invoice.workspaceUniqueID})`}
                        </Text>
                        <Text type="secondary">
                            <b>Owner: </b>
                            {invoice?.workspaceOwnerEmail || ''}
                        </Text>
                    </Space>
                </Link>
            ),
        },
        {
            title: 'Plan',
            dataIndex: 'planName',
            key: 'planID',
            render: (text: string, invoice: IInvoice) => (
                <Link to={`/v2/plans/${invoice.planID}`}>
                    <Text style={{ color: '#177ddc' }}>
                        {text}
                    </Text>
                </Link>
            ),
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
            align: 'center' as 'center',
            render: (text: string) => (
                <Text>
                    {text}
                </Text>
            ),
        },
        {
            title: 'Expired Date',
            dataIndex: 'expiredAt',
            key: 'expiredAt',
            align: 'center' as 'center',
            render: (text: string) => {
                const date = moment(text).format('dddd, D MMM');
                const time = moment(text).format('h:mm a');
                return (
                    text ? (
                        <Space size={4}>
                            <Text>
                                {date}
                            </Text>
                            <Text type="secondary"> at </Text>
                            <Text>
                                {time}
                            </Text>
                        </Space>
                    ) : (
                        <Text>
                            -
                        </Text>
                    )
                );
            },
        },
        {
            title: 'Payment Gateway',
            dataIndex: 'gateway',
            key: 'gateway',
            align: 'center' as 'center',
            render: (text: string) => (
                <Text>
                    {text}
                </Text>
            ),
        },
        {
            title: 'Payment Method',
            dataIndex: 'paymentMethod',
            key: 'paymentMethod',
            align: 'center' as 'center',
            render: (text: string) => (
                <Text>
                    {text}
                </Text>
            ),
        },
        {
            title: 'Actions',
            dataIndex: '_id',
            key: 'actions',
            align: 'center' as 'center',
            render: (_id: string, record: IInvoice) => (
                <Actions
                    item={record}
                    setItem={setItem}
                    fetchData={fetchData}
                />
            ),
        },
    ];

    return INITIAL_COLUMNS as ColumnProps[];
};

export default InitialColumns;
