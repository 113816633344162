import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Dropdown, Space, Typography } from 'antd';
import { BsThreeDots } from 'react-icons/bs';
import { IoMdOpen } from 'react-icons/io';
import { BiCheckCircle } from 'react-icons/bi';

import TransactionMenu from './Menu';
import { ITransaction } from '../../../interfaces/transaction.interface';

const { Text } = Typography;

export const InitialColumns = () => {
    const navigate = useNavigate();

    const INITIAL_COLUMNS = [
        {
            title: 'Date & Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text: string) => {
                const date = moment(text).format('dddd, D MMM');
                const time = moment(text).format('h:mm a');
                return (
                    <Space direction="vertical" size={4}>
                        <Text>
                            {date}
                        </Text>
                        <Text>
                            {time}
                        </Text>
                    </Space>
                );
            },
        },
        {
            title: 'Transaction ID',
            dataIndex: 'transactionUniqueID',
            key: 'transactionUniqueID',
        },
        {
            title: 'Booking ID',
            dataIndex: 'bookingNumericId',
            key: 'bookingNumericId',
            render: (text: string, transaction: ITransaction) => (
                <Space
                    size={8}
                    direction="horizontal"
                    onClick={() => {
                        if (!transaction) return;
                        navigate(`/v2/booking?id=${transaction.bookingID}`);
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    <Text style={{ color: '#00B2E3' }}>
                        {`# ${text}` || 'Not available'}
                    </Text>
                    <IoMdOpen
                        color="#00B2E3"
                        size={16}
                        style={{ verticalAlign: 'middle' }}
                    />
                </Space>
            ),
        },
        {
            title: 'Business Name',
            dataIndex: 'businessName',
            key: 'businessName',
            render: (text: string, transaction: ITransaction) => (
                <Space
                    size={8}
                    direction="horizontal"
                    onClick={() => {
                        if (!transaction) return;
                        navigate(`/v2/business/${transaction.businessID}`);
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    <Text style={{ color: '#00B2E3' }}>
                        {text || 'Not available'}
                    </Text>
                    <IoMdOpen
                        color="#00B2E3"
                        size={16}
                        style={{ verticalAlign: 'middle' }}
                    />
                </Space>
            ),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text: number, record: ITransaction) => {
                const { currency } = record;
                return (
                    <Text>
                        {`${text.toFixed(2)} ${currency}`}
                    </Text>
                );
            },
        },
        {
            title: 'In-Store',
            dataIndex: 'isCashCollect',
            key: 'isCashCollect',
            render: (isCashCollect: boolean) => (
                isCashCollect
                    ? null
                    : <BiCheckCircle color="#00B2E3" size={20} />
            ),
            filters: [
                {
                    text: 'In-Store',
                    value: false,
                },
                {
                    text: 'Cash collect',
                    value: true,
                },
            ],
            onFilter: (filterValue: boolean, record: ITransaction) => record.isCashCollect === filterValue,
        },
        {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            render: (text: string) => (
                <Text>
                    {text}
                </Text>
            ),
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            fixed: 'right',
            width: 60,
            render: (text: string, record: ITransaction) => (
                <Dropdown
                    placement="bottomRight"
                    overlay={(
                        <TransactionMenu
                            transaction={record}
                        />
                    )}
                    // @ts-ignore
                    onClick={(e: any) => { e.stopPropagation(); }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            <BsThreeDots size={25} style={{ verticalAlign: 'middle' }} />
                        </a>
                    </div>
                </Dropdown>
            ),
        },
    ];

    return INITIAL_COLUMNS;
};
