import {
    Divider,
    List, Space, Spin, Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { IoIosBusiness } from 'react-icons/io';
import { FiPackage } from 'react-icons/fi';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRef } from 'react';
import { ESearchType } from '../context/summary.enum';

interface Props {
    results: any[];
    loading: boolean;
    total: number;
    searchParams: URLSearchParams;
    fetchResults: (value?: string, type?: string) => void;
}

const { Text } = Typography;

const Results = ({ ...props }: Props) => {
    const {
        loading,
        results,
        total,
        searchParams,
        fetchResults,
    } = props;

    const searchType = searchParams.get('searchType') || ESearchType.WORKSPACE;

    const navigate = useNavigate();

    const scrollableRef = useRef<HTMLDivElement | null>(null);

    const handleNavigate = (id: string) => {
        switch (searchType) {
        case ESearchType.WORKSPACE:
            navigate(`/v2/workspaces/${id}`);
            break;
        case ESearchType.BOOKING:
            navigate(`/v2/booking?id=${id}`);
            break;
        default:
            break;
        }
    };

    return (
        loading ? (
            <div style={{ marginTop: 16 }}>
                <Space size={8}>
                    <Spin />
                    <Text>
                        Searching...
                    </Text>
                </Space>
            </div>
        ) : results?.length ? (
            <div className="main-search__results">
                <Space direction="vertical" size={8} className="full__width">
                    <div
                        id="scrollable"
                        ref={scrollableRef}
                        style={{
                            width: '100%',
                            overflow: 'auto',
                            height: '40vh',
                            padding: '12px 0',
                        }}
                    >
                        <InfiniteScroll
                            dataLength={results?.length}
                            next={fetchResults}
                            hasMore={results.length < total}
                            loader={loading && <Spin size="small" />}
                            endMessage={
                                !loading && (
                                    <Divider plain>
                                        {`No more ${searchType === ESearchType.WORKSPACE ? 'workspaces' : 'bookings'}`}
                                    </Divider>
                                )
                            }
                            scrollableTarget="scrollable"
                        >
                            <List
                                split={false}
                                dataSource={results}
                                renderItem={(item) => (
                                    <List.Item
                                        key={item._id}
                                        onClick={() => handleNavigate(item?._id)}
                                    >
                                        {
                                            searchType === ESearchType.WORKSPACE && (
                                                <List.Item.Meta
                                                    avatar={(
                                                        <IoIosBusiness style={{ verticalAlign: 'middle' }} size={16} />
                                                    )}
                                                    title={(
                                                        <Text strong>
                                                            {`${item?.name} (${item?.workspaceUniqueID})`}
                                                        </Text>
                                                    )}
                                                    description={item?.ownerEmail}
                                                    className="main-search__results--list-item"
                                                />
                                            )
                                        }
                                        {
                                            searchType === ESearchType.BOOKING && (
                                                <List.Item.Meta
                                                    avatar={(
                                                        <FiPackage style={{ verticalAlign: 'middle' }} size={16} />
                                                    )}
                                                    title={(
                                                        <Text strong>
                                                            {`Nº ${item?.bookingNumericId} - ${item?.statusText}`}
                                                        </Text>
                                                    )}
                                                    description={(
                                                        <Text>
                                                            {item?.customerName}
                                                        </Text>
                                                    )}
                                                    className="main-search__results--list-item"
                                                />
                                            )
                                        }
                                    </List.Item>
                                )}
                            />
                        </InfiniteScroll>
                    </div>
                </Space>
            </div>
        ) : <div />
    );
};

export default Results;
