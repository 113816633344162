import axios from 'axios';
import { OCTOPUS_BASE_URL } from '.';

export const getMonitoringData = (bookingID: string, bookingNumericId: number) => {
    const url = `${OCTOPUS_BASE_URL}/booking/${bookingID}/monitoring`;
    const params = new URLSearchParams({ numericID: bookingNumericId.toString() });
    const response = axios.get(url, { params });
    return response;
};

export const toggleActivateMonitor = () => {
    const url = `${OCTOPUS_BASE_URL}/tpd-monitor/activate`;
    const response = axios.post(url);
    return response;
};
