import { notification } from 'antd';

type TCopyText = {
    value: string;
    messageSuccess?: string;
    messageError?: string;
}

const copyText = async ({
    value,
    messageSuccess,
    messageError,
}: TCopyText) => {
    try {
        await navigator.clipboard.writeText(value);
        messageSuccess && notification.success({
            message: messageSuccess,
        });
    } catch (error) {
        console.log(error);
        messageError && notification.error({
            message: messageError,
        });
    }
};

export {
    copyText,
};
