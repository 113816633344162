import ReactDOM from 'react-dom';
// import 'antd/dist/antd.less';

import './styles/globals.scss';
import axios from 'axios';
import { Provider } from 'react-redux';
import { LoadScript } from '@react-google-maps/api';
import { AppRouter } from './routers/AppRouter';
import { store } from './store/store';
import { GOOGLE_MAPS_KEY } from './const/envs';

const accessToken = window.localStorage.getItem('token');

axios.defaults.headers.common.authorization = `Bearer ${accessToken}`;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['content-language'] = 'es';
axios.defaults.headers.common.utcoffset = '-300';

ReactDOM.render(
    <Provider store={store}>
        <LoadScript googleMapsApiKey={GOOGLE_MAPS_KEY} libraries={['drawing', 'places']}>
            <AppRouter />
        </LoadScript>
    </Provider>,
    document.getElementById('root'),
);
