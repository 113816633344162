import {
    Input, Modal, notification, Space, Typography,
} from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { closeModal } from '../../../actions/modals';
import { cancelBooking } from '../../../api/booking';
import { ModalTypes } from '../../../types/modals';

const { Title } = Typography;
const { TextArea } = Input;

interface ICancelService {
    onSucess?: () => void
}

export const CancelService = ({ onSucess }: ICancelService) => {
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState('');

    const { activeModal, modalData } = useSelector((state: any) => state.modals);
    const isOpen = activeModal === ModalTypes.CancelService;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onFinish = async () => {
        setLoading(true);
        try {
            const payload = {
                bookingID: modalData._id,
                cancelReason: description,
                isAdmin: true,
            };
            await cancelBooking(payload);
            notification.success({
                message: 'Service request cancelled successfully!',
                description: 'See booking detail',
                onClick: () => {
                    navigate(`/v2/booking?id=${modalData._id}`);
                },
            });
            setDescription('');
            dispatch(closeModal());
            onSucess && onSucess();
        } catch (error) {
            console.log(error);
            notification.error({
                message: 'An error has occurred when canceling the service',
            });
        } finally {
            setLoading(false);
        }
    };

    const onCancel = () => dispatch(closeModal());

    const handleChange = (event: any) => setDescription(event.target.value);

    return (
        <Modal
            width="70%"
            visible={isOpen}
            title={`Cancel Booking: ${modalData?.bookingNumericId}`}
            onCancel={onCancel}
            onOk={onFinish}
            confirmLoading={loading}
        >
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', margin: '8px 0' }}>
                <Title level={4}>Are you sure you want to cancel booking?</Title>
            </Space>
            <TextArea disabled={loading} onChange={handleChange} placeholder="Leave a comment..." rows={4} />
        </Modal>
    );
};
