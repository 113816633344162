import { Dispatch, useState } from 'react';
import { Modal, notification } from 'antd';

import { removeUserFromWorkspace } from '../../../api/workspaces';

interface RemoveUserModalProps {
  removeUser: any
  setRemoveUser: Dispatch<React.SetStateAction<any>>
  setReload: Dispatch<React.SetStateAction<boolean>>
}

export const RemoveUserModal = ({ removeUser, setRemoveUser, setReload }: RemoveUserModalProps) => {
    const [loading, setLoading] = useState(false);
    const handleOk = () => {
        setLoading(true);
        removeUserFromWorkspace(removeUser.userID, removeUser.workspaceID)
            .then(({ data }) => {
                const { message } = data;
                notification.success({
                    message,
                });
            })
            .catch((error) => {
                const message = error.response?.data?.message;
                notification.error({
                    message,
                });
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
                setRemoveUser(null);
                setReload((reload: boolean) => !reload);
            });
    };

    const handleCancel = () => {
        setRemoveUser(null);
    };
    return (
        <Modal
            title="Remove User"
            visible={removeUser}
            onOk={handleOk}
            onCancel={handleCancel}
            confirmLoading={loading}
        >
            <p>Are you sure you want to REMOVE this user:</p>
            <p>{`${removeUser?.name} ${removeUser?.lastName}?`}</p>
        </Modal>
    );
};
