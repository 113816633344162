import { Typography, Space, Spin } from 'antd';
import { CSSProperties } from 'react';

const { Title } = Typography;

const styles: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40vh',

};

const Loading = () => (
    <div style={styles}>
        <Space size={16} align="center">
            <Spin />
            <Title level={4} style={{ marginBottom: 0 }}>
                Loading...
            </Title>
        </Space>
    </div>
);

export default Loading;
