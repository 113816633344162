/* eslint-disable no-unused-vars */
import { ECurrency } from './payment';
import { WorkspaceSettings } from './workspace-settings';

enum ECarType {
  LITE = 0,
  BIKE = 1
}

enum ECarName {
  LITE = 'LITE',
  BIKE = 'BIKE'
}

enum EWorkspaceCountry {
  EC = 'EC',
  MX = 'MX',
  DO = 'DO',
  PE = 'PE',
  CO = 'CO',
  GT = 'GT',
  CR = 'CR',
  CL = 'CL',
  AR = 'AR',
  UY = 'UY',
  PA = 'PA',
}
export interface IKMRange {
  min: number;
  max: number;
  amount: number;
}
export interface IFareSetting {
  cancellationFreeTimeInSecond: number;
  minimumCancellationFee: number;
  commissionCharge: number;
  carName: ECarName;
  baseFare: number;
  perMinuteCharge: number;
  baseDistance: number;
  distanceRange: number;
  perKmCharge: number;
  freeTime: number;
  averageTime: number;
  carType: ECarType;
  kmRanges: IKMRange[];
}

export type Address = {
  _id: string;
  fullAddress: string;
}

export type BillingData = {
  name: string;
  email: string;
  mobile: string;
  address: Address;
  identification: string
}

interface IPlan {
  _id: string;
  name: string;
  isVisible: boolean;
  isActive: boolean;
  maxNumberOfStores: number;
  price: number;
  usesDashboard: boolean;
  maxDeliveryPartners: number;
  usesIntegrations: boolean;
  usesNotifications: boolean;
  usesOwnFleet: boolean;
  usesWSAndRoles: boolean;
  usesBI: boolean;
  isCustomizable: boolean;
  debitFrecuency: string;
  priceForExtraStore: number;
  canAddExtraStore: boolean;
  index: number;
  country: EWorkspaceCountry;
  currency: ECurrency;
}

export interface IManager {
  email: string;
  name: string;
  lastName: string;
  mobile: string;
  countryCode: string;
}
export interface IPayment {
  _id: string;
  paymentMethod: string;
  paymentGateway: string;
  manager?: IManager;
}
export interface IWorkspace {
  fareSettings: IFareSetting[]
  _id: string;
  billingData: BillingData;
  businesses: string[];
  drivers: string[];
  name: string;
  planID: IPlan;
  freeTrialExpirationTime: Date;
  createdAt: Date;
  updatedAt: Date;
  payment?: IPayment;
  ownerID: string;
  ownerEmail: string;
  workspaceUniqueID: string;
  country: EWorkspaceCountry;
  currency: ECurrency;
  isDeleted: boolean;
  isSuspended: boolean;
  workspaceSettings: WorkspaceSettings;
  showLiteProvider?: boolean;
  masterKey: string;
  proofOfDelivery?: string;
  requiresProofOfDelivery?: boolean;
}

export enum EWorkspaceRole {
  MANAGEMENT = 'MANAGEMENT',
  OPERATOR = 'OPERATOR',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export enum EWorkspaceStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  SUSPENDED = 'SUSPENDED',
  DELETED_SUSPENDED = 'DELETED_SUSPENDED'
}

export enum EWorkspaceStatusText {
  ACTIVE = 'Active',
  DELETED = 'Deleted',
  SUSPENDED = 'Suspended',
  DELETED_SUSPENDED = 'Deleted Suspended'
}

export interface IWallet {
    _id: string;
    balance: number;
    currency: string;
}

export interface ITransaction {
    _id: string;
    amount: number;
    createdAt: Date;
    type: string;
    registeredByName: string;
    currency: string;
}

export interface IWalletCreatePayload {
    balance: number;
    type: string;
    currency: string;
    walletID: string
}
