import {
    Button, Form, Input, Space,
} from 'antd';
import { useEffect, useState } from 'react';
import { BsPlusCircle, BsTrash } from 'react-icons/bs';
import { createInvoice } from '../../api/stripe';
import { getOneWorkspace } from '../../api/workspaces';
import { OctopusPaginatorQuery } from '../../types/paginator';
import WorkspaceInputSearch from '../WorkspaceInputSearch';
import PayButton from './PaymentButton';
import StripeProductSearch from './StripeProductSearch';
import UploadInput from './UploadInput';

interface StripeReceivablesProps {
    setPaymentsPagination: React.Dispatch<React.SetStateAction<OctopusPaginatorQuery>>
}

interface PaymentEntryProps {
    id: string;
    field: any;
    name: string;
}

const StripeReceivables = ({ setPaymentsPagination }: StripeReceivablesProps) => {
    const [form] = Form.useForm();
    const [business, setBusiness] = useState([]);

    useEffect(() => {
        form.setFieldsValue({ business });
    }, [business, form]);

    const addPayment = async (paymentId: string) => {
        const values = form.getFieldsValue().business[paymentId];
        const invoice = await createInvoice(values.customer);
        setPaymentsPagination((paymentsPagination) => ({ ...paymentsPagination }));
    };

    const workspaceSelectedData = async ({ id, field, name }: PaymentEntryProps) => {
        const { business } = form.getFieldsValue();
        const { data }: any = await getOneWorkspace(id);
        const customer = data.data?.payment?.gatewayClientId;
        business[field.key] = {
            name,
            id,
            customer,
        };
        form.setFieldsValue({ business });
    };

    const productSelectedData = ({ id, field, name }: PaymentEntryProps) => {
        const { business } = form.getFieldsValue();

        business[field.key] = {
            name,
            id,
        };

        form.setFieldsValue({ business });
    };

    const handleSubmit = (values: any) => {
        console.log(values);
    };

    return (
        <div style={{ margin: '0 25px' }}>
            <Form
                size="large"
                form={form}
                onFinish={handleSubmit}
                wrapperCol={{ span: 12 }}
                layout="vertical"
            >
                <Form.List name="business">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field) => (
                                <div key={field.key}>
                                    <Space align="baseline">
                                        <Form.Item
                                            {...field}
                                            name={[field.name, 'customer']}
                                            hidden
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            {...field}
                                            label="Workspace Id"
                                            name={[field.name, 'id']}
                                            rules={[{ required: true, message: 'Missing Workspace Id' }]}
                                        >
                                            <Input placeholder="WS123" style={{ width: 200 }} />
                                        </Form.Item>
                                        <Form.Item
                                            {...field}
                                            label="Workspace Name"
                                            name={[field.name, 'name']}
                                            rules={[{ required: true, message: 'Missing name' }]}
                                        >
                                            <WorkspaceInputSearch
                                                placeholder="Picker Workspace"
                                                style={{ width: 200 }}
                                                field={field}
                                                workspaceSelectedData={workspaceSelectedData}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...field}
                                            label="Workspace Name"
                                            name={[field.name, 'name']}
                                            rules={[{ required: true, message: 'Missing name' }]}
                                        >
                                            <StripeProductSearch
                                                placeholder="Item"
                                                style={{ width: 200 }}
                                                field={field}
                                                productSelectedData={productSelectedData}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            {...field}
                                            label="Amount"
                                            name={[field.name, 'amount']}
                                            rules={[{ required: true, message: 'Missing amount' }]}
                                        >
                                            <Input type="number" placeholder="10.0" />
                                        </Form.Item>

                                        <Form.Item
                                            {...field}
                                            label="Tax"
                                            name={[field.name, 'tax']}
                                            rules={[{ required: true, message: 'Missing tax' }]}
                                        >
                                            <Input type="number" placeholder="10.0" />
                                        </Form.Item>
                                        <Button danger onClick={() => remove(field.name)}>
                                            <span className="anticon">
                                                <BsTrash size={17} />
                                            </span>
                                        </Button>
                                        <PayButton
                                            form={form}
                                            field={field}
                                            addPayment={addPayment}
                                        />
                                    </Space>
                                </div>
                            ))}

                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    icon={<span className="anticon"><BsPlusCircle /></span>}
                                >
                                    Add workspace
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Form>
            {business.length === 0 && (
                <UploadInput handleFileUpload={setBusiness} />
            )}
        </div>
    );
};

export default StripeReceivables;
