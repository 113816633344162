import axios from 'axios';
import { PaginatorType } from '../types/paginator';
import { BASE_URL } from './booking';
import { OCTOPUS_BASE_URL } from '.';

type GetBusinessT = {
    paginator?: PaginatorType
    startDate?: string,
    endDate?: string
}

export const getAllBusiness = (paginator: PaginatorType) => {
    const query: any = {
        status: 'EXISTING',
        ...paginator,
    };

    const urlParams = new URLSearchParams(query);

    return axios.get(`${BASE_URL}/admin/getAllBusinesses?${urlParams.toString()}`);
};

export const getBusiness = ({ paginator, startDate, endDate }: GetBusinessT) => {
    let query: any = { ...paginator };

    if (startDate && endDate) query = { ...query, startDate, endDate };

    const urlParams = new URLSearchParams(query);

    return axios.get(`${BASE_URL}/admin/getCSVDataForAdmin?CSVtype=businessDeliveryProvidersReport&${urlParams.toString()}`);
};

export const getOneBusiness = (businessUserId: string) => (
    axios.get(`${OCTOPUS_BASE_URL}/business/${businessUserId}`)
);

export const updateDeliveryProviderBusinessStatus = (
    businessUserId: string,
    providerKey: string,
    status: boolean,
) => axios.post(
    `${OCTOPUS_BASE_URL}/business/${businessUserId}/delivery-provider/${providerKey}`,
    { status },
);

export const updateDeliveryProviderFromBussines = (
    businessId: string,
    deliveryProviders: any[],
    tiers: any[],
) => axios.put(`${OCTOPUS_BASE_URL}/business/${businessId}/tier-providers`, { deliveryProviders, tiers });

export const updateIgnoreAPIAddress = (userBusinessID: string, ignoreAPIAddress: boolean) => {
    const url = `${OCTOPUS_BASE_URL}/business/${userBusinessID}`;
    const payload = { ignoreAPIAddress };
    return axios.put(url, payload);
};

export const getProvidersFromBusiness = (businessId: string) => axios.get(`${OCTOPUS_BASE_URL}/business/${businessId}/delivery-provider`);
