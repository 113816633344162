import { PageHeader, Tabs } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { ExpressDeliveryProvider } from '../components/ExpressDelivery/context/express-delivery.context';
import { DeliveriesTable, DeliveryDetail } from '../components/ExpressDelivery/components';
import { GuidesProvider } from '../components/Guides/context/guides.context';
import GuidesTable from '../components/Guides/components/Table';
import GuideDetail from '../components/Guides/components/GuideDetail';

const ExpressDeliveries = () => {
    const [searchParams] = useSearchParams();

    const items = [
        {
            key: 'eds',
            label: 'Express Deliveries',
            children: (
                <div style={{ marginTop: 24 }}>
                    <DeliveriesTable params={searchParams} />
                </div>
            ),
        },
        {
            key: 'routes',
            label: 'Routes',
            children: (
                <div style={{ marginTop: 24 }}>
                    <GuidesTable params={searchParams} />
                </div>
            ),
        },
    ];

    return (
        <div>
            <GuidesProvider>
                <ExpressDeliveryProvider>
                    <DeliveryDetail />
                    <GuideDetail />
                    <PageHeader
                        title="Express Delivery"
                        style={{ padding: '32px 24px 24px 0' }}
                        footer={(
                            <Tabs
                                defaultActiveKey="eds"
                                items={items}
                            />
                        )}
                    />
                </ExpressDeliveryProvider>
            </GuidesProvider>
        </div>
    );
};

export default ExpressDeliveries;
