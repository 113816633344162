import axios from 'axios';

const { REACT_APP_PICKER_CHAT } = process.env;

export const getMessages = (lastMessage: string) => axios.get(`${REACT_APP_PICKER_CHAT}/messages?after=${lastMessage}&limit=10`);

export const getRun = (runId: string) => axios.get(`${REACT_APP_PICKER_CHAT}/runs/${runId}`);

export const getSummary = (prompt: string, metadata: Record<string, any>) => axios.post(`${REACT_APP_PICKER_CHAT}`, { prompt, model: 'MONITOR', metadata });

export const getBookingSummary = (bookingId: number) => axios.get(`${REACT_APP_PICKER_CHAT}/bookings/${bookingId}`);

export const sendVote = (bookingId: string, vote: boolean) => axios.post(`${REACT_APP_PICKER_CHAT}/vote`, { bookingId, vote });
