import _ from 'lodash';
import moment from 'moment';
import { STATUSES, NOTIFICATIONS } from '../const/booking';

export const getLastStatus = (statuses: any[], statusText: string) => {
    const lastStatus = _.findLast(statuses, (status: { newStatusText: string }) => status.newStatusText === statusText);
    return lastStatus;
};

const getDeliveryFee = (booking: any) => {
    if (booking.deliveryProvider === 'GACELA') {
        return booking.providerFee || booking.deliveryFee;
    }
    if (booking.businessTag === 'SPOONITY' && booking.paymentMethod !== 'CASH') {
        return _.get(booking, 'perKmCharge', 0) + _.get(booking, 'perMinCharge', 0) + booking.baseFare;
    }
    return booking.deliveryFee;
};

const getProviderFee = (booking: any): number => {
    let providerFee = booking.providerFee || 0;
    if (booking.deliveryProvider === 'PICKER' || booking.deliveryProvider === 'MY_FLEET') {
        if (booking.sumOrderAndDelivery) {
            providerFee = _.get(booking, 'perKmCharge', 0) + booking.baseFare;
        }
    }
    return providerFee;
};

export const getTimeStamp = (status: { timeStamp: string }) => {
    if (status && status.timeStamp) {
        return moment(status.timeStamp).format('HH:mm:ss');
    }
    return '---';
};

const getItemFee = (booking: any): number => {
    if (!booking.businessDeliveryFee) {
        return booking.orderAmount;
    }
    return booking.orderAmount - booking.businessDeliveryFee;
};

const isViaNotification = (viaOfNotifications: [string], typeOfVia: string) => {
    const notifications = viaOfNotifications || [];
    return notifications.includes(typeOfVia) ? 'Y' : 'N';
};

const getSeconds = (millis: number) => millis / 1000;

export const formatServicesRequestReport = (data: any) => {
    const report = [];
    const DRIVER_PERCENTAGE = 0.80;
    const COMPANY_PERCENTAGE = 0.20;
    const CANCELLED_STATUSES = [
        'CANCELLED', 'CANCELLED_BY_ADMIN', 'CANCELLED_BY_DRIVER', 'CANCELLED_REQUEST', 'CANCELLED_BY_BUSINESS', 'CANCELLED_BY_DELIVERY_PROVIDER',
    ];
    for (let i = 0; i < data.length; ++i) {
        const actual = data[i];
        const EXTRA_COMMISSION: number = _.get(actual, 'driverExtraCommission', 0.15);
        // LOCATION INFO
        const pALat = (_.get(actual, 'pickupCoordinates.coordinates[1]', 0)).toString();
        const pALng = (_.get(actual, 'pickupCoordinates.coordinates[0]', 0)).toString();
        const dOLat = (_.get(actual, 'deliveryCoordinates.coordinates[1]', 0)).toString();
        const dOLng = (_.get(actual, 'deliveryCoordinates.coordinates[0]', 0)).toString();
        const realLat = (actual.realPosition && actual.realPosition.coordinates.length > 0) ? actual.realPosition.coordinates[1].toString() : '';
        const realLng = (actual.realPosition && actual.realPosition.coordinates.length > 0) ? actual.realPosition.coordinates[0].toString() : '';
        // PICKUP INFO
        const statusUpdates = actual.statusUpdates.filter((status: any) => !!status);
        const acceptedStatus = getLastStatus(statusUpdates, STATUSES.ACCEPTED.TEXT);
        let acceptedTimeStamp;
        let waitingTime = 0; // Time spent from PENDING to ACCEPTED
        if (acceptedStatus) {
            acceptedTimeStamp = acceptedStatus.timeStamp;
            waitingTime = (new Date(acceptedTimeStamp).getTime() - new Date(actual.createdAt).getTime()) / 1000;
        }
        const pointAStatus = getLastStatus(statusUpdates, STATUSES.ARRIVED_AT_PICKUP.TEXT);
        const pointBStatus = getLastStatus(statusUpdates, STATUSES.ARRIVED_AT_DELIVERY.TEXT);
        let pointATimeStamp;
        let timeToPointA = 0;
        if (pointAStatus) {
            pointATimeStamp = pointAStatus.timeStamp;
            timeToPointA = (new Date(pointATimeStamp).getTime() - new Date(actual.createdAt).getTime()) / 1000;
        }
        const cancelledStatus = statusUpdates.find((status: any) => CANCELLED_STATUSES.includes(status.newStatusText));
        let cancelledTimeStamp;
        let timeToCancellation = 0;
        if (cancelledStatus) {
            cancelledTimeStamp = cancelledStatus.timeStamp;
            timeToCancellation = (new Date(cancelledTimeStamp).getTime() - new Date(actual.createdAt).getTime()) / 1000;
        }
        const completedStatus = getLastStatus(statusUpdates, STATUSES.COMPLETED.TEXT);
        let completedTimeStamp;
        let timeToCompleted = 0;
        if (completedStatus) {
            completedTimeStamp = completedStatus.timeStamp;
            timeToCompleted = (new Date(completedTimeStamp).getTime() - new Date(actual.createdAt).getTime()) / 1000;
        }
        let cancelledBy = '';
        let cancelReason = '';
        if (cancelledStatus) {
            switch (actual.statusText) {
            case 'CANCELLED_BY_ADMIN':
                cancelledBy = 'ADMIN';
                cancelReason = actual.cancelReasonByAdmin || '';
                break;
            case 'CANCELLED_REQUEST':
            case 'CANCELLED':
                cancelledBy = 'CUSTOMER';
                cancelReason = actual.cancelReasonByCustomer || '';
                break;
            case 'CANCELLED_BY_DRIVER':
                cancelledBy = 'DRIVER';
                cancelReason = actual.cancelReasonByDriver || '';
                break;
            case 'CANCELLED_BY_BUSINESS':
                cancelledBy = 'BUSINESS';
                cancelReason = actual.cancelReasonByBusiness || '';
                break;
            case 'CANCELLED_BY_DELIVERY_PROVIDER':
                cancelledBy = 'DELIVERY_PROVIDER';
                cancelReason = actual.cancelReasonByServiceProvider || '';
                break;
            default:
                break;
            }
        }
        let timeToWTD = 0;
        const wayToDeliverStatus = getLastStatus(statusUpdates, STATUSES.WAY_TO_DELIVER.TEXT);
        if (actual.typeText === 'CORPORATE' && actual.statusText === 'COMPLETED') {
            const wtdTimestamp = _.get(wayToDeliverStatus, 'timeStamp', null);
            if (wtdTimestamp && acceptedTimeStamp) {
                timeToWTD = (new Date(wtdTimestamp).getTime() - new Date(actual.createdAt).getTime()) / 1000;
            }
        }
        // ORDER INFO
        let totalRequestedAmount = 0;
        let totalAcceptedAmount = 0;
        let totalRejectedAmount = 0;
        for (const order of actual.orderUpdates) {
            if (order.status === 0) {
                totalRequestedAmount += order.orderAmount;
            }
            if (order.status === 1) {
                totalAcceptedAmount += order.orderAmount;
            }
            if (order.status === 2) {
                totalRejectedAmount += order.orderAmount;
            }
        }

        // REFUND DATA
        actual.orderRefund = 0;
        actual.deliveryRefund = 0;
        if (actual.refunds) {
            for (const refund of actual.refunds) {
                switch (refund.type) {
                case 'DELIVERY_CHARGE':
                    actual.deliveryRefund += refund.amount;
                    break;
                case 'ITEM_CHARGE':
                    actual.orderRefund += refund.amount;
                    break;
                case 'MARKETPLACE_CHARGE':
                    actual.deliveryRefund += actual.deliveryFee;
                    actual.orderRefund += actual.orderAmount;
                    break;
                }
            }
        }

        actual.billing = 0;
        if (actual.typeText === 'CORPORATE') {
            actual.billing = (actual.baseFare + actual.perKmCharge + actual.perMinCharge - actual.receivedByDriver);
        }

        // PRICE BREAKUP INFO
        actual.totalDiscount = 0;
        actual.totalFee = 0;
        actual.driverShare = 0;
        actual.companyShare = 0;
        actual.netIncome = 0;
        actual.totalPaid = 0;
        actual.cashInflow = 0;
        actual.deliveryFee = getDeliveryFee(actual);
        if (actual.statusText === 'COMPLETED') {
            actual.totalDiscount = actual.promoDiscount + actual.referralDiscount;
            actual.totalFee = actual.deliveryFee - actual.totalDiscount;
            actual.driverShare = (actual.deliveryFee * DRIVER_PERCENTAGE) - EXTRA_COMMISSION;
            actual.companyShare = (actual.deliveryFee * COMPANY_PERCENTAGE) + EXTRA_COMMISSION;
            actual.netIncome = actual.companyShare - actual.totalDiscount;
            actual.totalPaid = actual.orderAmount + actual.totalFee + actual.serviceFee;
            if (actual.paymentMethod === 'CARD') {
                actual.cashInflow = actual.totalPaid;
            }
        }

        const providerFee = getProviderFee(actual);
        const { hasIssue } = actual;
        let issues = '';
        let issueDescriptions = '';
        if (hasIssue) {
            issues = actual.issues.map((issue: any) => issue.name || '').join(', ');
            issueDescriptions = actual.issues.map((issue: any) => issue.details || '').join(', ');
        }
        let requiresProofOfDelivery = 'N';
        if (actual.proofOfDeliveryType) {
            requiresProofOfDelivery = actual.proofOfDeliveryType !== 'NONE' ? 'Y' : 'N';
        }

        const itemFee = getItemFee(actual);

        report.push({
            bookingNumericId: actual.bookingNumericId,
            typeOfService: actual.typeText,
            bookingStatus: actual.statusText,
            paymentMethod: actual.paymentMethod,
            billingMethod: actual.billingMethod,
            startDate: moment(actual.createdAt).format('YYYY-MM-DD'),
            startTime: moment(actual.createdAt).format('HH:mm:ss'),
            acceptedDate: (acceptedTimeStamp) ? moment(acceptedTimeStamp).format('YYYY-MM-DD') : '---',
            acceptedTime: (acceptedTimeStamp) ? moment(acceptedTimeStamp).format('HH:mm:ss') : '---',
            cancelledDate: (cancelledTimeStamp) ? moment(cancelledTimeStamp).format('YYYY-MM-DD') : '---',
            cancelledTime: (cancelledTimeStamp) ? moment(cancelledTimeStamp).format('HH:mm:ss') : '---',
            cancelledBy,
            cancelReason,
            completedDate: (completedTimeStamp) ? moment(completedTimeStamp).format('YYYY-MM-DD') : '---',
            completedTime: (completedTimeStamp) ? moment(completedTimeStamp).format('HH:mm:ss') : '---',
            timeToCancellation: timeToCancellation.toFixed(2),
            timeToPointA: timeToPointA.toFixed(2),
            timeToWTD: timeToWTD ? timeToWTD.toFixed(0) : '0',
            timeToCompleted: timeToCompleted.toFixed(2),
            // LOCATION INFO
            pointALat: pALat,
            pointALng: pALng,
            pickupAddress: actual.pickupAddress,
            pickupReference: _.get(actual, 'pickupReferences', 'N/A'),
            pickupState: actual.pickupState,
            pickupCity: actual.pickupCity,
            pickupZipCode: actual.pickupZipCode,
            dropOffLat: dOLat,
            dropOffLng: dOLng,
            dropOffAdress: actual.deliveryAddress,
            deliveryReference: _.get(actual, 'deliveryReferences', 'N/A'),
            dropOffState: actual.deliveryState,
            dropOffCity: actual.deliveryCity,
            dropOffZipCode: actual.deliveryZipCode,
            realDistance: actual.realDistance.toFixed(2),
            speed: (actual.realDistance === 0 || timeToCompleted === 0) ? 0 : actual.realDistance / timeToCompleted,
            // USER INFO
            customerName: actual.customerName,
            customerMobile: actual.customerMobile,
            customerEmail: actual.customerEmail,
            customerSegment: _.get(actual, 'customerSegment', ''),
            customerDeviceID: '',
            realLat,
            realLng,
            customerDevice: _.get(actual, 'customerDeviceType', ''),
            isFirstBooking: actual.completedCustomerBookings === 0 ? 'Y' : 'N',
            driverName: _.get(actual, 'driverName', ''),
            deliveryProvider: actual.deliveryProvider
                ? `${actual.deliveryProvider}${actual.deliveryProvider === 'SMALL_FLEETS' && actual.driverTag ? ` - ${actual.driverTag}` : ''}`
                : 'Picker',
            driverTag: actual.driverTag,
            driverMobile: _.get(actual, 'driverMobile', ''),
            driverEmail: _.get(actual, 'driverEmail', ''),
            driverAccountingType: _.get(actual, 'driverAccountingType', 'OLD'),
            driverPickerPoints: _.get(actual, 'driverPickerPoints', ''),
            businessName: _.get(actual, 'businessName', ''),
            businessTrafficLight: _.get(actual, 'region.regionStatus', ''),
            businessMobile: _.get(actual, 'businessMobile', ''),
            businessEmail: '',
            businessUniqueID: _.get(actual, 'businessUniqueID', ''),
            // PAYMENT INFO
            minimumDriverCash: _.get(actual, 'minimumDriverCash', 0).toFixed(2),
            baseFare: actual.baseFare.toFixed(2),
            billedDistance: (actual.billedDistance || 0).toFixed(2),
            perKmCharge: _.get(actual, 'perKmCharge', 0).toFixed(2),
            billedTime: actual.billedTime.toFixed(2),
            perMinCharge: _.get(actual, 'perMinCharge', 0).toFixed(2),
            rushHourIncreaseType: _.get(actual, 'rushHourIncreaseType', ''),
            rushHourIncreaseCoefficient: _.get(actual, 'rushHourIncreaseCoefficient', 0),
            rushHourIncrementAmount: _.get(actual, 'rushHourIncrementAmount', 0),
            rushHourCharge: _.get(actual, 'rushHourCharge', 0).toFixed(2),
            deliveryFee: actual.deliveryFee.toFixed(2),
            promoDiscount: _.get(actual, 'promoDiscount', 0).toFixed(2),
            promoPercentage: actual.promoPercentage || '',
            promoAlias: actual.promo || '',
            referralDiscount: actual.referralDiscount.toFixed(2),
            totalDiscount: _.get(actual, 'totalDiscount', 0).toFixed(2),
            totalFee: _.get(actual, 'totalFee', 0).toFixed(2),
            driverShare: actual.driverShare.toFixed(2),
            driverExtraCommission: _.get(actual, 'driverExtraCommission', 0).toFixed(2),
            companyPay: _.get(actual, 'companyPay', 0).toFixed(2),
            businessShare: _.get(actual, 'businessShare', 0).toFixed(2),
            companyPaymentBusiness: _.get(actual, 'companyPaymentBusiness', 0).toFixed(2),
            companyPaymentDriver: _.get(actual, 'companyPaymentDriver', 0).toFixed(2),
            companyShare: actual.companyShare.toFixed(2),
            netIncome: actual.netIncome.toFixed(2),
            businessDeliveryFee: actual.businessDeliveryFee ? actual.businessDeliveryFee.toFixed(2) : '',
            itemFee: itemFee.toFixed(2),
            providerFee: providerFee.toFixed(2),
            corporateDiscount: _.get(actual, 'corporateDiscount', 0).toFixed(2),
            // time estimation
            estimatedTimeToPointA: actual.estimatedTimeToPointA,
            estimatedTimeToPointB: actual.estimatedTimeToPointB,
            // Order INFO
            totalRequestedAmount: totalRequestedAmount.toFixed(2),
            totalAcceptedAmount: totalAcceptedAmount.toFixed(2),
            serviceFee: _.get(actual, 'serviceFee', 0).toFixed(2),
            orderAmount: actual.orderAmount.toFixed(2),
            businessOrderAmount: (actual.businessOrderAmount || 0).toFixed(2),
            totalPaid: actual.totalPaid.toFixed(2),
            cashInflow: actual.cashInflow.toFixed(2),
            deliveryRefund: actual.deliveryRefund.toFixed(2),
            orderRefund: actual.orderRefund.toFixed(2),
            isPossibleFraud: actual.isPossibleFraud ? 'Y' : 'N',
            isWithHack: actual.isWithHack ? 'Y' : 'N',

            region: _.get(actual, 'region.regionName', ''),
            superRegion: _.get(actual, 'superRegion.name', ''),
            billing: (actual.billing || 0).toFixed(2),

            // timestamps for the report
            acceptedTimeStamp: getTimeStamp(acceptedStatus),
            arrivedAtPickupTimeStamp: getTimeStamp(pointAStatus),
            wayToDeliveryTimeStamp: getTimeStamp(wayToDeliverStatus),
            arrivedAtDeliveryTimeStamp: getTimeStamp(pointBStatus),
            completedTimeStamp: getTimeStamp(completedStatus),
            // extra info
            providerID: _.get(actual, 'externalOrderId', 'N/A'),
            origin: _.get(actual, 'origin', 'N/A'),
            responsable: _.get(actual, 'responsable', 'N/A'),
            reassign: _.get(actual, 'reassign', 0),
            hasIssues: hasIssue ? 'Y' : 'N',
            issues,
            issueDescriptions,
            operationBonus: (actual.operationBonus || 0).toFixed(2),
            typeProofOfDelivery: actual.proofOfDeliveryType,
            proofOfDelivery: actual.proofOfDelivery || '',
            requiresProofOfDelivery,
            usedWhatsapp: isViaNotification(actual.smrSentVia, NOTIFICATIONS.WHATSAPP.TEXT),
            usedMail: isViaNotification(actual.smrSentVia, NOTIFICATIONS.EMAIL.TEXT),
            usedSMS: isViaNotification(actual.smrSentVia, NOTIFICATIONS.SMS.TEXT),
            businessTag: actual.businessTag || '',
            isProofValidated: actual.isProofOfDeliveryApproved ? 'Y' : 'N',
            wasManuallyRequested: actual.wasManuallyRequested ? 'Y' : 'N',
            wasManuallyAssigned: actual.wasManuallyAssigned ? 'Y' : 'N',
            lastResponsableReassign: actual.lastResponsableReassign || '',
            lastResponsable: actual.lastResponsable || '',
            lastResponsableAction: actual.lastResponsableAction || '',
            manualRequestReason: actual.manualRequestReason || '',
            manualAssignReason: actual.manualAssignReason || '',
            workspaceName: actual.workspaceName || '',
            currency: actual.currency || '',
            fromExternalCredentials: actual.fromExternalCredentials ? 'Y' : 'N',
            userBookingCreator: actual.userBookingCreator || '',
            externalBookingId: _.get(actual, 'externalBookingId', 'N/A'),
            wasInBatch: actual.timeOnBatch ? 'Y' : 'N',
            timeOnBatch: actual.timeOnBatch || '',
            tip: (actual.tip || 0).toFixed(2),
            cookTime: getSeconds(actual.cookTime) || '',
            exactDistance: (actual.exactDistance || 0).toFixed(2),
            internalId: actual._id,
            usedGoogleAddress: actual.usedGoogleAddress ? 'Y' : 'N',
        });
    }
    return report;
};
