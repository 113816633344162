import {
    Avatar, Button, message, Space, Typography,
} from 'antd';
import { useContext } from 'react';
import { BiStore } from 'react-icons/bi';
import { ICreateChatPayload } from '../../../../types/chat';
import { ChatContext } from '../../context/chatContext';
import { ShieldContext } from '../../context/shieldContext';

const { Title, Text, Paragraph } = Typography;

const CreateChat = () => {
    const { issue } = useContext(ShieldContext);
    const { loading, createChat } = useContext(ChatContext);

    const hasImage = issue.bookingData?.businessImage?.thumbnail;

    const handleCreateChatConfirm = () => {
        if (!issue?.responsableID || !issue?.responsableName) {
            message.error('Chat cannot be created, no responsable person assigned');
            return;
        }

        const chatName = `${issue.bookingData?.businessName} - ${issue?.issuePublicID}`;
        console.log(chatName);

        const payload: ICreateChatPayload = {
            createdBy: issue?.responsableID,
            externalID: issue?.issuePublicID,
            type: 'ISSUE',
            name: chatName,
            users: [
                {
                    userID: issue?.responsableID,
                    name: issue?.responsableName,
                    role: 'SUPPORT',
                },
            ],
        };
        createChat(payload);
    };

    return (
        <div className="chat__create">
            <Space direction="vertical" size={2}>
                <Avatar
                    src={
                        hasImage
                            ? issue.bookingData?.businessImage?.thumbnail
                            : <BiStore size={56} />
                    }
                    size={64}
                    className="store"
                />
                <Title style={{ textAlign: 'center' }}>
                    { issue.bookingData?.businessName}
                </Title>
                <Paragraph style={{ textAlign: 'center' }}>
                    { issue.bookingData?.pickupAddress}
                </Paragraph>
                <Paragraph style={{ textAlign: 'center' }}>
                    {
                        issue.bookingData?.pickupReferences && issue.bookingData?.pickupReferences !== 'N/A'
                            ? issue.bookingData?.pickupReferences
                            : 'Without reference'
                    }
                </Paragraph>
            </Space>
            <Button
                type="primary"
                onClick={() => handleCreateChatConfirm()}
            >
                Start conversatión
            </Button>
        </div>
    );
};

export default CreateChat;
