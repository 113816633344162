export enum TEvent {
    CALL_DRIVER = 'CALL_DRIVER',
    COPY_LINK = 'COPY_LINK',
    COPY_DRIVER_NAME = 'COPY_DRIVER_NAME',
    COPY_BOOKING_ID = 'COPY_BOOKING_ID',
    CLICK_DRIVER_NAME = 'CLICK_DRIVER_NAME',
    CLICK_BOOKING_ID = 'CLICK_BOOKING_ID',
    DETAIL_BOOKING = 'DETAIL_BOOKING',
    RELOAD_MAP = 'RELOAD_MAP',
    CHAT_SUPPORT = 'CHAT_SUPPORT',
    WHATSAPP_DRIVER = 'WHATSAPP_DRIVER',
    RELOAD_PAGE = 'RELOAD_PAGE',
    EXTERNAL_TRACKING = 'EXTERNAL_TRACKING',
    TIME_IN_SMR = 'TIME_IN_SMR',
    OPEN_TRACKING = 'OPEN_TRACKING',
    CLOSE_TRACKING = 'CLOSE_TRACKING',
}
