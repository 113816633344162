import moment from 'moment';
import {
    Button,
    Col,
    PageHeader,
    Row,
    Space,
    Table,
    Tooltip,
    Typography,
} from 'antd';
import { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';

import VariableModal from '../Modals/VariableModal';
import { VariableContext } from './context/VariableContext';
import { IVariable } from '../../interfaces/plan';

const { Text } = Typography;

export const VariableTable = () => {
    const [variable, setVariable] = useState<any>();
    const [showVariable, setShowVariable] = useState(false);
    const { id } = useParams();
    const {
        variables, variablesLoading, addVariable, fetchVariables,
    } = useContext(VariableContext);

    useEffect(() => {
        fetchVariables();
    }, []);

    const menu = (variableId: string) => (
        <Row justify="center" gutter={12}>
            <Col>
                <Tooltip title="Edit">
                    <Button
                        icon={<FaEdit style={{ verticalAlign: 'middle', color: '#177DDC' }} />}
                        type="ghost"
                        onClick={() => {
                            setVariable(variables.find((v: any) => v._id === variableId));
                            setShowVariable(true);
                        }}
                    />
                </Tooltip>
            </Col>
        </Row>
    );

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name: string, variable: IVariable) => (
                (variable.planID || !id) ? (
                    <a
                        style={{ color: '#177DDC' }}
                        onClick={() => {
                            setVariable({ ...variable });
                            setShowVariable(true);
                        }}
                    >
                        {name}
                    </a>
                ) : (
                    <Text style={{ color: '#87d068' }}>
                        {name}
                    </Text>
                )
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string) => (
                <Text>
                    {moment(createdAt).format('MMM DD MMM')}
                </Text>
            ),
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type: string) => (
                <Text>
                    {type || 'Not available'}
                </Text>
            ),
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            render: (value: string) => (
                <Text>
                    {value || 0.00}
                </Text>
            ),
        },
        {
            title: '',
            dataIndex: '_id',
            key: 'actions',
            width: 60,
            fixed: 'right' as 'right',
            render: (_id: string, variable: IVariable) => ((variable.planID || !id) ? menu(_id) : <Space />),
        },
    ];

    const handleAddVariable = () => {
        setShowVariable(true);
        setVariable({ planID: id });
    };

    const handleCancelVariable = (variable: any) => {
        if (variable) {
            addVariable(variable);
        }
        setShowVariable(false);
    };

    return (
        <Row justify="end" gutter={[0, 20]}>
            <VariableModal
                visible={showVariable}
                variable={variable}
                onCancel={handleCancelVariable}
            />
            <Col span={24}>
                <PageHeader
                    title="Variables"
                    style={{ padding: 0 }}
                    extra={[
                        <Button type="primary" size="small" onClick={handleAddVariable}>
                            Add Variable
                        </Button>,
                    ]}
                />
            </Col>
            <Col span={24}>
                <Table
                    columns={columns}
                    dataSource={variables}
                    loading={variablesLoading}
                    pagination={false}
                    rowKey="_id"
                    locale={{ emptyText: 'No variables' }}
                />
            </Col>
        </Row>
    );
};
