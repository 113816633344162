import { isUndefined } from 'lodash';
import moment from 'moment';

export const formatSearchRequestReport = (data: any) => {
    const report = [];
    for (let i = 0; i < data.length; ++i) {
        const actual = data[i];

        report.push({
            driverSearchID: actual.reportNumericId,
            driverSearchNumericId: actual.driverSearchNumericId,
            bookingNumericId: actual.bookingNumericId,
            strategy: actual.strategy,
            round: actual.round ? actual.round : 'N/A',
            distance: actual.round ? actual.round : 'N/A',
            searchDate: moment(actual.createdAt).format('YYYY-MM-DD'),
            searchTime: moment(actual.createdAt).format('HH:mm:ss'),
            wasListed: actual.wasListed ? 'Y' : 'N',
            rejectedReason: actual.rejectedReason,
            driverName: actual.driverName,
            driverEmail: actual.driverEmail, // si
            wasNotified: actual.wasNotified ? 'Y' : 'N',
            notificationDate: moment(actual.notificationDate).format('YYYY-MM-DD'),
            notificationTime: moment(actual.notificationDate).format('HH:mm:ss'),
            status: actual.status ? actual.status : 'N/A',
            driverLocation: `${actual.driverLocation.coordinates[1]}, ${actual.driverLocation.coordinates[0]}`,
            minSearchWallet: actual.minSearchWallet,
            driverWallet: actual.driverWallet,
            driverWorkBalance: actual.driverWorkBalance,
            driverBatteryPercentage: actual.driverBatteryPercentage,
            handledBooking: actual.handledBooking ? 'Y' : 'N',
            receivedNotification: !isUndefined(actual.receivedNotification) ? actual.receivedNotification ? 'Y' : 'N' : '',
            receivedNotificationDate: actual.notificationDate ? moment(actual.notificationDate).format('YYYY-MM-DD') : '',
            receivedNotificationTime: actual.notificationDate ? moment(actual.notificationDate).format('HH:mm:ss') : '',
            rejectedNotification: !isUndefined(actual.rejectedNotification) ? actual.rejectedNotification ? 'Y' : 'N' : '',
        });
    }
    return report;
};
