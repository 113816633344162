import { useState } from 'react';
import {
    Dropdown, Menu, Modal, Typography, Form,
    message,
    notification,
} from 'antd';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { IWhatsappCertification } from '../context/certifications.interface';
import ChangeStatus from './ChangeStatus';
import { updateCertification } from '../../../api/whatsapp-certifications';
import RejectedReason from './RejectedReason';

const { ItemGroup } = Menu;
const { confirm } = Modal;
const { Title } = Typography;

interface Props {
    item: IWhatsappCertification;
    setItem: (item: IWhatsappCertification) => void;
    fetchData: () => void;
}

const Actions = ({ item, setItem, fetchData }: Props) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const handleChangeStatus = async () => {
        confirm({
            width: 500,
            title: (
                <Title level={4}>
                    Are you sure change status of this certification?
                </Title>
            ),
            content: (
                <ChangeStatus
                    form={form}
                    item={item}
                />
            ),
            onCancel: () => {},
            okButtonProps: { loading },
            onOk: () => {
                setLoading(true);
                const status = form.getFieldsValue();

                if (status === item.status) {
                    message.warning('Status not changed');
                    return;
                }
                updateCertification(item._id || '', { ...status })
                    .then(() => {
                        notification.success({
                            message: 'Certification status updated successfully',
                        });
                        fetchData();
                    })
                    .catch((error: any) => {
                        const description = error?.response?.data?.message || error.message;
                        notification.error({
                            message: 'Error',
                            description,
                        });
                    })
                    .finally(() => setLoading(false));
            },
        });
    };

    const handleAddRejectedReason = async () => {
        confirm({
            width: 500,
            title: (
                <Title level={4}>
                    Add a new reason for rejection of the certification
                </Title>
            ),
            content: (
                <RejectedReason
                    form={form}
                />
            ),
            onCancel: () => {},
            okButtonProps: { loading },
            onOk: () => {
                setLoading(true);
                const { reason } = form.getFieldsValue();

                if (!reason || reason === '') {
                    message.warning('Please add a reason');
                    return;
                }

                const payload = {
                    rejectedReasons: [...item.rejectedReasons, reason],
                };

                updateCertification(item._id || '', { ...payload })
                    .then(() => {
                        notification.success({
                            message: 'Rejected reason added successfully',
                        });
                        fetchData();
                    })
                    .catch((error: any) => {
                        const description = error?.response?.data?.message || error.message;
                        notification.error({
                            message: 'Error',
                            description,
                        });
                    })
                    .finally(() => setLoading(false));
            },
        });
    };

    const OPTIONS = [
        {
            children: 'View Details',
            onClick: () => setItem(item),
        },
        {
            children: 'Change Status',
            onClick: () => handleChangeStatus(),
        },
        {
            children: 'Add Rejected Reason',
            onClick: () => {
                if (item.rejectedReasons.length >= 3) {
                    message.warning('You can only add up to 3 rejected reasons. If you required more, please contact the customer to provide more details');
                    return;
                }
                handleAddRejectedReason();
            },
        },
    ];

    return (
        <Dropdown
            placement="bottomRight"
            trigger={['click']}
            overlay={(
                <Menu>
                    <ItemGroup
                        key="g"
                    >
                        {
                            OPTIONS.map((option, index) => (
                                <Menu.Item key={index} {...option} />
                            ))
                        }
                    </ItemGroup>
                </Menu>
            )}
        >
            <BiDotsVerticalRounded size={24} />
        </Dropdown>
    );
};

export default Actions;
