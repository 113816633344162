import moment from 'moment';
import { useEffect, useState } from 'react';

const CountdownTimer = () => {
    const [timeLeft, setTimeLeft] = useState<string>('');

    const updateCountdown = () => {
        const now = moment();
        const nextHour = moment().add(1, 'hour').startOf('hour');
        const diffInSeconds = nextHour.diff(now, 'seconds');
        setTimeLeft(moment.utc(diffInSeconds * 1000).format('mm:ss'));
    };

    useEffect(() => {
        updateCountdown();
        const intervalId = setInterval(updateCountdown, 1000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div
            style={{
                color: '#FFF',
            }}
        >
            {`Next grouping in ${timeLeft} min`}
        </div>
    );
};

export default CountdownTimer;
