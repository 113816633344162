import {
    Modal, Form, Input, Select, Button, Space, Row, Col, AutoComplete, Divider, Descriptions, Table,
} from 'antd';
import { BsPlusCircle, BsTrash } from 'react-icons/bs';
import { useState, useEffect } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { MdCancel } from 'react-icons/md';
import { testConcept } from '../../../api/payments';

const { Option } = Select;

interface IConceptEvalModal {
  visible: boolean;
  concept: any;
  onCancel: () => void;
}

const ConceptEvalModal = ({
    visible,
    concept,
    onCancel,
}: IConceptEvalModal) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [conceptEval, setConceptEval] = useState<any>();

    const handleTest = (values: any) => {
        setLoading(true);
        testConcept(concept.planID, concept._id, values)
            .then(({ data }) => {
                setConceptEval(data.data);
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const columns = [
        {
            title: 'Field',
            dataIndex: 'field',
            key: 'field',
        },
        {
            title: 'Operator',
            dataIndex: 'operator',
            key: 'operator',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'Booking Value',
            dataIndex: 'bookingValue',
            key: 'bookingValue',
        },
        {
            title: 'Result',
            dataIndex: 'result',
            key: 'result',
            render: (data: boolean) => (
                data ? <AiFillCheckCircle color="green" size={25} /> : <MdCancel color="red" size={25} />
            ),
        },
    ];

    const handleCancel = () => {
        setConceptEval(undefined);
        form.resetFields();
        onCancel();
    };

    useEffect(() => {
        if (!concept) return;
    }, [concept, form]);

    return (
        <Modal
            title={`Test Concept: ${concept?.name}`}
            visible={visible}
            width={1000}
            onCancel={handleCancel}
        >
            <Form form={form} layout="vertical" onFinish={handleTest}>
                <Row gutter={3}>
                    <Col span={20}>
                        <Form.Item label="Booking" name="bookingId">
                            <Input placeholder="888888888" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="&nbsp;">
                            <Button type="primary" block htmlType="submit" loading={loading}>
                                Test
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table dataSource={conceptEval?.conditions} columns={columns} loading={loading} />
            <Descriptions title="Operations" bordered column={1}>
                {conceptEval?.operations.map((operation: any) => (
                    <Descriptions.Item key={operation.name} label={operation.name}>{operation.amount}</Descriptions.Item>
                ))}
                <Descriptions.Item label="Total">{conceptEval?.total}</Descriptions.Item>
            </Descriptions>
        </Modal>
    );
};

export default ConceptEvalModal;
