/* eslint-disable react/jsx-filename-extension */
import {
    Routes, Route, BrowserRouter, Navigate,
} from 'react-router-dom';

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout } from '../components/Layout';
import Login from '../screens/Login';
import { startLoadDeliveries } from '../actions/deliveries';
import { startLoadPlans } from '../actions/plans';
import ProtectedRoutes from './PrivateRoute';
import { startLoadUser } from '../actions/login';

export const AppRouter = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const token = localStorage.getItem('token');
        dispatch(startLoadDeliveries());
        dispatch(startLoadPlans());
        if (token) {
            dispatch(startLoadUser(token));
        }
    }, [dispatch]);
    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route path="/v2/login" element={<Login />} />
                    <Route path="/v2/*" element={<ProtectedRoutes />} />
                </Routes>
            </Layout>
        </BrowserRouter>
    );
};
