import {
    Checkbox, Col, Form, Input, Modal, Row, Select, Typography, notification,
} from 'antd';
import { useState } from 'react';
import { createAlert } from '../../../api/alerts';
import PreviewAlert from './Preview';

interface Props {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    setNewAlert: (alert: any) => void;
}

const { Text } = Typography;

const CreateAlert = ({ ...props }: Props) => {
    const TYPES = [
        {
            value: 'info',
            label: 'Informative',
        },
        {
            value: 'success',
            label: 'Success',
        },
        {
            value: 'warning',
            label: 'Warning',
        },
        {
            value: 'error',
            label: 'Error',
        },
    ];

    const CTA_TEXT = [
        {
            value: 'SEE_MORE',
            label: 'See more',
        },
        {
            value: 'HERE',
            label: 'Here',
        },
    ];

    const [form] = Form.useForm();
    const [isClosable, setIsClosable] = useState<boolean>(false);
    const [alert, setAlert] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = (values: any) => {
        setLoading(true);

        const payload = {
            ...values,
            description: {
                en: values.en,
                es: values.es,
            },
        };

        delete payload.en;
        delete payload.es;

        createAlert(payload)
            .then(({ data }: any) => {
                form.resetFields();
                props.setNewAlert(data.data);
                notification.success({
                    message: 'Success',
                    description: 'Alert created successfully',
                });
            })
            .catch((error: any) => {
                const description = error?.response?.data?.description || 'Something went wrong';
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => {
                setLoading(false);
                props.setVisible(false);
            });
    };

    return (
        <Modal
            width={850}
            title="Create Alert"
            open={props.visible}
            closable={false}
            onCancel={() => props.setVisible(false)}
            onOk={() => form.submit()}
            okButtonProps={{
                loading,
            }}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={onSubmit}
                initialValues={{
                    subType: 'ALERT_WORKSPACE',
                    type: 'info',
                    isActive: true,
                    isClosable: false,
                }}
            >
                <Form.Item name="subType" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="isActive" hidden>
                    <Input />
                </Form.Item>
                <Row style={{ width: '100%' }} justify="space-between" align="top" gutter={[24, 24]}>
                    <Col span={12}>
                        <Form.Item
                            name="es"
                            label="Spanish description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a spanish description',
                                },
                            ]}
                        >
                            <Input
                                placeholder="Spanish description"
                                type="text"
                                onChange={(e) => {
                                    setAlert({
                                        ...alert,
                                        description: {
                                            ...alert?.description,
                                            es: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="en"
                            label="English description"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a english description',
                                },
                            ]}
                        >
                            <Input
                                placeholder="English description"
                                type="text"
                                onChange={(e) => {
                                    setAlert({
                                        ...alert,
                                        description: {
                                            ...alert?.description,
                                            en: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="type"
                            label="Alert type"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a alert type',
                                },
                            ]}
                        >
                            <Select
                                placeholder="Select a alert type"
                                options={TYPES}
                                onChange={(value) => {
                                    setAlert({
                                        ...alert,
                                        type: value,
                                    });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="isClosable"
                            label="Closable Alert"
                        >
                            <Checkbox
                                defaultChecked={false}
                                onChange={(e) => {
                                    form.setFieldsValue({
                                        isClosable: e.target.checked,
                                    });
                                    setAlert({
                                        ...alert,
                                        isClosable: e.target.checked,
                                    });
                                }}
                                value={isClosable}
                            >
                                Show icon to close the alert
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="CTAText"
                            label="CTA Text"
                        >
                            <Select
                                placeholder="Select a CTA text"
                                options={CTA_TEXT}
                                onChange={(value) => {
                                    const label = CTA_TEXT.find((item) => item.value === value)?.label;
                                    setAlert({
                                        ...alert,
                                        CTAText: label,
                                    });
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="CTAUrl"
                            label="CTA Url"
                            help="Path to which users when clicking on the alert."
                        >
                            <Input
                                placeholder="/settings/general"
                                type="text"
                            />
                        </Form.Item>

                    </Col>
                </Row>
            </Form>

            <PreviewAlert
                alert={alert}
            />
        </Modal>
    );
};

export default CreateAlert;
