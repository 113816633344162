import {
    Alert, Space, Typography, Card, Tooltip,
} from 'antd';
import { AiFillCloseCircle } from 'react-icons/ai';
import { IAlert } from '../../../interfaces/workspace-alert';

interface Props {
    alert?: IAlert | null;
}

const { Text } = Typography;
const PreviewAlert = ({ alert }: Props) => (
    <Card bordered={false} bodyStyle={{ padding: 0 }}>
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
            <Text>
                This is how the alert will look like when sent to the workspace.
            </Text>

            <div className="preview">
                <div className="preview__layout">
                    <div className="preview__layout--sidebar" />
                    <div className="preview__layout--content">
                        <div className="preview__layout--alert">
                            <Alert
                                type={alert?.type}
                                message={(
                                    <Text style={{ color: '#3F4D70' }} strong>
                                        {alert?.description?.en || ''}
                                        .
                                        {
                                            alert?.CTAUrl && (
                                                <Tooltip title={alert?.CTAUrl}>
                                                    <span style={{ marginLeft: 4, textDecoration: 'underline' }}>
                                                        {alert?.CTAText}
                                                    </span>
                                                </Tooltip>
                                            )
                                        }
                                    </Text>
                                )}
                                closable={alert?.isClosable}
                                closeIcon={(
                                    <AiFillCloseCircle
                                        size={14}
                                        style={{ verticalAlign: 'middle' }}
                                    />
                                )}
                                style={{
                                    pointerEvents: 'none',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </Space>
    </Card>
);

export default PreviewAlert;
