import { List } from 'antd';
import { useContext } from 'react';
import { CommandCenterContext } from '../../context/commandCenterContext';
import DriverCardItem from '../DriverCardItem';
import { IDriverTracking } from '../../../../types/driver';

const DriversList = ({ drivers }:{drivers: IDriverTracking[]}) => {
    const { isLoadingCommandCenterContext } = useContext(CommandCenterContext);

    return (
        <List
            loading={isLoadingCommandCenterContext}
            dataSource={drivers}
            renderItem={(item: IDriverTracking) => (
                <DriverCardItem
                    id={item.driverID}
                    driver={item}
                    loading={isLoadingCommandCenterContext}
                />
            )}
        />

    );
};

export default DriversList;
