import moment from 'moment';

interface DummyRequestProps {
  file: any;
  onSuccess: (data: any) => void;
}

export const dummyRequest = ({ file, onSuccess }: DummyRequestProps) => {
    setTimeout(() => {
        onSuccess('ok');
    }, 0);
};

export const diffTimeInMinutes = (date1: Date, date2: Date) => {
    const diff = moment(date2).diff(moment(date1));
    const minutes = moment.duration(diff).asMinutes();
    return Math.round(minutes);
};
