/* eslint-disable react/no-unused-prop-types */
import { Image } from 'antd';
import { useContext, useMemo } from 'react';
import { driverMarkerImage } from '../../utils/map';
import { checkDriverStatus } from '../../utils/driver';

import { IDriverTracking } from '../../../../types/driver';
import { CommandCenterContext } from '../../context/commandCenterContext';

type TDriverMarkerPopover = {
    driver: IDriverTracking;
    lat: number;
    lng: number;
};

const DriverMarkerPopover = ({
    driver,
}: TDriverMarkerPopover) => {
    const { updatedDriverMarkerSelected } = useContext(CommandCenterContext);

    const handleClick = () => updatedDriverMarkerSelected(driver.driverID);

    const imageMarker: string = useMemo(
        () => driverMarkerImage(checkDriverStatus(driver.isBusy, driver?.onDutyFlag)),
        [driver],
    );

    return (
        <Image
            id={`driver-marker-${driver.driverID}`}
            style={{ cursor: 'pointer' }}
            onClick={handleClick}
            preview={false}
            src={imageMarker}
            width={20}
            alt="Driver image marker"
        />
    );
};

export default DriverMarkerPopover;
