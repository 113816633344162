import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import UsersScreen from '../screens/Users';
import UserScreen from '../screens/User';
import { UsersProvider } from '../components/Users/context/users.context';

const UsersRouter: FC = () => (
    <Routes>
        <Route
            path=""
            element={(
                <UsersProvider>
                    <UsersScreen />
                </UsersProvider>
            )}
        />
        <Route
            path=":id"
            element={(
                <UsersProvider>
                    <UserScreen />
                </UsersProvider>
            )}
        />
    </Routes>
);

export default UsersRouter;
