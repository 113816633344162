import axios from 'axios';
import { PaginatorType } from '../types/paginator';

const DASHBOARD_BASE_URL = process.env.REACT_APP_DASHBOARD_BASE_URL;

export const getMicroFleet = (paginator: PaginatorType) => {
    const urlParams = new URLSearchParams(paginator as any);
    return axios.get(`${DASHBOARD_BASE_URL}/admin/getSmallFleet?${urlParams.toString()}`);
};

export const getMicroFleetById = (id: any, paginator: PaginatorType) => {
    const urlParams = new URLSearchParams({
        page: paginator.page,
        limit: paginator.limit,
        smallFleetID: id,
    } as any);
    return axios.get(`${DASHBOARD_BASE_URL}/admin/getAllDriversBySmallFleet?${urlParams.toString()}`);
};

export const addDriverToSmallFleet = (data: any) => axios.post(`${DASHBOARD_BASE_URL}/admin/addDriverToSmallFleet`, data);

export const removeDriverFromSmallFleet = ({ userID, smallFleetID }: any) => axios.post(`${DASHBOARD_BASE_URL}/admin/removeDriverFromSmallFleet`, {
    userID,
    smallFleetID,
});

export const getDriverFromSmallFleet = ({ id, smallFleetId }: any) => {
    const urlParams = new URLSearchParams({
        smallFleetID: smallFleetId,
        userID: id,
    });
    return axios.get(`${DASHBOARD_BASE_URL}/admin/getDriverFromSmallFleet?${urlParams.toString()}`);
};

export const editDriverFromSmallFleet = (data: any) => axios.post(`${DASHBOARD_BASE_URL}/admin/editDriverFromSmallFleet`, data);
