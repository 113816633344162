import { Form, Select } from 'antd';
import { IDispersion } from '../../../interfaces/dispersion.interface';

interface Props {
    form: any;
    dispersion: IDispersion;
}

const ChangeStatus = ({ form, dispersion }: Props) => {
    const STATUS = [
        { label: 'Pending', value: 'PENDING' },
        { label: 'Active', value: 'ACTIVE' },
        { label: 'Paid', value: 'PAID' },
    ];

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={() => {}}
            initialValues={{ status: dispersion.status }}
        >
            <Form.Item
                name="status"
                label="Select the new status of the dispersion"
                rules={[{ required: true, message: 'Please select the status' }]}
            >
                <Select
                    style={{ width: '100%' }}
                    placeholder="Select status"
                    options={STATUS}
                    onChange={(value: string) => {}}
                />
            </Form.Item>
        </Form>
    );
};

export default ChangeStatus;
