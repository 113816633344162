import { useEffect, useState } from 'react';
import {
    Avatar, Card, Divider, List, Space, Typography,
} from 'antd';
import moment from 'moment';

import { DriverType } from '../../types/driver';
import { getPickerPay } from '../../api/drivers';

const { Text } = Typography;

interface FleetDetails {
  fleet?: DriverType
}

export const DriverUserDetails = ({ fleet }: FleetDetails) => {
    const [pickerPay, setPickerPay] = useState(0);

    console.log(fleet);

    const workBalance = fleet?.driverID?.workBalance.toFixed(2) || '0.00';
    const fullMobile = fleet?.fullMobile || `${fleet?.countryCode}${fleet?.mobile}` || 'N/A';
    const fullName = fleet ? `${fleet?.name} ${fleet?.lastName}` : 'N/A';
    const wallet = fleet?.driverID?.wallet.toFixed(2) || '0.00';
    const userImage = fleet?.image?.thumbnail;
    const email = fleet?.email || 'N/A';
    const emailVerificationToken = fleet?.emailVerificationToken || 'N/A';
    const startDate = moment().startOf('day').format();
    const endDate = moment().endOf('day').format();

    useEffect(() => {
        if (!fleet) return;
        getPickerPay({ startDate, endDate, pickerID: fleet._id })
            .then(({ data }) => setPickerPay(data.data))
            .catch((err) => console.log(err));
    }, [fleet]);

    return (
        <Card
            style={{ height: '600px' }}
            title="Basic Details"
            bordered
        >
            <List>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Avatar size={100} src={userImage || 'https://dev-admin.pickerexpress.com/assets/img/default.png'} />
                </div>
            </List>
            <Divider />
            <List>
                <List.Item>
                    <List.Item.Meta
                        title={
                            <Text style={{ fontSize: 13 }}>Name:</Text>
                        }
                    />
                    <Text type="secondary" style={{ fontSize: 12 }}>
                        {fullName}
                    </Text>
                </List.Item>
                <div />
            </List>
            <List>
                <List.Item>
                    <List.Item.Meta
                        title={
                            <Text style={{ fontSize: 13 }}>Email:</Text>
                        }
                    />
                    <Text type="secondary" style={{ fontSize: 12 }}>
                        {email}
                    </Text>
                </List.Item>
                <div />
            </List>
            <List>
                <List.Item>
                    <List.Item.Meta
                        title={
                            <Text style={{ fontSize: 13 }}>Phone No.:</Text>
                        }
                    />
                    <Text type="secondary" style={{ fontSize: 12 }}>
                        {fullMobile}
                    </Text>
                </List.Item>
                <div />
            </List>
            <List>
                <List.Item>
                    <List.Item.Meta
                        title={
                            <Text style={{ fontSize: 13 }}>OTP Code:</Text>
                        }
                    />
                    <Text type="secondary" style={{ fontSize: 12 }}>
                        {emailVerificationToken}
                    </Text>
                </List.Item>
                <div />
            </List>
            <List>
                <List.Item>
                    <List.Item.Meta
                        title={
                            <Text style={{ fontSize: 13 }}>{'Work Balance: '}</Text>
                        }
                    />
                    <Space>
                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                            <Text type="secondary" style={{ fontSize: 12 }}>
                                $
                                {workBalance}
                            </Text>
                        </Space>
                    </Space>
                </List.Item>
                <div />
            </List>
            <List>
                <List.Item>
                    <List.Item.Meta
                        title={
                            <Text style={{ fontSize: 13 }}>Earnings: </Text>
                        }
                    />
                    <Text type="secondary" style={{ fontSize: 12 }}>
                        {pickerPay == null ? '$0.00' : `$${pickerPay?.toFixed(2)}`}
                    </Text>
                </List.Item>
                <div />
            </List>
            <List>
                <List.Item>
                    <List.Item.Meta
                        title={
                            <Text style={{ fontSize: 13 }}>Wallet: </Text>
                        }
                    />
                    <Text type="secondary" style={{ fontSize: 12 }}>
                        $
                        {wallet}
                    </Text>
                </List.Item>
                <div />
            </List>
        </Card>
    );
};
