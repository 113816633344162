export enum EChatType {
    ISSUE = 'ISSUE',
}

export enum EChatUserRole {
    STORE = 'STORE',
    SUPPORT = 'SUPPORT',
    CLIENT = 'CLIENT'
}

export enum EMessageType {
    TEXT = 'text',
    AUDIO = 'audio',
    IMAGE = 'image',
    VIDEO = 'video'
}
