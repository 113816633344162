import { Button, Dropdown } from 'antd';
import { IoFilterSharp } from 'react-icons/io5';
import Filters from './Filters';

interface Props {
    showFilters: boolean;
    setShowFilters: (show: boolean) => void;
    handleAlertType: (value: string) => void;
    handleStatus: (value: string) => void;
    resetFilters: () => void;
}

const FiltersMenu = ({ ...props }: Props) => (
    <Dropdown
        trigger={['click']}
        placement="bottomRight"
        visible={props.showFilters}
        overlay={(
            <Filters
                handleAlertType={props.handleAlertType}
                handleStatus={props.handleStatus}
                setShowFilters={props.setShowFilters}
                resetFilters={props.resetFilters}
            />
        )}
    >
        <Button
            type="ghost"
            icon={(
                <IoFilterSharp
                    size={16}
                    style={{
                        verticalAlign: 'middle',
                    }}
                />
            )}
            className="flex-row center-row-center"
            onClick={() => props.setShowFilters(!props.showFilters)}
        />
    </Dropdown>
);

export default FiltersMenu;
