import { FC, createContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { initialContext } from './users.initial';
import { IContext } from './users.interface';
import { OctopusPaginatorQuery } from '../../../types/paginator';
import {
    getUser, getUsers, getWorkspacesRelated, getWorkspaceInvitations,
} from '../../../api/users';
import { IUser } from '../../../interfaces/user.interface';

export const UsersContext = createContext<IContext>(initialContext);

export const UsersProvider: FC = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingTab, setLoadingTab] = useState<boolean>(false);
    const [users, setUsers] = useState<IUser[]>(initialContext.users);
    const [user, setUser] = useState<IUser>(initialContext.user);
    const [total, setTotal] = useState<number>(initialContext.total);
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState<string>('1');

    const page = searchParams.get('page') || '1';
    const limit = searchParams.get('limit') || '10';
    const search = searchParams.get('search') || '';

    const [paginator, setPaginator] = useState<OctopusPaginatorQuery>({
        limit: Number(limit),
        page: Number(page),
    });

    const fetchUsers = () => {
        setLoading(true);

        getUsers({ paginator, search, role: 'business' })
            .then(({ data: response }: any) => {
                setUsers(response.data);
                setTotal(response.meta.itemCount);
            })
            .catch((error: any) => console.log(error))
            .finally(() => setLoading(false));
    };

    const handlePaginator = (newPaginator: OctopusPaginatorQuery) => {
        const paginationUpdated = { ...paginator, ...newPaginator };
        const params = new URLSearchParams({ ...(paginationUpdated as any) });
        setPaginator(paginationUpdated);
        setSearchParams(params);
    };

    const fetchUser = (id: string) => {
        setLoading(true);

        getUser(id)
            .then(({ data: response }: any) => setUser(response.data))
            .catch((error: any) => console.log(error))
            .finally(() => setLoading(false));
    };

    const fetchWorkspacesRelated = (userID: string) => {
        setLoadingTab(true);

        getWorkspacesRelated(userID)
            .then(({ data: response }: any) => {
                setUser(
                    (prevState: IUser) => ({ ...prevState, workspaces: response.data }),
                );
            })
            .catch((error: any) => console.log(error))
            .finally(() => setLoadingTab(false));
    };

    const fetchWorkspaceInvitations = (userID: string) => {
        setLoadingTab(true);

        getWorkspaceInvitations(userID)
            .then(({ data: response }: any) => {
                setUser(
                    (prevState: IUser) => ({ ...prevState, invitations: response.data }),
                );
            })
            .catch((error: any) => console.log(error))
            .finally(() => setLoadingTab(false));
    };

    return (
        <UsersContext.Provider
            value={{
                loading,
                loadingTab,
                users,
                user,
                activeTab,
                total,
                paginator,
                searchParams,
                setActiveTab,
                setPaginator,
                fetchUsers,
                fetchUser,
                fetchWorkspacesRelated,
                fetchWorkspaceInvitations,
                handlePaginator,
            }}
        >
            {children}
        </UsersContext.Provider>
    );
};
