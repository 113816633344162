import { IMAGES } from './images';

const images = IMAGES.providers;

export const PROVIDERS = {
    PICKER: { label: 'Picker', image: images.picker },
    MY_FLEET: { label: 'Mi Flota', image: images.own_fleet },
    GACELA: { label: 'Gacela', image: images.gacela },
    UBER: { label: 'Uber', image: images.uber },
    DELIVEREO: { label: 'Delivereo', image: images.delivereo },
    MI_PILOTO: { label: 'Mi Piloto', image: images.picker },
    UBER_MX: { label: 'Uber MX', image: images.uber },
    UBER_DAAS: { label: 'Uber Daas', image: images.uber },
    NINETY_NINE_MINUTES: { label: '99 Minutos', image: images.minutos99 },
    FASTER: { label: 'Faster', image: images.faster },
    STAR_DELIVERY: { label: 'Star Delivery', image: images.star_delivery },
    KANGURO: { label: 'Kanguro', image: images.kanguro },
    SMALL_FLEETS: { label: 'Small Fleets', image: images.small_fleet },
    IVOY: { label: 'Ivoy', image: images.ivoy },
    LALAMOVE: { label: 'Lalamove', image: images.lalamove },
    RAPPI: { label: 'Rappi', image: images.rappi },
    PEDIDOS_YA: { label: 'Pedidos Ya', image: images.pedidosya },
    MENSAJEROS_URBANOS: { label: 'Mensajeros Urbanos', image: images.mensajeros_urbanos },
    RAPPI_CARGO: { label: 'Rappi Cargo', image: images.rappi },
    RAPPI_CARGO_EC: { label: 'Rappi Cargo Ec', image: images.rappi },
    RAPPI_CARGO_CO: { label: 'Rappi Cargo Co', image: images.rappi },
    PEDIDOS_YA_EC: { label: 'Pedidos Ya Ec', image: images.pedidosya },
    BORZO: { label: 'Borzo', image: images.borzo },
    CHAZKI: { label: 'Chazki', image: images.chazki },
    PIBOX: { label: 'Pibox', image: images.pibox },
};

export enum Providers {
  PICKER = 'PICKER',
  MY_FLEET = 'MY_FLEET',
  GACELA = 'GACELA',
  UBER = 'UBER',
  DELIVEREO = 'DELIVEREO',
  MI_PILOTO = 'MI_PILOTO',
  UBER_MX = 'UBER_MX',
  UBER_DAAS = 'UBER_DAAS',
  NINETY_NINE_MINUTES = 'NINETY_NINE_MINUTES',
  FASTER = 'FASTER',
  STAR_DELIVERY = 'STAR_DELIVERY',
  KANGURO = 'KANGURO',
  SMALL_FLEETS = 'SMALL_FLEETS',
  IVOY = 'IVOY',
  LALAMOVE = 'LALAMOVE',
  RAPPI = 'RAPPI',
  PEDIDOS_YA = 'PEDIDOS_YA',
  RAPPI_CARGO = 'RAPPI_CARGO',
  RAPPI_CARGO_EC = 'RAPPI_CARGO_EC',
  RAPPI_CARGO_CO = 'RAPPI_CARGO_CO',
  PEDIDOS_YA_EC = 'PEDIDOS_YA_EC',
  BORZO = 'BORZO',
  CHAZKI = 'CHAZKI',
  PIBOX = 'PIBOX',
}

export const InternalProviders = [Providers.PICKER, Providers.MY_FLEET, Providers.SMALL_FLEETS];
