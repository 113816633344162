/* eslint-disable no-unused-vars */
import { Menu, Modal, notification } from 'antd';
import { useContext, useState } from 'react';
import { ITransaction } from '../../../interfaces/transaction.interface';
import { deleteTransaction } from '../../../api/dispersions';
import { DispersionContext } from '../context/dispersionContext';

interface Props {
    transaction: ITransaction;
}

interface IMenuItem {
    children: string;
    onClick: () => void;
}

type MenuItems = IMenuItem[];

const { ItemGroup, Item } = Menu;
const { confirm } = Modal;

const TransactionMenu = ({ transaction }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);

    const { fetchDispersion } = useContext(DispersionContext);

    const handleDeleteTransaction = () => {
        const data = {
            transactionID: transaction._id.toString(),
        };

        confirm({
            title: 'Are you sure to delete this transaction?',
            content: 'This action cannot be undone',
            onCancel: () => {},
            onOk: () => {
                setLoading(true);
                deleteTransaction(data)
                    .then(({ data }: any) => {
                        notification.success({
                            message: data.message,
                        });
                        fetchDispersion();
                    })
                    .catch((error: any) => {
                        const description = error?.response?.data?.message;
                        notification.error({
                            message: 'Oops! An error has occurred',
                            description,
                        });
                    })
                    .finally(() => setLoading(false));
            },
        });
    };

    const MENU: MenuItems = [
        {
            children: 'Eliminar transacción',
            onClick: () => handleDeleteTransaction(),
        },
    ];

    return (
        <Menu>
            <ItemGroup key="g">
                {
                    MENU.map((item: any, index: number) => (
                        <Item key={index} {...item} />
                    ))
                }
            </ItemGroup>
        </Menu>
    );
};

export default TransactionMenu;
