/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable guard-for-in */
import {
    Card, Col, Modal, Row, notification, Empty, Table, Space, Button, Alert,
} from 'antd';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { setSettingsTiersForBussines } from '../../../actions/modals';
import { getProvidersFromBusiness, updateDeliveryProviderFromBussines } from '../../../api/business';
import { IDeliveryProviderData } from '../../../types/deliveryProvider';
import { mutliDragAwareReorder } from '../../../utils/tier';
import { onClickRow, onTouchEndRow } from '../../../utils/drag-drop-action';
import { TierTime } from '../../TierTime';

interface Provider {
    name: string,
    id: string
}
interface DefaultTier {
  providers: Provider[],
  columnIds: string[]
  columns: any
}
interface Columns {
  name: string,
  id: string
  providerIds: string[]
}
interface DeliveryProvider {
  index: string;
  name: string;
  tier: number;
}

const defaultTier: DefaultTier = {
    providers: [],
    columnIds: ['deliveryProviders', '0', '1', '2', '3'],
    columns: {
        deliveryProviders: {
            id: 'deliveryProviders',
            name: 'Delivery Providers',
            providerIds: [
            ],
        },
        0: {
            id: '0',
            name: 'Tier 0',
            providerIds: [],
            time: 0,
        },
        1: {
            id: '1',
            name: 'Tier 1',
            providerIds: [],
            time: 0,
        },
        2: {
            id: '2',
            name: 'Tier 2',
            providerIds: [],
            time: 0,
        },
        3: {
            id: '3',
            name: 'Tier 3',
            providerIds: [],
            time: 0,
        },
    },
};

export const BussinesTiersModal = () => {
    const [loading, setLoading] = useState(false);
    const { bussines } = useSelector((state: any) => state.modals);
    const dispatch = useDispatch();
    const [entities, setEntities] = useState<DefaultTier>(defaultTier);
    const [isModified, setIsModified] = useState(false);

    const [selectedProviderIds, setSelectedProviderIds] = useState<any[]>([]);
    const [draggingProviderId, setDraggingProviderId] = useState(null);
    const tableColumns = [
        {
            dataIndex: 'name',
        },
    ];

    const handleOk = () => {
        setLoading(true);
        const deliveryProviders: DeliveryProvider[] = cloneDeep(bussines?.externalAPIConfiguration?.deliveryProviders);

        for (const tierNumber in entities.columns) {
            const tierNumberParsed = Number(tierNumber);
            const tier = entities.columns[tierNumberParsed];
            if (tier && tier.providerIds.length) {
                tier.providerIds.forEach((id: string) => {
                    const provider = deliveryProviders.find((providerActive: any) => providerActive.keyName === id);
                    if (provider) {
                        provider.tier = tierNumberParsed;
                    }
                });
            }
        }

        updateDeliveryProviderFromBussines(bussines._id, deliveryProviders, [])
            .then(({ data }) => {
                notification.success({
                    message: data.message,
                });
            })
            .catch((err) => {
                console.log(err);
                notification.error({ message: 'oops! An error has occurred' });
            })
            .finally(() => {
                setLoading(false);
                handleCancel();
            });
    };

    const handleCancel = () => {
        setEntities({ ...defaultTier });
        setSelectedProviderIds([]);
        setDraggingProviderId(null);
        dispatch(setSettingsTiersForBussines(null));
    };

    useEffect(() => {
        if (!bussines) return;
        setLoading(true);
        if (bussines.externalAPIConfiguration?.deliveryProviders?.length) {
            const isModified = bussines.externalAPIConfiguration?.deliveryProviders.some((provider: any) => provider.tier !== undefined);
            setIsModified(isModified);
        }
        getProvidersFromBusiness(bussines?._id)
            .then(({ data }) => {
                const deliveryProviders = data.data;
                const providers = deliveryProviders.map(
                    (provider: IDeliveryProviderData) => ({ id: provider.keyName, name: provider.name }),
                );
                const tierUpdated = cloneDeep(defaultTier);
                tierUpdated.providers = [...providers];
                let maxTier = tierUpdated.columnIds.length - 2;

                deliveryProviders.forEach((provider: { name: string, tier: number, keyName :string }) => {
                    if (provider.tier !== undefined) {
                        if (provider.tier > maxTier) {
                            const difference = provider.tier - maxTier;
                            for (let index = 0; index < difference; index++) {
                                const newTier = addTier();
                                tierUpdated.columnIds.push(`${newTier!.id}`);
                                tierUpdated.columns = { ...tierUpdated.columns, [`${newTier!.id}`]: newTier };
                                maxTier = tierUpdated.columnIds.length - 2;
                            }
                        }
                        tierUpdated.columns[provider.tier].providerIds.push(provider.keyName);
                    } else {
                        tierUpdated.columns.deliveryProviders.providerIds.push(provider.keyName);
                    }
                });
                if (bussines.externalAPIConfiguration?.tiers?.length) {
                    bussines.externalAPIConfiguration.tiers.forEach((tier: { time: number, index: number }) => {
                        if (tierUpdated.columns[`${tier.index}`]) {
                            tierUpdated.columns[`${tier.index}`].time = tier.time / 60;
                        } else {
                            const newTier = addTier();
                            tierUpdated.columnIds.push(`${newTier!.id}`);
                            tierUpdated.columns = { ...tierUpdated.columns, [`${newTier!.id}`]: newTier };
                            tierUpdated.columns[`${tier.index}`].time = tier.time / 60;
                        }
                    });
                }
                setEntities({ ...tierUpdated });
            })
            .catch((err) => {
                console.log({ err });
            })
            .finally(() => {
                setLoading(false);
            });
    }, [bussines]);

    const onWindowClick = useCallback((e) => {
        if (e.defaultPrevented) {
            return;
        }

        setSelectedProviderIds([]);
    }, []);

    const onWindowKeyDown = useCallback((e) => {
        if (e.defaultPrevented) {
            return;
        }

        if (e.key === 'Escape') {
            setSelectedProviderIds([]);
        }
    }, []);

    const onWindowTouchEnd = useCallback((e) => {
        if (e.defaultPrevented) {
            return;
        }

        setSelectedProviderIds([]);
    }, []);

    useEffect(() => {
        window.addEventListener('click', onWindowClick);
        window.addEventListener('keydown', onWindowKeyDown);
        window.addEventListener('touchend', onWindowTouchEnd);

        return () => {
            window.removeEventListener('click', onWindowClick);
            window.removeEventListener('keydown', onWindowKeyDown);
            window.removeEventListener('touchend', onWindowTouchEnd);
        };
    }, [onWindowClick, onWindowKeyDown, onWindowTouchEnd]);

    const addTier = (): Columns| undefined => {
        const maxTier = 21;
        if (entities.columnIds.length > maxTier) return;
        const id = `${entities.columnIds.length - 1}`;

        const newTier = {
            id,
            name: `Tier ${entities.columnIds.length - 1}`,
            providerIds: [],
            time: 0,
        };
        const tierUpdated = cloneDeep(entities);
        tierUpdated.columns = { ...tierUpdated.columns, [`${id}`]: newTier };
        tierUpdated.columnIds.push(id);
        setEntities({ ...tierUpdated });
        return newTier;
    };

   interface Props {
    columnId: string, providers: Provider[], className: string
  }
   const DroppableTableBody:React.FC<Props> = (props) => (
       <Droppable
           droppableId={props.columnId}
       >
           {(provided, snapshot) => (
               <tbody
                   ref={provided.innerRef}
                   {...props}
                   {...provided.droppableProps}
                   className={`${props.className} ${
                       snapshot.isDraggingOver && entities.columnIds.includes(props.columnId)
                           ? 'is-dragging-over'
                           : ''
                   }`}
               />
           )}
       </Droppable>
   );

  interface Props1 {
    index: number, record: {id: string}, columnId: string, providers: Provider[], 'data-row-key':any
  }
  const DraggableTableRow :React.FC<Props1> = (props) => {
      if (!props.providers.length) {
          return (
              <tr className="ant-table-placeholder row-item" {...props}>
                  <td colSpan={tableColumns.length} className="ant-table-cell">
                      <div className="ant-empty ant-empty-normal">
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      </div>
                  </td>
              </tr>
          );
      }

      const isSelected = selectedProviderIds.some(
          (selectedProviderId) => selectedProviderId === props.record.id,
      );
      const isGhosting = isSelected && Boolean(draggingProviderId) && draggingProviderId !== props.record.id;
      return (
          <Draggable
              key={props['data-row-key']}
              draggableId={props['data-row-key']?.toString()}
              index={props.index}
          >
              {(provided, snapshot) => (
                  <tr
                      ref={provided.innerRef}
                      {...props}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`row-item ${isSelected ? 'row-selected' : ''} ${
                          isGhosting ? 'row-ghosting' : ''
                      } ${snapshot.isDragging ? 'row-dragging' : ''}`}
                  />
              )}
          </Draggable>
      );
  };

  const getProviders = (entities: DefaultTier, id: string) => entities.columns[id].providerIds.map(
      (providerId: string) => entities.providers.find((item: { id: string }) => item.id === providerId),
  );

  const onDragEnd = (result: any) => {
      const { destination } = result;
      const { source } = result;

      if (!destination || result.reason === 'CANCEL') {
          setDraggingProviderId(null);
          return;
      }

      const processed = mutliDragAwareReorder({
          entities,
          selectedProviderIds,
          source,
          destination,
      });

      setEntities(processed.entities);
      setDraggingProviderId(null);
  };

  const onBeforeCapture = (start: any) => {
      const { draggableId } = start;
      const selected = selectedProviderIds.find((providerId) => providerId === draggableId);

      if (!selected) {
          setSelectedProviderIds([]);
      }

      setDraggingProviderId(draggableId);
  };

  const handleTierTimeChange = async (tierNumber: number, time: number) => {
      const tiers = bussines.externalAPIConfiguration?.tiers || [];
      const tierIndex = tiers.findIndex((tier: any) => tier.index === tierNumber);
      let tierUpdated: any = { index: tierNumber, time: time * 60 };
      if (tierIndex === -1) {
          tierUpdated.isActive = true;
          tiers.push(tierUpdated);
      } else {
          tierUpdated = { ...bussines.externalAPIConfiguration?.tiers![tierIndex], ...tierUpdated };
          tiers[tierIndex] = tierUpdated;
      }
      try {
          notification.info({
              message: 'Updating tier time',
              duration: 2,
          });
          const tierUpdated = { ...entities };
          tierUpdated.columns[tierNumber].time = time;
          setEntities({ ...tierUpdated });
          await updateDeliveryProviderFromBussines(bussines._id, [], tiers);
          notification.success({ message: 'Updated tiers configuration' });
      } catch (error) {
          console.log({ error });
          notification.error({ message: 'Error updating tier time' });
      }
  };

  return (
      <Modal
          title="Settings Bussines Tiers"
          visible={bussines}
          onOk={handleOk}
          onCancel={handleCancel}
          confirmLoading={loading}
          bodyStyle={{ padding: 0 }}
          width={1000}
      >
          <>
              <Alert
                  message={isModified ? 'Tiers configuration has been modified' : "Tiers configuration hasn't been modified"}
                  type="warning"
                  showIcon
                  closable
                  style={{ margin: '10px 20px' }}
              />
              {entities.providers
          && (
              <DragDropContext
                  onBeforeCapture={onBeforeCapture}
                  onDragEnd={onDragEnd}
              >
                  <Row gutter={[20, 20]}>
                      {entities.columnIds.map((id:string) => (
                          <Col key={id} xs={24} md={12}>
                              <Card
                                  className={`c-multi-drag-table ${draggingProviderId ? 'is-dragging' : ''}`}
                                  loading={loading}
                              >
                                  <div className="inner-col">
                                      <Row justify="space-between" align="middle">
                                          <h2>{entities.columns[id].name}</h2>
                                          <TierTime time={entities.columns[id].time || 0} tier={id} setTime={handleTierTimeChange} />
                                      </Row>

                                      <Table
                                          dataSource={getProviders(entities, id)}
                                          pagination={false}
                                          columns={tableColumns}
                                          rowKey="id"
                                          components={{
                                              body: {
                                              // Custom tbody
                                                  wrapper: (val: any) => DroppableTableBody({
                                                      columnId: id,
                                                      providers: getProviders(entities, id),
                                                      ...val,
                                                  }),
                                                  row: (val: any) => DraggableTableRow({
                                                      providers: getProviders(entities, id),
                                                      ...val,
                                                  }),
                                              },
                                          }}
                                          onRow={(record, index) => ({
                                              index,
                                              record,
                                              onClick: (e) => onClickRow(e, record, selectedProviderIds, setSelectedProviderIds, entities),
                                              onTouchEnd: (e) => onTouchEndRow(e, record, selectedProviderIds, setSelectedProviderIds),
                                          })}
                                      />
                                  </div>
                              </Card>
                          </Col>
                      ))}
                  </Row>
                  <br />
              </DragDropContext>
          )}

          </>
          <Space direction="vertical" style={{ width: '100%' }}>
              <Button onClick={addTier} type="dashed" ghost block>
                  + Add Tier
              </Button>
          </Space>
      </Modal>
  );
};
