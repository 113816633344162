import moment from 'moment';
import {
    Button,
    Space,
    Typography,
} from 'antd';
import { AiFillFileExcel } from 'react-icons/ai';
import { FaFileCsv } from 'react-icons/fa';
import { IInvoice } from '../context/invoices.interface';
import { OCTOPUS_BASE_URL } from '../../../api';

interface Props {
    invoice: IInvoice;
}

const { Text } = Typography;

const Transactions = ({ invoice }: Props) => {
    const handleDownload = () => {
        const token = localStorage.getItem('token');
        const paymentId = invoice?._id;
        const utc = (moment().utcOffset() / 60).toString();
        window.open(`${OCTOPUS_BASE_URL}/reports?CSVtype=paymentsReport&paymentId=${paymentId}&token=${token}&utc=${utc}`, '_blank');
    };

    return (
        <Space direction="vertical" size={16}>
            <Text>
                Download report of bookings associated with this invoice
            </Text>
            <Space size={8}>
                <Button
                    type="ghost"
                    onClick={() => handleDownload()}
                    icon={<FaFileCsv style={{ verticalAlign: 'middle', marginRight: 4 }} size={12} />}
                >
                    Download CSV
                </Button>
                <Button
                    type="ghost"
                    disabled
                    onClick={() => {}}
                    icon={<AiFillFileExcel style={{ verticalAlign: 'middle', marginRight: 4 }} size={13} />}
                >
                    Download Excel (xlsx)
                </Button>
            </Space>
        </Space>
    );
};

export default Transactions;
