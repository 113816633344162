import { Issue } from '../components/Shield';
import { ShieldProvider } from '../components/Shield/context/shieldContext';

const IssueDetail = () => (
    <div className="screen__issue-detail">
        <ShieldProvider>
            <Issue />
        </ShieldProvider>
    </div>
);

export default IssueDetail;
