export interface ContactsType {
  countryCode: string
  isPrimary: boolean
  isVerified: boolean
  mobile: string
  role: string
  _id: string
}

export interface DriverID {
  MCNumber: string
  accountingType: string
  automaticAcceptation: boolean
  batteryPercentage: any
  batteryStatus: any
  bookingsCompleted: number
  carType: number
  createdAt: string
  currentDriverLocation: { coordinates: number[], type: string }
  isActive: boolean
  lastOTPValidation: string
  locationBackground: boolean
  locationForeground: boolean
  onDutyFlag: boolean
  payment: { value: number, type: string }
  paymentMethod: any
  savingBattery: boolean
  tag: string
  type: string
  updatedAt: string
  validatedOTP: boolean
  vehicleColor: string
  vehicleModelName: string
  vehicleType: string
  vehicleYear: number
  wallet: number
  workBalance: number
}
export interface DriverType {
  assignedBy: null
  assignedTo: null
  businessAccess: any[]
  businessAddressID: null
  businessID: null
  contacts: ContactsType[]
  countryCode: string
  createdAt: string
  createdBy: null
  customerAddressID: null
  customerID: null
  dateOfBirth: string
  driverAddressID: string
  driverID: DriverID
  email: string
  emailVerificationToken?: string
  favoriteBusinesses: any[]
  favoriteDrivers: any[]
  favoriteProducts: any[]
  fullMobile: string
  groups: any[]
  identityNumber: string
  image: { original: string, thumbnail: string }
  isAdminVerified: boolean
  isBlocked: boolean
  isBusy: boolean
  isDeleted: boolean
  isEmailVerified: boolean
  isPhoneVerified: boolean
  lastName: string
  mobile: string
  name: string
  onDutyFlag: boolean
  online: boolean
  ownFleetDrivers: any[]
  passwordResetToken: string
  preference: object
  promoAdded: null
  ratedByUserCount: 0
  rememberMe: false
  role: string[]
  serviceProviderAddressID: null
  serviceProviderID: null
  socialAccounts: any[]
  superRegionID: null
  totalCreatedUsers: 0
  totalRatingPoints: 0
  updatedAt: string
  userUniqueID: string
  _id: string
}

export interface IDriverLocation {
  coordinates: [lng: number, lat: number],
  type: string;
}

export interface IDriverTrackingDetails {
  accountingType: string;
  automaticAcceptation: boolean;
  batteryPercentage: number;
  batteryStatus: number;
  bookingsCompleted: number;
  carType: number;
  currentDriverLocation: IDriverLocation
  isActive: boolean;
  isSmallFleetsDriver: boolean;
  lastOTPValidation: string;
  locationBackground: boolean;
  locationForeground: boolean;
  onDutyFlag: boolean;
  paymentBalance: number;
  paymentMethod: any;
  savingBattery: boolean;
  tag: string;
  type: string;
  updatedAt: string;
  validatedOTP: boolean;
  vehicleType: string;
  wallet: number;
  workBalance: number;
}

export interface IDriverTracking {
  businessAccess: any[];
  businessAddressID: any
  businessID: any
  countryCode: string;
  details: IDriverTrackingDetails[]
  driverID: string;
  email: string;
  favoriteBusinesses: any[]
  favoriteDrivers: any[]
  favoriteProducts: any[]
  fullMobile: string;
  groups: any[]
  identityNumber: string;
  image: { original: null | string, thumbnail: null | string }
  isBlocked: boolean;
  isBusy: boolean;
  isDeleted: boolean;
  lastName: string;
  mobile: string;
  name: string;
  onDutyFlag: boolean;
  online: boolean;
  ownFleetDrivers: any[]
  superRegionID: any;
  statusText: string;
}

export enum ECarName {
  LITE = 'LITE',
  BIKE = 'BIKE'
}

export interface IDriverManualRequestUser {
  countryCode: string;
  email: string;
  image: { original: string, thumbnail: string }
  isBusy: boolean;
  lastName: string;
  mobile: string;
  name: string;
  onDutyFlag: boolean;
}

export interface IDriverManualRequest {
  batteryPercentage: number;
  batteryStatus: number;
  carType: number;
  distance: number;
  driverID: string;
  location: [lng: number, lat: number];
  locationBackground: boolean;
  locationForeground: boolean;
  tag: string;
  type: string;
  updatedAt: string;
  user: IDriverManualRequestUser;
  userID: string;
  workBalance: number;
  _id: string;
}

export type TGetAllAvailableDriver = {
  limit: number;
  skip: number;
  carType: number;
  latitude: number;
  longitude: number;
  bookingID: string;
}
