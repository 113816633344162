import {
    Button,
    Col, PageHeader, Row, Select, Space, Table, Tabs,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getPayments } from '../api/payments';
import { OctopusPaginatorQuery } from '../types/paginator';
import PaymentTransactions from '../components/Modals/PaymentTransactions';
import PaymentezReceivables from '../components/Receivables/PaymentezReceivables';
import StripeReceivables from '../components/Receivables/StripeReceivables';
import { CreatePayment } from '../components/Modals/CreatePayment';
import { IPayment } from '../interfaces/payment.interface';

const { TabPane } = Tabs;
interface Payments {
    data: IPayment[];
    currentPage: number;
    pages: number;
}

const Receivables = () => {
    const [paymentsPagination, setPaymentsPagination] = useState<OctopusPaginatorQuery>({
        limit: 10,
        page: 1,
    });
    const [payments, setPayments] = useState<Payments>({ data: [], currentPage: 1, pages: 1 });
    const [createPayment, setCreatePayment] = useState(false);
    const [loadingPayments, setLoadingPayments] = useState(false);
    const [paymentSelected, setPaymentSelected] = useState<any>();
    const [searchParams, setSearchParams] = useSearchParams();
    const currentStatusString = searchParams.get('currentStatus') || '[]';
    const currentStatus = JSON.parse(currentStatusString);

    const OPTIONS = [
        { label: 'Active', value: 'ACTIVE' },
        { label: 'Success', value: 'SUCCESS' },
        { label: 'Failed', value: 'FAILED' },
        { label: 'Pending', value: 'PENDING' },
    ];

    useEffect(() => {
        setLoadingPayments(true);
        getPayments(paymentsPagination, currentStatus)
            .then(({ data }: any) => {
                setPayments(data.data);
            })
            .catch((error: any) => {
                console.log(error);
            })
            .finally(() => setLoadingPayments(false));
    }, [paymentsPagination, currentStatusString]);

    const columns = [
        {
            title: 'Id',
            dataIndex: 'paymentId',
            key: 'paymentId',
            render: (paymentId: string, payment: any) => (
                <div style={{ color: '#08d108' }} onClick={() => setPaymentSelected(payment)}>
                    {paymentId}
                </div>
            ),
        },
        {
            title: 'WS UniqueId',
            dataIndex: 'workspaceUniqueID',
            key: 'workspaceUniqueID',
        },
        {
            title: 'WS',
            dataIndex: 'workspaceName',
            key: 'workspaceName',
        },
        {
            title: 'External Id',
            dataIndex: 'externalId',
            key: 'externalId',
        },
        {
            title: 'Gateway',
            dataIndex: 'gateway',
            key: 'gateway',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Auth Code',
            dataIndex: 'authCode',
            key: 'authCode',
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: Date) => moment(createdAt).format('HH:mm DD/MM/YY'),
        },
        {
            title: 'Error Messaje',
            dataIndex: 'errorMessage',
            key: 'errorMessage',
        },
    ];

    const handleOpenCreatePayment = () => {
        setCreatePayment(true);
    };

    const handleCloseCreatePayment = (payment: IPayment) => {
        if (payment) {
            setPayments((payments) => ({ ...payments, data: [payment, ...payments.data] }));
        }
        setCreatePayment(false);
    };

    const getParams = () => {
        const query: any = {};
        currentStatus.length && (query.currentStatus = currentStatus);
        return query;
    };

    const handleCurrentStatus = (status: any) => {
        const { currentStatus, ...params } = getParams();
        status.length && (params.currentStatus = JSON.stringify(status));
        setSearchParams(params);
        setPaymentsPagination((pagination) => ({ ...pagination, page: 1 }));
    };

    const onPageChange = (page: number, pageSize: number) => {
        setPaymentsPagination({ limit: pageSize, page });
    };

    const updatePayment = (payment: any) => {
        setPayments((payments) => {
            const data = payments.data.map((p) => {
                if (p._id === payment._id) {
                    return payment;
                }
                return p;
            });
            return { ...payments, data };
        });
    };

    return (
        <div>
            <PageHeader
                title="Receivables"
            />
            <Tabs defaultActiveKey="1">
                <TabPane tab="Paymentez" key="1">
                    <PaymentezReceivables setPaymentsPagination={setPaymentsPagination} />
                </TabPane>
                <TabPane tab="Stripe" key="2">
                    <StripeReceivables setPaymentsPagination={setPaymentsPagination} />
                </TabPane>
            </Tabs>
            <PaymentTransactions
                payment={paymentSelected}
                onClose={(payment: any) => {
                    if (payment) {
                        updatePayment(payment);
                    }
                    setPaymentSelected(null);
                }}
            />
            <CreatePayment
                visible={createPayment}
                onCancel={handleCloseCreatePayment}
            />
            <Row justify="space-between" gutter={[16, 24]}>
                <Col>
                    <Select
                        defaultValue={currentStatus}
                        mode="multiple"
                        style={{ width: '300px' }}
                        placeholder="Dispersion status"
                        onChange={handleCurrentStatus}
                        options={OPTIONS}
                        maxTagCount="responsive"
                    />
                </Col>
                <Col>
                    <Button onClick={handleOpenCreatePayment} type="primary">Create Payment</Button>
                </Col>
            </Row>
            <Space direction="vertical" size={24} style={{ width: '100%' }}>
                <Table
                    loading={loadingPayments}
                    columns={columns}
                    dataSource={payments.data}
                    bordered
                    scroll={{ x: 1500 }}
                    pagination={{
                        total: payments.pages * (paymentsPagination.limit || 1),
                        current: payments.currentPage,
                        pageSize: paymentsPagination.limit,
                        onChange: onPageChange,
                        showSizeChanger: true,
                    }}
                    rowKey="_id"
                />
            </Space>
        </div>
    );
};

export default Receivables;
