import { Checkbox, Col } from 'antd';
import { useContext } from 'react';
import { CommandCenterContext } from '../context/commandCenterContext';
import BusinessInputSearch from './BusinessInputSearch';
import DeliveryProviderSelect from './DeliveryProviderSelect';
import DriverTagsSelect from './DriverTagsSelect';

const MapOptionSection = () => {
    const { isLoadingCommandCenterContext, updateShowRegion } = useContext(CommandCenterContext);
    return (
        <>
            <Col span={6}>
                <DeliveryProviderSelect
                    style={{ width: '100%' }}
                />
            </Col>
            <Col span={6}>
                <DriverTagsSelect
                    style={{ width: '100%' }}
                />
            </Col>
            <Col span={6}>
                <BusinessInputSearch
                    style={{ width: '100%' }}
                />
            </Col>
            <Col>
                <Checkbox disabled={isLoadingCommandCenterContext} onChange={updateShowRegion}>Regions</Checkbox>
            </Col>
        </>
    );
};

export default MapOptionSection;
