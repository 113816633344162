export const COORDINATES: any = {
    EC: { lat: -2.170998, lng: -79.922356 },
    MX: { lat: 19.3971568, lng: -99.1944326 },
    DO: { lat: 18.4860575, lng: -69.93121169999999 },
    PE: { lat: -12.0463731, lng: -77.042754 },
    CO: { lat: 4.710988599999999, lng: -74.072092 },
    GT: { lat: 15.7199877, lng: -91.3558783 },
    CR: { lat: 9.748916999999999, lng: -83.753428 },
    CL: { lat: -33.4543808, lng: -70.6968268 },
    AR: { lat: -34.6192929, lng: -58.4942789 },
    UY: { lat: -32.522779, lng: -55.765835 },
    PA: { lat: 9.0815755, lng: -79.5355375 },
};
