import { useEffect, useState } from 'react';
import {
    Alert, Form, Input, Modal, notification, Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { setReportIssue } from '../../../actions/modals';
import { getBookingIssue, registerBookingIssue, setBookingIssue } from '../../../api/booking';

const { TextArea } = Input;
const { Option } = Select;

export const ReportIssueModal = () => {
    const [loading, setLoading] = useState(false);
    const [issues, setIssues] = useState([]);

    const { ridesReportIssue } = useSelector((state: any) => state.modals);

    const dispatch = useDispatch();

    const [form] = Form.useForm();

    const requiredRule = { required: true, message: 'Required' };

    useEffect(() => {
        setLoading(true);
        getBookingIssue()
            .then((res: any) => setIssues(res.data.data))
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    }, []);

    const onFinish = async ({ issue, details, name }: any) => {
        setLoading(true);
        let issueId = issue;
        try {
            if (issue === 'other') {
                const data = await registerBookingIssue({ name, details });
                issueId = data.data.data._id;
            }
            const data = await setBookingIssue(ridesReportIssue._id, [issueId]);
            notification.success({
                message: data.data.message,
                description: 'Booking reported successfully',
            });
            dispatch(setReportIssue(null));
        } catch (error: any) {
            notification.error({ message: error.response?.data?.message });
        } finally { setLoading(false); }
        form.resetFields();
    };

    const onCancel = () => {
        dispatch(setReportIssue(null));
    };

    return (
        <Modal
            visible={ridesReportIssue}
            title={`Report Issue Booking N°: #${ridesReportIssue?.bookingNumericId}`}
            onCancel={onCancel}
            onOk={() => form.submit()}
            confirmLoading={loading}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                requiredMark={false}
            >
                <Form.Item label="Options" name="issue" rules={[requiredRule]}>
                    <Select style={{ width: '100%' }}>
                        {
                            issues.map((issue: any) => (
                                <Option key={issue._id} value={issue._id}>{issue.name}</Option>
                            ))
                        }
                        <Option value="other">Add another issue</Option>
                    </Select>
                </Form.Item>

                <Form.Item shouldUpdate={(prev, curr) => prev.issue !== curr.issue} noStyle>
                    {() => {
                        const issueId = form.getFieldValue('issue');
                        const issue: any = issues.find((issue: any) => issue._id === issueId);
                        if (!issue) return null;
                        return (
                            <Alert message={issue.details} type="warning" showIcon closable />
                        );
                    }}
                </Form.Item>
                <Form.Item shouldUpdate={(prev, curr) => prev.issue !== curr.issue} noStyle>
                    {() => {
                        const issueId = form.getFieldValue('issue');
                        if (issueId !== 'other') return null;
                        return (
                            <>
                                <Form.Item label="Name" name="name" rules={[requiredRule]}>
                                    <Input placeholder="name" />
                                </Form.Item>
                                <Form.Item label="Details" name="details" rules={[requiredRule]}>
                                    <TextArea placeholder="details" rows={4} />
                                </Form.Item>
                            </>
                        );
                    }}
                </Form.Item>
            </Form>
        </Modal>
    );
};
