import moment from 'moment';
import cs from 'classnames';
import { useContext } from 'react';
import { Space, Typography } from 'antd';
import { IExpressDelivery } from '../../../interfaces/express-delivery.interface';
import Actions from './Actions';
import { ExpressDeliveryContext } from '../context/express-delivery.context';

interface ColumnProps {
    title: string;
    dataIndex: string;
    key: string;
    align?: 'center' | 'left' | 'right';
    render?: (value: any, record: any) => React.ReactNode;
}

const { Text } = Typography;

const InitialColumns = () => {
    const { setExpressSelected } = useContext(ExpressDeliveryContext);
    const INITIAL_COLUMNS: ColumnProps[] = [
        {
            title: 'Numeric ID',
            dataIndex: 'expressNumericId',
            key: 'expressNumericId',
            render: (text: string) => (
                <Text>
                    {text}
                </Text>
            ),
        },
        {
            title: 'Workspace',
            dataIndex: 'workspaceUniqueID',
            key: 'workspaceUniqueID',
            render: (text: string, item: any) => (
                <Space direction="vertical" size={4}>
                    <Text>
                        {text}
                    </Text>
                    <Text>
                        {item.workspaceName}
                    </Text>
                </Space>
            ),
        },
        {
            title: 'Date & Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text: string) => {
                const date = moment(text).format('dddd, D MMM');
                const time = moment(text).format('h:mm a');
                return (
                    <Space direction="vertical" size={4}>
                        <Text>
                            {date}
                        </Text>
                        <Text>
                            {time}
                        </Text>
                    </Space>
                );
            },
        },
        {
            title: 'Customer',
            dataIndex: 'customerName',
            key: 'customerName',
            render: (text: string, item: any) => (
                <Text>
                    {`${text} ${item.customerLastName}`}
                </Text>
            ),
        },
        {
            title: 'Business',
            dataIndex: 'businessName',
            key: 'businessName',
            render: (text: string) => (
                <Text>
                    {text}
                </Text>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: string) => (
                <Text className={cs(text, 'status')}>
                    {text}
                </Text>
            ),
        },
        {
            title: 'Actions',
            dataIndex: '_id',
            key: 'actions',
            align: 'center' as 'center',
            render: (_id: string, record: IExpressDelivery) => (
                <Actions
                    expressDelivery={record}
                    setExpressSelected={setExpressSelected}
                />
            ),
        },
    ];

    return INITIAL_COLUMNS;
};

export default InitialColumns;
