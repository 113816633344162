import moment from 'moment';
import cs from 'classnames';
import { useState } from 'react';
import {
    Button, Card, Space, Typography,
} from 'antd';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { BookingType } from '../../../types/booking';
import { IBookingLog } from '../../../interfaces/booking-log.interface';

interface Props {
    showDetail?: boolean;
    type?: 'SUCCESS' | 'FAILED' | 'RESOLVING'
    booking: BookingType;
    bookingLog?: IBookingLog;
    bookingItemSelected: boolean;
}

const { Title, Text } = Typography;

const CardLog = ({
    showDetail = false, type = 'SUCCESS', booking, bookingLog, bookingItemSelected,
}: Props) => {
    const [seeAdditionalInfo, setSeeAdditionalInfo] = useState<boolean>(false);

    const date = moment(bookingLog?.createdAt).format('DD MMM HH:mm:ss');

    const payloadViewer = ({ payload }: { payload: any }) => {
        const jsonString = JSON.stringify(payload, null, 2);
        const jsonLines = jsonString.split('\n').map((line, index) => (
            <span key={index}>
                <span
                    style={{
                        color: '#6E7B9C',
                        marginRight: 16,
                    }}
                >
                    {index + 1}
                </span>
                {' '}
                {line}
                <br />
            </span>
        ));

        return (
            <div style={{ fontFamily: 'monospace', whiteSpace: 'pre' }}>
                {jsonLines}
            </div>
        );
    };

    return (
        !showDetail ? (
            <div className={cs('card__event', { active: bookingItemSelected })}>
                <Space size={8}>
                    <Text className="card__tag log">
                        Log
                    </Text>
                </Space>
                <Text>
                    {bookingLog?.description}
                </Text>
                <Text style={{ fontSize: 12, fontStyle: 'italic' }}>
                    {date}
                </Text>
            </div>
        ) : (
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
                <Title level={5} style={{ margin: 0 }}>
                    Log Detail
                </Title>

                <Space size={8}>
                    <Text className="card__tag log">
                        Log
                    </Text>
                    { bookingLog?.endpointType && (
                        <Text className={cs('card__tag')}>
                            {bookingLog?.endpointType}
                        </Text>
                    )}
                </Space>

                <Text>
                    {bookingLog?.description}
                </Text>

                <Space direction="vertical" size={8} style={{ width: '100%' }}>
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => setSeeAdditionalInfo(!seeAdditionalInfo)}
                        icon={(
                            seeAdditionalInfo
                                ? (
                                    <FaCaretUp
                                        size={14}
                                        style={{ verticalAlign: 'middle', marginRight: 6 }}
                                    />
                                ) : (
                                    <FaCaretDown
                                        size={14}
                                        style={{ verticalAlign: 'middle', marginRight: 6 }}
                                    />
                                )
                        )}
                    >
                        See unformatted log
                    </Button>
                    {
                        seeAdditionalInfo && (
                            <Card>
                                <Text>
                                    {bookingLog?.additionalInfo}
                                </Text>
                            </Card>
                        )
                    }
                </Space>

                <Space direction="vertical" size={8} style={{ width: '100%' }}>
                    <Text strong>
                        Payload data
                    </Text>
                    <Card
                        style={{
                            maxHeight: 500,
                            overflowY: 'scroll',
                        }}
                    >
                        {payloadViewer({ payload: bookingLog?.payload })}
                    </Card>
                </Space>

                <Text style={{ fontSize: 12, fontStyle: 'italic' }}>
                    {date}
                </Text>
            </Space>
        )
    );
};

export default CardLog;
