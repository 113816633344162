import cs from 'classnames';
import moment from 'moment';
import {
    List, Space, Spin, Typography,
} from 'antd';
import { IPayment } from '../../../interfaces/payment.interface';
import ItemData from '../../../widgets/ItemData';

interface Props {
    payments: IPayment[] | [];
    loading?: boolean;
}

const { Text } = Typography;

const Payments = ({ payments, loading }: Props) => (

    loading ? (
        <Space>
            <Spin size="small" />
            <Text type="secondary">
                Loading payments...
            </Text>
        </Space>
    )
        : payments.length === 0 ? (
            <Text type="secondary">
                No payments found.
            </Text>
        ) : (

            <List
                dataSource={payments}
                renderItem={(payment: IPayment) => {
                    const createdDate = moment(payment.createdAt).format('ddd D MMM YYYY');

                    return (
                        <List.Item key={payment._id}>
                            <List.Item.Meta
                                title={(
                                    <Space direction="vertical">
                                        <Text style={{ fontSize: 12 }} type="secondary">
                                            {`Created at ${createdDate}`}
                                        </Text>
                                        <Space>
                                            {`Payment: ${payment.paymentId}`}
                                            <Text className={cs(payment.status.toLowerCase(), 'status small')}>
                                                {payment.status}
                                            </Text>
                                        </Space>
                                    </Space>
                                )}
                                description={(
                                    <Space direction="vertical" size={16} style={{ width: '100%' }}>
                                        {
                                            payment.errorMessage && (
                                                <Text>
                                                    Error:
                                                    {' '}
                                                    <Text type="secondary">
                                                        {payment.errorMessage || ''}
                                                    </Text>
                                                </Text>
                                            )
                                        }
                                        <Space
                                            size={0}
                                            direction="vertical"
                                            style={{ width: '100%', marginTop: 8 }}
                                        >
                                            <ItemData label="Amount" value={payment.amount.toFixed(2) || 0.00} />
                                            <ItemData label="Method" value={payment.method} />
                                            <ItemData label="Gateway" value={payment.gateway} />
                                        </Space>
                                    </Space>
                                )}
                            />
                        </List.Item>
                    );
                }}
            />
        )
);

export default Payments;
