/* eslint-disable react/no-unused-prop-types */
import {
    Alert, Button, Input, Select, Typography,
} from 'antd';
import { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { TIssue } from '../../../../../types/issue';

const { Option } = Select;

interface ICreateReportIssue {
    currentIssues: TIssue[];
    onSuccess?: () => void;
    setMode: any;
    setIssueSelectedID: (params: any) => void
}

const CreateReportIssue = ({
    currentIssues,
    setMode,
    setIssueSelectedID,
}: ICreateReportIssue) => {
    const [issue, setIssue] = useState<any>();

    const handleSelect = (value: string) => {
        const issueFind = currentIssues.find((i: any) => i._id === value);
        issueFind && setIssue(issueFind);
        issueFind && setIssueSelectedID(issueFind._id);
    };

    return (
        <>
            <Input.Group compact style={{ width: '100%', justifyContent: 'center', margin: '16px 0' }}>
                <Select
                    style={{ width: '90%' }}
                    showSearch
                    placeholder="Search to issue"
                    optionFilterProp="children"
                    filterOption={(input, option) => (option!.children as unknown as string).includes(input)}
                    filterSort={(optionA, optionB) => (optionA!.children as unknown as string)
                        .toLowerCase()
                        .localeCompare((optionB!.children as unknown as string).toLowerCase())}
                    onSelect={handleSelect}
                >

                    {currentIssues.map((issue) => <Option key={issue._id} value={issue._id}>{issue.name}</Option>)}
                </Select>
                <Button style={{ width: '10%' }} onClick={() => setMode('CREATE_ISSUE')} type="primary"><AiOutlinePlus size={20} /></Button>
            </Input.Group>
            {issue && <Alert message={issue.details} type="warning" showIcon />}
        </>
    );
};

export default CreateReportIssue;
