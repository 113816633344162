import { useState } from 'react';
import {
    Avatar, Button, Card, List, Space, Tooltip, Typography,
    message,
} from 'antd';
import { GrMap } from 'react-icons/gr';
import { FaCopy } from 'react-icons/fa';

import { useSelector } from 'react-redux';
import { isUndefined } from 'lodash';
import { Providers } from '../const/providers';
import ModalForm from './ModalForm';
import { IMAGES } from '../const/images';
import { PUBLIC_BOOKING_URL } from '../api/booking';

const items = {
    businessName: 'Nombre del local',
    typeText: 'Tipo',
    customerName: 'Nombre del cliente',
    customerMobile: 'Teléfono del cliente',
};

const { Text } = Typography;
const MILISECONDS_TO_MINUTE = 60000;

export const BookingDetails = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { ride: { active: booking }, deliveries: { deliveries, loading: loadingDeliveries } } = useSelector((state: any) => state);

    const deliveryProvider = booking && booking.deliveryProvider;
    const delivery = !loadingDeliveries ? deliveries.find((d: any) => d.keyName === deliveryProvider) || {} : {};
    const smallFleet = booking && booking.driverTag;

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    const pickupCoordinates = booking && booking.pickupCoordinates.coordinates;
    const deliveryCoordinates = booking && booking.deliveryCoordinates.coordinates;

    const isOnBatch = !isUndefined(booking.isActiveOnBatch) && !booking.isActiveOnBatch;

    const handleGoToButton = (coor: number[]) => {
        window.open(
            `https://www.google.com/maps/place/${coor[1]},+${coor[0]}/@${[coor[1], coor[0]].join(',')}z`,
        );
    };

    const footer = (
        <List.Item
            actions={[
                <Button onClick={openModal} size="small" type="primary">Editar</Button>,
            ]}
        >
            <List.Item.Meta
                title="Proveedor de delivery"
            />
            <Space>
                {deliveryProvider ? (
                    <>
                        <Avatar src={delivery?.image} />
                        {delivery?.name}
                        {deliveryProvider === Providers.SMALL_FLEETS && `: ${smallFleet}`}
                    </>
                ) : 'Sin asignar'}
            </Space>
        </List.Item>
    );

    return (
        <Card
            title="Detalles del pedido"
            extra={
                isOnBatch
          && (
              <Tooltip title="Driver has another booking in progress">
                  <Avatar
                      src={IMAGES.icons.batch}
                      shape="circle"
                      size={27}
                  />
                  In batch
              </Tooltip>
          )
            }
        >
            {isOpen && (<ModalForm visible={isOpen} onCancel={closeModal} />)}
            <List
                dataSource={Object.entries(items)}
                footer={footer}
            >
                {Object.entries(items).map(([key, value]) => (
                    <List.Item key={key}>
                        <List.Item.Meta
                            title={value}
                        />
                        <div>{booking && (booking as any)[key]}</div>
                    </List.Item>
                ))}
                <List.Item>
                    <List.Item.Meta
                        title="Dirección de pickup"
                    />
                    <Space>
                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                            <Text style={{ fontSize: 13 }}>{booking && booking.pickupAddress}</Text>
                            <Text type="secondary" style={{ fontSize: 12 }}>
                                {booking && pickupCoordinates[1]}
                                {' '}
                                {booking && pickupCoordinates[0]}
                            </Text>
                        </Space>
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleGoToButton(pickupCoordinates)}
                        >
                            <GrMap size={20} />
                        </div>
                    </Space>
                </List.Item>
                <List.Item>
                    <List.Item.Meta
                        title="Dirección de entrega"
                    />
                    <Space>
                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                            <Text style={{ fontSize: 13 }}>{booking && booking.deliveryAddress}</Text>
                            <Text type="secondary" style={{ fontSize: 12 }}>
                                {booking && deliveryCoordinates[1]}
                                {' '}
                                {booking && deliveryCoordinates[0]}
                            </Text>
                        </Space>
                        <div
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleGoToButton(deliveryCoordinates)}
                        >
                            <GrMap size={20} />
                        </div>
                    </Space>
                </List.Item>
                <List.Item>
                    <List.Item.Meta
                        title="Tiempo de preparación"
                    />
                    <div>
                        {booking.cookTime / MILISECONDS_TO_MINUTE || 0}
                        {' '}
                        min
                    </div>
                </List.Item>
                <List.Item>
                    <List.Item.Meta title="Canales de notificación" />
                    <div>
                        {`Envío notificado en: ${booking.smrSentVia.join(', ') || 'Sin canales de notificación'}` }
                    </div>
                </List.Item>
                <List.Item>
                    <List.Item.Meta title="Public Booking Url" />
                    <Space size={4}>
                        <Button
                            type="link"
                            style={{ padding: 0 }}
                            onClick={() => {
                                const token = booking?.publicBookingToken;
                                const url = `${PUBLIC_BOOKING_URL}?token=${token}`;
                                if (token) {
                                    window.open(url);
                                } else {
                                    message.error('No public booking token');
                                }
                            }}
                        >
                            {`${booking?.publicBookingToken || 'No public booking token'}` }
                        </Button>
                        <Button
                            icon={<FaCopy size={14} />}
                            type="link"
                            onClick={() => {
                                const token = booking?.publicBookingToken;
                                const url = `${PUBLIC_BOOKING_URL}?token=${token}`;
                                if (token) {
                                    navigator.clipboard.writeText(url);
                                    message.success('Public Booking Token copied to clipboard');
                                } else {
                                    message.error('No public booking token');
                                }
                            }}
                        />
                    </Space>
                </List.Item>
                <div />
            </List>
        </Card>
    );
};
