import {
    Col, PageHeader, Row, Space,
} from 'antd';
import { DriversSection, MapOptionSection, SearchLocation } from './components';
import { CommandCenterProvider } from './context/commandCenterContext';
import CommandCenterMapSection from './components/CommandCenterMapSection';

const CommandCenter = () => (
    <>
        <PageHeader
            title="Command Center"
        />
        <CommandCenterProvider>
            <Row gutter={[20, 20]}>
                <Col xs={24} md={24} lg={8} style={{ minWidth: 320 }}>
                    <DriversSection />
                </Col>
                <Col xs={24} md={24} lg={16}>
                    <Space direction="vertical" size={10} style={{ display: 'flex' }}>
                        <Row>
                            <SearchLocation />
                        </Row>
                        <Row align="middle" gutter={15}>
                            <MapOptionSection />
                        </Row>
                        <Row>
                            <CommandCenterMapSection />
                        </Row>
                    </Space>
                </Col>
            </Row>
        </CommandCenterProvider>
    </>
);

export default CommandCenter;
