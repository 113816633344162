import { imageMarker } from './markerImage';

const driverMarkerImage = (status: string) => {
    let image = imageMarker.driver.desactive;

    switch (status) {
    case 'online': {
        image = imageMarker.driver.active;
        break;
    }
    case 'busy': {
        image = imageMarker.driver.busy;
        break;
    }
    default:
        break;
    }

    return image;
};

export {
    driverMarkerImage,
};
