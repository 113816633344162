import { Form, notification } from 'antd';
import {
    FC, createContext, useEffect, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { initialContext } from './alerts.initial';
import { IAlertsContext, ISendPendingAlert, ISendWorkspaceAlert } from './alerts.interface';
import { IAlert, IWorkspaceAlert } from '../../../interfaces/workspace-alert';
import {
    getAlerts, getWorkspacesByAlert, sendWorkspaceAlert, setMarkAsRead,
} from '../../../api/alerts';
import { sendPendingAlert } from '../../../api/pending-alerts';

export const AlertsContext = createContext<IAlertsContext>(initialContext);

export const AlertsProvider: FC = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingWorkspaceAlerts, setLoadingWorkspaceAlerts] = useState<boolean>(false);
    const [loadingSendAlert, setLoadingSendAlert] = useState<boolean>(false);
    const [loadingReadAlert, setLoadingReadAlert] = useState<boolean>(false);
    const [showCreateAlert, setShowCreateAlert] = useState<boolean>(false);
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [alerts, setAlerts] = useState<IAlert[]>(initialContext.alerts);
    const [alert, setAlert] = useState<IAlert | null>(initialContext.alert);
    const [workspaceAlerts, setWorkspaceAlerts] = useState<IWorkspaceAlert[]>(initialContext.workspaceAlerts);
    const [maxAlerts, setMaxAlerts] = useState<number>(initialContext.maxAlerts);
    const [limit] = useState<number>(12);
    const [total] = useState<number>(initialContext.total);

    const [searchParams, setSearchParams] = useSearchParams();

    const type = searchParams.get('type');
    const isActive = searchParams.get('isActive');

    const [form] = Form.useForm();

    const getParams = () => {
        const params: any = {};
        type && (params.type = type);
        isActive && (params.isActive = isActive);
        return params;
    };

    const fetchAlerts = (
        type?: string,
        isActive?: boolean,
    ) => {
        if (loading) return;

        setLoading(true);
        const PAGE = Math.ceil(alerts.length / limit);
        const params: any = {
            ...getParams(),
            subType: 'ALERT_WORKSPACE',
            limit,
            page: PAGE + 1,
        };

        if (type) params.type = type;
        if (isActive) params.isActive = isActive;

        getAlerts(params)
            .then((response: any) => {
                const { data } = response;
                setAlerts([...alerts, ...data.data]);
                setMaxAlerts(data.meta.itemCount);
                if (alerts.length === 0 && data.data.length > 0) {
                    setAlert(data.data[0]);
                }
            })
            .catch((error: any) => {
                console.log(error);
            })
            .finally(() => setLoading(false));
    };

    const fetchWorkspaceAlerts = () => {
        if (!alert?._id) return;
        setLoadingWorkspaceAlerts(true);
        getWorkspacesByAlert(alert._id)
            .then(({ data }: any) => {
                setWorkspaceAlerts(data.data);
            })
            .catch((error: any) => {
                const description = error?.response?.data?.description || 'Something went wrong';
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoadingWorkspaceAlerts(false));
    };

    const sendAlert = (payload: ISendWorkspaceAlert) => {
        setLoadingSendAlert(true);
        sendWorkspaceAlert(payload)
            .then(() => {
                notification.success({
                    message: 'Success',
                    description: 'Alert sent successfully',
                });
            })
            .catch((error: any) => {
                const description = error?.response?.data?.description || 'Something went wrong';

                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoadingSendAlert(false));
    };

    const sendProgrammedAlert = (payload: ISendPendingAlert) => {
        setLoadingSendAlert(true);
        sendPendingAlert(payload)
            .then(() => {
                notification.success({
                    message: 'Success',
                    description: 'Pending Alert create successfully',
                });
            })
            .catch((error: any) => {
                const description = error?.response?.data?.description || 'Something went wrong';

                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoadingSendAlert(false));
    };

    const refreshAlerts = () => {
        setAlerts([]);
        setMaxAlerts(0);
        fetchAlerts();
    };

    const setNewAlert = (alert: IAlert) => {
        setAlerts([alert, ...alerts]);
        setMaxAlerts(maxAlerts + 1);
    };

    const handleType = (value: string) => {
        const { type, ...params } = getParams();
        value && (params.type = value);
        setSearchParams(params);
    };

    const handleIsActive = (value: any) => {
        const { isActive, ...params } = getParams();
        value && (params.isActive = value === 'ACTIVE');
        setSearchParams(params);
    };

    const markAsRead = (wsAlertID: string, isRead: boolean) => {
        setLoadingReadAlert(true);
        setMarkAsRead(wsAlertID, isRead)
            .then(() => {
                notification.success({
                    message: 'Success',
                    description: isRead ? 'Alert marked as read' : 'Alert marked as unread',
                });
                const newWorkspaceAlerts = workspaceAlerts.map((wsAlert: IWorkspaceAlert) => {
                    if (wsAlert._id === wsAlertID) {
                        return {
                            ...wsAlert,
                            isRead,
                        };
                    }
                    return wsAlert;
                });
                setWorkspaceAlerts(newWorkspaceAlerts);
            })
            .catch((error: any) => {
                const description = error?.response?.data?.description || 'Something went wrong';
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoadingReadAlert(false));
    };

    const updateAlert = (newAlert: IAlert) => {
        const newAlerts = alerts.map((alert) => (alert._id === newAlert._id ? newAlert : alert));
        setAlerts(newAlerts);
        setAlert(newAlert);
    };

    useEffect(() => {
        fetchWorkspaceAlerts();
    }, [alert]);

    return (
        <AlertsContext.Provider
            value={{
                loading,
                loadingWorkspaceAlerts,
                loadingSendAlert,
                loadingReadAlert,
                showCreateAlert,
                alerts,
                alert,
                workspaceAlerts,
                maxAlerts,
                total,
                form,
                showFilters,
                fetchAlerts,
                setAlert,
                sendAlert,
                setShowCreateAlert,
                refreshAlerts,
                setNewAlert,
                setShowFilters,
                handleIsActive,
                handleType,
                sendProgrammedAlert,
                markAsRead,
                updateAlert,
            }}
        >
            {children}
        </AlertsContext.Provider>
    );
};
