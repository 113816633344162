import { Col, Row } from 'antd';
import { useMemo } from 'react';
import { IWorkspace } from '../../../types/workspaces';
import BillingDetail from './BillingDetail';
import ContactDetail from './ContactDetail';
import BankDetail from './BankDetail';
import { getBillingDataFromWorkspace } from './utils/workspace';

interface IBillingDetailContent {
    workspace: IWorkspace;
}

const BillingDetailContent = ({ workspace }: IBillingDetailContent) => {
    const billing: any = useMemo(() => {
        if (!workspace) return;

        return getBillingDataFromWorkspace(workspace);
    }, [workspace]);

    return (
        <Row gutter={24} style={{ width: '100%' }}>
            <Col xs={24} md={24} lg={24} xl={8}>
                <BillingDetail
                    identification={billing?.identification}
                    name={billing?.name}
                    email={billing?.email}
                    fullAddress={billing?.fullAddress}
                    mobile={billing?.mobile}
                />
            </Col>
            <Col xs={24} md={24} lg={24} xl={8}>
                <ContactDetail
                    email={workspace.payment?.manager?.email}
                    name={workspace.payment?.manager?.name}
                    lastName={workspace.payment?.manager?.lastName}
                    mobile={workspace.payment?.manager?.mobile}
                    countryCode={workspace.payment?.manager?.countryCode}
                />
            </Col>
            <Col xs={24} md={24} lg={24} xl={8}>
                <BankDetail
                    bank={billing?.bank}
                    accountType={billing?.accountType}
                    accountNumber={billing?.accountNumber}
                    accountHolderName={billing?.accountHolderName}
                    accountIdentification={billing?.accountIdentification}
                />
            </Col>
        </Row>
    );
};

export default BillingDetailContent;
