import MainSearch from '../components/Summary/MainSearch';
import { SummaryProvider } from '../components/Summary/context/summary.context';

const Summary = () => (
    <SummaryProvider>
        <div className="screen screen-remaining">
            <MainSearch />
        </div>
    </SummaryProvider>
);

export default Summary;
