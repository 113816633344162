import axios from 'axios';
import { OCTOPUS_BASE_URL } from '.';
import { ISendWorkspaceAlert } from '../components/Alerts/context/alerts.interface';

export const getAlerts = (searchParams: any) => {
    const params = new URLSearchParams(searchParams);
    const url = `${OCTOPUS_BASE_URL}/alerts`;
    const response = axios.get(url, { params });
    return response;
};

export const getWorkspacesByAlert = (alertID: string) => {
    const url = `${OCTOPUS_BASE_URL}/workspace-alerts/${alertID}/workspaces`;
    const response = axios.get(url);
    return response;
};

export const sendWorkspaceAlert = (payload: ISendWorkspaceAlert) => {
    const url = `${OCTOPUS_BASE_URL}/workspaces/sendNotification`;
    const response = axios.post(url, payload);
    return response;
};

export const createAlert = (payload: any) => {
    const url = `${OCTOPUS_BASE_URL}/alerts`;
    const response = axios.post(url, payload);
    return response;
};

export const setMarkAsRead = (id: string, isRead: boolean) => {
    const url = `${OCTOPUS_BASE_URL}/workspace-alerts/${id}/read`;
    const response = axios.patch(url, { isRead });
    return response;
};

export const updateAlert = (id: string, payload: any) => {
    const url = `${OCTOPUS_BASE_URL}/alerts/${id}`;
    const response = axios.put(url, payload);
    return response;
};
