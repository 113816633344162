import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';

interface IHelperIconTooltip{
    icon: any;
    text: any;
    placement?: TooltipPlacement;
}

const HelperIconTooltip = ({ icon, text, placement }: IHelperIconTooltip) => (
    <Tooltip
        color="#ffffff"
        arrowPointAtCenter={false}
        placement={placement || 'right'}
        title={text}
    >
        {icon}
    </Tooltip>
);

export default HelperIconTooltip;
