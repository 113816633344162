import { useEffect, FC } from 'react';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { socketInstance } from '../../context/SocketClientContext';
import socketEvents from '../../const/socketEvent';

export const ActivityNotification: FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        socketInstance.on(socketEvents.NOTIFICATION, (notificationData) => {
            if (notificationData?.payload) {
                const { data } = notificationData.payload;
                notification.info({
                    message: data.action,
                    description: `Has a new message for the issue ${data.issueID.issuePublicID}`,
                    onClick: () => {
                        navigate('/v2/booking-issues');
                    },
                });
            }
        });

        return () => {
            socketInstance.off(socketEvents.BOOKING_SEND_TO_ADMIN);
        };
    }, []);

    return <div />;
};
