/* eslint-disable no-return-assign */
export const getColorDriverConnection = (driver: any, type?: string) => {
    let color = 'red';
    switch (type) {
    case 'locationBackground':
        if (driver.locationBackground) return color = 'green';
        return color = 'red';
    case 'locationForeground':
        if (driver.locationForeground) return color = 'green';
        return color = 'red';
    case 'savingBattery':
        if (driver.savingBattery) return color = 'green';
        return color = 'red';
    default:
        return color;
    }
};

export const countDriversConnected = (drivers: Array<any>) => {
    let connectedDrivers = 0;
    let busyDrivers = 0;
    drivers.forEach((driver: any) => {
        if (driver.onDutyFlag) connectedDrivers++;
        if (driver.isBusy && driver.onDutyFlag) busyDrivers++;
    });
    return {
        connected: connectedDrivers,
        busy: busyDrivers,
    };
};

export const getBatteryStatus = (statusNumber: number) => {
    switch (statusNumber) {
    case 1:
        return 'UNKNOWN';
    case 2:
        return 'CHARGING';
    case 3:
        return 'DISCHARGING';
    case 4:
        return 'NOT CHARGING';
    case 5:
        return 'FULL';
    default:
        return 'N/A';
    }
};

export const returnVehicleType = (type: number) => {
    switch (type) {
    case 0:
        return 'CAR';
    case 1:
        return 'BIKE';
    case 4:
        return 'SKATE';
    default:
        return '';
    }
};
