import {
    FC, createContext, useState,
} from 'react';
import { initialContext } from './monitor.initial';
import { IContext, IMonitorData } from './monitor.interface';
import { getMonitoringData } from '../../../api/monitor';

export const TPDMonitorContext = createContext<IContext>(initialContext);

export const TPDMonitorProvider: FC = ({ children }) => {
    const [data, setData] = useState<IMonitorData[]>(initialContext.data);
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [showBookingDrawer, setShowBookingDrawer] = useState<boolean>(initialContext.showBookingDrawer);
    const [bookingNumericId, setBookingNumericId] = useState<number>(initialContext.bookingNumericId);
    const [workspaceName, setWorkspaceName] = useState<string>(initialContext.workspaceName);

    const fetchMonitorData = (bookingID: string, bookingNumericId: number, workspaceName: string) => {
        setLoading(true);
        setBookingNumericId(bookingNumericId);
        setWorkspaceName(workspaceName);
        getMonitoringData(bookingID, bookingNumericId)
            .then(({ data: response }: any) => setData(response.data))
            .catch((error: any) => console.log(error))
            .finally(() => setLoading(false));
    };

    return (
        <TPDMonitorContext.Provider
            value={{
                bookingNumericId,
                data,
                loading,
                showBookingDrawer,
                workspaceName,
                setShowBookingDrawer,
                fetchMonitorData,
            }}
        >
            {children}
        </TPDMonitorContext.Provider>
    );
};
