/* eslint-disable react/destructuring-assignment */
import {
    Card, Space, Row, Typography, Col,
    Button,
    Modal,
    Form,
    Input,
    notification,
} from 'antd';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { BsBell } from 'react-icons/bs';
import { FaRegEdit } from 'react-icons/fa';
import { updateStatusUpdates } from '../../api/booking';
import { BookingType } from '../../types/booking';
import { setActive } from '../../actions/ride';

interface StatusUpdatesProps {
  statusUpdates: any[];
  booking?: BookingType;
}

const { Text } = Typography;

export const StatusUpdates = ({ statusUpdates, booking }: StatusUpdatesProps) => {
    const [edit, setEdit] = useState<boolean>(false);
    const [form] = Form.useForm();

    const dispatch = useDispatch();

    const UpdateStatus = (statusUpdates: any[]) => {
        const onSubmit = (values: any) => {
            const payload = statusUpdates;

            const newStatusUpdates = payload.map((item, index) => {
                const originalTimeStamp = item.timeStamp;
                const newTimeStamp = values.status[index];
                const timeStamp = new Date(newTimeStamp);
                return {
                    ...item,
                    realTimeStamp: originalTimeStamp,
                    timeStamp,
                };
            });

            updateStatusUpdates(booking?._id || '', newStatusUpdates)
                .then(() => {
                    const newBooking = { ...booking, statusUpdates: newStatusUpdates };
                    dispatch(setActive(newBooking as BookingType));

                    notification.success({
                        message: 'Success',
                        description: 'Booking updated successfully',
                    });
                })
                .catch((error: any) => {
                    const description = error.response.data.message || 'An error occurred';
                    notification.error({ message: 'Error', description });
                })
                .finally(() => {
                    form.resetFields();
                });
        };

        return (
            <Modal
                open={edit}
                title="Update Status"
                width={600}
                onCancel={() => setEdit(false)}
                onOk={() => {
                    form.submit();
                    setEdit(false);
                }}
            >
                <Form
                    form={form}
                    onFinish={onSubmit}
                    layout="vertical"
                    initialValues={{
                        dates: statusUpdates.map((item) => moment(item.timeStamp)),
                    }}
                >
                    <Row gutter={[20, 20]}>

                        {
                            statusUpdates.map((status, index) => (
                                <Col xs={24}>
                                    <Form.Item
                                        key={index}
                                        name={['status', index]}
                                        initialValue={moment(status.timeStamp)}
                                        label={(
                                            <Space direction="vertical">
                                                <Space>
                                                    <Text>
                                                        {status.newStatusText}
                                                    </Text>
                                                    <Text type="secondary">
                                                        {status?.realTimeStamp !== status.timeStamp && 'edited'}
                                                    </Text>
                                                </Space>
                                                {
                                                    (status?.realTimeStamp !== status.timeStamp) && (
                                                        <Text type="secondary">
                                                            {` (Original: ${moment(status.realTimeStamp)})`}
                                                        </Text>
                                                    )
                                                }
                                            </Space>
                                        )}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            ))
                        }

                    </Row>
                </Form>
            </Modal>
        );
    };

    return (
        <Card
            title={(
                <Space size={8}>
                    <BsBell style={{ verticalAlign: 'middle' }} />
                    <Text>
                        Status update
                    </Text>
                    <Button
                        type="link"
                        size="small"
                        icon={<FaRegEdit style={{ verticalAlign: 'middle' }} size={16} />}
                        onClick={() => setEdit(!edit)}
                    />
                </Space>
            )}
        >
            {edit && UpdateStatus(statusUpdates)}
            <Row gutter={[20, 20]}>
                {
                    statusUpdates.map((status) => {
                        const time = moment(status.timeStamp).format('HH:mm:ss:SSS');
                        const date = moment(status.timeStamp).format('ddd DD MMM');
                        return (
                            <Col key={status.timeStamp} span={6}>
                                <Card>
                                    <Card.Meta
                                        title={status.newStatusText}
                                        description={(
                                            <Space>
                                                <Text>
                                                    {date}
                                                </Text>
                                                <Text type="secondary"> at </Text>
                                                <Text>
                                                    {time}
                                                </Text>
                                            </Space>
                                        )}
                                    />
                                </Card>
                            </Col>
                        );
                    })
                }
            </Row>
        </Card>
    );
};
