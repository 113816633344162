import moment from 'moment';

export interface RegisteredByInspectorEvent {
    role: string;
}

export interface IInspectorEvent {
    date: string;
    _id: string;
    description: string;
    bookingID: string;
    type: string;
    eventType?: string;
    createdAt: Date;
    updatedAt: Date;
    registeredBy?: RegisteredByInspectorEvent;
}

export class InspectorEvent implements IInspectorEvent {
    _id: string;

    description: string;

    bookingID: string;

    type: string;

    updatedAt: Date;

    createdAt: Date;

    registeredBy?: RegisteredByInspectorEvent;

    constructor({ date, ...partial }: InspectorEvent) {
        Object.assign(this, partial);
    }

    get date() {
        return moment(this.createdAt).format('HH:mm:ss:SSS');
    }
}
