import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { InvoicesProvider } from '../components/Invoices/context/invoices.context';
import InvoicesScreen from '../screens/Invoices';

const InvoicesRouter: FC = () => (
    <Routes>
        <Route
            path=""
            element={(
                <InvoicesProvider>
                    <InvoicesScreen />
                </InvoicesProvider>
            )}
        />
    </Routes>
);

export default InvoicesRouter;
