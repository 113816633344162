import {
    Form, Modal, Input, Space, Typography, notification, Select,
    Tooltip,
} from 'antd';
import { useContext, useState } from 'react';
import { BsLightbulb } from 'react-icons/bs';
import { addInvoiceTransaction } from '../../../api/dispersions';
import { DispersionContext } from '../../DispersionDetail/context/dispersionContext';

const { Text } = Typography;
const { TextArea } = Input;

interface IProps {
    dispersion: any
    visible: boolean;
    onCancel: (transaction: any) => void;
}

export const AddTransactionModal = ({
    visible, onCancel, dispersion,
}: IProps) => {
    const { fetchDispersion } = useContext(DispersionContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values: any) => {
        setLoading(true);
        addInvoiceTransaction(dispersion._id, values)
            .then(({ data }) => {
                notification.success({
                    message: 'Transaction added successfully',
                });
                fetchDispersion();
                onCancel(data.data);
            })
            .catch((error) => {
                const description = error.response?.data?.message;
                notification.error({
                    message: 'Error adding transaction',
                    description,
                });
            })
            .finally(() => {
                setLoading(false);
                form.resetFields();
            });
    };

    const RULES = [
        {
            required: true,
            message: 'Please input amount!',
        },
    ];

    return (
        <Modal
            visible={visible}
            title="Add new transaction"
            onCancel={() => onCancel(null)}
            onOk={() => form.submit()}
            okText="Add"
            confirmLoading={loading}
        >
            <Space direction="vertical" size={24}>
                <Space direction="horizontal" size={8}>
                    <BsLightbulb color="#FFF" size={14} />
                    <Text>
                        Remember to be clear in your concept as it will make it easier to understand for future reference.
                    </Text>
                </Space>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="type"
                        label="Concept"
                        rules={RULES}
                    >
                        <Select>
                            <Select.Option value="SET_UP">Set-Up</Select.Option>
                            <Select.Option value="SCOPE">Scope</Select.Option>
                        </Select>
                    </Form.Item>
                    <Tooltip
                        title="You can set booking if you want to pay for a specific booking"
                        showArrow
                        placement="topLeft"
                    >
                        <Form.Item name="bookingNumericId" label="Booking (optional)">
                            <Input />
                        </Form.Item>
                    </Tooltip>
                    <Form.Item
                        name="amount"
                        label="Amount"
                        rules={RULES}
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item
                        name="comment"
                        label="Comment"
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                </Form>
            </Space>
        </Modal>
    );
};
