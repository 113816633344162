import { Button, Card, Space } from 'antd';
import { useContext, useState } from 'react';
import { TiersConfigurationContext } from '../../context/tiersConfigurationContext';
import HelperMessages from '../HelperMessages';
import TiersDeliveryProviderTable from './TiersDeliveryProviderTable';
import TiersRegionsTable from './TiersRegionsTable';

const TiersSection = () => {
    const {
        updateActionModal, elementSelected, globalTiers, deliveryProviders,
    } = useContext(TiersConfigurationContext);
    const [activeTabKey, setActiveTabKey] = useState('tiers');

    const onTabChange = (key: string) => {
        setActiveTabKey(key);
    };

    const handleAddTier = () => {
        updateActionModal({
            active: true,
            data: {
                action: 'add',
                type: 'tier',
            },
        });
    };

    const tabList = [
        {
            key: 'tiers',
            tab: (
                <Space style={{ width: '100%' }} align="center" direction="horizontal" size={4}>
                    Tier
                    {' '}
                    {`(${
                        elementSelected && elementSelected.tiers && elementSelected.tiers.length > 0
                            ? elementSelected.tiers.length
                            : globalTiers.data.length
                    })`}
                    {!elementSelected?.tiers || elementSelected.tiers.length === 0
                        ? <HelperMessages type="tiersDefault" />
                        : null}
                </Space>
            ),
        },
        {
            key: 'deliveryProviderTiers',
            tab: `Delivery Provider Tiers (${deliveryProviders.data.length})`,
        },
    ];

    const contentList: any = {
        tiers: <TiersRegionsTable />,
        deliveryProviderTiers: <TiersDeliveryProviderTable />,
    };

    const contentButton: any = {
        tiers: (
            <Button
                disabled={!elementSelected}
                onClick={handleAddTier}
                type="primary"
            >
                + Add Tier
            </Button>
        ),
    };

    return (
        <Card
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={onTabChange}
            tabBarExtraContent={contentButton[activeTabKey]}

        >
            {contentList[activeTabKey]}
        </Card>
    );
};
export default TiersSection;
