import moment from 'moment';
import { IWhatsappCertification } from './certifications.interface';

export const initialContext = {
    loading: false,
    loadingAction: false,
    data: [] as IWhatsappCertification[],
    item: null as IWhatsappCertification | null,
    total: 0,
    paginator: {
        limit: 10,
        page: 1,
    },
    startDate: moment().startOf('day').toISOString(),
    endDate: moment().endOf('day').toISOString(),
    searchParams: {},
    fetchData: () => {},
    fetchItem: () => {},
    setLoading: () => {},
    setItem: () => {},
    setData: () => {},
    handleStatus: () => { },
    handleDate: () => { },
    handleSearch: () => { },
    onPageChange: () => {},
};
