import {
    Divider,
    List, Modal, Space, Typography,
} from 'antd';
import { IDispersion } from '../../../interfaces/dispersion.interface';

interface Props {
    open: boolean;
    dispersionsToPay: IDispersion[];
    onClose: () => void;
    payMultipleDispersions: () => void;
}

const { Text } = Typography;

const MultiplePay = ({
    open,
    onClose,
    dispersionsToPay,
    payMultipleDispersions,
}: Props) => {
    const totalAmount = dispersionsToPay.reduce((acc, dispersion) => acc + dispersion.amount, 0);

    return (
        <Modal
            title={`Dispersions Multiple Pay (${dispersionsToPay.length || 0})`}
            open={open}
            onCancel={onClose}
            closable={false}
            onOk={payMultipleDispersions}
            okText="Pay"
            cancelText="Cancel"
        >
            <div
                style={{
                    maxHeight: '400px',
                    overflowY: 'scroll',
                    paddingRight: 16,
                }}
            >
                <List
                    dataSource={dispersionsToPay}
                    renderItem={(dispersion: IDispersion) => (
                        <List.Item
                            key={dispersion._id}
                            extra={[
                                <Space size={8}>
                                    <Text>
                                        {dispersion.amount?.toFixed(2) || 0.00}
                                    </Text>
                                    <Text type="secondary">
                                        {dispersion.currency}
                                    </Text>
                                </Space>,
                            ]}
                        >
                            <List.Item.Meta
                                title={dispersion.dispersionUniqueID}
                                description={dispersion.workspaceName}
                            />
                        </List.Item>
                    )}
                />
            </div>

            <Divider />

            <List.Item
                key="total"
                extra={[
                    <Text strong style={{ float: 'right' }}>
                        {totalAmount.toFixed(2)}
                    </Text>,
                ]}
            >
                <List.Item.Meta
                    title="Total amount:"
                />
            </List.Item>
        </Modal>
    );
};

export default MultiplePay;
