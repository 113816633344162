import { IContext } from './summary.interface';
import { ESearchType } from './summary.enum';

export const initialContext: IContext = {
    loading: false,
    results: [] as any[],
    total: 0,
    searchParams: new URLSearchParams(),
    setSearchParams: () => {},
    setResults: () => {},
    handleSearch: () => {},
    fetchResults: () => {},
};
