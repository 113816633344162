/* eslint-disable react/no-unescaped-entities */
import {
    Button, Col, Form, Modal, notification, Row, Space, Typography,
} from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { AiFillQuestionCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from '../../../actions/modals';
import { editRegion } from '../../../api/regions';
import { checkRegionStatus } from '../../../screens/CommandCenter/utils/region';
import { ModalTypes } from '../../../types/modals';
import { getPathsFromPolygon } from '../../../utils/polygons';
import HelperIconTooltip from '../../HelperIconTooltip';
import ShowContent from './ShowContent';
import StatusIndicator from './StatusIndicator';

const { Title, Text } = Typography;

interface IRegionActionModal {
    onSuccess?: () => void
}

const RegionActionModal = ({
    onSuccess,
}: IRegionActionModal) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [newPolygon, setNewPolygon] = useState<google.maps.Polygon>();

    const { activeModal, modalData } = useSelector((state: any) => state.modals);
    const isOpen = activeModal === ModalTypes.RegionActions;

    const onPolygonComplete = (polygon: google.maps.Polygon) => {
        newPolygon?.setMap(null);
        setNewPolygon(polygon);
    };

    const handleReset = () => {
        newPolygon?.setMap(null);
        setNewPolygon(undefined);
    };

    const handleCancel = () => {
        dispatch(closeModal());
        setEdit(false);
        handleReset();
    };

    const handleCancelEdit = () => {
        setEdit(false);
        handleReset();
        form.resetFields();
    };

    const handleEdit = () => setEdit(true);

    const onSubmit = async (values: any) => {
        if (!edit) return handleCancel();

        setLoading(true);

        try {
            const coordinates = newPolygon ? getPathsFromPolygon(newPolygon) : modalData.region.geoLongLat.coordinates[0];

            await editRegion({
                ...values,
                region_id: modalData.region._id,
                coordinates,
                role: 'admin',
            });

            notification.success({
                message: 'Region Edited',
                description: 'Region edited successfully',
            });

            onSuccess && onSuccess();
            handleCancel();
        } catch (error: any) {
            const { message } = error.response.data;
            notification.error({
                message: 'Error',
                description: message,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        form.resetFields();
    }, [modalData]);

    const statusColors = useMemo(
        () => modalData && checkRegionStatus(modalData.region.regionStatus),
        [modalData],
    );

    const EDIT_HELP = (
        <Text style={{ color: 'black' }}>
            <Text strong style={{ color: '#0092bb' }}>The region to edit</Text>
            {' '}
            is shown with the rest of the regions.
            If you need to change the shape of the region, you must click on
            <Text strong style={{ color: 'black' }}> 'Draw shape' </Text>
        </Text>
    );

    return (
        <Modal
            width="40%"
            visible={isOpen}
            title={(
                <Space align="center">
                    <Title style={{ margin: '0 8px 0 0' }} level={4}>
                        Region Detail:
                        {modalData?.region?.regionName}
                    </Title>
                    {statusColors && <StatusIndicator stroke={statusColors.stroke} fill={statusColors.fill} size={20} />}
                    {edit && <HelperIconTooltip icon={<AiFillQuestionCircle size={20} />} text={EDIT_HELP} />}
                </Space>
            )}
            footer={null}
            onCancel={handleCancel}
        >

            <Form
                disabled={loading}
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                style={{ width: '100%' }}
            >
                {modalData
                    && (
                        <ShowContent
                            region={modalData.region}
                            allRegions={modalData.allRegions}
                            edit={edit}
                            onPolygonComplete={onPolygonComplete}
                        />
                    )}
                <Row justify="end" style={{ marginTop: 16 }} gutter={16}>
                    {newPolygon
                        && (
                            <Col>
                                <Button type="primary" onClick={handleReset}>
                                    Clear Poligon
                                </Button>
                            </Col>
                        )}
                    <Col>
                        <Button type="primary" onClick={edit ? handleCancelEdit : handleEdit}>
                            {edit ? 'Cancel' : 'Edit'}
                        </Button>
                    </Col>
                    <Col>
                        <Button type="primary" htmlType="submit">
                            Ok
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default RegionActionModal;
