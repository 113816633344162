import { Modal } from 'antd';
import { getBookingRequests, getOrderRequest, getOrderResponse } from '../../api/booking';

export const showOrderResponseModal = async (orderId: string) => {
    let orderResponse = {} as any;
    try {
        const { data } = await getOrderResponse(orderId as string);
        orderResponse = data.data;
    } catch (error) {
        console.log(error);
    }

    return Modal.info({
        title: 'Order Response',
        width: '80%',
        icon: null,
        content: (
            <pre>
                {JSON.stringify(orderResponse, null, 2)}
            </pre>
        ),
        onOk() {},
    });
};

export const showOrderRequestModal = async (orderId: string) => {
    let orderRequest = {} as any;
    try {
        const { data } = await getOrderRequest(orderId as string);
        orderRequest = data.data;
    } catch (error) {
        console.log(error);
    }

    return Modal.info({
        title: 'Order Request',
        width: '80%',
        icon: null,
        content: (
            <pre>
                {JSON.stringify(orderRequest, null, 2)}
            </pre>
        ),
        onOk() {},
    });
};
