import {
    Button, Card, Col, Form, InputNumber, notification, Row, Switch, Typography,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { IoCogSharp } from 'react-icons/io5';
import { updateWorkspaceSettings } from '../../../api/workspaces';

import { IWorkspace } from '../../../types/workspaces';
import CardDataBase from '../../CardDataBase';
import { CoverageContext } from '../context/coverageContext';
import CoverageArea from '../Coverage/CoverageArea';
import { polygonStyles } from '../Coverage/polygonStyles';

interface IGeneralDetailContent {
    workspace: IWorkspace | any
}

const { Title } = Typography;

const ServicesDetailContent = ({ workspace }: IGeneralDetailContent) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const {
        actionLoading: actionLoadingCoverage,
        error: errorCoverage,
        showArea,
        currentLocation,
        setShowArea,
        onPolygonComplete,
        resetPolygon,
        newPolygon,
        setNewPolygon,
        saveCoverageArea,
    } = useContext(CoverageContext);

    const SERVICES: any = {
        tracking: 'Tracking',
        cashDelivery: 'Cash Delivery',
        cardDelivery: 'Card Delivery',
        shield: 'Shield',
        orchestrator: 'Orchestrator',
        myFleet: 'My Fleet',
        fastDeposits: 'Fast deposits',
        deliverySupport: 'Delivery support',
        inspector: 'Inspector',
    };

    const services = workspace?.workspaceSettings?.services;

    const handleSubmit = async (services: any) => {
        setLoading(true);
        try {
            const { data } = await updateWorkspaceSettings(workspace._id, { services });
            console.log({ data });
            notification.success({
                message: 'Success',
                description: 'Services updated successfully',
            });
        } catch (error) {
            console.log({ error });
            notification.error({
                message: 'Error',
                description: 'Error updating services',
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!services) return;
        form.setFieldsValue(services);
    }, [services]);

    useEffect(() => {
        if (!workspace?.workspaceSettings?.myFleetCoverage) return;

        const coverageArea = workspace?.workspaceSettings?.myFleetCoverage;

        if (coverageArea?.coordinates?.length) {
            const coordinates = coverageArea?.coordinates.map((coordinate: any) => ({
                lat: coordinate[0],
                lng: coordinate[1],
            }));

            const polygon = new google.maps.Polygon({
                paths: coordinates,
                ...polygonStyles,
            });

            setNewPolygon(polygon);
        }
    }, [workspace._id]);

    return (
        <Row gutter={24} style={{ width: '100%' }}>
            { showArea && (
                <CoverageArea
                    workspaceID={workspace._id}
                    actionLoading={actionLoadingCoverage}
                    error={errorCoverage}
                    showArea={showArea}
                    currentLocation={currentLocation}
                    onCancel={() => setShowArea(false)}
                    onPolygonComplete={onPolygonComplete}
                    resetPolygon={resetPolygon}
                    newPolygon={newPolygon}
                    saveCoverageArea={saveCoverageArea}
                />
            )}
            <Col span={24}>
                <CardDataBase title="Services" style={{ minHeight: 345 }}>
                    <Form
                        form={form}
                        onFinish={handleSubmit}
                        size="small"
                        labelCol={{ span: 14 }}
                        labelAlign="left"
                    >
                        <Row gutter={[10, 10]}>
                            {Object.keys(SERVICES).map((service: string) => (
                                <Col xs={24} md={12} lg={6}>
                                    <Card>
                                        { SERVICES[service] === 'My Fleet' && (
                                            <IoCogSharp
                                                size={24}
                                                style={{
                                                    color: '#FFF',
                                                    position: 'absolute',
                                                    top: 12,
                                                    right: 12,
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => setShowArea(true)}
                                            />
                                        )}
                                        <Title level={5}>{SERVICES[service]}</Title>
                                        <Form.Item name={[service, 'active']} label="Status" valuePropName="checked">
                                            <Switch />
                                        </Form.Item>
                                        <Form.Item name={[service, 'percentCost']} label="Percent Cost:">
                                            <InputNumber type="number" step={0.1} />
                                        </Form.Item>
                                        <Form.Item name={[service, 'valueCost']} label="Value Cost:">
                                            <InputNumber type="number" step={0.01} />
                                        </Form.Item>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        <Form.Item style={{ textAlign: 'right' }}>
                            <Button
                                size="middle"
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                            >
                                Save
                            </Button>
                        </Form.Item>
                    </Form>
                </CardDataBase>
            </Col>
        </Row>
    );
};

export default ServicesDetailContent;
