import { TEvent } from '../const/events';
import { IInspectorEvent } from '../interfaces/inspector.interface';

export const processEvents = (originalEvents: IInspectorEvent[]): IInspectorEvent[] => {
    const newEvents: any[] = [];
    let i = 0;

    while (i < originalEvents.length) {
        if (originalEvents[i].type === TEvent.CLOSE_TRACKING) {
            if (
                i < originalEvents.length - 1
                && originalEvents[i + 1].type === TEvent.OPEN_TRACKING
            ) {
                const closeTimestamp = new Date(originalEvents[i].createdAt).getTime();
                const openTimestamp = new Date(originalEvents[i + 1].createdAt).getTime();
                const timeDifference = (openTimestamp - closeTimestamp) / (1000 * 60);

                if (timeDifference <= 1) {
                    newEvents.push({
                        createdAt: originalEvents[i].createdAt,
                        type: TEvent.RELOAD_PAGE,
                        eventType: 'INSPECTOR_EVENT',
                        description: 'Reload the page',
                        date: originalEvents[i].createdAt.toString(),
                        _id: originalEvents[i]._id,
                        bookingID: originalEvents[i].bookingID,
                        updatedAt: originalEvents[i].updatedAt,
                        registeredBy: originalEvents[i].registeredBy,
                    });
                    i += 2;
                } else {
                    newEvents.push({
                        createdAt: originalEvents[i].createdAt,
                        type: originalEvents[i].type,
                        eventType: 'INSPECTOR_EVENT',
                        description: originalEvents[i].description,
                        _id: originalEvents[i]._id,
                        bookingID: originalEvents[i].bookingID,
                        updatedAt: originalEvents[i].updatedAt,
                        date: originalEvents[i].createdAt.toString(),
                        registeredBy: originalEvents[i].registeredBy,
                    });
                    i += 1;
                }
            } else {
                newEvents.push({
                    createdAt: originalEvents[i].createdAt,
                    type: originalEvents[i].type,
                    eventType: 'INSPECTOR_EVENT',
                    description: originalEvents[i].description,
                    date: originalEvents[i].createdAt.toString(),
                    _id: originalEvents[i]._id,
                    bookingID: originalEvents[i].bookingID,
                    updatedAt: originalEvents[i].updatedAt,
                    registeredBy: originalEvents[i].registeredBy,
                });
                i += 1;
            }
        } else if (originalEvents[i].type === TEvent.RELOAD_MAP) {
            if (
                i < originalEvents.length - 2
                && originalEvents[i + 1].type === TEvent.CLOSE_TRACKING
                && originalEvents[i + 2].type === TEvent.OPEN_TRACKING
            ) {
                newEvents.push({
                    createdAt: originalEvents[i].createdAt,
                    type: TEvent.RELOAD_MAP,
                    eventType: 'INSPECTOR_EVENT',
                    description: originalEvents[i].description,
                    date: originalEvents[i].createdAt.toString(),
                    _id: originalEvents[i]._id,
                    bookingID: originalEvents[i].bookingID,
                    updatedAt: originalEvents[i].updatedAt,
                    registeredBy: originalEvents[i].registeredBy,
                });
                i += 3;
            } else {
                newEvents.push({
                    createdAt: originalEvents[i].createdAt,
                    type: originalEvents[i].type,
                    eventType: 'INSPECTOR_EVENT',
                    description: originalEvents[i].description,
                    date: originalEvents[i].createdAt.toString(),
                    _id: originalEvents[i]._id,
                    bookingID: originalEvents[i].bookingID,
                    updatedAt: originalEvents[i].updatedAt,
                    registeredBy: originalEvents[i].registeredBy,
                });
                i += 1;
            }
        } else {
            newEvents.push({
                createdAt: originalEvents[i].createdAt,
                type: originalEvents[i].type,
                eventType: 'INSPECTOR_EVENT',
                description: originalEvents[i].description,
                date: originalEvents[i].createdAt.toString(),
                _id: originalEvents[i]._id,
                bookingID: originalEvents[i].bookingID,
                updatedAt: originalEvents[i].updatedAt,
                registeredBy: originalEvents[i].registeredBy,
            });
            i += 1;
        }
    }
    return newEvents;
};
