import { useEffect, useState } from 'react';
import { Input } from 'antd';
import styles from './styles.module.scss';

interface TierTimeProps {
    tier: string;
    time: number;
    setTime: (tier: number, time: number) => void;
}

export const TierTime = ({ tier, time, setTime }: TierTimeProps) => {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(time);

    useEffect(() => {
        setValue(time);
    }, [time]);

    const InputNumber = ({ ...props }) => {
        const validateBeforeChange = (event: any) => {
            if (event.key === 'e') {
                event.preventDefault();
            }
        };
        return (
            <Input style={{ maxWidth: '100px' }} type="number" onKeyDown={validateBeforeChange} {...props} />
        );
    };

    const handleChange = (e: any) => setValue(e.target.value);

    const finishEditing = () => {
        setEditing(false);
        setTime(Number(tier), Number(value));
    };

    const handleClick = () => setEditing(true);

    return (
        editing ? (
            <InputNumber
                className={styles.inputTime}
                value={value}
                onChange={handleChange}
                onBlur={finishEditing}
                onPressEnter={finishEditing}
                autoFocus
            />
        ) : (
            <div className={styles.time} onClick={handleClick}>
                {`${time || 0} Min.`}
            </div>
        )
    );
};
