import {
    useEffect, useState, useRef,
} from 'react';
import {
    Button, Col, Form, notification, PageHeader, Row,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
    DrawingManager, GoogleMap, InfoWindow, Polygon, Marker,
} from '@react-google-maps/api';

import { useNavigate, useParams } from 'react-router-dom';
import { googleMapsStyles } from '../components/Map/googleMapStyles';
import {
    addCoverage, deleteCoverage, getCoverage, updateCoverage,
} from '../api/deliveryProviders';
import { Coverage } from '../const/coverages';
import { getPathsFromPolygon } from '../utils/polygons';
import { openModal } from '../actions/modals';
import { ModalTypes } from '../types/modals';
import CoverageEditModal from '../components/Modals/CoverageEditModal';

const mapOptions = {
    styles: googleMapsStyles,
};

const center = {
    lng: -79.8882522301666,
    lat: -2.09757780361609,
};

const initialActions = {
    show: false,
    position: { lat: 0, lng: 0 },
};

export const DeliveryProviderDetail = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const provider = params.id as string;
    const navigate = useNavigate();
    const [coverages, setCoverages] = useState<Coverage[]>([]);
    const [showActions, setShowActions] = useState(initialActions);
    const [polygonEdit, setPolygonEdit] = useState<any>(null);
    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const polygonRef = useRef<any>(null);

    const fetchGetCoverages = () => {
        getCoverage(provider)
            .then(({ data }) => {
                setCoverages(data.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setInitialLoading(false);
            });
    };

    const onPolygonComplete = async (polygon: google.maps.Polygon) => {
        try {
            const coordinates = getPathsFromPolygon(polygon);
            const { data } = await addCoverage(provider, coordinates);
            setCoverages([...coverages, data.data]);
            notification.success({
                message: 'Coverage added',
                description: 'Coverage added successfully',
            });
        } catch (error: any) {
            const { message } = error.response.data;
            notification.error({
                message: 'Error',
                description: message,
            });
        } finally {
            polygon.setMap(null);
        }
    };

    useEffect(() => {
        fetchGetCoverages();
    }, [provider]);

    const handleDelete = async (id: string) => {
        setShowActions(initialActions);
        try {
            await deleteCoverage(id);
            const newCoverages = coverages.filter((coverage) => coverage._id !== id);
            setCoverages(newCoverages);
        } catch (error: any) {
            console.log(error);
            const { message } = error.response.data;
            notification.error({
                message: 'Error',
                description: message,
            });
        }
    };

    const handleEdit = (id: string) => {
        const coverage = coverages.find((coverage) => coverage._id === id);
        if (!coverage) return;
        const paths = coverage.polygon[0].coordinates[0].map((coordinate: number[]) => (
            { lat: coordinate[1], lng: coordinate[0] }
        ));
        setPolygonEdit({ id: coverage._id, paths, ...coverage });
        setShowActions(initialActions);
        setCoverages(coverages.filter((coverage) => coverage._id !== id));
    };

    const handleFinishEdit = async () => {
        try {
            const coordinates = getPathsFromPolygon(polygonRef.current);
            const { data } = await updateCoverage(polygonEdit.id, { keyName: provider, polygon: coordinates });
            setCoverages([...coverages, data.data]);
            setPolygonEdit(null);
            polygonRef.current = null;
            setShowActions(initialActions);
            notification.success({
                message: 'Coverage updated',
                description: 'Coverage updated successfully',
            });
        } catch (error: any) {
            console.log(error);
            const { message } = error.response.data;
            notification.error({
                message: 'Error',
                description: message,
            });
        } finally {
            setLoading(false);
        }
    };

    const onLoadPolygon = (polygon: any) => {
        polygonRef.current = polygon;
    };

    const handleCancelEdit = () => {
        setCoverages([...coverages, polygonEdit]);
        setPolygonEdit(null);
        polygonRef.current = null;
        setShowActions(initialActions);
    };

    const handleEditCoverage = (coverage: Coverage) => {
        const newCoverages = coverages.map((coverageItem) => {
            if (coverageItem._id === coverage._id) {
                return coverage;
            }
            return coverageItem;
        });
        setCoverages(newCoverages);
    };

    const handleEditPolygon = (coverageId: string) => {
        handleEdit(coverageId);
    };

    if (initialLoading) {
        return (
            <div>Loading...</div>
        );
    }

    return (
        <div>
            <PageHeader
                onBack={() => navigate('/v2/delivery-providers')}
                title={`${provider} Coverage`}
            />
            <CoverageEditModal onSuccess={handleEditCoverage} editPolygon={handleEditPolygon} deleteCoverage={handleDelete} />
            <Form layout="vertical" requiredMark={false}>
                <Row>
                    <Col span={24}>
                        <GoogleMap
                            mapContainerStyle={{ height: '68vh', width: '100%' }}
                            options={mapOptions}
                            zoom={10}
                            center={center}
                        >
                            <DrawingManager
                                onPolygonComplete={onPolygonComplete}
                                options={{
                                    drawingControl: true,
                                    drawingControlOptions: {
                                        drawingModes: ['polygon' as any],
                                    },
                                }}
                            />
                            {showActions.show && (
                                <InfoWindow
                                    position={showActions.position}
                                    onCloseClick={() => setShowActions(initialActions)}
                                >
                                    <Row gutter={[5, 5]}>
                                        <Col span={12}>
                                            <Button
                                                onClick={handleCancelEdit}
                                                size="small"
                                                danger
                                                block
                                            >
                                                Cancel
                                            </Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button
                                                onClick={handleFinishEdit}
                                                size="small"
                                                type="primary"
                                                block
                                                loading={loading}
                                            >
                                                Save
                                            </Button>
                                        </Col>
                                    </Row>
                                </InfoWindow>
                            )}
                            {coverages.map((coverage: Coverage) => {
                                const paths = coverage.polygon[0].coordinates[0].map((coordinate: number[]) => (
                                    { lat: coordinate[1], lng: coordinate[0] }
                                ));
                                const bounds = new google.maps.LatLngBounds();
                                paths.forEach((path) => {
                                    bounds.extend(path);
                                });
                                const center = bounds.getCenter();
                                return (
                                    <>
                                        <Marker
                                            key={coverage._id}
                                            position={center}
                                            label={{
                                                text: coverage.name,
                                                color: '#fff',
                                                fontWeight: 'bold',
                                            }}
                                            icon={{ scaledSize: new google.maps.Size(0, 0), url: '' }}
                                        />
                                        <Polygon
                                            key={coverage._id}
                                            paths={paths}
                                            options={{
                                                fillColor: coverage.isVisible ? 'green' : 'goldenrod',
                                                strokeColor: coverage.isVisible ? 'green' : 'goldenrod',
                                            }}
                                            onClick={() => dispatch(openModal(ModalTypes.CoverageEdit, { coverage }))}
                                        />
                                    </>
                                );
                            })}
                            {polygonEdit && (
                                <Polygon
                                    onLoad={onLoadPolygon}
                                    key={polygonEdit._id}
                                    paths={polygonEdit.paths}
                                    options={{
                                        fillColor: '#bc2828',
                                        strokeColor: '#9d1515',
                                    }}
                                    editable
                                    onClick={(data: any) => {
                                        setShowActions({
                                            position: { lat: data.latLng.lat(), lng: data.latLng.lng() },
                                            show: true,
                                        });
                                    }}
                                />
                            )}
                        </GoogleMap>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};
