import {
    createContext, FC, useEffect, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { getBookingIssues, getIssueDetail } from '../../../api/issues';
import { IIssue } from '../../../interfaces/issue.interface';
import { IShieldContext } from './context.interface';
import { initialContext } from './initial.context';
import * as APIBooking from '../../../api/booking';

export const ShieldContext = createContext<IShieldContext>(initialContext);

export const ShieldProvider: FC = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [loadingDetail, setLoadingDetail] = useState<boolean>(initialContext.loadingDetail);
    const [issues, setIssues] = useState<IIssue[]>(initialContext.issues);
    const [issueSelected, setIssueSelected] = useState<string>(initialContext.issueSelected);
    const [maxIssues, setMaxIssues] = useState<number>(initialContext.maxIssues);
    const [issue, setIssue] = useState<IIssue>(initialContext.issue);
    const [limit] = useState<number>(10);
    const [total, setTotal] = useState<number>(initialContext.total);
    const [chatOpen, setChatOpen] = useState<boolean>(initialContext.chatOpen);
    const [informationOpen, setInformationOpen] = useState<boolean>(initialContext.informationOpen);
    const [searchParams, setSearchParams] = useSearchParams();

    const workspaceUniqueID = searchParams.get('workspaceUniqueID');
    const bookingNumericId = searchParams.get('bookingNumericId');
    const issuePublicID = searchParams.get('issuePublicID');
    const statusString = searchParams.get('status') || '[]';
    const status = JSON.parse(statusString);

    const getParams = () => {
        const params: any = {};
        status.length && (params.status = status);
        workspaceUniqueID && (params.workspaceUniqueID = workspaceUniqueID);
        bookingNumericId && (params.bookingNumericId = bookingNumericId);
        issuePublicID && (params.issuePublicID = issuePublicID);
        return params;
    };

    const fetchIssues = (
        bookingNumericId?: number,
        workspaceUniqueID?: string,
        issuePublicID?: string,
        status?: string,
        unread?: boolean,
    ) => {
        if (loading) return;
        setLoading(true);
        const PAGE = Math.ceil(issues.length / limit);
        const params: any = {
            ...getParams(),
            limit,
            page: PAGE + 1,
        };

        if (bookingNumericId) params.bookingNumericId = bookingNumericId;
        if (workspaceUniqueID) params.workspaceUniqueID = workspaceUniqueID;
        if (issuePublicID) params.issuePublicID = issuePublicID;
        if (status) params.status = JSON.stringify(status);
        if (unread) params.hasNewMessages = unread;

        getBookingIssues(params)
            .then((response: any) => {
                const { data: { data } } = response;
                const newIssues = data.data;
                setIssues((issues) => [...issues, ...newIssues]);
                setMaxIssues(data.total);
            })
            .catch((error: any) => {
                console.log({ error });
            })
            .finally(() => setLoading(false));
    };

    const fetchIssueDetail = (issuePublicID: string) => {
        setLoadingDetail(true);

        getIssueDetail(issuePublicID)
            .then(async (response: any) => {
                const { data: { data } } = response;
                const responseBooking = await APIBooking.getBookingDetails(data.data[0].bookingID);
                const { data: { booking } } = responseBooking.data;
                setIssue({ ...data.data[0], bookingData: booking });
            })
            .catch((error: any) => {
                console.log({ error });
            })
            .finally(() => setLoadingDetail(false));
    };

    const removeIssue = (issueID: string) => {
        const newIssues = issues.filter((issue) => issue._id !== issueID);
        setIssues(newIssues);
        setIssueSelected(newIssues[0]?.issuePublicID || '');
    };

    const handleWorkspace = (value: string) => {
        const { workspaceUniqueID, ...params } = getParams();
        value && (params.workspaceUniqueID = value);
        setSearchParams(params);
    };

    const handleStatusIssue = (value: any) => {
        const { status, ...params } = getParams();
        value.length !== '' && (params.status = JSON.stringify(value));
        console.log(params);
        setSearchParams(params);
    };
    const handleBookingNumericID = (value: any) => {
        const { bookingNumericId, ...params } = getParams();
        value && (params.bookingNumericId = value);
        setSearchParams(params);
    };

    const handleIssuePublicID = (value: any) => {
        const { issuePublicID, ...params } = getParams();
        value && (params.issuePublicID = value);
        setSearchParams(params);
    };

    const handleHasNewMessages = (value: boolean) => {
        const { hasNewMessages, ...params } = getParams();
        value && (params.hasNewMessages = value);
        setSearchParams(params);
    };

    const addIssue = (value: any) => {
        const newIssues = [value, ...issues];
        setIssues(newIssues);
    };

    const updateIssue = (issueID: string, data: any) => {
        const newIssues = issues.map((issue) => {
            if (issue._id === issueID) {
                return { ...issue, ...data };
            }
            return issue;
        });
        setIssues(newIssues);
        setIssue({ ...issue, ...data });
    };

    const handleReadNotification = async (issueID: string) => {
        setIssues((issues) => {
            const issuesUnread = issues.map((issue) => {
                if (issue._id !== issueID) return issue;
                return { ...issue, hasNewMessages: false };
            });
            return [...issuesUnread];
        });
    };

    return (
        <ShieldContext.Provider
            value={{
                issues,
                maxIssues,
                issue,
                issueSelected,
                loading,
                loadingDetail,
                total,
                chatOpen,
                informationOpen,
                fetchIssues,
                addIssue,
                fetchIssueDetail,
                handleStatusIssue,
                handleWorkspace,
                handleBookingNumericID,
                handleIssuePublicID,
                handleHasNewMessages,
                handleReadNotification,
                setIssueSelected,
                removeIssue,
                updateIssue,
                setChatOpen,
                setInformationOpen,
                setIssues,
            }}
        >
            {children}
        </ShieldContext.Provider>
    );
};
