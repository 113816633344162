import { PageHeader, Space } from 'antd';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../widgets/Loading';
import { ShieldContext } from '../context/shieldContext';

const Issue = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { loading, fetchIssueDetail } = useContext(ShieldContext);

    const { id } = useParams();

    const onBackHandle = () => {
        if (location.key !== 'default') navigate(-1);
        else navigate('/v2/booking-issues');
    };

    useEffect(() => {
        if (!id) return;
        fetchIssueDetail(id);
    }, [id]);

    if (loading) return <Loading />;

    return (
        <>
            <PageHeader
                onBack={onBackHandle}
                title="Issue Detail"
                style={{ padding: '32px 24px 24px 0' }}
            />
            <Space direction="vertical" size={24} style={{ width: '100%' }}>
                Hi
            </Space>
        </>
    );
};

export default Issue;
