import {
    Button, notification, Row, Space,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { useContext, useState } from 'react';
import { updateRegion, updateSuperRegion } from '../../../../../api/faresWorker';
import { TSuperRegion } from '../../../../../types/faresWorker';
import { type } from '../../../constants';
import { TiersConfigurationContext } from '../../../context/tiersConfigurationContext';
import { generateDataToDelete } from '../../../utils/editModal';

const DeleteContent = () => {
    const {
        modalData, elementSelected, updateActionModal, fetchRegions, fetchSuperRegions,
    } = useContext(TiersConfigurationContext);
    const [loading, setLoading] = useState(false);

    const onOk = async () => {
        if (elementSelected && modalData) {
            try {
                setLoading(true);

                const currentElement = generateDataToDelete({
                    elementSelected,
                    modalData,
                });

                if (currentElement.type === type.REGION) {
                    await updateRegion(currentElement);
                    fetchRegions();
                } else {
                    await updateSuperRegion((currentElement as TSuperRegion));
                    fetchSuperRegions();
                }

                notification.success({
                    message: 'Tier deleted successfully',
                });

                updateActionModal(null);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    };

    const onCancel = () => updateActionModal(null);

    return (
        <>
            <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center', margin: '16px 0' }}>
                <Title level={5}>Are you sure you want to delete tier?</Title>
            </Space>
            <Row justify="end">
                <Button disabled={loading} onClick={onCancel} type="default">
                    Cancel
                </Button>
                <Button disabled={loading} type="primary" onClick={onOk}>
                    Ok
                </Button>
            </Row>
        </>
    );
};

export default DeleteContent;
