import { useEffect, useState } from 'react';
import {
    Button, Col, Form, Input, notification, PageHeader, Radio, Row,
} from 'antd';
import {
    DrawingManager, GoogleMap, LoadScript, Polygon, InfoWindow,
} from '@react-google-maps/api';

import { useDispatch } from 'react-redux';
import { addRegion, getRegions } from '../api/regions';
import {
    Region, CarType, PaymentType, RideType,
} from '../const/region';
import { GOOGLE_MAPS_KEY } from '../const/envs';
import RegionActionModal from '../components/Modals/RegionActionsModal';
import { openModal } from '../actions/modals';
import { ModalTypes } from '../types/modals';
import { checkRegionStatus } from './CommandCenter/utils/region';
import { googleMapsStyles } from '../components/Map/googleMapStyles';
import { getPathsFromPolygon } from '../utils/polygons';

const mapOptions = {
    styles: googleMapsStyles,
};

const center = {
    lng: -79.8882522301666,
    lat: -2.09757780361609,
};

export const AddRegion = () => {
    const dispatch = useDispatch();
    const [regions, setRegions] = useState<Region[]>([]);
    const [newPolygon, setNewPolygon] = useState<google.maps.Polygon>();
    const [showInfoWindow, setShowInfoWindow] = useState({
        show: false,
        lat: 0,
        lng: 0,
        regionName: '',
    });

    const fetchGetRegions = () => {
        getRegions()
            .then(({ data }) => {
                setRegions(data.data.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        fetchGetRegions();
    }, []);

    const onPolygonComplete = (polygon: google.maps.Polygon) => {
        setNewPolygon(polygon);
    };

    const onSubmit = async (values: any) => {
        if (!newPolygon) {
            notification.error({
                message: 'Error',
                description: 'No polygon selected',
            });
            return null;
        }
        try {
            const coordinates = getPathsFromPolygon(newPolygon);
            const payload = { ...values, role: 'admin', coordinates };
            const { data } = await addRegion(payload);
            setRegions([...regions, data.data]);
            newPolygon.setMap(null);
            notification.success({
                message: 'Region added',
                description: 'Region added successfully',
            });
        } catch (error: any) {
            const { message } = error.response.data;
            notification.error({
                message: 'Error',
                description: message,
            });
        } finally {
            newPolygon.setMap(null);
            setNewPolygon(undefined);
        }
    };

    const handleReset = () => {
        newPolygon?.setMap(null);
        setNewPolygon(undefined);
    };

    return (
        <div>
            <PageHeader
                className="site-page-header"
                title="Add region"
            />
            <RegionActionModal onSuccess={fetchGetRegions} />
            <Form onFinish={onSubmit} layout="vertical" requiredMark={false}>
                <Row gutter={20}>
                    <Col xs={24} lg={8}>
                        <Form.Item name="regionName" label="Area name" rules={[{ required: true }]}>
                            <Input placeholder="Region 1" />
                        </Form.Item>
                        <Form.Item label="Car type" name="carType" initialValue={CarType.BIKE}>
                            <Radio.Group>
                                <Radio value={CarType.BIKE}>Bike</Radio>
                                <Radio value={CarType.LITE}>Lite</Radio>
                                <Radio value={CarType.BOTH}>Both</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="Ride type" name="rideType" initialValue={1}>
                            <Radio.Group>
                                <Radio value={RideType.RIDE_NOW}>Ride Now</Radio>
                                <Radio value={RideType.RIDE_LATER}>Ride Later</Radio>
                                <Radio value={RideType.BOTH}>Both</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="Payment type" name="paymentType" initialValue={1}>
                            <Radio.Group>
                                <Radio value={PaymentType.CARD}>Card</Radio>
                                <Radio value={PaymentType.CASH}>Cash</Radio>
                                <Radio value={PaymentType.BOTH}>Both</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Row gutter={10}>
                            <Col>
                                <Button onClick={handleReset}>
                                    Reset
                                </Button>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} lg={16}>
                        <LoadScript googleMapsApiKey={GOOGLE_MAPS_KEY} libraries={['drawing']}>
                            <GoogleMap
                                mapContainerStyle={{ height: '68vh', width: '100%' }}
                                options={mapOptions}
                                zoom={12}
                                center={center}
                            >
                                <DrawingManager
                                    onPolygonComplete={onPolygonComplete}
                                    options={{
                                        drawingControl: true,
                                        drawingControlOptions: {
                                            drawingModes: ['polygon' as any],
                                        },
                                    }}
                                />

                                {showInfoWindow.show
                  && (
                      <InfoWindow
                          position={{
                              lat: showInfoWindow.lat,
                              lng: showInfoWindow.lng,
                          }}
                          onCloseClick={() => setShowInfoWindow({ ...showInfoWindow, show: false })}
                      >
                          <div style={{ color: '#1A1F36' }}>
                              {showInfoWindow.regionName}
                          </div>
                      </InfoWindow>
                  )}

                                {regions.map((region: any) => {
                                    const paths = region.geoLongLat.coordinates[0].map((coordinate: number[]) => (
                                        { lat: coordinate[1], lng: coordinate[0] }
                                    ));

                                    const { fill: fillColor, stroke: strokeColor } = checkRegionStatus(region.regionStatus);
                                    return (
                                        <Polygon
                                            onClick={() => dispatch(openModal(ModalTypes.RegionActions, { region, allRegions: regions }))}
                                            key={region._id}
                                            paths={paths}
                                            options={{
                                                fillColor,
                                                strokeColor,
                                            }}
                                            onMouseOver={(data: any) => {
                                                setShowInfoWindow({
                                                    lat: data.latLng.lat(),
                                                    lng: data.latLng.lng(),
                                                    show: true,
                                                    regionName: region.regionName,
                                                });
                                            }}
                                            onMouseOut={() => {
                                                setShowInfoWindow({
                                                    ...showInfoWindow,
                                                    show: false,
                                                });
                                            }}
                                        />
                                    );
                                })}
                            </GoogleMap>
                        </LoadScript>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};
