import { notification } from 'antd';
import { FC, createContext, useState } from 'react';
import { COORDINATES } from '../../../const/coordinates';
import { updateCoverageAreaMyFleet } from '../../../api/workspaces';

export interface ILocationMap {
    center: {
        lat: number;
        lng: number;
    },
    zoom: number;
}

export interface ICoverageArea {
    actionLoading: boolean;
    error: any;
    showArea: boolean;
    currentLocation: ILocationMap;
    newPolygon: any;
    setShowArea: (value: boolean) => void;
    setNewPolygon: (value: google.maps.Polygon) => void;
    onPolygonComplete: (polygon: google.maps.Polygon) => void;
    resetPolygon: () => void;
    saveCoverageArea: (workspaceID: string) => void;
}

export const initialCoverageArea = {
    actionLoading: false,
    error: null,
    showArea: false,
    currentLocation: {
        center: {
            lat: 0,
            lng: 0,
        },
        zoom: 0,
    },
    newPolygon: undefined,
    setShowArea: () => { },
    setNewPolygon: () => { },
    onPolygonComplete: () => { },
    resetPolygon: () => { },
    saveCoverageArea: () => { },
};

export const CoverageContext = createContext<ICoverageArea>(initialCoverageArea);

export const CoverageProvider: FC = ({ children }) => {
    const defaultCenter = COORDINATES.EC;

    const [actionLoading, setActionLoading] = useState<boolean>(initialCoverageArea.actionLoading);
    const [error, setError] = useState<any>(initialCoverageArea.error);
    const [showArea, setShowArea] = useState<boolean>(initialCoverageArea.showArea);
    const [currentLocation, setCurrentLocation] = useState<ILocationMap>(
        {
            center: { ...defaultCenter },
            zoom: 12,
        },
    );
    const [newPolygon, setNewPolygon] = useState<google.maps.Polygon>();

    const onPolygonComplete = (polygon: google.maps.Polygon) => setNewPolygon(polygon);

    const resetPolygon = () => {
        newPolygon?.setMap(null);
        setNewPolygon(undefined);
    };

    const saveCoverageArea = (workspaceID: string) => {
        setActionLoading(true);

        const coordinates = newPolygon?.getPath().getArray().map((coordinate) => [
            coordinate.lat(),
            coordinate.lng(),
        ]);

        if (coordinates && coordinates.length > 3) {
            const firstCoordinate = coordinates?.[0];
            coordinates?.push(firstCoordinate);
        }

        const payload = {
            myFleetCoverage: coordinates,
        };

        updateCoverageAreaMyFleet(workspaceID, payload)
            .then((response: any) => {
                const message = response?.message || 'Coverage area updated successfully';
                notification.success({
                    message,
                });
            })
            .catch((err) => {
                const description = err?.response?.data?.message || 'Error updating coverage area';
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => {
                setActionLoading(false);
                setShowArea(false);
            });
    };

    return (
        <CoverageContext.Provider
            value={{
                actionLoading,
                error,
                showArea,
                setShowArea,
                currentLocation,
                newPolygon,
                setNewPolygon,
                onPolygonComplete,
                resetPolygon,
                saveCoverageArea,
            }}
        >
            {children}
        </CoverageContext.Provider>
    );
};
