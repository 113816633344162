/* eslint-disable prefer-promise-reject-errors */
import {
    Card, Form, Input, Modal, Select, notification,
} from 'antd';
import { Rule } from 'antd/lib/form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../actions/modals';
import { editDeliveryProvider } from '../../../api/deliveryProviders';
import { ModalTypes } from '../../../types/modals';
import { PAYMENT_METHODS } from '../../../const/paymentMethods';

const requiredRule: Rule = { required: true, message: 'Required' };
const greaterThanZeroRule: Rule = {
    validator: (rule, value) => (value >= 0 ? Promise.resolve() : Promise.reject('Must be greater or equal to 0')),
};
const OPTIONS = [
    { value: PAYMENT_METHODS.CASH, label: 'Cash' },
    { value: PAYMENT_METHODS.CARD, label: 'Card' },
    { value: PAYMENT_METHODS.BILLING_CARD, label: 'Billing Card' },
    { value: PAYMENT_METHODS.BILLING_TRANSFER, label: 'Billing Transfer' },
    { value: PAYMENT_METHODS.TERMINAL, label: 'Terminal' },
];

export const EditDeliveryProviderModal = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { activeModal, modalData, onSuccess } = useSelector((state: any) => state.modals);

    const isOpen = activeModal === ModalTypes.EditDeliveryProvider;
    const handleCancel = () => {
        form.resetFields();
        dispatch(closeModal());
    };

    useEffect(() => {
        form.setFieldsValue({ ...modalData });
    }, [modalData]);

    const onFinish = async (values: any) => {
        setLoading(true);
        try {
            await editDeliveryProvider(modalData._id, values);
            notification.success({ message: 'Success', description: 'Delivery Provider updated successfully' });
            onSuccess && onSuccess();
            handleCancel();
        } catch (error: any) {
            console.log({ error });
            const message = error.response?.data?.message;
            notification.error({
                message,
            });
        } finally {
            setLoading(false);
        }
    };

    const INPUTS = [
        { label: 'Max amount of cash', name: 'maxAmountCash', type: 'number' },
        { label: 'Min amount of cash', name: 'minAmountCash', type: 'number' },
    ];

    return (
        <Modal
            title={`Edit Delivery Provider: ${modalData?.name}`}
            visible={isOpen}
            onOk={() => form.submit()}
            onCancel={handleCancel}
            confirmLoading={loading}
            okText="Update"
        >
            <Card
                bordered={false}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    requiredMark={false}
                    layout="vertical"
                >
                    {INPUTS.map((input) => (
                        <Form.Item
                            label={input.label}
                            name={input.name}
                            rules={[requiredRule, ...(input.type === 'number' ? [greaterThanZeroRule] : [])]}
                            key={input.name}
                            initialValue={input.type === 'number' ? 0 : ''}
                        >
                            <Input placeholder={input.label} type={input.type} />
                        </Form.Item>
                    ))}
                    <Form.Item label="Payment Methods" name="paymentMethod">
                        <Select
                            defaultValue={modalData?.paymentMethod || []}
                            style={{ width: '100%' }}
                            mode="multiple"
                            options={OPTIONS}
                        />
                    </Form.Item>
                </Form>
            </Card>
        </Modal>
    );
};
