import { debounce } from 'lodash';
import {
    useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    Card, Divider, Input, List, Menu, Modal, PageHeader, Space, Spin, notification,
} from 'antd';
import { Responsable } from '../../../types/responsable';
import { getAdministrators } from '../../../api/admin';
import { ShieldContext } from '../context/shieldContext';
import { updateIssue } from '../../../api/issues';

interface Props {
    handleActive: () => void;
    responsableId: any;
    onChangeResponsable: (responsableID: any) => void;
}

const { Search } = Input;
const { confirm } = Modal;

const ResponsablesList = ({
    handleActive,
    responsableId,
    onChangeResponsable,
}: Props) => {
    const [responsables, setResponsables] = useState<Responsable[]>([]);
    const [maxResponsables, setMaxResponsables] = useState<number>(0);
    const [search, setSearch] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const { issue, updateIssue: updateStatus } = useContext(ShieldContext);

    const fetchAdministrators = async (search?: string) => {
        if (loading) return;
        if (responsables.length >= maxResponsables) setResponsables([]);
        setLoading(true);
        const LIMIT = 6;
        const PAGE = Math.ceil(responsables.length / LIMIT);

        const params: any = {
            limit: LIMIT,
            skip: PAGE,
            role: 'admin',
            isBlocked: false,
            isDeleted: false,
            isAdminVerified: true,
        };

        if (search && search !== '') { params.search = search; }

        getAdministrators(params)
            .then(({ data: { data } }) => {
                setResponsables([...responsables, ...data.users]);
                setMaxResponsables(data.count);
            })
            .catch((error) => console.log(error))
            .finally(() => setLoading(false));
    };

    const scrollableResponsablesRef = useRef<HTMLDivElement | null>(null);
    const fetchDebounced = useCallback<any>(debounce(fetchAdministrators, 700), []);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearch(value);
        fetchDebounced(value);
    };

    const confirmAssigned = (responsable: any) => confirm({
        title: 'Assign issue',
        content: `Are you sure to assign this issue to ${responsable?.name} ${responsable?.lastName}?`,
        onOk: async () => {
            try {
                const payload = {
                    responsableID: responsable?._id,
                    responsableName: `${responsable?.name} ${responsable?.lastName}`,
                    status: issue?.status,
                    description: issue?.description,
                    companyName: issue?.bookingData?.businessName,
                    userID: issue?.userID,
                    bookingNumericId: issue?.bookingNumericId,
                    issuePublicID: issue?.issuePublicID,
                    role: 'business',
                };
                const issueID = issue?._id;
                await updateIssue(issueID, payload);
                notification.success({
                    message: 'Issue assigned successfully',
                });
                updateStatus(
                    issueID,
                    {
                        responsableID: responsable?._id,
                        responsableName: `${responsable?.name} ${responsable?.lastName}`,
                    },
                );
                handleActive();
            } catch (error) {
                console.log(error);
                notification.error({
                    message: 'Error',
                    description: 'Something went wrong, please try again later',
                });
            }
        },
        onCancel: () => {
            if (!issue?.responsableID) return;
            onChangeResponsable(issue?.responsableID);
        },
        okText: 'Assign',
        cancelText: 'Cancel',
        zIndex: 9999,
    });

    const changeResponsable = (responsableID: String) => {
        onChangeResponsable(responsableID);
        const findResponsable = responsables.find((responsable: Responsable) => responsable._id === responsableID);
        confirmAssigned(findResponsable);
    };

    useEffect(() => {
        fetchAdministrators();
    }, []);

    const ITEMS = [
        {
            key: '1',
            label: (
                <Card
                    bordered
                    style={{
                        minWidth: 300,
                        maxWidth: 300,
                    }}
                >
                    <PageHeader
                        title="Responsables"
                        style={{
                            padding: 0,
                            paddingRight: 0,
                            width: '100%',
                            marginBottom: 12,
                        }}
                        extra={(
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={handleActive}
                            >
                                close
                            </span>
                        )}
                    />

                    <Space direction="vertical" size={12}>
                        <Search
                            allowClear
                            enterButton
                            onChange={handleSearch}
                            placeholder="Search by name"
                        />

                        <div
                            id="scrollableResponsables"
                            ref={scrollableResponsablesRef}
                            style={{
                                width: '100%',
                                overflow: 'auto',
                                height: '20vh',
                            }}
                        >
                            {
                                responsables.length === 0 && (
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: 8,
                                        }}
                                    >
                                        <Spin />
                                        <span>
                                            Getting responsables...
                                        </span>
                                    </div>
                                )
                            }
                            <InfiniteScroll
                                dataLength={responsables.length}
                                next={fetchAdministrators}
                                hasMore={responsables.length < maxResponsables}
                                loader={loading && <Spin />}
                                endMessage={
                                    !loading && (
                                        <Divider plain>
                                            No more responsables 🤐
                                        </Divider>
                                    )
                                }
                                scrollableTarget="scrollableResponsables"
                            >
                                <List
                                    dataSource={responsables}
                                    renderItem={(item: Responsable) => (
                                        item.name && (
                                            <List.Item
                                                id={item._id}
                                                key={item._id}
                                                onClick={() => changeResponsable(item._id)}
                                            >
                                                <List.Item.Meta
                                                    title={item.name}
                                                />
                                            </List.Item>
                                        )
                                    )}
                                />
                            </InfiniteScroll>
                        </div>

                    </Space>

                </Card>
            ),
        },
    ];

    return (
        <Menu items={ITEMS} />
    );
};

export default ResponsablesList;
