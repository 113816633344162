import { IRegion } from '../../../types/region';

const checkRegionStatus = (status: string) => {
    let fill = '#00b2e3';
    let stroke = '#067fa1';

    switch (status) {
    case 'GREEN': {
        fill = '#63d2b3';
        stroke = '#36AF8D';
        break;
    }

    case 'YELLOW': {
        fill = '#e9d54b';
        stroke = '#BBA927';
        break;
    }

    case 'RED': {
        fill = '#bc2828';
        stroke = '#9d1515';
        break;
    }
    default:
        break;
    }

    return {
        fill,
        stroke,
    };
};

const generatePolygonDataFromRegion = (region: IRegion) => {
    const { fill, stroke } = checkRegionStatus(region.regionStatus);

    const polygonData = {
        paths: region.geoLongLat.coordinates[0].map((coord) => ({ lat: coord[1], lng: coord[0] })),
        strokeColor: fill,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: stroke,
        fillOpacity: 0.35,
    };

    return polygonData;
};

export {
    checkRegionStatus,
    generatePolygonDataFromRegion,
};
