export enum ECurrency {
  USD = 'USD',
  MXN = 'MXN',
  DOP = 'DOP',
}

export enum PaymentMethod {
  CARD = 'CARD',
  BILLING_CARD = 'BILLING_CARD',
  BILLING_TRANSFER = 'BILLING_TRANSFER',
  CASH = 'CASH',
  ACTIVATION_CODE = 'ACTIVATION_CODE',
  TERMINAL = 'TERMINAL'
}
