import { IObjection } from '../../../interfaces/objection.interface';

export const initialContext = {
    objections: [] as IObjection[],
    objectionSelected: null as IObjection | null,
    loading: false,
    loadingDetail: false,
    showDetails: false,
    total: 0,
    paginator: {
        limit: 10,
        page: 1,
    },
    searchParams: {},
    statusString: '[]',
    onPageChange: () => {},
    handleStatus: () => { },
    handleWorkspace: () => {},
    handleObjection: () => {},
    setShowDetails: () => {},
    setObjectionSelected: () => {},
    fetchObjections: () => {},
    fetchObjectionDetail: () => {},
    updateStatus: () => {},
};
