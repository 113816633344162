/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { googleMapsStyles } from './googleMapStyles';

const Map = (props) => {
    const [mapRef, setMapRef] = useState();

    const setBounds = ({ map }) => {
        if (!mapRef) setMapRef(map);
        // eslint-disable-next-line no-undef
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(props.pos1);
        if (props.pos2) bounds.extend(props.pos2);
        map.fitBounds(bounds);
    };

    useEffect(() => {
        if (!mapRef) return;
        setBounds({ map: mapRef });
    }, [props]);

    return (
        <GoogleMapReact
            onGoogleApiLoaded={setBounds}
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
            // googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: '100%' }}><p>Cargando</p></div>}
            containerElement={<div style={{ height: '100%' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            defaultZoom={15}
            center={props.pos1}
            options={{ styles: googleMapsStyles }}
        >
            {props.children}
        </GoogleMapReact>
    );
};

export const Marker = (props) => (
    <div {...props} />
);

export default Map;
