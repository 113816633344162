/* eslint-disable no-unused-vars */
import {
    useEffect, useState, createContext, FC, useMemo,
} from 'react';
import { getDeliveryProviders } from '../../../api/deliveryProviders';
import { getAllRegions, getAllSuperRegions, getGlobalTiers } from '../../../api/faresWorker';
import { IDeliveryProviderData } from '../../../types/deliveryProvider';
import {
    TDeliveryProviders, TModalData, TRegion, TSuperRegion, TTier,
} from '../../../types/faresWorker';
import { type } from '../constants';

type TRegionContent = {
  data: TRegion[];
  loading: boolean;
};

type TDeliveryProviderContent = {
  data: TDeliveryProviders[];
  loading: boolean;
};

type TSuperRegionContent = {
  data: TSuperRegion[];
  loading: boolean;
}

type TGlobalTiersContent = {
  data: TTier[];
  loading: boolean;
}

interface ITiersConfigurationContext {
  regions: TRegionContent;
  superRegions: TSuperRegionContent;
  globalTiers: TGlobalTiersContent;
  elementSelected: TRegion | TSuperRegion | null,
  modalData: TModalData | null
  deliveryProviders: TDeliveryProviderContent;
  indexsElement: number[]

  selectElement: (element: TRegion | TSuperRegion) => void
  updateActionModal: (params: TModalData | null) => void
  fetchRegions: () => void
  fetchSuperRegions: () => void
}

const initialContext = {
    regions: {
        loading: false,
        data: [],
    },
    deliveryProviders: {
        loading: false,
        data: [],
    },
    superRegions: {
        loading: false,
        data: [],
    },
    globalTiers: {
        loading: false,
        data: [],
    },
    elementSelected: null,
    modalData: null,
    indexsElement: [],
    selectElement: () => { },
    updateActionModal: () => { },
    fetchRegions: () => { },
    fetchSuperRegions: () => {},
};

export const TiersConfigurationContext = createContext<ITiersConfigurationContext>(initialContext);

export const TiersConfigurationProvider: FC = ({ children }) => {
    const [regions, setRegions] = useState<TRegionContent>(initialContext.regions);
    const [superRegions, setSuperRegions] = useState<TSuperRegionContent>(initialContext.superRegions);
    const [deliveryProviders, setDelveryProviders] = useState<TDeliveryProviderContent>(initialContext.deliveryProviders);
    const [globalTiers, setGlobalTiers] = useState<TGlobalTiersContent>(initialContext.globalTiers);
    const [elementSelected, setElementSelected] = useState<TRegion | TSuperRegion | null>(null);
    const [modalData, setModalData] = useState<TModalData | null>(null);

    const fetchRegions = async () => {
        try {
            setRegions({ ...regions, loading: true });
            await getAllRegions()
                .then(({ data }) => {
                    const regionData = data.data.map((d: any) => ({ ...d, type: type.REGION }));
                    setRegions({
                        data: regionData,
                        loading: false,
                    });
                });
        } catch (error) {
            setRegions({ ...regions, loading: false });
        }
    };

    const fetchSuperRegions = async () => {
        try {
            setSuperRegions({ ...superRegions, loading: true });
            await getAllSuperRegions()
                .then(({ data }) => {
                    const superRegionsData = data.data.map((d: any) => ({ ...d, type: type.SUPER_REGION }));
                    setSuperRegions({
                        data: superRegionsData,
                        loading: false,
                    });
                });
        } catch (error) {
            setSuperRegions({ ...superRegions, loading: false });
        }
    };

    const fetchDeliveryProviders = async () => {
        try {
            setDelveryProviders({ ...deliveryProviders, loading: true });
            await getDeliveryProviders()
                .then(({ data }: any) => {
                    const deliveryProviderData = data.deliveryProviders.map((dp: IDeliveryProviderData) => ({
                        keyName: dp.keyName,
                        tier: 0,
                    }));

                    setDelveryProviders({
                        data: deliveryProviderData,
                        loading: false,
                    });
                });
        } catch (error) {
            setDelveryProviders({ ...deliveryProviders, loading: false });
        }
    };

    const fetchGlobalTiers = async () => {
        try {
            setGlobalTiers({ ...globalTiers, loading: true });
            await getGlobalTiers()
                .then(({ data }: any) => {
                    setGlobalTiers({
                        data: data.data,
                        loading: false,
                    });
                });
        } catch (error) {
            setGlobalTiers({ ...globalTiers, loading: false });
        }
    };

    useEffect(() => {
        fetchRegions();
        fetchSuperRegions();
        fetchDeliveryProviders();
        fetchGlobalTiers();
    }, []);

    const selectElement = (element: TRegion | TSuperRegion) => setElementSelected(element);

    const updateActionModal = (params: TModalData | null) => setModalData(params);

    const indexsElement = useMemo(() => {
        if (elementSelected?.tiers) {
            return elementSelected.tiers.map((tier) => tier.index);
        }
        return [];
    }, [elementSelected]);

    return (
        <TiersConfigurationContext.Provider
            value={{
                regions,
                superRegions,
                globalTiers,
                elementSelected,
                modalData,
                deliveryProviders,
                indexsElement,

                selectElement,
                updateActionModal,
                fetchRegions,
                fetchSuperRegions,
            }}
        >
            {children}
        </TiersConfigurationContext.Provider>
    );
};
