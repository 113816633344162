import {
    Card, Menu, Input, Select, Space, PageHeader, Switch,
} from 'antd';

interface Props {
    status: any;
    handleBookingID: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleWorkspaceID: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleIssueID: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleStatus: (value: any) => void;
    handleActive: () => void;
    handleNewMessages: (value: boolean) => void;
}

const { Search } = Input;

const Filters = ({
    status,
    handleBookingID,
    handleWorkspaceID,
    handleIssueID,
    handleStatus,
    handleActive,
    handleNewMessages,
}: Props) => {
    const OPTIONS = [
        { label: 'PENDING', value: 'PENDING' },
        { label: 'ACTIVE', value: 'ACTIVE' },
        { label: 'SOLVED', value: 'SOLVED' },
        { label: 'NOT SOLVED', value: 'NOT_SOLVED' },
    ];

    const ITEMS = [
        {
            key: '1',
            label: (
                <Card
                    bordered
                    className="card-filters"
                >
                    <PageHeader
                        title="Filters"
                        style={{
                            padding: 0,
                            width: '100%',
                            marginBottom: 12,
                        }}
                        extra={(
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={handleActive}
                            >
                                Close
                            </span>
                        )}
                    />
                    <Space
                        direction="vertical"
                        size={8}
                        style={{
                            width: '100%',
                            marginBottom: 12,
                        }}
                    >
                        <h4>Issue ID</h4>
                        <Search
                            allowClear
                            enterButton
                            onChange={handleIssueID}
                            placeholder="Issue ID"
                        />
                    </Space>
                    <Space
                        direction="vertical"
                        size={8}
                        style={{
                            width: '100%',
                            marginBottom: 12,
                        }}
                    >
                        <h4>Booking Numeric ID</h4>
                        <Search
                            allowClear
                            enterButton
                            onChange={handleBookingID}
                            placeholder="Booking Numeric ID"
                        />
                    </Space>
                    <Space
                        direction="vertical"
                        size={8}
                        style={{
                            width: '100%',
                            marginBottom: 12,
                        }}
                    >
                        <h4>Workspace Unique ID</h4>
                        <Search
                            allowClear
                            enterButton
                            onChange={handleWorkspaceID}
                            placeholder="Workspace Unique ID"
                        />
                    </Space>

                    <Space
                        direction="vertical"
                        size={8}
                        style={{
                            width: '100%',
                            marginBottom: 12,
                        }}
                    >
                        <h4>Issue status</h4>
                        <Select
                            defaultValue={status}
                            mode="multiple"
                            style={{ width: '300px' }}
                            placeholder="Issue status"
                            onChange={handleStatus}
                            options={OPTIONS}
                            maxTagCount="responsive"
                        />
                    </Space>
                    <Space
                        direction="vertical"
                        size={8}
                        style={{
                            width: '100%',
                            marginBottom: 12,
                        }}
                    >
                        <h4>New Messages</h4>
                        <Switch
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                            onChange={handleNewMessages}
                        />
                    </Space>
                </Card>
            ),
        },
    ];

    return (
        <Menu items={ITEMS} />
    );
};

export default Filters;
