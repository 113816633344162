import {
    Col, Divider, Input, Row, Spin,
} from 'antd';
import {
    useEffect, useContext, useMemo, useState, useRef,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CommandCenterContext } from '../context/commandCenterContext';
import { checkIsClickedDriverMarker, filterDriversFromValue } from '../utils/driver';
import DriverSelectStatus from './DriverSelectStatus';
import DriversList from './DriversList';

const { Search } = Input;

const DriversSection = () => {
    const [value, setValue] = useState('');
    const {
        drivers, isLoadingCommandCenterContext, driverMarkerSelected, updatedDriverMarkerSelected,
    } = useContext(CommandCenterContext);
    const [limit, setLimit] = useState(10);

    const scrollableDriversRef = useRef<HTMLInputElement | null>(null);

    const handleClick = (e: any) => {
        if (checkIsClickedDriverMarker(scrollableDriversRef, e)) {
            return;
        }
        updatedDriverMarkerSelected(null);
    };

    const handleChange = (e: any) => {
        setValue(e.target.value);
        if (e.target.value.length === 0) {
            setLimit(10);
        }
    };

    const handleNext = () => {
        setLimit(limit + 10);
    };

    const driversFiltered = useMemo(
        () => filterDriversFromValue(value, drivers, driverMarkerSelected).slice(0, limit).map((d) => d),
        [drivers, value, limit, driverMarkerSelected],
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => document.removeEventListener('mousedown', handleClick);
    }, [scrollableDriversRef]);

    return (
        <>
            <Row gutter={5}>
                <Col span={10}>
                    <DriverSelectStatus />
                </Col>
                <Col span={14}>
                    <Search placeholder="Search Driver..." defaultValue="" onChange={handleChange} enterButton />
                </Col>
            </Row>
            <Row>
                <div
                    id="scrollableDrivers"
                    ref={scrollableDriversRef}
                    style={{
                        width: '100%',
                        overflow: 'auto',
                        marginTop: '12px',
                        padding: '4px',
                        height: '73vh',
                        border: '1px solid #303030',
                    }}
                >
                    <InfiniteScroll
                        dataLength={driversFiltered.length}
                        next={handleNext}
                        hasMore={driversFiltered.length < drivers.length}
                        loader={isLoadingCommandCenterContext && <Spin />}
                        endMessage={!isLoadingCommandCenterContext && <Divider plain>It is all, nothing more 🤐</Divider>}
                        scrollableTarget="scrollableDrivers"
                    >
                        <DriversList drivers={driversFiltered} />
                    </InfiniteScroll>

                </div>

            </Row>
        </>
    );
};

export default DriversSection;
