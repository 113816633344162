import { Space, Tag, Typography } from 'antd';
import { InspectorEvent } from '../../interfaces/inspector.interface';

const { Text } = Typography;

const statusColor: any = {
    guest: 'cyan',
    business: 'gold',
};

const InspectorEventCard = ({ eventData }: { eventData: InspectorEvent }) => {
    const event = new InspectorEvent(eventData);
    const { registeredBy, description } = event;

    return (
        <Space direction="vertical">
            <Text>
                {description}
            </Text>
            {
                registeredBy && (
                    <Tag color={statusColor[registeredBy.role]}>
                        {registeredBy.role.toUpperCase()}
                    </Tag>
                )
            }
        </Space>
    );
};

export default InspectorEventCard;
