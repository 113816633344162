import { useState, useEffect } from 'react';
import {
    Modal, Form, Input, Button, notification,
    Select,
} from 'antd';
import { addPlan, updatePlan } from '../../../api/payments';

interface IPlanModal {
  visible: boolean;
  plan: any;
  onCancel: (plan: any) => void;
}

const PlanModal = ({
    visible,
    plan,
    onCancel,
}: IPlanModal) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue(plan);
    }, [visible, plan]);

    const handleSubmit = (values: any) => {
        setLoading(true);
        const { _id, ...payload } = values;
        if (_id) {
            updatePlan(_id, payload)
                .then(({ data }) => {
                    notification.success({ message: 'Plan updated successfully' });
                    form.resetFields();
                    onCancel(data.data);
                })
                .catch((err) => {
                    notification.error({ message: err.response.data.message });
                })
                .finally(() => setLoading(false));
        } else {
            addPlan(payload)
                .then(({ data }) => {
                    notification.success({ message: 'Plan created successfully' });
                    form.resetFields();
                    onCancel(data.data);
                })
                .catch((err) => {
                    notification.error({ message: err.response.data.message });
                })
                .finally(() => setLoading(false));
        }
    };

    const handleCancel = () => {
        form.resetFields();
        onCancel(null);
    };

    return (
        <Modal
            title="Add Plan"
            visible={visible}
            width={1000}
            onCancel={handleCancel}
            footer={[
                <Button type="primary" loading={loading} onClick={form.submit}>
                    {plan?._id ? 'Update' : 'Add'}
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Form.Item name="_id" hidden>
                    <Input />
                </Form.Item>
                <Form.Item label="Name" name="name">
                    <Input placeholder="Plan" />
                </Form.Item>
                <Form.Item label="Frequency" name="frequency">
                    <Select placeholder="Select a frequency">
                        <Select.Option value="WEEKLY">Weekly (7 days)</Select.Option>
                        <Select.Option value="FORTNIGHTLY">Fortnightly (15 days)</Select.Option>
                        <Select.Option value="MONTHLY">Monthly (30 days)</Select.Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default PlanModal;
