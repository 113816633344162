import {
    Col, Input, PageHeader, Row,
} from 'antd';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getWorkspaces } from '../api/workspaces';
import { RestoreWorkspace } from '../components/Modals';
import { ChangePaymentWorkspacesModal } from '../components/Modals/ChangePaymentWorkspaces';
import { DeactivateWorkspace } from '../components/Modals/DeactivateWorkspace/DeactivateWorkspace';
import { SettingsTiers } from '../components/Modals/Workspace';
import { WorkspacesTable } from '../components/WorkspacesTable';
import { OctopusPaginatorQuery } from '../types/paginator';
import { IWorkspace } from '../types/workspaces';

const { Search } = Input;

export const Workspaces = () => {
    const [workspaces, setWorkspaces] = useState<IWorkspace[]>([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [reload, setReload] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const search = searchParams.get('search') || '';
    const page = searchParams.get('page') || '1';
    const limit = searchParams.get('limit') || '10';
    const [paginator, setPaginator] = useState<OctopusPaginatorQuery>({ limit: Number(limit), page: Number(page) });

    useEffect(() => {
        setLoading(true);
        getWorkspaces({ paginator, search })
            .then(({ data }) => {
                setWorkspaces(data.data);
                setTotal(data.meta.itemCount);
            })
            .catch()
            .finally(() => setLoading(false));
    }, [paginator, reload]);

    const handlePaginator = (newPaginator: OctopusPaginatorQuery) => {
        const paginationUpdated = { ...paginator, ...newPaginator };
        const params = new URLSearchParams({ ...(paginationUpdated) as any });
        setPaginator(paginationUpdated);
        setSearchParams(params);
    };

    return (
        <div>
            <PageHeader
                title="Workspaces"
            />
            <Row justify="end" gutter={16}>
                <Col>
                    <Search
                        placeholder="Search..."
                        onSearch={(search) => {
                            handlePaginator({ ...paginator, page: 1, search });
                        }}
                        defaultValue={search}
                        enterButton
                    />
                </Col>
            </Row>
            <br />
            <WorkspacesTable
                workspaces={workspaces}
                loading={loading}
                paginator={paginator}
                setPaginator={handlePaginator}
                total={total}
            />
            <ChangePaymentWorkspacesModal reload={reload} setReload={setReload} />
            <RestoreWorkspace setReload={setReload} />
            <DeactivateWorkspace setReload={setReload} />
            <SettingsTiers setReload={setReload} reload={reload} actionSettingsTiers="setSettingsTiers" />
        </div>
    );
};
