/* eslint-disable react/jsx-no-useless-fragment */
import cs from 'classnames';
import { useContext } from 'react';
import { CommandCenterContext } from '../../context/commandCenterContext';
import styles from './styles.module.scss';

const DriversLegend = () => {
    const { drivers } = useContext(CommandCenterContext);
    return (
        <>
            {drivers.length > 0
                && (
                    <div className={cs(styles.driversLegend)}>

                        <div className={cs(styles.legendOption)}>
                            <div className={cs(styles.dot, styles.online)} />
                            Online
                        </div>
                        <div className={cs(styles.legendOption)}>
                            <div className={cs(styles.dot, styles.offline)} />
                            Offline
                        </div>
                        <div className={cs(styles.legendOption)}>
                            <div className={cs(styles.dot, styles.busy)} />
                            Busy
                        </div>

                    </div>
                )}
        </>
    );
};

export default DriversLegend;
