import {
    Button, Card, Dropdown, List, Menu, notification, PageHeader, Table, Tag, Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { getMicroFleetById, removeDriverFromSmallFleet } from '../api/microFleet';
import { AddNewDriverModal } from '../components/Modals';

const { Text } = Typography;

const FleetMenu = ({ fleet, smallFleetID, removeDriver }: any) => {
    const navigate = useNavigate();

    const menu = [
        {
            children: 'Deleted Driver',
            onClick: () => removeDriver(fleet._id),
        },
        {
            children: 'Show Details',
            onClick: () => {
                navigate(`/v2/small-fleet/${smallFleetID}/${fleet._id}`);
            },
        },
    ];

    return (
        <Menu>
            <Menu.ItemGroup key="g">
                {menu.map((o, i) => (
                    <Menu.Item key={i} {...o} />
                ))}
            </Menu.ItemGroup>
        </Menu>
    );
};

export const SmallFleetDetails = () => {
    const [showAddNewDriverModal, setShowAddNewDriverModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fleets, setFleets] = useState();
    const [paginator, setPaginator] = useState({ limit: 10, page: 1 });
    const [total, setTotal] = useState(0);
    const navigate = useNavigate();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const name = searchParams.get('name');

    useEffect(() => {
        setLoading(true);
        getMicroFleetById(id, paginator)
            .then(({ data }) => {
                setFleets(data.body.drivers);
                setTotal(data.body.pages * paginator.limit);
            })
            .catch((err) => {
                console.log(err);
                notification.error({ message: err.response?.data?.message });
            })
            .finally(() => setLoading(false));
    }, [showAddNewDriverModal, paginator]);

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({ limit: pageSize, page });
    };

    const removeDriver = (driverID: string) => {
        removeDriverFromSmallFleet({ userID: driverID, smallFleetID: id })
            .then((res: any) => {
                setPaginator((paginator: any) => ({ ...paginator }));
                notification.success({
                    message: 'Success!',
                    description: `${res.data.message}`,
                });
            })
            .catch((err) => notification.error({
                message: err.response?.data?.message,
            }));
    };

    const COLORS = {
        OFFLINE: 'red',
        ONLINE: 'green',
        BUSY: 'orange',
    };

    const colums = [
        {
            title: 'Full Name',
            dataIndex: '',
            key: 'name',
            render: ({ name, lastName }: any) => (
                `${name} ${lastName}`
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Mobile',
            dataIndex: 'fullMobile',
            key: 'fullMobile',
        },
        {
            title: 'Status',
            dataIndex: '',
            key: 'driver',
            render: (driver: any) => {
                const isBusy = driver?.isBusy;
                const onDutyFlag = driver?.onDutyFlag;
                let status = 'OFFLINE';
                if (!isBusy && onDutyFlag) status = 'ONLINE';
                if (isBusy) status = 'BUSY';

                return (
                    <Tag color={(COLORS as any)[status]}>
                        {' '}
                        {status}
                    </Tag>
                );
            },
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            width: 100,
            fixed: 'right' as 'right',
            // eslint-disable-next-line react/display-name
            render: (_: any, record: any) => (
                <Dropdown
                    placement="bottomRight"
                    overlay={(
                        <FleetMenu
                            fleet={record}
                            smallFleetID={id}
                            removeDriver={removeDriver}
                        />
                    )}
                    // @ts-ignore
                    onClick={(e: any, a: any) => { e.stopPropagation(); }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            <BsThreeDots size={25} />
                        </a>
                    </div>
                </Dropdown>
            ),
        },
    ];

    return (
        <div>
            <PageHeader title="Lista del Fleet" onBack={() => navigate('/v2/small-fleet')} />
            <Card title="Detalles">
                <List>
                    <List.Item>
                        <List.Item.Meta
                            title="Name"
                        />
                        <Text style={{ fontSize: 13 }}>{name}</Text>
                    </List.Item>
                    <div />
                </List>
            </Card>
            <PageHeader
                title="Lista de conductores"
                extra={[
                    <Button onClick={() => setShowAddNewDriverModal(true)} key="1">Add Driver</Button>,
                ]}
            />
            <Table
                loading={loading}
                columns={colums}
                dataSource={fleets}
                rowKey="_id"
                pagination={{
                    total,
                    current: paginator.page,
                    pageSize: paginator.limit,
                    onChange: onPageChange,
                    showSizeChanger: true,
                }}
            />
            {showAddNewDriverModal && (
                <AddNewDriverModal
                    showAddNewDriverModal={showAddNewDriverModal}
                    setShowAddNewDriverModal={setShowAddNewDriverModal}
                    smallFleetID={id}
                />
            )}
        </div>
    );
};
