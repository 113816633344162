import { useEffect, useState } from 'react';
import {
    Modal, Form, Select, Avatar, Space, Input,
} from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { getDeliveryProviders, getSmallFleets } from '../api/deliveryProviders';
import { useQuery } from '../hooks/useQuery';
import { DeliveryType, SmallFleetType } from '../types/delivery';
import { startUpdateRide } from '../actions/ride';
import SearchInput from './SearchInput';
import { findDriver } from '../api/drivers';
import { InternalProviders, Providers } from '../const/providers';

const { Option } = Select;

interface ModalFormProps {
  visible: boolean;
  onCancel: () => void;
}

interface FormValues {
  selectedProvider: Providers;
  deliveryFee: string;
  driver: { id: string, name: string };
  trackingLink: string;
  driverTag?: string;
}

const ModalForm = ({ visible, onCancel }: ModalFormProps) => {
    const query = useQuery();
    const bookingID = query.get('id') || '';
    const [deliveries, setDeliveries] = useState<DeliveryType[]>([]);
    const [smallFleets, setSmallFleets] = useState<SmallFleetType[]>([]);
    const [form] = Form.useForm();
    const { loading } = useSelector((state: any) => state.ride);
    const dispatch = useDispatch();

    useEffect(() => {
        getDeliveryProviders()
            .then(({ data }: any) => setDeliveries(data.deliveryProviders))
            .catch(console.log);
        getSmallFleets(100)
            .then(({ data }: any) => {
                setSmallFleets(data.body.smallFleets);
            })
            .catch(console.log);
    }, []);

    const handleSubmit = async (values: FormValues) => {
        const payload: any = { ...values, driverName: values.driver.name, driverID: values.driver.id };
        const isSmallFleets = payload.selectedProvider === 'SMALL_FLEETS';
        if (!isSmallFleets) {
            delete payload.driverTag;
        }
        delete payload.driver;
        await dispatch(startUpdateRide(bookingID, payload));
        onCancel();
    };

    const getDriverByName = (name: string) => {
        const query = { search: name };
        return findDriver(query)
            .then(({ data }) => {
                const drivers = data.data.data;
                return drivers.map((driver: any) => ({
                    id: driver._id,
                    name: `${driver.name} ${driver.lastName}`,
                }));
            })
            .catch(console.log);
    };

    const resetDriverID = () => {
        form.setFieldsValue({ driver: {} });
    };

    return (
        <Modal
            visible={visible}
            title="Asignar proveedor"
            onCancel={onCancel}
            onOk={() => form.submit()}
            confirmLoading={loading}
        >
            <Form form={form} onFinish={handleSubmit} layout="vertical">
                <Form.Item name="selectedProvider" label="Proveedor">
                    <Select placeholder="Picker" onChange={resetDriverID}>
                        {deliveries.map((delivery) => (
                            <Option key={delivery.keyName} value={delivery.keyName} label={delivery.name}>
                                <Space>
                                    <Avatar size="small" src={delivery.image} />
                                    {delivery.name}
                                </Space>
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item shouldUpdate={(prev, curr) => prev.selectedProvider !== curr.selectedProvider} noStyle>
                    {() => {
                        const selectedProvider = form.getFieldValue('selectedProvider');
                        const isSmallFleets = selectedProvider === 'SMALL_FLEETS';
                        if (!isSmallFleets) return null;
                        return (
                            <Form.Item name="driverTag" label="Flota">
                                <Select placeholder="Picker">
                                    {smallFleets.map((smallFleet) => (
                                        <Option key={smallFleet.keyName} value={smallFleet.keyName} label={smallFleet.name}>
                                            {smallFleet.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        );
                    }}
                </Form.Item>
                <Form.Item name="deliveryFee" label="Precio del delivery">
                    <Input placeholder="0.10" />
                </Form.Item>
                <Form.Item shouldUpdate={(prev, curr) => prev.selectedProvider !== curr.selectedProvider} noStyle>
                    {() => {
                        const selectedProvider = form.getFieldValue('selectedProvider');
                        const isInternalProvider = InternalProviders.includes(selectedProvider);
                        return (
                            <Form.Item name="driver" label="Nombre del conductor" initialValue={{}}>
                                <SearchInput
                                    getData={isInternalProvider ? getDriverByName : null}
                                    optionKeys={['id', 'name']}
                                    searchKey="name"
                                    placeholder="Alejandro Sosa"
                                />
                            </Form.Item>
                        );
                    }}
                </Form.Item>
                <Form.Item name="trackingLink" label="URL del tracking">
                    <Input type="url" placeholder="https://picker.com/tracking/123ABC" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalForm;
