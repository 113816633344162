import {
    Col, Dropdown, Menu, Row, Table, Typography,
} from 'antd';
import React, { useContext, useState } from 'react';
import { DispersionContext } from '../context/dispersionContext';
import { InitialColumns } from './InititalColumns';
import { FileType } from '../../../types/reports';
import { parseData } from '../../../utils/userReport';

const { Title } = Typography;

const TransactionsTable = () => {
    const INITIAL_COLUMNS: any = InitialColumns();
    const [fileType, setFileType] = useState<FileType>(FileType.CSV);
    const [loading, setLoading] = useState(false);

    const {
        dispersion,
        totalTransactions,
        loadingTransactions,
        paginator,
        onTableChange,
    } = useContext(DispersionContext);

    const { limit, page } = paginator;

    const handleChangeFileType = (type: any) => {
        setFileType(type.key);
    };

    const menu = (
        <Menu onClick={handleChangeFileType}>
            <Menu.Item key={FileType.CSV}>CSV</Menu.Item>
            <Menu.Item key={FileType.XLSX}>XLSX</Menu.Item>
        </Menu>
    );

    const handleClick = () => {
        setLoading(true);
        parseData({
            rows: dispersion.dispersionTransactions,
            fileName: dispersion.dispersionUniqueID || dispersion.workspaceUniqueID,
            selectedRows: ['transactionId', 'bookingId', 'date', 'hour', 'businessName', 'amount', 'comment', 'internalId', 'inStore'],
            headersType: 'dispersionsReport',
            fileType,
        });
        setLoading(false);
    };

    return (
        <Table
            title={
                () => (
                    <Row justify="space-between">
                        <Col>
                            <Title level={4}>
                                {`Transactions (${totalTransactions || 0})`}
                            </Title>
                        </Col>
                        <Col>
                            <Dropdown.Button
                                overlay={menu}
                                trigger={['click']}
                                type="primary"
                                buttonsRender={([leftButton, rightButton]) => [
                                    React.cloneElement(leftButton as React.ReactElement<any, string>, { loading }),
                                    rightButton,
                                ]}
                                onClick={handleClick}
                            >
                                Download
                                {' '}
                                {fileType}
                            </Dropdown.Button>
                        </Col>
                    </Row>
                )
            }
            loading={loadingTransactions}
            columns={INITIAL_COLUMNS}
            dataSource={dispersion.dispersionTransactions}
            pagination={{
                total: totalTransactions,
                current: page,
                pageSize: limit,
                showSizeChanger: true,
            }}
            onChange={onTableChange}
            bordered
            scroll={{ x: 1500 }}
            rowKey="_id"
        />
    );
};

export default TransactionsTable;
