import { useEffect, useState } from 'react';
import {
    Col, Dropdown, Menu, Modal, Row, Space, Typography, notification, Avatar, Drawer, Spin, Card, List, Select, Input, Form, Button,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { AiFillQuestionCircle } from 'react-icons/ai';
import moment from 'moment';
import cs from 'classnames';

import { BiWallet } from 'react-icons/bi';
import CardDataBase from '../../../CardDataBase';
import HelperIconTooltip from '../../../HelperIconTooltip';
import styles from '../../styles.module.scss';
import { editWorkspace } from '../../../../api/workspaces';
import { createTransaction, getTransactionsByWorkspace } from '../../../../api/wallet';
import { ITransaction, IWallet, IWalletCreatePayload } from '../../../../types/workspaces';
import { TypesCharge } from '../../../../const/wallet';
import CreateTransactionModal from '../../../Modals/WalletTransactionModal';

const { Text, Title } = Typography;

const colorTextHelp = '#000';

interface IWorkspacePayemntDetail {
    workspaceId: string
    payment?: {
        paymentGateway?: string;
        paymentMethod?: string;
    }
    wallet?: IWallet;
    setWallet: (wallet: any) => any
}

const PAYMENT_METHOD = ['BILLING_TRANSFER', 'BILLING_CARD', 'WALLET'];

const WorkspacePayemntDetail = ({
    workspaceId,
    payment,
    wallet,
    setWallet,
}: IWorkspacePayemntDetail) => {
    const [paymentMethod, setPaymentMethod] = useState(payment?.paymentMethod);

    useEffect(() => {
        if (paymentMethod) return setPaymentMethod(paymentMethod);
        if (!payment?.paymentMethod) return;
        setPaymentMethod(payment.paymentMethod);
    }, [paymentMethod]);

    const confirmPaymentMethod = (paymentMethod: string) => Modal.confirm({
        title: 'Change payment method',
        content: `Are you sure you want to change the payment method to ${paymentMethod}?`,
        onOk: async () => {
            try {
                const payment = { paymentMethod };

                editWorkspace(workspaceId, payment)
                    .then(({ data }) => {
                        notification.success({
                            message: data.message,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        notification.error({ message: 'oops! An error has occurred' });
                    })
                    .finally(() => {
                        setPaymentMethod(paymentMethod);
                    });
            } catch (error) { console.log(error); }
        },
        okText: 'Change',
        cancelText: 'Cancel',
    });

    const menu = (
        <Menu>
            {
                PAYMENT_METHOD.map((payment: string) => (
                    <Menu.Item key={payment} onClick={() => confirmPaymentMethod(payment)}>
                        {payment}
                    </Menu.Item>
                ))
            }
        </Menu>
    );
    const HELP_GATEWAY = (
        <Text style={{ color: colorTextHelp }}>
            Shows with which
            {' '}
            <Text strong style={{ color: colorTextHelp }}>entity </Text>
            your company makes payments
        </Text>
    );
    const [open, setOpen] = useState(false);
    const [loadingTransactions, setLoadingTransactions] = useState(false);
    const [transactions, setTransactions] = useState<ITransaction[]>([]);

    const [modalVisible, setModalVisible] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [form] = Form.useForm();

    const onClose = () => {
        setOpen(false);
        setTransactions([]);
    };

    const handleViewTransactions = () => {
        setLoadingTransactions(true);
        setOpen(true);
        getTransactionsByWorkspace(workspaceId)
            .then((data) => {
                setTransactions(data.data.data);
            })
            .finally(() => setLoadingTransactions(false));
    };

    const handleCloseModal = () => {
        setModalVisible(false);
        form.resetFields();
    };

    const handleSubmitModal = (values: any) => {
        const payload: IWalletCreatePayload = {
            ...values,
            amount: values.balance,
            walletID: wallet?._id,
            currency: wallet?.currency,
            workspaceID: workspaceId,
        };

        setModalLoading(true);
        createTransaction(payload)
            .then(() => {
                setModalVisible(false);
                notification.success({
                    message: 'Transaction created',
                });
                setWallet({ ...wallet, balance: Number(wallet?.balance) + Number(values.balance) });
            })
            .catch((e) => {
                notification.error({ message: e.response?.data?.message });
            })
            .finally(() => {
                setModalLoading(false);
                form.resetFields();
            });
    };

    const handleOpenModal = () => {
        if (wallet) {
            setModalVisible(true);
        }
    };

    return (
        <CardDataBase title="Workspace Payment" style={{ minHeight: 345 }}>
            <Space style={{ width: '100%' }} align="center" direction="horizontal" size={8}>
                <Title level={5}>Gateway</Title>
                <HelperIconTooltip icon={<AiFillQuestionCircle size={16} />} text={HELP_GATEWAY} />
            </Space>

            <Space align="center" direction="horizontal" size={8}>
                {payment?.paymentGateway
                    ? (
                        <Text>
                            The entity that makes the payments:
                            <Text style={{ color: '#00B2E3' }} strong>{payment?.paymentGateway}</Text>
                        </Text>
                    )
                    : <Text type="secondary">No registered</Text>}
            </Space>

            <Title style={{ marginTop: 32 }} level={5}>Method</Title>
            {payment?.paymentMethod
                ? (
                    <Row align="middle" justify="space-between" style={{ margin: '16px 0' }}>
                        <Col>
                            <Text>
                                The entity that makes the payments
                                :
                            </Text>
                        </Col>
                        <Col>
                            <Dropdown overlay={menu} trigger={['click']}>
                                <a onClick={(e) => e.preventDefault()} style={{ color: 'white' }}>
                                    <Space>
                                        {paymentMethod}
                                        <DownOutlined />
                                    </Space>
                                </a>
                            </Dropdown>

                        </Col>
                    </Row>
                )
                : <Text type="secondary">No registered</Text>}

            <Title style={{ marginTop: 32 }} level={5}>Wallet Balance</Title>
            {
                wallet
                    ? (
                        <div>
                            <div style={{ paddingBottom: 10 }}>
                                <span
                                    className={styles.textWallet}
                                    onClick={handleViewTransactions}
                                >
                                    View
                                    Transactions 👁️
                                </span>

                                <span
                                    className={styles.textWallet}
                                    onClick={handleOpenModal}
                                >
                                    Create
                                    Transaction 💰
                                </span>
                            </div>

                            <div className={styles.wallet}>
                                <Avatar className={styles.walletIcon}>
                                    <BiWallet className="anticon" color="white" size={35} />
                                </Avatar>
                                <span className={styles.walletBalance}>
                                    {`$ ${(wallet?.balance || 0).toFixed(2).toLocaleString()}`}
                                </span>
                            </div>
                        </div>
                    )
                    : (<Text>Wallet not available</Text>)

            }
            <Drawer
                title="Wallet Transactions"
                width={720}
                onClose={onClose}
                open={open}
            >

                { loadingTransactions ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '20vw',
                        }}
                    >
                        <Spin />
                        <Text>
                            Loading transactions
                        </Text>
                    </div>
                ) : (
                    <Space direction="vertical" size={24} style={{ width: '100%' }}>

                        {
                            !transactions.length
                                ? (
                                    <Text>
                                        This wallet has no transactions
                                    </Text>
                                ) : (
                                    <List
                                        className={styles.transactions}
                                        loading={loadingTransactions}
                                        itemLayout="horizontal"
                                        dataSource={transactions}
                                        renderItem={(item) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={(
                                                        <Avatar
                                                            style={{
                                                                backgroundColor: '#468dcf',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}
                                                            icon={<BiWallet />}
                                                        />
                                                    )}
                                                    title={TypesCharge[item.type as keyof typeof TypesCharge]}
                                                    description={(
                                                        <div>
                                                            <div>{moment(item.createdAt).format('DD/MM/YYYY')}</div>
                                                            <div>{`${item.registeredByName}`}</div>
                                                        </div>
                                                    )}
                                                />
                                                <div className={cs({ [styles.incremental]: item.amount > 0, [styles.decremental]: item.amount < 0 })}>
                                                    {`$ ${item.amount.toFixed(2)} ${item.currency}`}
                                                </div>
                                            </List.Item>
                                        )}
                                    />
                                )
                        }
                    </Space>
                )}
            </Drawer>
            { modalVisible && (
                <CreateTransactionModal
                    visible={modalVisible}
                    loading={modalLoading}
                    onCancel={handleCloseModal}
                    onSubmit={handleSubmitModal}
                />
            )}
        </CardDataBase>
    );
};

export default WorkspacePayemntDetail;
