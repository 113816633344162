import { InputNumber } from 'antd';
import { useEffect, useState } from 'react';

interface TierTimeProps {
    tier: number;
    time: number;
    setTime: (tier: number, time: number) => void;
}

export const TierTime = ({ tier, time, setTime }: TierTimeProps) => {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(time / 60);

    useEffect(() => {
        setValue(time / 60);
    }, [time]);

    const handleChange = (value: any) => setValue(value);

    const finishEditing = () => {
        setEditing(false);
        setTime(tier, Number(value) * 60);
    };

    const handleClick = () => setEditing(true);

    return (
        editing ? (
            <InputNumber
                value={value}
                onChange={handleChange}
                onBlur={finishEditing}
                onPressEnter={finishEditing}
                autoFocus
            />
        ) : (
            <div onClick={handleClick}>
                {`${(time || 0) / 60} Min.`}
            </div>
        )
    );
};
