import {
    Button, Checkbox, Col, Form, InputNumber, notification, Row, Typography,
} from 'antd';
import { useContext, useState } from 'react';
import { updateRegion, updateSuperRegion } from '../../../../../api/faresWorker';
import { TDeliveryProviders, TSuperRegion, TTier } from '../../../../../types/faresWorker';
import { type } from '../../../constants';
import { TiersConfigurationContext } from '../../../context/tiersConfigurationContext';
import { generateDataToUpdate } from '../../../utils/editModal';

const { Text } = Typography;

const EditContent = () => {
    const {
        indexsElement, modalData, elementSelected, updateActionModal, fetchRegions, fetchSuperRegions,
    } = useContext(TiersConfigurationContext);
    const [loading, setLoading] = useState(false);

    const onFinish = async (values: any) => {
        if (elementSelected && modalData) {
            try {
                setLoading(true);

                const currentElement = generateDataToUpdate({
                    elementSelected,
                    modalData,
                    values,
                });

                if (currentElement.type === type.REGION) {
                    await updateRegion(currentElement);
                    fetchRegions();
                } else {
                    await updateSuperRegion((currentElement as TSuperRegion));
                    fetchSuperRegions();
                }

                notification.success({
                    message: 'Tier edited successfully',
                });

                updateActionModal(null);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    };

    const onCancel = () => updateActionModal(null);

    const content: any = {
        tier: (
            <>
                <Row gutter={24}>
                    <Col span={15}>
                        <Form.Item
                            label="Time"
                            name="time"
                            rules={[{ type: 'number' }]}
                            initialValue={(modalData?.data.content as TTier).time}
                        >
                            <InputNumber type="number" min={0} addonAfter="Seg" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            label="Index"
                            name="index"
                            initialValue={(modalData?.data.content as TTier).index}
                            rules={[
                                { type: 'number' },
                                {
                                    validator(_, value) {
                                        if (!indexsElement.includes(value) || (modalData?.data.content as TTier).index === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Index number exist'));
                                    },
                                },
                            ]}
                        >
                            <InputNumber type="number" min={0} />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={5}>
                    <Col span={24}>
                        <Text style={{ color: 'white' }}>
                            <Text strong style={{ color: 'white' }}>Activates</Text>
                            {' '}
                            or
                            <Text strong style={{ color: 'white' }}>Deactivates</Text>
                            {' '}
                            the tier of the selected region/super region.
                        </Text>
                        <br />
                        <Form.Item name="isActive" valuePropName="checked" initialValue={(modalData?.data.content as TTier).isActive}>
                            <Checkbox defaultChecked={(modalData?.data.content as TTier).isActive} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end" gutter={5}>
                    <Col>
                        <Button onClick={onCancel} type="default">
                            Cancel
                        </Button>
                    </Col>
                    <Col>
                        <Button type="primary" htmlType="submit">
                            Send
                        </Button>
                    </Col>
                </Row>
            </>
        ),
        deliveryProvider: (
            <>
                <Form.Item
                    label="Tier"
                    name="tier"
                    initialValue={(modalData?.data.content as TDeliveryProviders).tier}
                >
                    <InputNumber type="number" min={0} />
                </Form.Item>
                <Row gutter={5} justify="end">
                    <Button onClick={onCancel} type="default">
                        Cancel
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Send
                    </Button>
                </Row>

            </>
        ),
    };

    return (
        <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            disabled={loading}
        >
            {modalData && content[modalData?.data.type]}
        </Form>
    );
};

export default EditContent;
