import { createContext, useEffect, useMemo } from 'react';
import { notification } from 'antd';
import { Socket } from 'socket.io-client';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { disconnectSocket, initiateSocket } from '../sockets';
import { handlerNotification } from '../handlers/issues.handler';

export const SocketsContext = createContext({});

export const SocketsProvider = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const subscriptions = (socket: Socket) => {
        handlerNotification({
            socket, notification, navigate, dispatch,
        });
    };

    useEffect(() => {
        const socket: any = initiateSocket();
        socket && subscriptions(socket);

        return () => disconnectSocket();
    }, []);

    const values = useMemo(() => ({}), []);

    return (
        <SocketsContext.Provider value={values}>
            {children}
        </SocketsContext.Provider>
    );
};
