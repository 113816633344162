import { Dispatch, SetStateAction } from 'react';
import {
    Dropdown, Menu, Table, Tooltip,
} from 'antd';
import { BsThreeDots } from 'react-icons/bs';
import { VscDebugDisconnect } from 'react-icons/vsc';
import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import { OctopusPaginatorQuery } from '../../types/paginator';
import { DeliveryType } from '../../types/delivery';
import { openModal, setChangeTier, setDeactivateDeliveryProvider } from '../../actions/modals';
import { ModalTypes } from '../../types/modals';

interface DeliveryProviderTableProps {
  deliveryProvider: DeliveryType[];
  loading: boolean;
  paginator: OctopusPaginatorQuery;
  total: number;
  setPaginator: Dispatch<SetStateAction<OctopusPaginatorQuery>>
}

interface ProviderDeliveryMenuProps {
    deliveryProvider: DeliveryType;
    reload: () => void;
}

const ProviderDeliveryMenu = ({ deliveryProvider, reload }: ProviderDeliveryMenuProps) => {
    const dispatch = useDispatch();
    const { isActive } = deliveryProvider;

    const menu = [
        {
            children: `${isActive ? 'Deactivate' : 'Active'} Delivery Provider`,
            onClick: () => {
                dispatch(setDeactivateDeliveryProvider(deliveryProvider));
            },
        },
        {
            children: 'Update Tier Delivery Provider',
            onClick: () => {
                dispatch(setChangeTier(deliveryProvider));
            },
        },
        {
            children: 'Edit fare settings',
            onClick: () => {
                dispatch(openModal(ModalTypes.EditFareSettings, { ...deliveryProvider, type: 'deliveryProvider' }, reload));
            },
        },
        {
            children: 'Edit Delivery Provider',
            onClick: () => {
                dispatch(openModal(ModalTypes.EditDeliveryProvider, { ...deliveryProvider, type: 'deliveryProvider' }, reload));
            },
        },
    ];

    return (
        <Menu>
            <Menu.ItemGroup key="g">
                {menu.map((o, i) => (
                    <Menu.Item key={i} {...o} />
                ))}
            </Menu.ItemGroup>
        </Menu>
    );
};

export const DeliveryProvidersTable = ({
    deliveryProvider, loading, paginator, setPaginator, total,
}: DeliveryProviderTableProps) => {
    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({ limit: pageSize, page });
    };

    const handleReload = () => {
        setPaginator((paginator: OctopusPaginatorQuery) => ({ ...paginator }));
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name: string, record: DeliveryType) => (
                <Link to={`/v2/delivery-providers/${record.keyName}`}>{name}</Link>
            ),
        },
        {
            title: 'Tier',
            dataIndex: 'tier',
            key: 'tier',
        },
        {
            title: 'isActive',
            dataIndex: 'isActive',
            key: 'isActive',
            width: 40,
            render: (isActive: boolean) => (
                isActive ? (
                    <Tooltip title="Delivery Provider is Active">
                        <VscDebugDisconnect color="green" size={25} />
                    </Tooltip>
                ) : (
                    <Tooltip title="Delivery Provider is deactivated">
                        <VscDebugDisconnect color="red" size={25} />
                    </Tooltip>
                )

            ),
        },
        {
            title: 'Actions',
            dataIndex: 'statusText',
            key: 'actions',
            width: 100,
            fixed: 'right' as 'right',
            // eslint-disable-next-line react/display-name
            render: (_statusText: any, record: DeliveryType) => (
                <Dropdown
                    placement="bottomRight"
                    overlay={(
                        <ProviderDeliveryMenu
                            deliveryProvider={record}
                            reload={handleReload}
                        />
                    )}
                    // @ts-ignore
                    onClick={(e: any) => { e.stopPropagation(); }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <a href="#" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            <BsThreeDots size={25} />
                        </a>
                    </div>
                </Dropdown>
            ),
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={deliveryProvider}
            loading={loading}
            rowKey="_id"
            rowClassName={(record: any) => (record.isDeleted ? 'table-row-warning' : 'booking-row')}
            pagination={{
                total,
                current: paginator.page,
                pageSize: paginator.limit,
                onChange: onPageChange,
                showSizeChanger: true,
            }}
        />
    );
};
