import { IChat, IMessage } from '../../../types/chat';
import { IContext } from './chat.interface';

export const initialContext: IContext = {
    loading: false,
    loadingMessages: false,
    chat: {} as IChat,
    messages: [] as IMessage[],
    lastMessage: {} as IMessage,
    selectedMessage: null,
    fetchChat: () => { },
    createChat: () => { },
    setMessages: () => { },
    fetchMessages: () => { },
    setLastMessage: () => { },
    addedMessage: () => { },
    setSelectedMessage: () => { },
    setReadMessages: () => { },
};
