import axios from 'axios';
import { OCTOPUS_BASE_URL, BASE_URL } from '.';
import { ICreateChatPayload } from '../types/chat';

export const getBookingIssues = (searchParams: any) => {
    const params = new URLSearchParams(searchParams);
    const url = `${OCTOPUS_BASE_URL}/issues`;
    const response = axios.get(url, { params });
    return response;
};

export const getIssueDetail = (issuePublicID: string) => {
    const url = `${OCTOPUS_BASE_URL}/issues?issuePublicID=${issuePublicID}`;
    const response = axios.get(url);
    return response;
};

export const updateIssue = (issueID: string, body: any) => {
    const url = `${OCTOPUS_BASE_URL}/issues/${issueID}`;
    const response = axios.put(url, body);
    return response;
};

export const deleteIssue = (issueID: string) => {
    const url = `${OCTOPUS_BASE_URL}/issues/${issueID}`;
    const response = axios.delete(url);
    return response;
};

export const getObjections = ({
    limit, page, status, workspaceUniqueID, objectionPublicID,
}: any) => {
    const params = new URLSearchParams();

    params.append('limit', limit);
    params.append('page', page);

    if (status && status.length) params.append('status', JSON.stringify(status));
    if (workspaceUniqueID) params.append('workspaceUniqueID', workspaceUniqueID);
    if (objectionPublicID) params.append('objectionPublicID', objectionPublicID);

    const url = `${OCTOPUS_BASE_URL}/objections`;
    const response = axios.get(url, { params });
    return response;
};

export const getChatData = (externalID: string, type: string) => {
    const params = new URLSearchParams();
    params.append('externalID', externalID);
    params.append('type', type);
    const url = `${OCTOPUS_BASE_URL}/issues-support/chat`;
    const response = axios.get(url, { params });
    return response;
};

export const getObjectionDetail = (objectionID: string) => {
    const url = `${OCTOPUS_BASE_URL}/objections/${objectionID}`;
    const response = axios.get(url);
    return response;
};

export const createNewChat = (payload: ICreateChatPayload) => {
    const url = `${OCTOPUS_BASE_URL}/issues-support/chat`;
    const response = axios.post(url, payload);
    return response;
};

export const getMessages = (chatID: string, lastMessageID?: string) => {
    const params = new URLSearchParams();
    if (lastMessageID) {
        params.append('lastMessageID', lastMessageID);
    }
    const url = `${OCTOPUS_BASE_URL}/issues-support/chat/${chatID}/messages`;
    const response = axios.get(url, { params });
    return response;
};

export const updateObjection = (objectionID: string, body: any) => {
    const url = `${OCTOPUS_BASE_URL}/objections/${objectionID}`;
    const response = axios.put(url, body);
    return response;
};

export const uploadFile = (file: any, type: string) => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('type', type);
    const url = `${BASE_URL}/user/uploadFile`;
    const headers = {
        'Content-Type': 'multipart/form-data',
    };
    const response = axios.post(url, formData, { headers });
    return response;
};

export const createIssue = (body: any) => {
    const url = `${OCTOPUS_BASE_URL}/issues`;
    const response = axios.post(url, body);
    return response;
};
