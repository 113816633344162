import axios from 'axios';
import { BASE_URL } from '.';

export const getAllAdmin = () => {
    const query = 'role=admin&isBlocked=false&isDeleted=false&isAdminVerified=true';
    return axios(`${BASE_URL}/admin/getAllUsers?${query}`);
};

export const getAdministrators = ({
    limit, skip, role, isBlocked, isDeleted, isAdminVerified, search,
}: any) => {
    const params = new URLSearchParams();

    params.append('limit', limit);
    params.append('skip', skip);

    params.append('role', role);
    params.append('isBlocked', isBlocked);
    params.append('isDeleted', isDeleted);
    params.append('isAdminVerified', isAdminVerified);

    if (search) {
        params.append('searchUser', search);
    }

    const url = `${BASE_URL}/admin/getAllUsers?${params.toString()}`;
    const response = axios.get(url);
    return response;
};
