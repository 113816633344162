import { ModalTypes } from '../types/modals';
import { types } from '../types/types';

const initialState = {
    loading: false,
    restartSearchId: null,
    restartCancelSearchId: null,
    ridesFraudModalId: null,
    changeStatus: null,
    ridesDriverManuallyModal: null,
    newsDriversModal: null,
    ridesReportIssue: null,
    workspace: null,
    removeWorkspace: null,
    activeModal: null,
    modalData: null,
    onSuccess: null,
    optionAction: null,
    addComment: null,

};

interface State {
  loading: boolean;
  restartSearchId: null;
  restartCancelSearchId: null;
  ridesFraudModalId: null;
  changeStatus: null;
  addComment: null;
  ridesDriverManuallyModal: null;
  newsDriversModal: null;
  activeModal: ModalTypes | null;
  modalData: any;
  onSuccess: any;
}

interface ActionProp {
  type: string;
  payload?: any;
}

export const modalsReducer = (state: State = initialState, action: ActionProp) => {
    switch (action.type) {
    case types.ridesRestartSearchId:
        return {
            ...state,
            restartSearchId: action.payload,
        };

    case types.ridesCancelSearchId:
        return {
            ...state,
            restartCancelSearchId: action.payload,
        };

    case types.ridesChangeStatus:
        return {
            ...state,
            changeStatus: action.payload,
        };
    case types.ridesAddComment:
        return {
            ...state,
            addComment: action.payload,
        };

    case types.ridesDriverManuallyModal:
        return {
            ...state,
            ridesDriverManuallyModal: action.payload,
        };

    case types.ridesReportIssue:
        return {
            ...state,
            ridesReportIssue: action.payload,
        };

    case types.workspaceEdit:
        return {
            ...state,
            workspace: action.payload,
        };

    case types.workspaceRemove:
        return {
            ...state,
            removeWorkspace: action.payload,
        };

    case types.workspaceDeactivate:
        return {
            ...state,
            deactivateWorkspace: action.payload,
        };

    case types.ridesStartLoading:
        return {
            ...state,
            loading: true,
        };

    case types.ridesFinishLoading:
        return {
            ...state,
            loading: false,
        };

    case types.openModal:
        return {
            ...state,
            activeModal: action.payload.type,
            modalData: action.payload.data,
            onSuccess: action.payload.onSuccess,
        };

    case types.closeModal:
        return {
            ...state,
            activeModal: null,
            modalData: null,
            onSuccess: null,
            optionAction: null,
        };

    case types.deliveryProviderDeactivate:
        return {
            ...state,
            deactivateDeliveryProvider: action.payload,
        };

    case types.deliveryProviderChangeTier:
        return {
            ...state,
            tierDeliveryProvider: action.payload,
        };

    case types.settingsTiers:
        return {
            ...state,
            optionAction: action.payload.optionAction,
            tiersWorkspace: action.payload.workspaces,
        };

    case types.bussinesSettingsTiers:
        return {
            ...state,
            bussines: action.payload,
        };

        // case types.workspaceEditMaxDistance:
        //   return {
        //     ...state,
        //     workspace: action.payload
        //   };

    default:
        return state;
    }
};
