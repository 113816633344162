import { PageHeader } from 'antd';
import HelperMessages from './components/HelperMessages';
import ModalAction from './components/ModalAction';
import RegionsSections from './components/RegionsSection';
import TiersSection from './components/TiersSection';
import { TiersConfigurationProvider } from './context/tiersConfigurationContext';

const TiersConfiguration = () => (
    <>
        <PageHeader
            className="site-page-header"
            title={(
                <>
                    Tier Configuration
                    <HelperMessages type="screen" />
                </>
            )}
        />
        <TiersConfigurationProvider>
            <RegionsSections />
            <br />
            <TiersSection />
            <ModalAction />
        </TiersConfigurationProvider>
    </>
);

export default TiersConfiguration;
