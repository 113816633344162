import { useState, useMemo, useContext } from 'react';
import {
    Button, Card, PageHeader, Row, Spin, Typography,
} from 'antd';
import { AiOutlineCopy } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';

import { AddUserModal } from '../../components/Modals';
import { AddDriverModal } from '../../components/Modals/AddDriversModal/AddDriversModal';
import WorkspaceDatail from '../../components/WorkspaceDetail';
import { WorkspaceDriversTable, WorkspaceUsersTable } from '../../components/WorkspacesTable';
import { WorkspaceBusinessesTable } from '../../components/WorkspacesTable/WorkspaceBusinessesTable';
import { copyText } from '../../utils/general';
import { BookingsTable } from '../../components/BookingsTable';
import { IWallet } from '../../types/workspaces';
import { CoverageProvider } from '../../components/WorkspaceDetail/context/coverageContext';
import { WorkspaceDetailsContext } from './context/WorkspaceDetailsContext';
import { WorkspaceInvitationsTable } from '../../components/WorkspacesTable/WorkspaceInvitationsTable';

const styles = {
    loadingStyles: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
    },
};

const { Text } = Typography;

export const WorkspaceDetailsContainer = () => {
    const [activeTabKey, setActiveTabKey] = useState('bookings');
    const [showAddUserModal, setShowAddUserModal] = useState({ visible: false, type: 'Add' });
    const [showAddDriverModal, setShowAddDriverModal] = useState({ visible: false, type: 'Add' });
    const [wallet, setWallet] = useState<IWallet>();
    type FetchStatus = {
        [key: string]: boolean;
      };
    const [fetchStatus, setFetchStatus] = useState<FetchStatus>({
        bookings: false,
        users: false,
        drivers: false,
        businesses: false,
    });
    const [loadingTable, setLoadingTable] = useState<boolean>(false);
    const {
        workspace, loading, bookings, fetchBookings, users, invitations, fetchUsers, fetchInvitations,
        drivers, fetchDrivers, businesses, fetchBusinesses, setLoading,
    } = useContext(WorkspaceDetailsContext);

    const navigate = useNavigate();
    const location = useLocation();

    const onBackHandle = () => {
        if (location.key !== 'default') navigate(-1);
        else navigate('/v2/workspaces');
    };

    const contentList: any = {
        bookings: <BookingsTable
            bookings={bookings}
            getBookings={fetchBookings}
        />,
        users: <WorkspaceUsersTable
            fetchUsers={fetchUsers}
            users={users}
            id={workspace?._id}
            setShowAddUserModal={setShowAddUserModal}
        />,
        invitations: <WorkspaceInvitationsTable
            fetchInvitations={fetchInvitations}
            invitations={invitations}
            id={workspace?._id}
        />,
        drivers: <WorkspaceDriversTable
            fetchDrivers={fetchDrivers}
            drivers={drivers}
            id={workspace?._id}
            setShowAddDriverModal={setShowAddDriverModal}
        />,
        businesses: <WorkspaceBusinessesTable
            businesses={businesses}
            uniqueID={workspace?.workspaceUniqueID}
        />,
    };

    const contentButton: any = {
        users: <Button onClick={() => setShowAddUserModal({ visible: true, type: 'Add' })} type="primary">+ Add User</Button>,
        drivers: <Button onClick={() => setShowAddDriverModal({ visible: true, type: 'Add' })} type="primary">+ Add Driver</Button>,
    };

    const tabList = useMemo(() => [
        {
            key: 'bookings',
            tab: `Workspace Bookings ${fetchStatus.bookings ? `(${bookings.count || 0})` : ''}`,
        },
        {
            key: 'users',
            tab: `Workspace Users ${fetchStatus.users ? `(${users.count || 0})` : ''}`,
        },
        {
            key: 'drivers',
            tab: `Workspace Drivers ${fetchStatus.drivers ? `(${drivers.count || 0})` : ''}`,
        },
        {
            key: 'businesses',
            tab: `Workspace Businesses ${fetchStatus.businesses ? `(${businesses.data.length || 0})` : ''}`,
        },
        {
            key: 'invitations',
            tab: `Workspace Invitations ${fetchStatus.invitations ? `(${invitations.count || 0})` : ''}`,
        },
    ], [bookings.count, users.count, invitations.count, drivers.count, businesses.data]);

    const onTabChange = (key: string) => {
        setLoadingTable(true);
        setActiveTabKey(key);
        if (!fetchStatus[key]) {
            switch (key) {
            case 'users':
                fetchUsers();
                break;
            case 'invitations':
                fetchInvitations();
                break;
            case 'drivers':
                fetchDrivers();
                break;
            case 'businesses':
                fetchBusinesses();
                break;
            default:
                break;
            }
            setFetchStatus((prevStatus) => ({
                ...prevStatus,
                [key]: true,
            }));
            setLoadingTable(false);
        }
    };

    return (
        <CoverageProvider>
            <div>
                {workspace?.workspaceUniqueID && workspace?.name
            && (
                <PageHeader
                    onBack={onBackHandle}
                    title={(
                        <Row align="middle">
                            <Text>{`${workspace?.name} (${workspace?.workspaceUniqueID})`}</Text>
                            <AiOutlineCopy
                                color="#C9D6E8"
                                size={24}
                                onClick={() => copyText({ value: workspace?.workspaceUniqueID, messageSuccess: 'WorkpsaceID Copied!' })}
                                cursor="pointer"
                            />
                        </Row>
                    )}
                />
            )}
                {loading ? (
                    <div style={styles.loadingStyles}>
                        <Spin size="large" />
                    </div>
                ) : (
                    <>
                        <WorkspaceDatail workspace={workspace} wallet={wallet} setWallet={setWallet} />
                        <br />
                        <Card
                            style={{ width: '100%' }}
                            tabList={tabList}
                            activeTabKey={activeTabKey}
                            onTabChange={onTabChange}
                            tabBarExtraContent={contentButton[activeTabKey]}
                        >
                            {contentList[activeTabKey]}
                        </Card>
                        {showAddUserModal && (
                            <AddUserModal
                                id={workspace?._id || ''}
                                showAddUserModal={showAddUserModal}
                                setShowAddUserModal={setShowAddUserModal}
                                setReload={() => fetchUsers(users.paginator)}
                            />
                        )}
                        {showAddDriverModal && (
                            <AddDriverModal
                                id={workspace?._id || ''}
                                showAddDriverModal={showAddDriverModal}
                                setShowAddDriverModal={setShowAddDriverModal}
                                setReload={() => fetchDrivers(drivers.paginator)}
                            />
                        )}
                    </>
                )}
            </div>
        </CoverageProvider>
    );
};
