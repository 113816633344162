import { useContext, useEffect } from 'react';
import { PageHeader, Space } from 'antd';
import {
    Filters, CertificationsTable, CertificationDetail,
} from './components';
import { CertificationsContext } from './context/certifications.context';

const WhatsappCertifications = ({ params }: { params: URLSearchParams }) => {
    const {
        fetchData,
        loading,
        loadingAction,
        data,
        item,
        total,
        paginator,
        onPageChange,
        searchParams,
        startDate,
        endDate,
        setItem,
        setData,
        handleDate,
        handleSearch,
        handleStatus,
    } = useContext(CertificationsContext);

    useEffect(() => {
        fetchData();
    }, [params, paginator]);

    return (
        <PageHeader
            title="Whatsapp Certifications"
            style={{ padding: '32px 24px 24px 0' }}
            footer={(
                <Space direction="vertical" size={24} style={{ width: '100%' }}>
                    {
                        item && (
                            <CertificationDetail
                                item={item}
                                open={!!item}
                                loading={loadingAction}
                                onClose={() => setItem(null)}
                            />
                        )
                    }
                    <Filters
                        searchParams={searchParams}
                        startDate={startDate}
                        endDate={endDate}
                        handleDate={handleDate}
                        handleStatus={handleStatus}
                        handleSearch={handleSearch}
                    />

                    <CertificationsTable
                        loading={loading}
                        data={data}
                        total={total}
                        paginator={paginator}
                        onPageChange={onPageChange}
                    />
                </Space>
            )}
        />
    );
};

export default WhatsappCertifications;
