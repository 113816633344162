import {
    createContext, FC, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { getPlanVariables, getVariables } from '../../../api/payments';
import { IVariable } from '../../../interfaces/plan';

interface IVariableContext {
    variables: any[],
    variablesLoading: boolean,
    fetchVariables: () => void,
    addVariable: (variable: any) => void,
}

const initialContext = {
    variables: [],
    variablesLoading: false,
    fetchVariables: () => {},
    addVariable: (variable: any) => {},
};

export const VariableContext = createContext<IVariableContext>(initialContext);

export const VariableProvider: FC = ({ children }) => {
    const [variablesLoading, setVariablesLoading] = useState<boolean>(initialContext.variablesLoading);
    const [variables, setVariables] = useState<IVariable[]>(initialContext.variables);
    const { id } = useParams();

    const fetchVariables = async () => {
        if (id) {
            setVariablesLoading(true);
            const { data } = await getPlanVariables(id);
            // Sort variables by planID, nulls last
            const variables = data.data.sort((a: any, b: any) => {
                if (!a.planID) return 1;
                if (!b.planID) return -1;
                return a.planID - b.planID;
            });
            setVariables(variables);
        } else {
            setVariablesLoading(true);
            const { data } = await getVariables();
            setVariables(data.data);
        }
        setVariablesLoading(false);
    };

    const addVariable = (variable: any) => {
        const exists = variables.find((c: any) => c._id === variable._id);
        if (exists) {
            setVariables((variables: any) => variables.map((c: any) => {
                if (c._id === variable._id) return variable;
                return c;
            }));
        } else {
            setVariables((variables: any) => [...variables, variable]);
        }
    };

    return (
        <VariableContext.Provider
            value={{
                variables,
                variablesLoading,
                fetchVariables,
                addVariable,
            }}
        >
            {children}
        </VariableContext.Provider>
    );
};
