import React, { useState } from 'react';
import {
    Form, Input, Button, Typography, notification, Space,
} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IMAGES } from '../const/images';
import { login } from '../api/login';
import { setUser } from '../actions/login';

const { Title } = Typography;

const Login = () => {
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onFinish = (values: any) => {
        const { email, password } = values;
        const role = 'admin';
        const deviceType = 'WEB';
        const payload = {
            email, password, role, deviceType,
        };
        setLoading(true);

        login(payload)
            .then((response: any) => {
                const { data: { data: { token, ...userData } } } = response;
                localStorage.setItem('token', token);
                localStorage.setItem('Role', role);
                dispatch(setUser(userData));
                navigate('/v2');
                window.location.reload();
                setLoading(false);
            })
            .catch((error) => {
                const message = error.response?.data?.message;
                notification.error({
                    message,
                });
                console.log(error);
            })
            .finally(() => setLoading(false));
    };

    const requiredRule = { required: true, message: 'Required' };

    return (
        <div className="screen screen-full">
            <div className="screen-container">
                <Form
                    name="login-form"
                    style={{ width: '100%', height: '100%' }}
                    onFinish={onFinish}
                >
                    <Space direction="vertical" size={8} align="center" style={{ width: '100%', marginBottom: 24 }}>
                        <img src={IMAGES.logo.iso} alt="Picker logo" style={{ display: 'block', margin: '0 auto' }} />
                        <Title level={4}>ADMIN PANEL</Title>
                    </Space>

                    <Form.Item
                        name="email"
                        rules={[requiredRule]}
                    >
                        <Input prefix={<UserOutlined />} placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[requiredRule]}
                    >
                        <Input.Password
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading} block>
                        Log in
                    </Button>
                </Form>
            </div>
        </div>
    );
};

export default Login;
