import {
    Col, Row, Space, Tooltip, Typography,
} from 'antd';
import { BsFillInfoSquareFill } from 'react-icons/bs';

interface ItemProps {
    label: string;
    value: string | number | React.ReactNode;
    info?: boolean;
    infoLabel?: string;
}

const { Text } = Typography;

const ItemData = ({
    label, value, info = false, infoLabel = '',
}: ItemProps) => (
    <Row justify="space-between" align="middle" style={{ width: '100%' }}>
        <Col span={12}>
            <Space size={8}>
                <Text>
                    {label}
                </Text>
                {
                    info && (
                        <Tooltip placement="top" title={infoLabel}>
                            <BsFillInfoSquareFill
                                size={12}
                                color="#687385"
                                style={{ verticalAlign: 'middle' }}
                            />
                        </Tooltip>
                    )
                }
            </Space>
        </Col>
        <Col span={12}>
            <Text className="booking__info--text-value">
                {value}
            </Text>
        </Col>
    </Row>
);
export default ItemData;
