import moment from 'moment';
import { Drawer, Spin, Typography } from 'antd';
import { useContext, useEffect } from 'react';
import { ChatContext } from '../context/chatContext';
import { IssuesContext } from '../../Issues/context/issues.context';
import Chat from './Chat';
import CreateChat from './CreateChat';
import MessageInfo from './MessageInfo';

const { Text, Paragraph } = Typography;

const ContainerChat = () => {
    const {
        loading, selectedMessage, chat, fetchChat, setSelectedMessage,
    } = useContext(ChatContext);
    const { item } = useContext(IssuesContext);

    useEffect(() => {
        if (!item) return;
        fetchChat(item?.issuePublicID);
    }, []);

    return (
        <div>
            <Drawer
                width="20%"
                title="Information Message"
                open={!!selectedMessage}
                placement="right"
                onClose={() => setSelectedMessage(null as any)}
            >
                {
                    selectedMessage ? (
                        <MessageInfo />
                    ) : (
                        <div className="chat__information">
                            <Paragraph style={{ textAlign: 'center' }}>
                                No message selected
                            </Paragraph>
                        </div>
                    )
                }
            </Drawer>
            {
                loading ? (
                    <div className="chat__loading">
                        <Spin />
                        <Paragraph style={{ textAlign: 'center' }}>
                            Loading chat
                        </Paragraph>
                    </div>
                ) : chat && chat._id ? (
                    <Chat />
                ) : (
                    <CreateChat />
                )
            }
        </div>
    );
};

export default ContainerChat;
