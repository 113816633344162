import { TDeliveryProviders, TRegion, TSuperRegion } from '../../../types/faresWorker';

const filterDeliveryProvidersElement = (
    elementSelected: TRegion | TSuperRegion | null,
    deliveryProviders: TDeliveryProviders[],
) => {
    if (elementSelected && elementSelected.deliveryProviders) {
        const newData = deliveryProviders.map((dp) => {
            const elementHasDp = elementSelected.deliveryProviders?.find((dps) => dps.keyName === dp.keyName);
            return elementHasDp || dp;
        });
        return newData;
    }
    return deliveryProviders;
};

export {
    filterDeliveryProvidersElement,
};
