import {
    Col, Form, Input, InputNumber, Modal, notification, Row, Select,
} from 'antd';
import { useState } from 'react';
import { addDriverToSmallFleet } from '../../../api/microFleet';

export const AddNewDriverModal = ({ setShowAddNewDriverModal, showAddNewDriverModal, smallFleetID }: any) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const requiredRule = { required: true, message: 'Required' };
    const vehicleType = [{ value: 'LITE', label: 'Car' }, { value: 'BIKE', label: 'Bike' }];
    const currency = [{ value: 'usd', label: 'USD' }, { value: 'mxn', label: 'MXN' }];
    const language = [{ value: 'es', label: 'Spanish' }, { value: 'en', label: 'English' }];

    const handleCancel = () => {
        setShowAddNewDriverModal(false);
    };
    const onFinish = (values: any) => {
        setLoading(true);
        addDriverToSmallFleet({ ...values, countryCode: `+${values.countryCode}`, smallFleetID })
            .then((res) => {
                notification.success({
                    message: res.data.message,
                });
                form.resetFields();
                setShowAddNewDriverModal(false);
            })
            .catch((err) => {
                notification.error({ message: err.response?.data?.message });
            })
            .finally(() => setLoading(false));
    };

    return (
        <Modal
            title="Add New Driver"
            visible={showAddNewDriverModal}
            onOk={() => form.submit()}
            onCancel={handleCancel}
            bodyStyle={{ padding: '0px 24px' }}
            confirmLoading={loading}
        >
            <br />
            <Form
                requiredMark={false}
                layout="vertical"
                form={form}
                onFinish={onFinish}
            >
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item name="name" label="Name" rules={[requiredRule]}>
                            <Input placeholder="First Name" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="lastName" label="Last Name" rules={[requiredRule]}>
                            <Input placeholder="Last Name" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={12}>
                        <Form.Item name="countryCode" label="Country Code" rules={[requiredRule]}>
                            <Input addonBefore="+" placeholder="Country Code" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="mobile" label="Mobile" rules={[requiredRule]}>
                            <Input style={{ width: '100%' }} placeholder="Mobile" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item name="email" label="Email" rules={[requiredRule]}>
                            <Input placeholder="Email" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={8}>
                        <Form.Item name="vehicleType" label="Vehicle Type" rules={[requiredRule]}>
                            <Select placeholder="Vehicle Type" options={vehicleType} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="vehicleModelName" label="Vehicle Model" rules={[requiredRule]}>
                            <Input placeholder="Vehicle Model" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="MCNumber" label="MCNumber" rules={[requiredRule]}>
                            <Input placeholder="MC Number" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item name="vehicleColor" label="vehicle Color" rules={[requiredRule]}>
                            <Input style={{ width: '100%' }} placeholder="Vehicle Color" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="vehicleYear" label="Vehicle Year" rules={[requiredRule]}>
                            <InputNumber style={{ width: '100%' }} placeholder="Vehicle Year" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item name="currency" label="Currency" rules={[requiredRule]}>
                            <Select placeholder="Currency" options={currency} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="language" label="Language" rules={[requiredRule]}>
                            <Select placeholder="Language" options={language} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
