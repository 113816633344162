import {
    Row, Col, Select, Input,
} from 'antd';

interface Props {
    defaultWorkspaceUniqueID: string;
    defaultObjectionPublicID: string;
    searchParams: any;
    handleStatus: (value: string) => void;
    handleWorkspace: (workspaceUniqueID: string) => void;
    handleObjection: (objectionPublicID: string) => void;
}

const { Search } = Input;

const Filters = ({
    defaultWorkspaceUniqueID,
    defaultObjectionPublicID,
    searchParams,
    handleStatus,
    handleWorkspace,
    handleObjection,
}: Props) => {
    const { status, workspaceUniqueID, objectionPublicID } = searchParams;

    const OPTIONS = [
        { label: 'PENDING', value: 'PENDING' },
        { label: 'ACTIVE', value: 'ACTIVE' },
        { label: 'COMPLETED', value: 'COMPLETED' },
        { label: 'CANCELLED', value: 'CANCELLED' },
    ];

    return (
        <Row justify="start" gutter={[16, 24]}>
            <Col>
                <Search
                    allowClear
                    enterButton
                    defaultValue={objectionPublicID || defaultObjectionPublicID}
                    onSearch={handleObjection}
                    placeholder="Objection ID"
                />
            </Col>
            <Col>
                <Search
                    allowClear
                    enterButton
                    defaultValue={workspaceUniqueID || defaultWorkspaceUniqueID}
                    onSearch={handleWorkspace}
                    placeholder="Workspace Unique ID"
                />
            </Col>
            <Col>
                <Select
                    defaultValue={status}
                    mode="multiple"
                    style={{ width: '300px' }}
                    placeholder="Objection status"
                    onChange={handleStatus}
                    options={OPTIONS}
                    maxTagCount="responsive"
                />
            </Col>
        </Row>
    );
};

export default Filters;
