import {
    Button,
    List, Space, Spin, Typography,
} from 'antd';
import { useEffect } from 'react';
import { MdOpenInNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { IUser } from '../../../interfaces/user.interface';
import { IWorkspaceUser } from '../../../interfaces/workspace-users.interface';

interface Props {
    user: IUser;
    loadingTab: boolean;
    fetchWorkspacesRelated: (userID: string) => void;
}

const { Text } = Typography;

const WorkspacesRelated = ({
    user, loadingTab, fetchWorkspacesRelated,
}: Props) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!user._id) return;
        fetchWorkspacesRelated(user._id);
    }, [user._id]);

    return (
        loadingTab ? (
            <Space size={16}>
                <Spin size="small" />
                <Text>
                    Getting data
                </Text>
            </Space>
        ) : (
            <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                <List
                    dataSource={user.workspaces}
                    renderItem={(item: IWorkspaceUser) => (
                        item.workspaceID && (
                            <List.Item
                                key={item._id}
                                extra={[
                                    <Button
                                        key="1"
                                        type="link"
                                        icon={<MdOpenInNew />}
                                        onClick={() => navigate(`/v2/workspaces/${item.workspaceID?._id}`)}
                                    />,
                                ]}
                            >
                                <List.Item.Meta
                                    title={(
                                        <Text strong>
                                            {`${item.workspaceID?.name} - ${item.workspaceID?.workspaceUniqueID}`}
                                        </Text>
                                    )}
                                    description={(
                                        <Space size={8}>
                                            <Text>
                                                User role in workspace as
                                            </Text>
                                            <Text strong>
                                                {
                                                    user._id === item.workspaceID.ownerID
                                                        ? 'OWNER'
                                                        : item.roleID.keyName.split('_').join(' ')
                                                }
                                            </Text>
                                        </Space>
                                    )}
                                />
                            </List.Item>
                        )
                    )}
                />
            </div>
        )
    );
};

export default WorkspacesRelated;
