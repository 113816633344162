import { useEffect, useState } from 'react';
import {
    Form, Input, Modal, notification, Select, Space, Transfer, Spin,
} from 'antd';

import { MdOpenInNew } from 'react-icons/md';
import {
    addUser, getAssociatedBusiness, getWorkspaceUser, updateUserInformation,
} from '../../../api/workspaces';
import { EWorkspaceRole } from '../../../types/workspaces';

interface showAddUserModal {
  visible: boolean;
  type: string;
  user?: any;
}

interface AddUserModalProps {
  id: string;
  showAddUserModal: showAddUserModal
  setShowAddUserModal: React.Dispatch<React.SetStateAction<any>>
  setReload: () => void
}

export const AddUserModal = ({
    id, showAddUserModal, setShowAddUserModal, setReload,
}: AddUserModalProps) => {
    const [loading, setLoading] = useState(false);
    const [loadingUser, setLoadingUser] = useState(false);
    const [business, setBusiness] = useState<any>([]);
    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [user, setUser] = useState<any>();
    const [form] = Form.useForm();

    const onChange = (nextTargetKeys: string[]) => {
        setTargetKeys(nextTargetKeys);
    };

    const options = [
        { value: EWorkspaceRole.MANAGEMENT, label: 'Admin' },
        { value: EWorkspaceRole.OPERATOR, label: 'Member' },
    ];

    useEffect(() => {
        if (!id) return;
        if (showAddUserModal.type === 'Edit') {
            setLoadingUser(true);
            getWorkspaceUser(id, showAddUserModal.user._id)
                .then(({ data: { data } }) => {
                    const businessIdsUser = data.business.map((store: any) => store._id);
                    const userData = {
                        ...data.user,
                        businessIdsUser,
                    };
                    setUser(userData);
                    if (!showAddUserModal.user?.hasAllBusiness) {
                        setTargetKeys(businessIdsUser);
                    }
                    form.setFieldsValue({
                        name: data.user.name,
                        lastName: data.user.lastName,
                        email: data.user.email,
                        countryCode: data.user.countryCode,
                        mobile: data.user.mobile,
                    });
                    form.setFieldValue('business', businessIdsUser);
                    form.setFieldValue('role', data.role.keyName);
                })
                .catch((err: any) => console.log({ err }))
                .finally(() => setLoadingUser(false));
        }
        setLoadingUser(true);
        getAssociatedBusiness(id)
            .then(({ data }) => {
                const dataMaped = data.body.data.map((business: any) => ({
                    key: business._id,
                    companyName: business.businessID.companyName,
                }));
                setBusiness(dataMaped);
                if (showAddUserModal.user?.hasAllBusiness) {
                    const businessIdsUser = data.body.data.map((store: any) => store._id);
                    setTargetKeys(businessIdsUser);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoading(false);
                setLoadingUser(false);
            });
    }, [id, showAddUserModal]);

    const onFinish = (values: any) => {
        const hasAllBusiness = targetKeys?.length === business.length;

        if (hasAllBusiness) {
            values.business = [];
        }

        const request = showAddUserModal.type === 'Add' ? addUser : updateUserInformation;
        const payload = {
            ...values, workspaceID: id, hasAllBusiness, business: targetKeys,
        };
        if (showAddUserModal.type === 'Edit') {
            payload.userID = showAddUserModal?.user._id;
        }

        request(payload)
            .then(({ data }) => {
                setLoading(true);
                const { message } = data;
                notification.success({
                    message,
                });
                setReload();
            })
            .catch((error) => {
                const message = error.response?.data?.message;
                notification.error({
                    message,
                });
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
                setShowAddUserModal({ visible: false });
                form.resetFields();
                setTargetKeys([]);
            });
    };

    const handleCancel = () => {
        setShowAddUserModal({ visible: false });
        form.resetFields();
        setTargetKeys([]);
    };

    const requiredRule = { required: true, message: 'Required' };
    const isEdit = showAddUserModal.type === 'Edit';

    return (
        <Modal
            title={(
                <Space
                    direction="horizontal"
                    size={8}
                >
                    <span>
                        {
                            !isEdit
                                ? `${showAddUserModal.type} User`
                                : `${showAddUserModal.type} User - ${showAddUserModal.user?.name} ${showAddUserModal.user?.lastName}`
                        }
                    </span>
                    {isEdit && <MdOpenInNew style={{ verticalAlign: 'middle' }} size={16} />}
                </Space>
            )}
            visible={showAddUserModal.visible}
            onOk={() => form.submit()}
            okText={isEdit ? 'Update' : 'Add'}
            onCancel={handleCancel}
            bodyStyle={{ padding: '0px 24px' }}
            confirmLoading={loading}
        >
            {
                loadingUser ? (
                    <Space direction="horizontal" size={12} style={{ margin: '16px auto' }}>
                        <Spin />
                        <span>Loading user data...</span>
                    </Space>
                ) : (
                    <Form
                        form={form}
                        onFinish={onFinish}
                        requiredMark={false}
                        layout="vertical"
                        style={{ marginTop: 20 }}
                    >
                        <Form.Item label="Role" name="role" rules={[requiredRule]}>
                            <Select placeholder="Role" options={options} />
                        </Form.Item>
                        <Form.Item label="Email" name="email" rules={[requiredRule]} hidden={isEdit}>
                            <Input placeholder="Email" />
                        </Form.Item>

                        <Form.Item label="Business Access" name="business">
                            <Transfer
                                style={{ display: 'flex', justifyContent: 'center' }}
                                dataSource={business}
                                titles={['Businesses', 'Selected']}
                                showSelectAll
                                targetKeys={targetKeys}
                                onChange={onChange}
                                render={(item: any) => item.companyName}
                                oneWay
                            />
                        </Form.Item>
                    </Form>
                )
            }
        </Modal>
    );
};
