import {
    Modal, notification, Radio, Space, Table,
} from 'antd';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setDriverManuallylId } from '../../../actions/modals';
import { assignBookingManually, getAllAvailableDriver } from '../../../api/deliveryProviders';
import { getBatteryStatus, returnVehicleType } from '../../../utils/driver';
import { getAssignReasons } from '../../../api/drivers';

export const AssigningDriverManually = () => {
    const [loading, setLoading] = useState(false);
    const [allUsers, setAllUsers] = useState<any>([]);
    const [assignReasons, setAssignReasons] = useState<any>([]);
    const reason = useRef();
    const [userID, setOnChangeValues] = useState('');
    const [paginator, setPaginator] = useState({ limit: 10, skip: 0 });
    const [total, setTotal] = useState(0);
    const { ridesDriverManuallyModal } = useSelector((state: any) => state.modals);
    const dispatch = useDispatch();

    const fetchAssignReasons = async () => {
        getAssignReasons()
            .then(({ data }) => {
                setAssignReasons(data.data);
            })
            .catch((error) => {
                console.log(error);
                notification.error({
                    message: 'Error!',
                    description: `${error.response.data.message}`,
                });
            });
    };

    useEffect(() => {
        setLoading(true);
        if (!ridesDriverManuallyModal) return;
        fetchAssignReasons();
        getAllAvailableDriver(ridesDriverManuallyModal, paginator)
            .then(({ data }) => {
                setAllUsers(data.data.data);
                setTotal(data.data.count);
            })
            .catch(console.log)
            .finally(() => setLoading(false));
    }, [paginator, ridesDriverManuallyModal]);

    const handleReasonChange = (e: any) => {
        reason.current = e.target.value;
    };

    const assignBooking = async () => {
        setLoading(true);
        const reasonValue = reason.current || '';
        const payload = {
            userID,
            manualAssignReason: reasonValue,
            bookingID: ridesDriverManuallyModal._id,
            assignConfirm: true,
            isAdmin: true,
        };
        assignBookingManually(payload)
            .then((res: any) => notification.success({
                message: 'Success!',
                description: `${res.data.message}`,
            }))
            .catch((error) => {
                console.log(error);
                notification.error({
                    message: 'Error!',
                    description: `${error.response.data.message}`,
                });
            })
            .finally(() => {
                dispatch(setDriverManuallylId(null));
                setLoading(false);
            });
    };

    const onCancel = () => {
        dispatch(setDriverManuallylId(null));
    };

    const onFinish = () => {
        Modal.confirm({
            title: 'Select a reason for the assignment',
            width: 500,
            content: (
                <Radio.Group onChange={handleReasonChange}>
                    <Space direction="vertical">
                        {assignReasons.map((reason: any) => (
                            <Radio key={reason._id} value={reason._id}>{reason.description}</Radio>
                        ))}
                    </Space>
                </Radio.Group>
            ),
            onOk: assignBooking,
            onCancel,
        });
    };

    const onChange = (e: any) => {
        setOnChangeValues(e.target.value);
    };

    const columns = [
        {
            title: 'Select',
            dataIndex: 'userID',
            key: '_id',
            width: '10px',
            render: (_id: any) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Radio
                        onChange={onChange}
                        value={_id}
                        checked={userID === _id}
                    />
                </div>
            ),
        },
        {
            title: 'Driver Name',
            dataIndex: 'user',
            key: 'name',
            render: (user: any) => user.name,
        },
        {
            title: 'Email',
            dataIndex: 'user',
            key: 'email',
            render: (user: any) => user.email,
        },
        {
            title: 'Phone',
            dataIndex: 'user',
            key: 'mobile',
            render: (user: any) => user.mobile,
        },
        {
            title: 'Distance',
            dataIndex: 'distance',
            key: 'distance',
            render: (distance: number) => `${(distance / 1000).toFixed(2)} km.`,
        },
        {
            title: 'Battery Status',
            dataIndex: 'batteryStatus',
            key: 'batteryStatus',
            width: '10px',
            render: (batteryStatus: number) => getBatteryStatus(batteryStatus),
        },
        {
            title: 'Battery Percentage',
            dataIndex: 'batteryPercentage',
            key: 'batteryPercentage',
            width: '10px',
            render: (batteryPercentage: number) => {
                const color = batteryPercentage > 75 ? 'green' : batteryPercentage > 30 ? 'yellow' : 'red';
                return (
                    <div style={{ color }}>
                        {batteryPercentage}
                    </div>
                );
            },
        },
        {
            title: 'Vehicle',
            dataIndex: 'carType',
            key: 'carType',
            render: (carType: number) => returnVehicleType(carType),
        },
        {
            title: 'Tag',
            dataIndex: 'tag',
            key: 'tag',
            render: (tag: number) => tag,
        },
        {
            title: 'Availability',
            dataIndex: 'user',
            key: 'isBusy',
            render: (user: any) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span>
                        {user.isBusy ? 'BUSY' : 'NOT BUSY'}
                    </span>
                </div>
            ),
        },
    ];

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({ limit: pageSize, skip: pageSize * (page - 1) });
    };

    return (
        <Modal
            width="70%"
            visible={ridesDriverManuallyModal && true}
            title="Asignar Picker Manual"
            onCancel={onCancel}
            onOk={onFinish}
            confirmLoading={loading}
        >
            <Table
                loading={loading}
                columns={columns}
                dataSource={allUsers}
                rowClassName="booking-row"
                bordered
                scroll={{ x: 800 }}
                rowKey="_id"
                pagination={{
                    total,
                    current: (paginator.skip / paginator.limit) + 1,
                    pageSize: paginator.limit,
                    onChange: onPageChange,
                    showSizeChanger: true,
                }}
                onRow={(record, rowIndex) => ({
                    onClick: (event) => {
                        setOnChangeValues(record._id);
                    },
                })}
            />
        </Modal>
    );
};
