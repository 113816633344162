import TPDMonitor from '../components/TPDMonitor';
import PDSummary from '../components/TPDMonitor/Summary';
import { TPDMonitorProvider } from '../components/TPDMonitor/context/monitor.context';

const MonitorScreen = () => (
    <TPDMonitorProvider>
        <TPDMonitor />
        <PDSummary />
    </TPDMonitorProvider>
);

export default MonitorScreen;
