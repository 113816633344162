import { debounce } from 'lodash';
import {
    useCallback, useEffect, useRef, useState,
} from 'react';
import {
    DatePicker, DatePickerProps, Divider, Form, List, Radio, Select, Space, Spin, Typography, Input, Row, Col, Card,
} from 'antd';
import { TiDeleteOutline } from 'react-icons/ti';
import InfiniteScroll from 'react-infinite-scroll-component';
import { WorkspaceInfo } from '../../../types/bookingRequests';
import { getWorkspaces } from '../../../api/workspaces';
import CardDataBase from '../../CardDataBase';
import { IAlert } from '../../../interfaces/workspace-alert';
import { ISendPendingAlert, ISendWorkspaceAlert } from '../context/alerts.interface';

interface Props {
    form: any;
    alert?: IAlert | null;
    loadingSendAlert: boolean;
    sendAlert: (payload: ISendWorkspaceAlert) => void;
    sendProgrammedAlert: (payload: ISendPendingAlert) => void;
}

const { Title, Text } = Typography;
const { Search } = Input;

const SendAlert = ({
    form, alert, loadingSendAlert, sendAlert, sendProgrammedAlert,
}: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [workspaces, setWorkspaces] = useState<WorkspaceInfo[]>([]);
    const [workspaceIds, setWorkspaceIds] = useState<WorkspaceInfo[]>([]);
    const [search, setSearch] = useState<string>('');
    const [total, setTotal] = useState<number>(0);
    const [limit] = useState<number>(10);

    const [option, setOption] = useState<number>(1);
    const [country, setCountry] = useState<string>('EC');
    const [sendAt, setSendAt] = useState<Date | null>(null);

    const COUNTRIES = [
        { value: 'EC', label: 'Ecuador' },
        { value: 'MX', label: 'México' },
        { value: 'DO', label: 'República Dominicana' },
        { value: 'PE', label: 'Perú' },
        { value: 'CO', label: 'Colombia' },
        { value: 'GT', label: 'Guatemala' },
        { value: 'CR', label: 'Costa Rica' },
        { value: 'CL', label: 'Chile' },
        { value: 'AR', label: 'Argentina' },
        { value: 'UY', label: 'Uruguay' },
        { value: 'PA', label: 'Panamá' },
    ];

    const fetchWorkspaces = (search?: string, page?: number) => {
        setLoading(true);

        const PAGE = Math.ceil(workspaces.length / limit);

        const params: any = {
            limit,
            page: PAGE + 1,
        };

        (search && search !== '') && (params.search = search);

        getWorkspaces({ paginator: params })
            .then(({ data }: any) => {
                setWorkspaces([...workspaces, ...data.data]);
                setTotal(data.meta.itemCount);
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const scrollableWorkspacesRef = useRef<HTMLDivElement | null>(null);

    const handleSubmit = () => {
        const payload: any = {
            alertID: alert?._id || '',
        };

        switch (option) {
        case 1:
            payload.country = country;

            if (sendAt) {
                payload.sendAt = sendAt.toISOString();
                sendProgrammedAlert(payload);
            } else {
                sendAlert(payload);
            }

            break;
        case 2:
            payload.workspaces = workspaceIds.map((ws) => ws._id);
            if (sendAt) {
                payload.sendAt = sendAt.toISOString();
                sendProgrammedAlert(payload);
            } else {
                sendAlert(payload);
            }
            break;
        default:
            break;
        }
    };

    const handleOptionChange = (e: any) => setOption(e.target.value);

    const handleCountryChange = (value: string) => setCountry(value);

    const handleDateChange: DatePickerProps['onChange'] = (date, dateString) => {
        if (!date) {
            setSendAt(null);
            return;
        }
        setSendAt(date.toDate());
    };

    const alreadySelected = (workspace: WorkspaceInfo) => workspaceIds.find((w) => w._id === workspace._id);

    const fetchDebounced = useCallback<any>(debounce(fetchWorkspaces, 700), []);

    useEffect(() => {
        fetchWorkspaces();
    }, []);

    return (
        <CardDataBase title="Workspaces to notify">
            <Form form={form} onFinish={handleSubmit} style={{ padding: '24px 0' }}>
                <Space direction="vertical" size={24} style={{ width: '100%' }}>
                    <Row justify="space-between" align="top" style={{ width: '100%' }} gutter={[32, 24]}>
                        <Col xs={24} md={12}>

                            <Space direction="vertical" size={20} style={{ width: '100%' }}>
                                <Title level={5}>
                                    Programmed this alert to be sent
                                </Title>
                                <DatePicker
                                    showTime
                                    onChange={handleDateChange}
                                />
                            </Space>

                        </Col>
                        <Col xs={24} md={12}>
                            <Space direction="vertical" size={20} style={{ width: '100%' }}>
                                <Title level={5}>
                                    Choose the workspaces to notify
                                </Title>
                                <Radio.Group
                                    onChange={handleOptionChange}
                                    value={option}
                                >
                                    <Radio value={1}>By country</Radio>
                                    <Radio value={2}>Manually</Radio>
                                </Radio.Group>
                            </Space>
                        </Col>
                    </Row>

                    <Card bordered>
                        {
                            option === 1 && (
                                <Space direction="vertical" size={24} style={{ width: '100%' }}>
                                    <Text>
                                        All the workspaces belonging to the country you selected will be notified
                                    </Text>
                                    <Form.Item name="country">
                                        <Select
                                            options={COUNTRIES}
                                            defaultValue={COUNTRIES[0].value}
                                            onChange={handleCountryChange}
                                        />
                                    </Form.Item>
                                </Space>
                            )
                        }

                        {
                            option === 2 && (

                                <Row justify="space-between" align="top" style={{ width: '100%' }} gutter={[32, 24]}>
                                    <Col span={12}>
                                        <Space direction="vertical" size={24} style={{ width: '100%' }}>
                                            <Text>
                                                Select workspaces manually to notify
                                            </Text>
                                            <Search
                                                placeholder="Search..."
                                                onSearch={(search) => {
                                                    setWorkspaces([]);
                                                    fetchDebounced(search, 1);
                                                    setSearch(search);
                                                }}
                                                allowClear
                                                defaultValue={search}
                                                enterButton
                                            />

                                            <div
                                                id="scrollableWorkspaces"
                                                ref={scrollableWorkspacesRef}
                                                style={{
                                                    width: '100%',
                                                    overflow: 'auto',
                                                    height: '40vh',
                                                    padding: '12px 0',
                                                }}
                                            >
                                                <InfiniteScroll
                                                    dataLength={workspaces.length}
                                                    next={fetchWorkspaces}
                                                    hasMore={workspaces.length < total}
                                                    loader={loading && <Spin size="small" />}
                                                    endMessage={
                                                        !loading && (
                                                            <Divider plain>
                                                                No more workspaces
                                                            </Divider>
                                                        )
                                                    }
                                                    scrollableTarget="scrollableWorkspaces"
                                                >
                                                    <List
                                                        dataSource={workspaces}
                                                        renderItem={(workspace: WorkspaceInfo) => (
                                                            <List.Item
                                                                key={workspace._id}
                                                                style={{
                                                                    backgroundColor: alreadySelected(workspace) ? '#177ddc42' : 'transparent',
                                                                    padding: 12,
                                                                    color: alreadySelected(workspace) ? '#fff' : 'inherit',
                                                                }}
                                                                onClick={() => {
                                                                    if (alreadySelected(workspace)) {
                                                                        const index = workspaceIds.indexOf(workspace);
                                                                        workspaceIds.splice(index, 1);
                                                                        setWorkspaceIds([...workspaceIds]);
                                                                        return;
                                                                    }
                                                                    setWorkspaceIds([...workspaceIds, workspace]);
                                                                }}
                                                            >
                                                                <List.Item.Meta
                                                                    title={workspace.name}
                                                                    description={`${workspace.workspaceUniqueID} - ${workspace.ownerEmail}`}
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                </InfiniteScroll>
                                            </div>
                                        </Space>
                                    </Col>
                                    <Col span={12}>
                                        <Space direction="vertical" size={16} style={{ width: '100%' }}>
                                            <Text>
                                                Workspaces to send alert
                                            </Text>

                                            <div
                                                style={{
                                                    width: '100%',
                                                    overflow: 'auto',
                                                    height: '40vh',
                                                    padding: '12px 0',
                                                }}
                                            >
                                                <List
                                                    dataSource={workspaceIds}
                                                    renderItem={(workspace: WorkspaceInfo) => (
                                                        <List.Item
                                                            key={workspace._id}
                                                            actions={[
                                                                <TiDeleteOutline
                                                                    color="red"
                                                                    style={{ verticalAlign: 'middle', cursor: 'pointer' }}
                                                                    size={20}
                                                                    onClick={() => {
                                                                        const index = workspaceIds.indexOf(workspace);
                                                                        workspaceIds.splice(index, 1);
                                                                        setWorkspaceIds([...workspaceIds]);
                                                                    }}
                                                                />,
                                                            ]}
                                                        >
                                                            <List.Item.Meta
                                                                title={workspace.name}
                                                                description={`${workspace.workspaceUniqueID} - ${workspace.ownerEmail}`}
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                            </div>
                                        </Space>
                                    </Col>
                                </Row>
                            )
                        }
                    </Card>

                </Space>
            </Form>
        </CardDataBase>
    );
};

export default SendAlert;
