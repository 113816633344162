import moment from 'moment';
import cs from 'classnames';
import { Space, Typography } from 'antd';
import { IoIosWarning } from 'react-icons/io';
import { BookingOrderStatus, BookingType } from '../../../types/booking';

interface Props {
    showDetail?: boolean;
    type?: 'SUCCESS' | 'FAILED' | 'RESOLVING'
    booking: BookingType;
    bookingOrder?: BookingOrderStatus;
    bookingItemSelected: boolean;
}

const { Title, Text } = Typography;

const CardOrder = ({
    showDetail = false, type = 'SUCCESS', booking, bookingOrder, bookingItemSelected,
}: Props) => {
    const date = moment.utc(bookingOrder?.createdAt).format('DD MMM HH:mm:ss');

    return (
        !showDetail ? (
            <div className={cs('card__event', { active: bookingItemSelected })}>
                <Space size={8}>
                    <Text className="card__tag order">
                        Order
                    </Text>
                </Space>
                <Text>
                    {`Order created in ${bookingOrder?.providerName}`}
                </Text>
                <Text style={{ fontSize: 12, fontStyle: 'italic' }}>
                    {date}
                </Text>
            </div>
        ) : (
            <Space direction="vertical" size={16}>
                <Title level={5} style={{ margin: 0 }}>
                    Order Detail
                </Title>
                <Space size={8}>
                    <Text className="card__tag order">
                        Order
                    </Text>
                    <Text className={cs('card__tag', bookingOrder?.status)}>
                        {bookingOrder?.status}
                    </Text>
                </Space>

                {
                    bookingOrder?.status === 'FAILED' ? (
                        <Space size={4} style={{ width: '100%' }} direction="vertical">
                            <Text>
                                Error to create order in
                                {' '}
                                <b>
                                    {bookingOrder?.providerName}
                                </b>
                            </Text>
                            <Text style={{ fontStyle: 'italic' }} strong>
                                <IoIosWarning color="#FFD875" size={14} style={{ verticalAlign: 'middle', marginRight: 8 }} />
                                {bookingOrder?.errorMessage}
                            </Text>
                        </Space>
                    ) : (
                        `Order created in ${bookingOrder?.providerName}`
                    )
                }

                <Text style={{ fontSize: 12, fontStyle: 'italic' }}>
                    {date}
                </Text>
            </Space>
        )
    );
};

export default CardOrder;
