/* eslint-disable array-callback-return */
import { useContext, useEffect, useState } from 'react';
import CommandCenterMap from './CommandCenterMap';
import { DriverMarkerPopover, BusinesMarker } from './mapMarkers';
import DriversLegend from './DriversLegend';
import { CommandCenterContext } from '../context/commandCenterContext';
import { generatePolygonDataFromRegion } from '../utils/region';

const CommandCenterMapSection = () => {
    const {
        drivers, regions, showRegions, business,
    } = useContext(CommandCenterContext);
    const [mapsLoaded, setMapsLoaded] = useState<any>();
    const [polygonRegionInstance, setPolygonRegionInstance] = useState<any>([]);

    useEffect(() => {
        if (!mapsLoaded) return;

        const currentPolygonsRegion: any = regions.map((region) => {
            const regionPolygon = new mapsLoaded.maps.Polygon(generatePolygonDataFromRegion(region));

            const popUp = new mapsLoaded.maps.InfoWindow({
                content: `<span style='color:#1A1F36'> ${region.regionName} </span>`, // red message
            });

            regionPolygon.addListener('mouseover', (event: any) => {
                popUp.setPosition(event.latLng);
                popUp.open(mapsLoaded.map);
            });

            regionPolygon.addListener('mouseout', (event: any) => {
                popUp.close();
            });

            return regionPolygon;
        });

        setPolygonRegionInstance(currentPolygonsRegion);
    }, [regions]);

    useEffect(() => {
        if (!polygonRegionInstance || polygonRegionInstance.length === 0) return;
        polygonRegionInstance.map((pr: any) => {
            pr.setMap(showRegions ? mapsLoaded.map : null);
        });
    }, [showRegions]);

    return (
        <>
            <DriversLegend />
            <CommandCenterMap
                setMapsLoaded={setMapsLoaded}
            >
                {business.map((b) => (
                    <BusinesMarker
                        lat={b.coordeninates.lat}
                        lng={b.coordeninates.lng}
                        name={b.name}
                    />
                ))}
                {drivers.map((d) => (
                    <DriverMarkerPopover
                        key={d.driverID}
                        lat={d.details[0].currentDriverLocation.coordinates[1]}
                        lng={d.details[0].currentDriverLocation.coordinates[0]}
                        driver={d}
                    />
                ))}
            </CommandCenterMap>
        </>
    );
};

export default CommandCenterMapSection;
