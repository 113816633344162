import { IAlert, IWorkspaceAlert } from '../../../interfaces/workspace-alert';

export const initialContext = {
    loading: false,
    loadingWorkspaceAlerts: false,
    loadingSendAlert: false,
    loadingReadAlert: false,
    showCreateAlert: false,
    maxAlerts: 0,
    alert: null as IAlert | null,
    alerts: [] as IAlert[],
    workspaceAlerts: [] as IWorkspaceAlert[],
    total: 0,
    form: {} as any,
    showFilters: false,
    fetchAlerts: () => { },
    setAlert: () => { },
    sendAlert: () => { },
    setShowCreateAlert: () => { },
    refreshAlerts: () => { },
    setNewAlert: () => { },
    setShowFilters: () => { },
    handleType: () => { },
    handleIsActive: () => { },
    sendProgrammedAlert: () => { },
    markAsRead: () => { },
    updateAlert: () => { },
};
