import { PageHeader, Space, Button } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loading from '../../../widgets/Loading';
import { NewTransaction } from '../../Modals/NewTransaction';
import { DispersionContext } from '../context/dispersionContext';
import DispersionData from './DispersionData';
import TransactionsTable from './Table';

const Dispersion = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { loading, dispersion, fetchDispersion } = useContext(DispersionContext);
    const { id } = useParams();

    const [modalNewTransaction, setModalNewTransaction] = useState<boolean>(false);

    const onBackHandle = () => {
        if (location.key !== 'default') navigate(-1);
        else navigate('/v2/cash-delivery');
    };

    const extraHeader = [
        <Button
            key="1"
            type="primary"
            icon={<BsFillPlusCircleFill color="#FFF" size={14} style={{ verticalAlign: 'middle' }} />}
            style={{
                gap: '8px', display: 'flex', alignItems: 'center', fontWeight: 600,
            }}
            onClick={() => setModalNewTransaction(true)}
        >
            Add Transaction
        </Button>,
    ];

    useEffect(() => {
        if (!id) return;
        fetchDispersion();
    }, [id]);

    if (loading) return <Loading />;

    return (
        <>
            {modalNewTransaction && (
                <NewTransaction
                    dispersion={dispersion}
                    visible={modalNewTransaction}
                    onCancel={() => setModalNewTransaction(false)}
                />
            )}
            <PageHeader
                onBack={onBackHandle}
                title="Dispersion Detail"
                style={{ padding: '32px 24px 24px 0' }}
                extra={extraHeader}
            />
            <Space direction="vertical" size={24} style={{ width: '100%' }}>
                <DispersionData />
                <TransactionsTable />
            </Space>
        </>
    );
};

export default Dispersion;
