/* eslint-disable react/jsx-no-useless-fragment */
import {
    List, Skeleton, Space, Avatar, Row, Col, Dropdown, Menu, Popover, Typography, Image,
} from 'antd';

import { BsFillCalendarCheckFill } from 'react-icons/bs';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { FaMobileAlt } from 'react-icons/fa';
import { MdOutlineMail } from 'react-icons/md';
import { IoIosArrowDown, IoIosArrowUp, IoMdWallet } from 'react-icons/io';
import { IoLocationSharp } from 'react-icons/io5';
import { TbBatteryCharging, TbBellRinging } from 'react-icons/tb';
import { AiOutlineDollarCircle } from 'react-icons/ai';
import {
    useContext, useState, useRef, useCallback,
} from 'react';
import cs from 'classnames';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { IMAGES } from '../../../../const/images';

import styles from './styles.module.scss';

import { IDriverTracking } from '../../../../types/driver';
import {
    checkAmount, checkGPSStatus, checkBatteryPercentage, checkDriverStatus,
} from '../../utils/driver';
import { CommandCenterContext } from '../../context/commandCenterContext';
import { openModal } from '../../../../actions/modals';
import { ModalTypes } from '../../../../types/modals';

interface ItemDriverProps {
    driver: IDriverTracking;
    id: string;
    loading: boolean;
}

interface AvatarUserProps {
    onDutyFlag: boolean;
    isBusy: boolean;
    image: any;
}

const { Text } = Typography;

const DriverCardItem = ({
    id,
    driver,
    loading,
}: ItemDriverProps) => {
    const { updateLocation, driverMarkerSelected } = useContext(CommandCenterContext);

    const [driverActive, setDriverActive] = useState('');
    const dispatch = useDispatch();
    const driverCardRef = useRef(null);

    const handleActiveDriver = (driverID: string) => {
        if (driverActive !== '') {
            setDriverActive('');
        } else {
            setDriverActive(driverID);
        }
    };

    const handleClickCard = useCallback(() => {
        updateLocation({
            center: {
                lat: driver.details[0].currentDriverLocation.coordinates[1],
                lng: driver.details[0].currentDriverLocation.coordinates[0],
            },
            zoom: 20,
        });
    }, [driver]);

    const isDriverActive = driverActive === id && driverActive !== '';
    const handleEditDriver = () => {
        dispatch(openModal(ModalTypes.EditDriver, driver));
    };
    const preview = [
        <Dropdown
            placement="bottomRight"
            overlay={(
                <Menu id="dropdown-driver-card-options">
                    <Menu.Item onClick={handleClickCard} key={0} icon={<Image preview={false} src={IMAGES.icons.location} width={20} height={20} />}>
                        <Text style={{ marginLeft: '8px' }}>View location</Text>
                    </Menu.Item>
                    <Menu.Item icon={<TbBellRinging size={20} />} key={1}>
                        <Text>Send notification</Text>
                    </Menu.Item>
                    <Menu.Item onClick={handleEditDriver} key={2}>
                        <Text onClick={handleEditDriver}>
                            Edit driver
                        </Text>
                    </Menu.Item>
                </Menu>
            )}
            trigger={['click']}
            // @ts-ignore
            onClick={(e: any) => { e.stopPropagation(); }}
        >
            <div style={{ textAlign: 'center' }}>
                <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                    <BiDotsVerticalRounded size={25} />
                </a>
            </div>
        </Dropdown>,
    ];

    const avatarUser = ({ onDutyFlag, isBusy, image }: AvatarUserProps) => {
        const dot = checkDriverStatus(isBusy, onDutyFlag);

        return (
            <div className={cs(styles.avatarUser)}>
                <Avatar
                    size="large"
                    src={image.thumbnail}
                    className={styles.avatar}
                />
                <div className={cs(styles.dot, styles[dot])} />
            </div>
        );
    };

    const detailDriver = () => {
        const { details } = driver;
        const { locationBackground, locationForeground } = details[0];

        const activate = locationBackground && locationForeground;
        const deactivate = !locationBackground && !locationForeground;
        const onlyForeground = !locationBackground && locationForeground;
        const onlyBackground = locationBackground && !locationForeground;

        return (
            <div className={cs(
                styles.moreInfo,
                { [styles.active]: driverActive === id || driverMarkerSelected === id },
            )}
            >
                <Row style={{ margin: '0 4px' }}>
                    <span className={cs(styles.header)}>
                        Driver Detail
                    </span>
                    <Row style={{ width: '100%' }}>
                        <List.Item.Meta
                            avatar={<TbBatteryCharging color={checkBatteryPercentage(driver.details[0].batteryPercentage || 0)} size={20} />}
                            title={(
                                <span>
                                    {driver.details[0].batteryPercentage ? `${driver.details[0].batteryPercentage}%` : '0%'}
                                </span>
                            )}
                        />
                        <List.Item.Meta
                            avatar={<AiOutlineDollarCircle color={checkAmount(driver.details[0].workBalance || 0)} size={20} />}
                            title={(
                                <span>
                                    {driver.details[0].workBalance?.toFixed(2) || 0}
                                </span>
                            )}
                        />
                        <List.Item.Meta
                            avatar={<IoMdWallet color={checkAmount(driver.details[0].wallet || 0)} size={20} />}
                            title={(
                                <span>
                                    {driver.details[0].wallet?.toFixed(2) || 0}
                                </span>
                            )}
                        />
                    </Row>
                    <Col span={24} style={{ marginTop: '8px' }}>
                        <List.Item.Meta
                            avatar={<IoLocationSharp color={checkGPSStatus(locationBackground, locationForeground)} size={20} />}
                            title={(
                                <span>
                                    {
                                        activate ? 'GPS Activated'
                                            : deactivate ? 'GPS Deactivated'
                                                : onlyForeground ? 'Only foreground notifications'
                                                    : onlyBackground ? 'Only background notifications' : 'Unknown'
                                    }
                                </span>
                            )}
                        />
                    </Col>
                    <Col span={24} style={{ marginTop: '8px' }}>
                        <List.Item.Meta
                            avatar={<MdOutlineMail size={20} />}
                            title={(
                                <Popover content={driver.email}>
                                    <Text
                                        style={{ width: 200 }}
                                        ellipsis
                                    >
                                        {driver.email}
                                    </Text>
                                </Popover>
                            )}
                        />
                        <List.Item.Meta
                            avatar={<FaMobileAlt size={20} />}
                            title={(
                                <span>
                                    {driver.fullMobile || `${driver.countryCode} ${driver.mobile}` || 'Not available'}
                                </span>
                            )}
                        />
                        <List.Item.Meta
                            avatar={<BsFillCalendarCheckFill size={15} />}
                            title={(
                                <span>
                                    {moment(driver.details[0].updatedAt).format('HH:mm - DD MMM YYYY')}
                                </span>
                            )}
                        />
                    </Col>
                </Row>
            </div>
        );
    };

    return (
        <div
            ref={driverCardRef}
            className={cs(
                styles.itemDriver,
                { [styles.selected]: driverMarkerSelected === id },
            )}
        >
            <List.Item
                id={id}
                actions={preview}
                className={cs('fullWidth', styles.content)}
                onClick={() => handleActiveDriver(id)}
            >
                <Skeleton avatar title={false} loading={loading} active>
                    <List.Item.Meta
                        avatar={
                            avatarUser({
                                onDutyFlag: driver.onDutyFlag,
                                isBusy: driver.isBusy,
                                image: driver.image,
                            })
                        }
                        title={(
                            <Space size={16} direction="vertical">
                                <span className="medium paragraph--3">
                                    {`${driver.name} ${driver.lastName}`}
                                </span>
                            </Space>
                        )}
                        description={(
                            <>
                                {
                                    driverMarkerSelected === id ? null
                                        : (
                                            <div
                                                className={cs('flex-row start-row-center', styles.more)}
                                            >
                                                {
                                                    isDriverActive
                                                        ? <IoIosArrowUp color="#6C8EEF" size={16} className={styles.icon} />
                                                        : <IoIosArrowDown color="#6C8EEF" size={16} className={styles.icon} />
                                                }
                                                <span className="cl--secondary-purple medium">
                                                    {isDriverActive ? 'See less' : 'See more'}
                                                </span>
                                            </div>
                                        )
                                }
                            </>

                        )}
                    />
                </Skeleton>
            </List.Item>
            {detailDriver()}
        </div>
    );
};

export default DriverCardItem;
