import { Select, Spin } from 'antd';
import React, { useContext, useState } from 'react';
import { getBusiness } from '../../../../api/commandCenter';
import { CommandCenterContext } from '../../context/commandCenterContext';

const { Option } = Select;

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;

const fetch = (
    value: string,
    callback: (data: any[]) => void,
    fetching: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;

    const call = () => {
        fetching(true);

        getBusiness(currentValue)
            .then(({ data }) => {
                const businesData = data.data.users.map((busines: any) => ({
                    id: busines.businessID._id,
                    name: busines.businessID.companyName,
                    coordeninates: {
                        lat: busines.businessID.latitude,
                        lng: busines.businessID.longitude,
                    },
                }));
                callback(businesData);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => fetching(false));
    };

    timeout = setTimeout(call, 500);
};

const BusinessInputSearch: React.FC<{
    style?: React.CSSProperties;
    name?: string;
}> = ({ name, style }) => {
    const { business, updateBusiness } = useContext(CommandCenterContext);

    const [data, setData] = useState<any[]>([]);
    const [fetching, setFetching] = useState<boolean>(false);

    const handleSearch = (newValue: string) => {
        if (newValue) {
            fetch(newValue, setData, setFetching);
        } else {
            setData([]);
        }
    };

    const handleSelect = (businessID: string) => {
        const currentBusiness = business;

        const selectedBusiness = data.find((d) => d.id === businessID);

        currentBusiness.push(selectedBusiness);

        updateBusiness(currentBusiness);
    };

    const handleDeselect = (businessID: string) => {
        updateBusiness(business.filter((b) => b.id !== businessID));
    };

    const options = data.map((d) => (<Option value={d.id} key={d.id}>{d.name}</Option>));

    return (
        <Select
            showSearch
            mode="multiple"
            maxTagCount="responsive"
            placeholder="Business..."
            style={style}
            onSearch={handleSearch}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            notFoundContent={fetching ? <Spin size="small" /> : null}
        >
            {options}
        </Select>
    );
};

export default BusinessInputSearch;
