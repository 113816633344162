import axios from 'axios';
import { IGetTotalOrderReportProps } from '../interfaces/report.interface';

import { BASE_URL, OCTOPUS_BASE_URL } from './booking';
import { DRIVER_SEARCH_URL } from './deliveryProviders';

export const getReport = (startDate: string, endDate: string, CSVtype: string) => {
    const urlParams = new URLSearchParams({ startDate, endDate, CSVtype });
    return axios.get(`${BASE_URL}/admin/getCSVDataForAdmin?${urlParams.toString()}`);
};

export const getTotalOrderReport = (startDate: string, endDate: string, providerName?: string, bookingNumericId?: string, status?: string) => {
    const body: any = { startDate, endDate };
    providerName && (body.providerName = providerName);
    bookingNumericId && (body.bookingNumericId = bookingNumericId);
    status && (body.status = status);

    return axios.post(`${BASE_URL}/admin/totalOrderRequests`, body);
};

export const getDriverSearchReport = (startDate: string, endDate: string) => {
    const urlParams = new URLSearchParams({ startDate, endDate });
    return axios.get(`${DRIVER_SEARCH_URL}/reports?${urlParams.toString()}`);
};

export const getManualRequestReport = (startDate: string, endDate: string) => {
    const urlParams = new URLSearchParams({ startDate, endDate });
    return axios.get(`${DRIVER_SEARCH_URL}/reports/manualRequest?${urlParams.toString()}`);
};

export const getTotalOrderReportNew = ({
    startDate, endDate, providerName, bookingNumericId, status, utc,
}: IGetTotalOrderReportProps) => {
    const query: any = {
        startDate,
        endDate,
        utc: utc || 0,
    };
    if (providerName) {
        query.providerName = providerName;
    }
    if (bookingNumericId) {
        query.bookingNumericId = bookingNumericId;
    }
    if (status) {
        query.status = status;
    }
    const urlParams = new URLSearchParams(query);
    return axios.get(`${OCTOPUS_BASE_URL}/orders/reportTotalOrderRequest?${urlParams.toString()}`);
};

export const getDriverConnectionReport = (startDate: string, endDate: string, utc: string) => {
    const query: any = {
        startDate,
        endDate,
        utc: utc || 0,
    };
    const urlParams = new URLSearchParams(query);
    return axios.get(`${OCTOPUS_BASE_URL}/driver-session/reportTotalDriverConnections?${urlParams.toString()}`);
};

export const getOperationsSummaryReport = (startDate: string, endDate: string, utc: string) => {
    const query: any = {
        startDate,
        endDate,
        utc: utc || 0,
        format: 'json',
    };
    const urlParams = new URLSearchParams(query);
    return axios.get(`${OCTOPUS_BASE_URL}/reports/reportOperationsSummary?${urlParams.toString()}`);
};

export const getOctopusReport = (startDate: string, endDate: string, CSVtype: string, utc: string) => {
    const urlParams = new URLSearchParams({
        startDate, endDate, CSVtype, utc, format: 'json',
    });
    return axios.get(`${OCTOPUS_BASE_URL}/reports?${urlParams.toString()}`);
};
