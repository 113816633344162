import {
    Dropdown, Menu, Table, Tooltip,
} from 'antd';
import { AiFillBank } from 'react-icons/ai';
import { BsCreditCard2Back, BsThreeDots } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { VscDebugDisconnect } from 'react-icons/vsc';
import { BiWallet } from 'react-icons/bi';
import { MdNumbers } from 'react-icons/md';
import {
    setEditWorkspace, setDeleteWorkspace, setDeactivateWorkspace, openModal, setSettingsTiers, setMaxDistanceMyFleet,
} from '../../actions/modals';
import { OctopusPaginatorQuery } from '../../types/paginator';
import { IPayment, IWorkspace } from '../../types/workspaces';
import { ModalTypes } from '../../types/modals';

interface WorkspacesTableProps {
  workspaces: IWorkspace[];
  loading: boolean;
  paginator: OctopusPaginatorQuery;
  total: number;
  setPaginator: (paginator: OctopusPaginatorQuery) => void;
}

interface WorkspacesMenuProps {
    workspaces: IWorkspace;
    reload: () => void;
  }

const WorkspacesMenu = ({ workspaces, reload }: WorkspacesMenuProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isDeleted } = workspaces;
    const { isSuspended } = workspaces;

    const menu = [
        {
            children: 'Details',
            onClick: () => {
                navigate(`/v2/workspaces/${workspaces._id}`);
            },
        },
        {
            children: 'Edit fare settings',
            onClick: () => {
                dispatch(openModal(ModalTypes.EditFareSettings, workspaces, reload));
            },
        },
        {
            children: 'Edit Workspace',
            onClick: () => {
                dispatch(setEditWorkspace(workspaces));
            },
        },
        {
            children: `${isDeleted ? 'Restore' : 'Delete'} Workspace`,
            onClick: () => {
                dispatch(setDeleteWorkspace(workspaces));
            },
        },
        {
            children: `${isSuspended ? 'Activate' : 'Deactivate'} Workspace`,
            onClick: () => {
                dispatch(setDeactivateWorkspace(workspaces));
            },
        },
        {
            children: 'Setting tiers for Workspace',
            onClick: () => {
                dispatch(setSettingsTiers(workspaces, 'setSettingsTiers'));
            },
        },
        {
            children: 'Edit max distance my fleet',
            onClick: () => {
                dispatch(openModal(ModalTypes.EditMaxDistance, workspaces, reload));
            },
        },
    ];

    return (
        <Menu>
            <Menu.ItemGroup key="g">
                {menu.map((o, i) => (
                    <Menu.Item key={i} {...o} />
                ))}
            </Menu.ItemGroup>
        </Menu>
    );
};

export const WorkspacesTable = ({
    workspaces, loading, paginator, setPaginator, total,
}: WorkspacesTableProps) => {
    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({ limit: pageSize, page });
    };

    const handleReload = () => {
        setPaginator({ ...paginator });
    };

    const columns = [
        {
            title: 'Workspace ID',
            dataIndex: 'workspaceUniqueID',
            key: 'workspaceUniqueID',
            render: (workspaceID: string, workspace: IWorkspace) => (
                <Link style={{ color: '#08d108' }} to={`/v2/workspaces/${workspace._id}`}>
                    {workspaceID}
                </Link>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'ownerEmail',
            key: 'ownerEmail',
        },
        {
            title: '',
            dataIndex: 'isSuspended',
            key: 'isSuspended',
            width: 40,
            render: (isSuspended: boolean) => (
                isSuspended ? (
                    <Tooltip title="Workspace is deactivated">
                        <VscDebugDisconnect color="red" size={25} />
                    </Tooltip>
                ) : null

            ),
        },
        {
            title: 'Paym.',
            dataIndex: 'payment',
            key: 'payment',
            width: 80,
            render: (payment: IPayment) => {
                const paymentMethod = payment?.paymentMethod;
                if (!paymentMethod) return '';
                const paymentMethods: any = {
                    BILLING_TRANSFER: 'Billing Transfer',
                    BILLING_CARD: 'Billing Card',
                    WALLET: 'Wallet',
                    ACTIVATION_CODE: 'Activation Code',
                };
                const icons: any = {
                    BILLING_TRANSFER: <AiFillBank color="#08d108" size={20} />,
                    BILLING_CARD: <BsCreditCard2Back color="#00beff" size={20} />,
                    WALLET: <BiWallet color="white" size={20} />,
                    ACTIVATION_CODE: <MdNumbers color="yellow" size={20} />,
                };
                return (
                    <Tooltip placement="topLeft" title={paymentMethods[paymentMethod]}>
                        {icons[paymentMethod]}
                    </Tooltip>
                );
            },
        },
        {
            title: 'Actions',
            dataIndex: 'statusText',
            key: 'actions',
            width: 100,
            fixed: 'right' as 'right',
            // eslint-disable-next-line react/display-name
            render: (_statusText: any, record: any) => (
                <Dropdown
                    placement="bottomRight"
                    overlay={(
                        <WorkspacesMenu
                            workspaces={record}
                            reload={handleReload}
                        />
                    )}
                    // @ts-ignore
                    onClick={(e: any, a: any) => { e.stopPropagation(); }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <a href="#" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            <BsThreeDots size={25} />
                        </a>
                    </div>
                </Dropdown>
            ),
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={workspaces}
            loading={loading}
            rowKey="_id"
            rowClassName={(record: any) => (record.isDeleted ? 'table-row-warning' : 'booking-row')}
            pagination={{
                total,
                current: paginator.page,
                pageSize: paginator.limit,
                onChange: onPageChange,
                showSizeChanger: true,
            }}
        />
    );
};
