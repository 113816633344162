import { ReactNode } from 'react';
import { Card, Space, Typography } from 'antd';
import cs from 'classnames';

import stylesCard from './styles.module.scss';

const { Title } = Typography;

interface ICardDataBase {
    children?: ReactNode;
    title: string;
    style?: {
        [x: string]: string | boolean | number;
    };
    action?: ReactNode[];
}

const CardDataBase = ({
    children,
    title,
    style,
    action,
}: ICardDataBase) => (
    <Card className={cs(stylesCard.content)} style={style}>
        <Space size={8}>
            <Title level={4} style={{ marginBottom: action ? 0 : 8 }}>{title}</Title>
            {action && action.map((act) => act)}
        </Space>
        {children}
    </Card>
);

export default CardDataBase;
