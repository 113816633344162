import { IContext, IMonitorData } from './monitor.interface';

export const initialContext: IContext = {
    bookingNumericId: 0,
    data: [] as IMonitorData[],
    loading: false,
    showBookingDrawer: false,
    workspaceName: '',
    setShowBookingDrawer: () => {},
    fetchMonitorData: () => {},
};
