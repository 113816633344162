import { Form, Input } from 'antd';

interface Props {
    form: any;
}

const { TextArea } = Input;

const RejectedReason = ({ form }: Props) => (
    <Form
        form={form}
        layout="vertical"
        onFinish={() => {}}
    >
        <Form.Item
            name="reason"
            label="Added a new reason for rejection of the certification"
            rules={[{ required: true, message: 'Please add a reason' }]}
        >
            <TextArea
                rows={4}
                placeholder="Add a reason"
                showCount
                maxLength={255}
                onChange={(e) => {}}
            />
        </Form.Item>
    </Form>
);

export default RejectedReason;
