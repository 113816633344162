import {
    Form, Input, Modal, Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { setChangeStatus } from '../../../actions/modals';
import { getUpdateStatus } from '../../../api/deliveryProviders';
import { STATUSES } from '../../../const/booking';

const { TextArea } = Input;

export const ChangeStatusModal = () => {
    const [form] = Form.useForm();
    const { changeStatus: booking } = useSelector((state: any) => state.modals);
    const dispatch = useDispatch();
    const options = Object.values(STATUSES).map((status: any) => ({ value: status.TEXT }));

    const onFinish = (values: any) => {
        const bookingCurrentStatus = (STATUSES as any)[values.bookingCurrentStatusText].INDEX;
        getUpdateStatus({
            ...values,
            bookingID: booking._id,
            bookingCurrentStatus,
            isAdmin: true,
        })
            .then((res) => console.log(res))
            .catch((err) => console.log(err))
            .finally(() => dispatch(setChangeStatus(null)));
    };
    const onCancel = () => {
        dispatch(setChangeStatus(null));
    };

    return (
        <Modal
            visible={booking}
            title={`Change Status Booking id: #${booking?.bookingNumericId}`}
            onCancel={onCancel}
            onOk={() => form.submit()}
            // confirmLoading={loading}
        >

            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item name="bookingCurrentStatusText">
                    <Select style={{ width: '100%' }} options={options} />
                </Form.Item>

                <Form.Item name="reason">
                    <TextArea rows={4} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
