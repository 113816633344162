import * as driversAPI from '../api/deliveryProviders';
import { types } from '../types/types';

export const setDeliveries = (deliveries: any) => ({
    type: types.deliveriesLoad,
    payload: [...deliveries],
});

export const startLoading = () => ({
    type: types.deliveriesStartLoading,
});

export const finishLoading = () => ({
    type: types.deliveriesFinishLoading,
});

export const startLoadDeliveries = () => async (dispatch: any) => {
    try {
        dispatch(startLoading());
        const { data } = await driversAPI.getDeliveryProviders();
        const deliveries = data.deliveryProviders;
        dispatch(setDeliveries(deliveries));
        dispatch(finishLoading());
    } catch (error) {
        console.log(error);
    }
};
