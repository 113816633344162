import { Select } from 'antd';
import { useContext } from 'react';
import { CommandCenterContext } from '../../context/commandCenterContext';

type SelectFilterT = {
    style?: {
        [x: string]: string;
    };
}

const { Option } = Select;

const DriverTagsSelect = ({
    style,
}: SelectFilterT) => {
    const { driverTags } = useContext(CommandCenterContext);

    const { updateDriversFilter, smallFleetSelected } = useContext(CommandCenterContext);

    const handleChange = (value: string[]) => {
        updateDriversFilter({
            tags: value,
        });
    };

    const children = driverTags.map((dp, index) => (
        <Option value={dp.value} key={index}>{dp.text}</Option>
    ));

    return (
        <Select
            mode="multiple"
            style={style}
            placeholder="Tags"
            onChange={handleChange}
            maxTagCount="responsive"
            // disabled={smallFleetSelected}
        >
            {children}
        </Select>
    );
};

export default DriverTagsSelect;
