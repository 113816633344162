const internalDeliveryProvider = [
    {
        text: 'Picker',
        value: 'PICKER',
    },
    {
        text: 'Picker Bases',
        value: 'PICKER_BASE',
    },
    {
        text: 'Small Fleets',
        value: 'SMALL_FLEETS',
    },
    {
        text: 'My Fleet',
        value: 'THIRD_PARTY_DRIVER',
    },
];

export {
    internalDeliveryProvider,
};
