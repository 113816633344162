import {
    useState, createContext, FC, useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
import { IWorkspace, IWallet } from '../../../types/workspaces';
import {
    getOneWorkspace, getWorkspaceBookings, getWorkspaceBusinesses, getWorkspaceDrivers, getWorkspaceInvitations, getWorkspaceUsers, getWorkspaceWallet,
} from '../../../api/workspaces';

interface IWorkspaceDetailsContext {
    loading: boolean;
    workspace: IWorkspace | undefined;
    bookings: any;
    users: any;
    invitations: any;
    drivers: any;
    businesses: any;
    wallet: IWallet | undefined;
    setLoading: (loading: boolean) => void;
    setWorkspace: (workspace: IWorkspace) => void;
    fetchBookings: (data: any) => void;
    fetchUsers: (data?: any) => void;
    fetchInvitations: (data?: any) => void;
    fetchDrivers: (data?: any) => void;
    setWallet: (data: IWallet) => void;
    fetchBusinesses: (data?: any) => void;
}

const defaultPaginator = {
    limit: 10,
    skip: 0,
    startDate: '',
    endDate: '',
};

const defaultDataState = {
    data: [] as any[],
    count: 0,
    paginator: defaultPaginator,
    loading: false,
};

const initialContext = {
    loading: false,
    workspace: undefined,
    bookings: defaultDataState,
    users: defaultDataState,
    invitations: defaultDataState,
    drivers: defaultDataState,
    businesses: defaultDataState,
    wallet: undefined,
    setLoading: () => { },
    setWorkspace: () => { },
    fetchBookings: () => { },
    fetchUsers: () => { },
    fetchInvitations: () => { },
    fetchDrivers: () => { },
    setBusinesses: () => { },
    setWallet: () => { },
    fetchBusinesses: () => { },
};

export const WorkspaceDetailsContext = createContext<IWorkspaceDetailsContext>(initialContext);

export const WorkspaceDetailsProvider: FC = ({ children }) => {
    const [loading, setLoading] = useState(true);
    const [workspace, setWorkspace] = useState<IWorkspace>();
    const [bookings, setBookings] = useState(defaultDataState);
    const [users, setUsers] = useState(defaultDataState);
    const [invitations, setInvitations] = useState(defaultDataState);
    const [drivers, setDrivers] = useState(defaultDataState);
    const [businesses, setBusinesses] = useState(defaultDataState);
    const [wallet, setWallet] = useState<IWallet>();
    const { id } = useParams();

    const fetchBookings = async (paginator = defaultPaginator) => {
        try {
            setBookings({ ...bookings, loading: true });
            const { data } = await getWorkspaceBookings(id as string, paginator);
            setBookings({
                paginator,
                data: data.data,
                count: data.meta.itemCount,
                loading: false,
            });
        } catch (error) {
            setBookings({ ...bookings, loading: false });
            console.log(error);
        }
    };

    const fetchUsers = async (paginator = defaultPaginator) => {
        try {
            setUsers({ ...users, loading: true });
            const { data: { data, meta } } = await getWorkspaceUsers(id as string, paginator);
            setUsers({
                paginator,
                data,
                count: meta.itemCount,
                loading: false,
            });
        } catch (error) {
            setUsers({ ...users, loading: false });
            console.log(error);
        }
    };

    const fetchInvitations = async (paginator = defaultPaginator) => {
        try {
            setInvitations({ ...invitations, loading: true });
            const { data: { data, meta } } = await getWorkspaceInvitations(id as string, paginator);
            setInvitations({
                paginator,
                data,
                count: meta.itemCount,
                loading: false,
            });
        } catch (error) {
            setInvitations({ ...invitations, loading: false });
            console.log(error);
        }
    };

    const fetchDrivers = async (paginator = defaultPaginator) => {
        try {
            setDrivers({ ...drivers, loading: true });
            const { data: { data, meta } } = await getWorkspaceDrivers(id as string, paginator);
            setDrivers({
                paginator,
                data,
                count: meta.itemCount,
                loading: false,
            });
        } catch (error) {
            setDrivers({ ...drivers, loading: false });
            console.log(error);
        }
    };

    const fetchBusinesses = async () => {
        setBusinesses({ ...businesses, loading: true });
        try {
            const { data } = await getWorkspaceBusinesses(id as string);
            setBusinesses({
                ...businesses,
                data: data.data,
                loading: false,
            });
        } catch (error) {
            setBusinesses({ ...businesses, loading: false });
            console.log(error);
        }
    };

    const fetchWallet = async () => {
        if (!id) return;
        setWallet(wallet);
        try {
            const { data } = await getWorkspaceWallet(id);
            setWallet(data.data);
        } catch (error) {
            setWallet(wallet);
            console.log(error);
        }
    };

    useEffect(() => {
        if (id) {
            setLoading(true);
            getOneWorkspace(id)
                .then(({ data }) => {
                    setWorkspace(data.data);
                    fetchBookings();
                    fetchWallet();
                })
                .catch((err) => console.log(err))
                .finally(() => setLoading(false));
        }
    }, [id]);

    return (
        <WorkspaceDetailsContext.Provider
            value={{
                loading,
                workspace,
                bookings,
                users,
                invitations,
                drivers,
                businesses,
                wallet,
                setLoading,
                setWorkspace,
                fetchBookings,
                fetchUsers,
                fetchInvitations,
                fetchDrivers,
                setWallet,
                fetchBusinesses,
            }}
        >
            {children}
        </WorkspaceDetailsContext.Provider>
    );
};
