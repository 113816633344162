/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-prop-types */
import { Select, Spin } from 'antd';
import React, { useState } from 'react';
import { getWorkspaces } from '../../api/workspaces';
import { IWorkspace } from '../../types/workspaces';

const { Option } = Select;

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;

const fetch = (
    value: string,
    callback: (data: { value: string; text: string }[]) => void,
    fetching: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;

    const call = () => {
        fetching(true);

        getWorkspaces({ search: currentValue })
            .then(({ data }) => {
                const workspaceData = data.data.map((workspace: any) => ({
                    id: workspace.workspaceUniqueID,
                    name: workspace.name,
                }));

                callback(workspaceData);
            })
            .catch((err) => {
                console.log(err);
                fetching(false);
            })
            .finally(() => fetching(false));
    };

    timeout = setTimeout(call, 300);
};

const WorkspaceInputSearch: React.FC<{
  placeholder: string;
  style?: React.CSSProperties;
  name?: string;
  workspaceSelectedData: ({ id, field, name }:{id: string, field: any, name: string}) => void;
  field: any
}> = (props) => {
    const [data, setData] = useState<any[]>([]);
    const [value, setValue] = useState<string>();
    const [fetching, setFetching] = useState<boolean>(false);

    const handleSearch = (newValue: string) => {
        if (newValue) {
            fetch(newValue, setData, setFetching);
        } else {
            setData([]);
        }
    };

    const handleChange = (newValue: string) => {
        setValue(newValue);
        props.workspaceSelectedData({
            id: data.find((w: IWorkspace) => w.name === newValue).id,
            field: props.field,
            name: newValue,
        });
    };

    const options = data.map((d) => <Option value={d.name} key={d.id}>{d.name}</Option>);

    return (
        <Select
            showSearch
            value={value}
            placeholder={props.placeholder}
            style={props.style}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            notFoundContent={fetching ? <Spin size="small" /> : null}
        >
            {options}
        </Select>
    );
};

export default WorkspaceInputSearch;
