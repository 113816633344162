import {
    Divider,
    PageHeader,
    Space,
    Typography,
} from 'antd';
import ItemData from '../../ExpressDelivery/components/Item';

interface Props {
    header?: string;
    estimatedDeliveryTime: number;
    totalDistance: number;
    providerFee: number;
}

const { Title } = Typography;

const MetaData = ({
    header,
    estimatedDeliveryTime,
    totalDistance,
    providerFee,
}: Props) => {
    const metersToKm = (value: number): number => value / 1000;
    const secondsToMinutes = (value: number): number => value / 60;

    return (
        <Space direction="vertical" size={12} style={{ width: '100%' }}>
            {
                header && (
                    <PageHeader
                        title={(
                            <Title level={5} style={{ marginBottom: 0 }}>
                                {header || 'Delivery Data'}
                            </Title>
                        )}
                        style={{ padding: 0 }}
                        footer={<Divider style={{ margin: '12px 0' }} />}
                    />
                )
            }
            <ItemData
                label="Estimated Delivery Time"
                value={`${secondsToMinutes(estimatedDeliveryTime).toFixed(2) || 0} min`}
            />
            <ItemData
                label="Total Distance"
                value={`${metersToKm(totalDistance) || 0} km`}
            />
            <ItemData
                label="Provider Fee"
                value={`${providerFee.toFixed(2)}` || 0.00}
            />
        </Space>
    );
};

export default MetaData;
