/* eslint-disable react/prop-types */
import { Select, Tag } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { useContext } from 'react';
import { CommandCenterContext } from '../../context/commandCenterContext';

const options: any = {
    values: [
        {
            value: 'online',
            label: 'Online',
        },
        {
            value: 'busy',
            label: 'Busy',
        },
        {
            value: 'offline',
            label: 'Offline',
        },
    ],
    colors: {
        online: {
            bg: '#00b2e3',
            color: '#ffffff',
        },
        offline: {
            bg: '#f0fcff',
            color: '#262424',
        },
        busy: {
            bg: '#ffeba6',
            color: '#262424',
        },
    },
};

const DriverSelectStatus = () => {
    const { updateDriverStatus } = useContext(CommandCenterContext);

    const tagRender = (props: CustomTagProps) => {
        const {
            label, value, closable, onClose,
        } = props;

        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };

        return (
            <Tag
                color={options.colors[`${value}`].bg}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3, color: options.colors[`${value}`].color }}
            >
                {label}
            </Tag>
        );
    };

    const handleChange = (value: string[]) => updateDriverStatus(value);

    return (
        <Select
            mode="multiple"
            placeholder="Status..."
            allowClear
            defaultValue={['online']}
            style={{ width: '100%' }}
            maxTagCount="responsive"
            tagRender={tagRender}
            options={options.values}
            onChange={handleChange}
        />
    );
};

export default DriverSelectStatus;
