import { Tooltip } from 'antd';
import { useMemo } from 'react';
import { EWorkspaceStatus } from '../../../types/workspaces';
import WorkspaceStatusCircle from '../WorkspaceStatusCircle';

const WorkspaceStatus = ({ status }: { status: EWorkspaceStatus | undefined }) => {
    const { fill, statusCircle } = useMemo(() => {
        let fill: string = '';
        let statusCircle: string = '';

        // eslint-disable-next-line default-case
        switch (status) {
        case EWorkspaceStatus.ACTIVE: {
            fill = '#01EA74';
            statusCircle = 'active';
            break;
        }
        case EWorkspaceStatus.DELETED: {
            fill = '#FF426F';
            statusCircle = 'deleted';
            break;
        }
        case EWorkspaceStatus.SUSPENDED: {
            fill = '#FFD875';
            statusCircle = 'suspended';
            break;
        }
        case EWorkspaceStatus.DELETED_SUSPENDED: {
            fill = '#FF426F';
            statusCircle = 'suspended';
            break;
        }
        }

        return {
            fill,
            statusCircle,
        };
    }, [status]);

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <WorkspaceStatusCircle status={statusCircle} />
            <path
                d="M26.8045 1.44488C26.9633 1.28615 27.0524 1.07087 27.0524 0.846389C27.0524
            0.621913 26.9633 0.40663 26.8045 0.247902C26.6458 0.0891727 26.4305 3.34496e-09 26.206
            0C25.9816 -3.34496e-09 25.7663 0.0891727 25.6076 0.247902L21.0969 4.76023C19.7843 3.73885
            18.1438 3.23206 16.4838 3.33511C14.8238 3.43816 13.2586 4.14395 12.0824 5.31983L11.5752
            5.82702C11.3411 6.06097 11.1554 6.33875 11.0286 6.64451C10.9019 6.95026 10.8367 7.27799
            10.8367 7.60896C10.8367 7.93994 10.9019 8.26766 11.0286 8.57342C11.1554 8.87917 11.3411
            9.15696 11.5752 9.3909L15.7426 13.5566C16.216 13.2185 16.7232 12.926 17.2558 12.6809L12.7705
            8.19393C12.6156 8.03863 12.5287 7.82827 12.5287 7.60896C12.5287 7.38965 12.6156 7.17929 12.7705
            7.024L13.2777 6.51681C14.1007 5.69309 15.1794 5.1737 16.3365 5.04391C17.4937 4.91412 18.6606 5.18165
            19.6457 5.80257C20.6307 6.42349 21.3754 7.3609 21.7574 8.46088C22.1394 9.56087 22.136 10.758 21.7478
            11.8559C22.326 11.8931 22.8924 11.9843 23.4385 12.123C23.7543 11.0782 23.8151 9.97291 23.6157 8.89979C23.4162
            7.82668 22.9624 6.81703 22.2922 5.95551L26.8045 1.44488ZM9.3909 11.5752L13.5566 15.7426C13.2185 16.216 12.926
            16.7232 12.6809 17.2558L8.19562 12.7722C8.11869 12.6953 8.02736 12.6342 7.92685 12.5926C7.82633 12.5509 7.7186
            12.5295 7.60981 12.5295C7.50101 12.5295 7.39328 12.5509 7.29277 12.5926C7.19225 12.6342 7.10093 12.6953 7.024
            12.7722L6.51681 13.2794C5.69224 14.102 5.17211 15.1808 5.04191 16.3382C4.91171 17.4957 5.17919 18.663 5.80038
            19.6483C6.42157 20.6336 7.35951 21.3782 8.46001 21.7598C9.56051 22.1413 10.7581 22.1371 11.8559 21.7478C11.8931
            22.3277 11.9843 22.8924 12.123 23.4385C11.0782 23.7543 9.97291 23.8151 8.89979 23.6157C7.82668 23.4162 6.81703
            22.9624 5.95551 22.2922L1.44488 26.8045C1.28615 26.9633 1.07087 27.0524 0.846389 27.0524C0.621913 27.0524 0.40663
            26.9633 0.247902 26.8045C0.0891727 26.6458 3.34496e-09 26.4305 0 26.206C-3.34496e-09 25.9816 0.0891727 25.7663
            0.247902 25.6076L4.76023 21.0969C3.73907 19.7841 3.23257 18.1435 3.33593 16.4835C3.43929 14.8235 4.14539 13.2584
            5.32152 12.0824L5.82871 11.5752C6.30112 11.1029 6.94179 10.8375 7.60981 10.8375C8.27783 10.8375 8.9185 11.1029
            9.3909 11.5752Z"
                fill={fill}
            />
        </svg>
    );
};

export default WorkspaceStatus;
