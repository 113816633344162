import {
    DrawingManager, GoogleMap, LoadScript, Polygon,
} from '@react-google-maps/api';
import { GOOGLE_MAPS_KEY } from '../../../const/envs';
import { googleMapsStyles } from '../../Map/googleMapStyles';
import { ILocationMap } from '../context/coverageContext';
import { polygonStyles } from './polygonStyles';

interface Props {
    currentLocation: ILocationMap;
    newPolygon: google.maps.Polygon | undefined;
    onPolygonComplete: (polygon: google.maps.Polygon) => void;
}

const CoverageMap = ({
    currentLocation,
    newPolygon,
    onPolygonComplete,
}: Props) => {
    const mapOptions = {
        panControl: false,
        mapTypeControl: false,
        scrollwheel: true,
        styles: googleMapsStyles,
    };

    const polygonOptions = {
        ...polygonStyles,
    };

    return (
        <div>
            <LoadScript googleMapsApiKey={GOOGLE_MAPS_KEY} libraries={['drawing']}>
                <GoogleMap
                    id="coverage-map"
                    mapContainerStyle={{ height: '45vh', width: '100%' }}
                    options={mapOptions}
                    zoom={currentLocation.zoom}
                    center={currentLocation.center}
                >
                    <DrawingManager
                        onPolygonComplete={onPolygonComplete}
                        options={{
                            drawingControl: true,
                            drawingControlOptions: {
                                drawingModes: ['polygon' as any],
                            },
                        }}
                    />
                    {newPolygon && (
                        <Polygon
                            path={newPolygon.getPath()}
                            options={polygonOptions}
                        />
                    )}
                </GoogleMap>
            </LoadScript>
        </div>
    );
};

export default CoverageMap;
