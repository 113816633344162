import { useSearchParams } from 'react-router-dom';
import Invoices from '../components/Invoices/Invoices';

const InvoicesScreen = () => {
    const [searchParams] = useSearchParams();
    return (
        <Invoices params={searchParams} />
    );
};

export default InvoicesScreen;
