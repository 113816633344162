/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/media-has-caption */
import moment from 'moment';
import cs from 'classnames';
import {
    Avatar, Dropdown, List, Menu, Space, Typography, Image,
} from 'antd';
import { useContext } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { BiStore, BiSupport } from 'react-icons/bi';
import { FaUser } from 'react-icons/fa';
import { ChatContext } from '../context/chatContext';
import { IMessage } from '../../../types/chat';
import { EChatUserRole, EMessageType } from '../../../enums/chat.enum';

const { Text, Paragraph } = Typography;

const Message = ({ message }: { message: IMessage }) => {
    const { setSelectedMessage } = useContext(ChatContext);

    const isMyMessage = message.roleType === 'SUPPORT';
    const isMedia = message.type.includes(EMessageType.IMAGE) || message.type.includes(EMessageType.VIDEO);

    const handleAction = (event: any) => {
        switch (event.key) {
        case '1':
            setSelectedMessage(message);
            break;
        case '2':
            if (message.type === EMessageType.IMAGE || message.type === EMessageType.VIDEO) {
                const fileExtension = message.data.split('.').pop();
                const a = document.createElement('a');
                a.href = message.data;
                a.target = '_blank';
                a.id = `image-${message._id}`;
                a.download = `message-picker-chat-${moment().format('DD-MM-YYYY')}.${fileExtension}`;
                document.body.appendChild(a);
                a.click();
            }
            break;
        default:
            break;
        }
    };

    const menu = () => (
        <Menu onClick={handleAction}>
            <Menu.Item key="1">Information</Menu.Item>
            {
                isMedia && (
                    <Menu.Item key="2">Download</Menu.Item>
                )
            }
        </Menu>
    );

    const returnTypeMessage = () => {
        if (message.type === EMessageType.TEXT) {
            return (
                <p
                    className="message__description-text"
                    dangerouslySetInnerHTML={{
                        __html: message.data,
                    }}
                />
            );
        } if (message.type === EMessageType.IMAGE) {
            return (
                <Image
                    id={`message_${message._id}`}
                    width="100%"
                    src={message.data}
                />
            );
        } if (message.type === EMessageType.AUDIO) {
            return (
                <audio
                    id={`message_${message._id}`}
                    controls
                    src={message.data}
                    style={{
                        width: '100%',
                        margin: '4px 0px',
                    }}
                />
            );
        } if (message.type === EMessageType.VIDEO) {
            return (
                <video
                    id={`message_${message._id}`}
                    controls
                    src={message.data}
                    width="200px"
                    height="auto"
                />
            );
        }
    };

    const returnAvatar = () => {
        if (message.roleType === EChatUserRole.SUPPORT) {
            return (<BiSupport color="#FFF" size={24} />);
        } if (message.roleType === EChatUserRole.STORE) {
            return (<BiStore color="#FFF" size={24} />);
        } if (message.roleType === EChatUserRole.CLIENT) {
            return (<FaUser color="#FFF" size={24} />);
        }
    };

    return (
        <List.Item.Meta
            style={{
                backgroundColor:
                    message?.color
                        ? message.color
                        : isMyMessage ? '#d3dfee1a' : '#c9d6e839',
            }}
            className={cs('message', { me: isMyMessage })}
            avatar={(
                <Avatar
                    className="message__avatar"
                    size={28}
                    src={returnAvatar()}
                />
            )}
            title={(
                <Space
                    size={4}
                    direction="horizontal"
                    align="center"
                    className="message__title"
                >
                    <Text strong className="message__title-name">
                        {message.user?.name}
                        {
                            isMyMessage && (
                                <Text type="secondary">
                                    {' '}
                                    - Me
                                </Text>
                            )
                        }
                    </Text>
                    <Dropdown.Button
                        overlay={menu}
                        icon={(
                            <BsChevronDown
                                color="#FFF"
                                className="message__title-icon"
                            />
                        )}
                    />
                </Space>
            )}
            description={(
                <Space direction="vertical" size={4} className="message__description">
                    {returnTypeMessage()}
                    <Paragraph className="message__description-time">
                        {moment(message.createdAt).format('HH:mm')}
                    </Paragraph>
                </Space>
            )}
        />
    );
};

export default Message;
