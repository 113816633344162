import { Button, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { IoIosWarning } from 'react-icons/io';

const { Title, Text } = Typography;

const InvalidSession = () => {
    const navigate = useNavigate();

    return (
        <div className="screen screen-full">
            <div className="screen-container">
                <Space direction="vertical" size={8}>
                    <Title level={3} style={{ margin: 0 }}>
                        Session Error
                    </Title>
                    <Text>
                        Your current session is invalid or has expired
                    </Text>
                </Space>

                <Space size={12} align="center">
                    <IoIosWarning style={{ verticalAlign: 'middle' }} size={16} />
                    Please log in again to continue
                </Space>

                <Button
                    type="primary"
                    block
                    onClick={() => navigate('/v2/login', { replace: true })}
                >
                    Login
                </Button>
            </div>
        </div>
    );
};

export default InvalidSession;
