import { Input } from 'antd';

interface Props {
    searchParams: URLSearchParams;
    handleSearch: (value: string) => void;
}

const { TextArea } = Input;

const Search = ({ ...props }: Props) => {
    const { handleSearch, searchParams } = props;

    const search = searchParams.get('search') || '';
    return (
        <div className="main-search__input">
            <div style={{ flex: 1, display: 'flex', padding: '5px 8px 0 14px' }}>
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flex: 1,
                    marginTop: 6,
                }}
                >
                    <TextArea
                        rows={1}
                        maxLength={2048}
                        defaultValue={search}
                        onChange={(value) => handleSearch(value.target.value)}
                    />
                </div>
            </div>
        </div>
    );
};

export default Search;
