import { Dropdown, Menu } from 'antd';
import { useEffect, useState } from 'react';
import { AiOutlineReload } from 'react-icons/ai';

export const DropDownButtonRefresh = ({ getBookingsData }: any) => {
    const [text, setText] = useState(0);
    const [interval, setIntervalText] = useState(0);

    function handleMenuClick(e: any) {
        setText(parseInt(e.key, 10));
        setIntervalText(parseInt(e.key, 10));
    }
    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="0">Ahora no</Menu.Item>
            <Menu.Item key="30">30s para actualizar</Menu.Item>
            <Menu.Item key="60">60s para actualizar</Menu.Item>
            <Menu.Item key="120">120s para actualizar</Menu.Item>
        </Menu>
    );

    useEffect(() => {
        let newInterval: any;
        if (text > 0) {
            newInterval = setInterval(() => {
                setText(text - 1);
            }, 1000);
        } else if (interval > 0) {
            getBookingsData();
            setText(interval);
        }
        return () => clearInterval(newInterval);
    }, [text]);

    return (
        <Dropdown.Button overlay={menu} onClick={() => getBookingsData()}>
            {text || <AiOutlineReload size={18} color="white" />}
        </Dropdown.Button>
    );
};
