import { Modal, notification, Table } from 'antd';
import { useState } from 'react';
import UploadInput from './UploadInput';
import { bulkTransactions } from '../../../api/invoices';

interface ITransactionsModal {
    visible: boolean;
    onCancel: () => void;
}

export const TransactionsModal = ({ visible, onCancel }: ITransactionsModal) => {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(false);
    const columns = [
        {
            title: 'Workspace',
            dataIndex: 'Workspace',
            key: 'Workspace',
        },
        {
            title: 'Business',
            dataIndex: 'Business',
            key: 'Business',
        },
        {
            title: 'Booking',
            dataIndex: 'Booking',
            key: 'Booking',
        },
        {
            title: 'Date',
            dataIndex: 'Date',
            key: 'Date',
        },
        {
            title: 'Description',
            dataIndex: 'Description',
            key: 'Description',
            render: (text: string) => <span>{text.slice(0, 50)}</span>,
        },
        {
            title: 'Amount',
            dataIndex: 'Amount',
            key: 'Amount',
        },
    ];
    const handleCreateTransactions = async () => {
        setLoading(true);
        try {
            const response = await bulkTransactions(transactions);
            notification.success({
                message: 'Success',
                description: 'Transactions created successfully',
            });
            onCancel();
        } catch (error) {
            console.log(error);
            notification.error({
                message: 'Error',
                description: 'An error occurred while creating transactions',
            });
        } finally {
            setLoading(false);
        }
    };
    return (
        <Modal
            visible={visible}
            title="Add Transactions"
            width={800}
            confirmLoading={loading}
            onCancel={onCancel}
            onOk={handleCreateTransactions}
        >
            {transactions.length > 0 ? (
                <div>
                    <p>
                        {`Transactions added: ${transactions.length}`}
                    </p>
                    <Table
                        dataSource={transactions}
                        columns={columns}
                        pagination={false}
                    />
                </div>
            ) : (
                <UploadInput handleFileUpload={setTransactions} />
            )}
        </Modal>
    );
};
