import { Select, Tag } from 'antd';
import { STATUSES } from '../../const/booking';
import { getStatusChipClass } from '../../utils/bookings';

const options = Object.values(STATUSES).map((status: any) => (
    { value: status.INDEX, label: status.TEXT }
));

export const SelectInput = ({ currentStatus, setCurrentStatus }: any) => {
    function handleChange(value: any) {
        setCurrentStatus(value);
    }

    function tagRender(props: any) {
        const {
            label, value, closable, onClose,
        } = props;
        const onPreventMouseDown = (event: any) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag
                {...getStatusChipClass(label)}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
            >
                {label}
            </Tag>
        );
    }

    return (
        <Select
            defaultValue={currentStatus}
            mode="multiple"
            style={{ width: '300px' }}
            placeholder="Please select"
            onChange={handleChange}
            options={options}
            maxTagCount="responsive"
            tagRender={tagRender}
        />
    );
};
