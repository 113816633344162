import { useState } from 'react';
import { Modal, notification } from 'antd';

import { removeDriverFromWorkspace } from '../../../api/workspaces';

interface RemoveDriverModalProps {
  removeDriver: any,
  setRemoveDriver: React.Dispatch<React.SetStateAction<any>>
  setReload: React.Dispatch<React.SetStateAction<boolean>>
}

export const RemoveDriverModal = ({ removeDriver, setRemoveDriver, setReload }: RemoveDriverModalProps) => {
    const [loading, setLoading] = useState(false);

    const handleOk = () => {
        setLoading(true);
        removeDriverFromWorkspace(removeDriver.userID, removeDriver.id)
            .then(({ data }) => {
                const { message } = data;
                notification.success({
                    message,
                });
            })
            .catch((error) => {
                const message = error.response?.data?.message;
                notification.error({
                    message,
                });
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
                setRemoveDriver(null);
                setReload((state: boolean) => !state);
            });
    };

    const handleCancel = () => {
        setRemoveDriver(null);
    };

    return (
        <Modal
            title="Remove Driver"
            visible={removeDriver}
            onOk={handleOk}
            onCancel={handleCancel}
            confirmLoading={loading}
        >
            <p>Are you sure you want to REMOVE this driver:</p>
            <p>{`${removeDriver?.name} ${removeDriver?.lastName}?`}</p>
        </Modal>
    );
};
