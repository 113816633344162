import {
    Col, Input, PageHeader, Row,
} from 'antd';
import { useEffect, useState } from 'react';
import { allProviders } from '../api/deliveryProviders';
import { DeliveryProvidersTable } from '../components/DeliveryProviders/DeliveryProvidersTable';
import { OctopusPaginatorQuery } from '../types/paginator';

import { DeliveryType } from '../types/delivery';
import { DeactivateDeliveryProvider } from '../components/Modals/DeliveryProviderModal/DeactivateDeliveryProvider';
import { ChangeTierDeliveryProviderModal } from '../components/Modals/DeliveryProviderModal';

const { Search } = Input;

export const DeliveryProvidersAll = () => {
    const [deliveryProvider, setDeliveryProvider] = useState<DeliveryType[]>([]);
    const [loading, setLoading] = useState(false);
    const [paginator, setPaginator] = useState<OctopusPaginatorQuery>({ limit: 10, page: 1 });
    const [total, setTotal] = useState(0);
    const [search, setSearch] = useState('');
    const [reload, setReload] = useState(false);

    useEffect(() => {
        setLoading(true);
        allProviders(paginator, search)
            .then(({ data }) => {
                setDeliveryProvider(data.data);
                setTotal(data.meta.itemCount);
            })
            .catch()
            .finally(() => setLoading(false));
    }, [paginator, search, reload]);

    return (
        <div>
            <PageHeader
                title="Delivery Providers"
            />
            <Row justify="end" gutter={16}>
                <Col>
                    <Search
                        placeholder="Search..."
                        onSearch={(e: any) => {
                            setPaginator(paginator);
                            setSearch(e);
                        }}
                        enterButton
                    />
                </Col>
            </Row>
            <br />
            <DeliveryProvidersTable
                deliveryProvider={deliveryProvider}
                loading={loading}
                paginator={paginator}
                setPaginator={setPaginator}
                total={total}
            />

            <DeactivateDeliveryProvider setReload={setReload} />
            <ChangeTierDeliveryProviderModal setReload={setReload} reload={reload} />
        </div>
    );
};
