import { useState } from 'react';
import { Modal, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { disableDeliveryProvider } from '../../../api/deliveryProviders';
import { setDeactivateDeliveryProvider } from '../../../actions/modals';

interface DeactivateDeliveryProviderProps {
setReload: React.Dispatch<React.SetStateAction<boolean>>
}

export const DeactivateDeliveryProvider = ({ setReload }: DeactivateDeliveryProviderProps) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { deactivateDeliveryProvider } = useSelector((state: any) => state.modals);

    const handleOk = async () => {
        setLoading(true);
        const payload = {
            isActive: !deactivateDeliveryProvider.isActive,
        };
        try {
            const { data } = await disableDeliveryProvider(deactivateDeliveryProvider._id, payload);
            notification.success({
                message: data.message,
            });
        } catch (error: any) {
            notification.error({ message: error.response?.data?.message });
            console.log(error);
        } finally {
            dispatch(setDeactivateDeliveryProvider(null));
            setReload((state: boolean) => !state);
            setLoading(false);
        }
    };

    const handleCancel = () => {
        dispatch(setDeactivateDeliveryProvider(null));
    };

    return (
        <Modal
            title={`${deactivateDeliveryProvider?.isActive ? 'Deactivate' : 'activate'} Delivery Provider`}
            visible={deactivateDeliveryProvider}
            onOk={handleOk}
            onCancel={handleCancel}
            confirmLoading={loading}
        >
            <p>
                Are you sure you want to
                {deactivateDeliveryProvider?.isActive ? 'deactivate' : 'active'}
                {' '}
                this Delivery Provider?
            </p>
            <p>{deactivateDeliveryProvider?.name}</p>
        </Modal>
    );
};
