import moment from 'moment';
import cs from 'classnames';
import { useState } from 'react';
import {
    Button, Card, Space, Typography,
} from 'antd';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { IBookingLog } from '../../../interfaces/booking-log.interface';

interface Props {
    showDetail?: boolean;
    bookingLog?: IBookingLog;
    bookingItemSelected: boolean;
}

const { Title, Text } = Typography;

const CardCloudWatch = ({
    showDetail = false, bookingLog, bookingItemSelected,
}: Props) => {
    const [seeAdditionalInfo, setSeeAdditionalInfo] = useState<boolean>(false);
    const date = moment(bookingLog?.createdAt).format('DD MMM HH:mm:ss');

    return (
        !showDetail ? (
            <div className={cs('card__event', { active: bookingItemSelected })}>
                <Space size={8}>
                    <Text className="card__tag log">
                        Cloudwatch
                    </Text>
                </Space>
                <Text>
                    {bookingLog?.description}
                </Text>
                <Text style={{ fontSize: 12, fontStyle: 'italic' }}>
                    {date}
                </Text>
            </div>
        ) : (
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
                <Title level={5} style={{ margin: 0 }}>
                    Log Detail
                </Title>

                <Space size={8}>
                    <Text className="card__tag log">
                        Cloudwatch
                    </Text>
                </Space>

                <Text>
                    {bookingLog?.description}
                </Text>

                <Space direction="vertical" size={8} style={{ width: '100%' }}>
                    <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={() => setSeeAdditionalInfo(!seeAdditionalInfo)}
                        icon={(
                            seeAdditionalInfo
                                ? (
                                    <FaCaretUp
                                        size={14}
                                        style={{ verticalAlign: 'middle', marginRight: 6 }}
                                    />
                                ) : (
                                    <FaCaretDown
                                        size={14}
                                        style={{ verticalAlign: 'middle', marginRight: 6 }}
                                    />
                                )
                        )}
                    >
                        See unformatted log
                    </Button>
                    {
                        seeAdditionalInfo && (
                            <Card>
                                <Text style={{ whiteSpace: 'pre-line' }}>
                                    {bookingLog?.additionalInfo}
                                </Text>
                            </Card>
                        )
                    }
                </Space>

                <Text style={{ fontSize: 12, fontStyle: 'italic' }}>
                    {date}
                </Text>
            </Space>
        )
    );
};

export default CardCloudWatch;
