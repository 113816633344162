/* eslint-disable no-use-before-define */
import { useCallback, useState } from 'react';
import { AutoComplete, Spin } from 'antd';
import { debounce } from 'lodash';

const SearchInput = ({
    onChange, getData, optionKeys, searchKey, value, ...rest
}: any) => {
    const [data, setData] = useState<any[]>([]);
    const [fetching, setFetching] = useState<boolean>(false);

    const handleSearch = useCallback(
        debounce(async (newValue: string) => {
            if (!getData) return;
            let data = [];
            if (newValue.length >= 3) {
                setFetching(true);
                data = await getData(newValue);
                setFetching(false);
            }
            setData(data);
        }, 500),
        [getData],
    );

    const handleChange = (value: string, optionSelected: any) => {
        let valueParsed = value;
        if (optionKeys) {
            const option = data.find(({ id }: { id: string}) => id === optionSelected.value);
            valueParsed = parser(option);
        }
        onChange && onChange(valueParsed);
    };

    const parser = (option: any) => {
        const value: any = {};
        optionKeys.forEach((key: string) => {
            value[key] = option[key];
        });
        return value;
    };

    const options = data.map((option) => ({
        value: option.id,
        label: option.name,
    }));

    return (
        <AutoComplete
            options={options}
            onSearch={handleSearch}
            onChange={(name) => onChange(parser({ [searchKey]: name }))}
            onSelect={handleChange}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            value={value[searchKey] || ''}
            {...rest}
        />
    );
};

export default SearchInput;
