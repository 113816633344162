import { Radio, Table } from 'antd';
import { useContext } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { MdCancel } from 'react-icons/md';
import { TiersConfigurationContext } from '../../../context/tiersConfigurationContext';

const SuperRegionTable = () => {
    const { elementSelected, superRegions, selectElement } = useContext(TiersConfigurationContext);

    const columns = [
        {
            title: '',
            dataIndex: '_id',
            key: '_id',
            width: 100,
            fixed: 'left' as 'left',
            render: (_id: any) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Radio
                        checked={!!(elementSelected && elementSelected._id === _id)}
                    />
                </div>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Active',
            dataIndex: 'isDeleted',
            key: 'isDeleted',
            render: (isDeleted: boolean) => (
                !isDeleted ? <AiFillCheckCircle color="green" size={25} /> : <MdCancel color="red" size={25} />
            ),
        },
        {
            title: 'Currency',
            dataIndex: 'currency',
            key: 'currency',
        },
    ];

    return (
        <div>
            <Table
                dataSource={superRegions.data}
                columns={columns}
                loading={superRegions.loading}
                rowKey="userID"
                scroll={{ x: 800 }}
                onRow={(record) => ({
                    onClick: () => selectElement(record),
                })}
            />
        </div>
    );
};

export default SuperRegionTable;
