import { useContext, useEffect } from 'react';
import { PageHeader, Space } from 'antd';
import { Filters, UsersTable } from './components';
import { UsersContext } from './context/users.context';

const Users = () => {
    const {
        users, loading, total, paginator, setPaginator, fetchUsers, handlePaginator, searchParams,
    } = useContext(UsersContext);

    const { search } = searchParams;

    useEffect(() => {
        fetchUsers();
    }, [paginator]);

    return (
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
            <PageHeader title="Users" />
            <Filters
                search={search}
                paginator={paginator}
                handlePaginator={handlePaginator}
            />
            <UsersTable
                users={users}
                loading={loading}
                total={total}
                paginator={paginator}
                setPaginator={setPaginator}
            />
        </Space>
    );
};

export default Users;
