import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import {
    Col,
    Form,
    InputNumber,
    Row,
    Select,
    Modal,
    Input,
    Tabs,
    Checkbox,
    notification,
} from 'antd';
import { Rule } from 'antd/lib/form';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from '../../../actions/modals';
import { editDriverOctopus, getDriverById } from '../../../api/drivers';
import { ECarName } from '../../../types/driver';
import { ModalTypes } from '../../../types/modals';

const requiredRule: Rule = { required: true, message: 'Required' };

export const EditDriverModal = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { activeModal, modalData } = useSelector((state: any) => state.modals);
    const isOpen = activeModal === ModalTypes.EditDriver;
    const [form] = Form.useForm();
    const [checkNick, setCheckNick] = useState(false);
    const { TabPane } = Tabs;
    const options = [
        { value: ECarName.LITE, label: 'Car' },
        { value: ECarName.BIKE, label: 'Bike' },
    ];
    useEffect(() => {
        if (!isOpen) return;
        if (modalData) {
            getDriverById(modalData?._id)
                .then(({ data }) => {
                    form.setFieldsValue({ ...data.data, ...data.data.driverID });
                })
                .catch((err) => console.log(err));
        }
        form.validateFields(['nickname']);
    }, [checkNick, form, activeModal, isOpen]);

    const onFinish = (values: any) => {
        values.vehicleType = values.vehicleType.toUpperCase();
        setLoading(true);
        editDriverOctopus(modalData?._id, values)
            .then(({ data }) => {
                notification.success({
                    message: data.message,
                });
            })
            .catch((err) => {
                console.log(err);
                notification.error({ message: 'oops! An error has occurred' });
            })
            .finally(() => {
                setLoading(false);
                dispatch(closeModal());
                form.resetFields();
            });
    };

    const handleCancel = () => {
        form.resetFields();
        dispatch(closeModal());
    };

    return (
        <Modal
            title="Edit Driver"
            visible={isOpen}
            onOk={() => form.submit()}
            onCancel={handleCancel}
            confirmLoading={loading}
            okText="Update"
        >
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                requiredMark={false}
            >
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Driver Information" key="1">
                        <Form.Item
                            name="onDutyFlag"
                            valuePropName="checked"
                            wrapperCol={{ offset: 8, span: 16 }}
                        >
                            <Checkbox>On Duty</Checkbox>
                        </Form.Item>
                        <Row gutter={10}>
                            <Col span={12}>
                                <Form.Item label="Name" name="name" rules={[requiredRule]}>
                                    <Input placeholder="First Name" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Last Name"
                                    name="lastName"
                                    rules={[requiredRule]}
                                >
                                    <Input placeholder="Last Name" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Country Code"
                                    name="countryCode"
                                    rules={[requiredRule]}
                                >
                                    <Input
                                        maxLength={4}
                                        addonBefore="+"
                                        placeholder="Country Code"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Mobile" name="mobile">
                                    <Input
                                        maxLength={10}
                                        style={{ width: '100%' }}
                                        placeholder="Mobile"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Email" name="email" rules={[requiredRule]}>
                                    <Input placeholder="Email" disabled={false} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Vehicle Information" key="2">
                        <Row gutter={12}>
                            <Col span={8}>
                                <Form.Item label="Vehicle Type" name="vehicleType">
                                    <Select placeholder="Vehicle Type" options={options} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Vehicle Model"
                                    name="vehicleModelName"
                                    rules={[requiredRule]}
                                >
                                    <Input placeholder="Vehicle Model" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="MC Number"
                                    name="MCNumber"
                                    rules={[requiredRule]}
                                >
                                    <Input placeholder="MC Number" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Vehicle Color" name="vehicleColor">
                                    <Input
                                        style={{ width: '100%' }}
                                        placeholder="Vehicle Color"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="Vehicle Year"
                                    name="vehicleYear"
                                    rules={[requiredRule]}
                                >
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        placeholder="Vehicle Year"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>
                </Tabs>
            </Form>
        </Modal>
    );
};
