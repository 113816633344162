import axios from 'axios';
import { OCTOPUS_BASE_URL } from '.';
import { IDispersion } from '../interfaces/dispersion.interface';

export const getDispersions = (queryParams: any, {
    workspaceID, dispersionUniqueID, status, search, currency,
}: any) => {
    const params = new URLSearchParams(queryParams);

    if (status.length) params.append('status', JSON.stringify(status));
    if (search && search !== '') params.append('search', search);
    if (workspaceID && workspaceID !== '') params.append('workspaceID', workspaceID);
    if (dispersionUniqueID && dispersionUniqueID !== '') params.append('dispersionUniqueID', dispersionUniqueID);
    if (currency.length) params.append('currency', JSON.stringify(currency));

    const url = `${OCTOPUS_BASE_URL}/dispersions`;
    const response = axios.get(url, { params });
    return response;
};

export const getTransactionsByDispersion = (dispersionID: string) => {
    const url = `${OCTOPUS_BASE_URL}/dispersions/transactions/${dispersionID}`;
    const response = axios.get(url);
    return response;
};

export const deleteTransaction = (payload: any) => {
    const url = `${OCTOPUS_BASE_URL}/dispersions/transactions`;
    const response = axios.delete(url, { data: payload });
    return response;
};

export const payDispersion = (id: string, payload: any) => {
    const url = `${OCTOPUS_BASE_URL}/dispersions/${id}/pay`;
    const response = axios.post(url, payload);
    return response;
};

export const addTransaction = (payload: any) => {
    const url = `${OCTOPUS_BASE_URL}/dispersions/transactions`;
    const response = axios.post(url, payload);
    return response;
};

export const addInvoiceTransaction = (dispersionID: string, payload: any) => {
    const url = `${OCTOPUS_BASE_URL}/invoices/${dispersionID}/transactions`;
    const response = axios.post(url, payload);
    return response;
};

export const payMultiple = (payload: IDispersion[]) => {
    const url = `${OCTOPUS_BASE_URL}/dispersions/pay/multiple`;
    const response = axios.post(url, payload);
    return response;
};

export const updateDispersion = (id: string, payload: any) => {
    const url = `${OCTOPUS_BASE_URL}/dispersions/${id}`;
    const response = axios.put(url, payload);
    return response;
};

export const deleteDispersion = (id: string, data: any) => {
    const url = `${OCTOPUS_BASE_URL}/dispersions/${id}`;
    const response = axios.delete(url, { data });
    return response;
};
