/* eslint-disable no-use-before-define */
import { multiSelectTo as multiSelect } from './tier';

const PRIMARY_BUTTON_NUMBER = 0;
export const onClickRow = (e: any, record: any, selectedProviderIds: any[], setSelectedProviderIds: any, entities: any) => {
    if (e.defaultPrevented) {
        return;
    }

    if (e.button !== PRIMARY_BUTTON_NUMBER) {
        return;
    }

    // marking the event as used
    e.preventDefault();
    performAction(e, record, selectedProviderIds, setSelectedProviderIds, entities);
};

export const performAction = (e: any, record: any, selectedProviderIds: any[], setSelectedProviderIds: any, entities: any) => {
    if (wasToggleInSelectionGroupKeyUsed(e)) {
        toggleSelectionInGroup(record.id, selectedProviderIds, setSelectedProviderIds);
        return;
    }

    if (wasMultiSelectKeyUsed(e)) {
        multiSelectTo(record.id, entities, selectedProviderIds, setSelectedProviderIds);
        return;
    }

    toggleSelection(record.id, selectedProviderIds, setSelectedProviderIds);
};

export const toggleSelection = (providerId: string, selectedProviderIds: any[], setSelectedProviderIds: any) => {
    const wasSelected = selectedProviderIds.includes(providerId);

    const newProviderIds = (() => {
        if (!wasSelected) {
            return [providerId];
        }

        if (selectedProviderIds.length > 1) {
            return [providerId];
        }

        return [];
    })();

    setSelectedProviderIds(newProviderIds);
};

export const multiSelectTo = (newProviderId: string, entities: any, selectedProviderIds: any[], setSelectedProviderIds: any) => {
    const updated = multiSelect(entities, selectedProviderIds, newProviderId);

    if (updated == null) {
        return;
    }

    setSelectedProviderIds(updated);
};

export const wasMultiSelectKeyUsed = (e: any) => e.shiftKey;

export const onTouchEndRow = (e: any, record: any, selectedProviderIds: any[], setSelectedProviderIds: any) => {
    if (e.defaultPrevented) {
        return;
    }

    e.preventDefault();
    toggleSelectionInGroup(record.id, selectedProviderIds, setSelectedProviderIds);
};

const toggleSelectionInGroup = (providerId: string, selectedProviderIds: any[], setSelectedProviderIds: any) => {
    const index = selectedProviderIds.indexOf(providerId);

    // if not selected - add it to the selected items
    if (index === -1) {
        setSelectedProviderIds([...selectedProviderIds, providerId]);

        return;
    }

    // it was previously selected and now needs to be removed from the group
    const shallow = [...selectedProviderIds];
    shallow.splice(index, 1);

    setSelectedProviderIds(shallow);
};

export const wasToggleInSelectionGroupKeyUsed = (e: any) => {
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
    return isUsingWindows ? e.ctrlKey : e.metaKey;
};

export const onBeforeCapture = (start: any, selectedProviderIds: any[], setSelectedProviderIds: any, setDraggingProviderId: any) => {
    const { draggableId } = start;
    const selected = selectedProviderIds.find((providerId) => providerId === draggableId);

    if (!selected) {
        setSelectedProviderIds([]);
    }

    setDraggingProviderId(draggableId);
};
