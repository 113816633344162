import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Card, Col, PageHeader, Row, Table, Tag,
} from 'antd';
import moment from 'moment';

import { BookingType } from '../types/booking';
import { DriverType } from '../types/driver';
import { getCustomerById } from '../api/customer';
import { getUserBooking } from '../api/booking';
import { getStatusChipClass } from '../utils/bookings';
import { DriverBookingsStats, DriverUserDetails, DriverVehicleDetails } from '../components/DriverDetails';

export const DriverDetailsScreen = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [bookings, setBookings] = useState<BookingType[]>([]);
    const [paginator, setPaginator] = useState({ limit: 10, skip: 0 });
    const [total, setTotal] = useState(0);
    const { id } = useParams();
    const [driver, setDriver] = useState<DriverType>();

    useEffect(() => {
        if (!id) return;
        getCustomerById(id)
            .then(({ data }) => {
                setDriver(data.data);
            })
            .catch((err) => console.log(err));
    }, [id]);

    useEffect(() => {
        if (!driver) return;
        setLoading(true);
        getUserBooking(paginator, 'driver', id)
            .then(({ data }) => {
                setBookings(data.data.bookingData);
                setTotal(data.data.dataCount);
            })
            .catch()
            .finally(() => setLoading(false));
    }, [paginator, driver]);

    const column = [
        {
            title: 'Service ID',
            dataIndex: 'bookingNumericId',
            key: 'bookingNumericId',
        },
        {
            title: 'Service Date/time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string) => (
                <div style={{ fontSize: 12, textAlign: 'center' }}>
                    <div>
                        {moment(createdAt).format('DD/MM/YY')}
                    </div>
                    <div>
                        {moment(createdAt).format('HH:mm')}
                    </div>
                </div>
            ),
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
        },
        {
            title: 'Amount Accepted',
            dataIndex: 'orderAmount',
            key: 'orderAmount',
            render: (amount: number) => `$${amount.toFixed(2)}`,
        },
        {
            title: 'Delivery Fee',
            dataIndex: 'ServiceAmount',
            key: 'ServiceAmount',
            render: (amount: number) => `$${amount.toFixed(2)}`,
        },
        {
            title: 'Status',
            dataIndex: 'statusText',
            key: 'statusText',
            render: (status: string) => (
                <Tag {...getStatusChipClass(status)}>{status}</Tag>
            ),
        },
    ];

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({ limit: pageSize, skip: pageSize * (page - 1) });
    };

    return (
        <div>
            <PageHeader title="Picker Details" onBack={() => navigate(-1)} />
            <Card bordered={false}>
                <Row gutter={[16, 24]}>
                    <Col span={24} lg={8}>
                        <DriverBookingsStats driverId={driver?._id} />
                    </Col>
                    <Col span={24} lg={8}>
                        <DriverUserDetails fleet={driver} />
                    </Col>
                    <Col span={24} lg={8}>
                        <DriverVehicleDetails fleet={driver} />
                    </Col>
                    <Col span={24}>
                        <Table
                            rowKey="_id"
                            loading={loading}
                            columns={column}
                            dataSource={bookings}
                            pagination={{
                                total,
                                current: (paginator.skip / paginator.limit) + 1,
                                pageSize: paginator.limit,
                                onChange: onPageChange,
                                showSizeChanger: true,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </div>
    );
};
