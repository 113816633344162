import {
    Card, Form, Modal, notification, Select,
} from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setChangeTier } from '../../../actions/modals';
import { editTier } from '../../../api/deliveryProviders';

export const ChangeTierDeliveryProviderModal = ({ reload, setReload }: any) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { tierDeliveryProvider } = useSelector((state: any) => state.modals);

    const requiredRule = { required: true, message: 'Required' };

    const handleOk = (values: any) => {
        setLoading(true);
        editTier(tierDeliveryProvider._id, values)
            .then(({ data }) => {
                notification.success({
                    message: data.message,
                });
            })
            .catch((err) => {
                console.log(err);
                notification.error({ message: 'oops! An error has occurred' });
            })
            .finally(() => {
                setLoading(false);
                dispatch(setChangeTier(null));
                setReload(!reload);
            });
    };

    const handleCancel = () => {
        dispatch(setChangeTier(null));
    };

    return (
        <Modal
            title="Change Tier Method"
            visible={tierDeliveryProvider}
            onOk={() => form.submit()}
            onCancel={handleCancel}
            confirmLoading={loading}
            bodyStyle={{ padding: 0 }}
        >
            <Card
                bordered={false}
            >
                <Form
                    form={form}
                    onFinish={handleOk}
                    layout="vertical"
                    requiredMark={false}
                >
                    <Form.Item rules={[requiredRule]} label="Tier" name="tier">
                        <Select
                            style={{ width: '100%' }}
                            options={
                                [{ value: 0, label: 'T0: My Fleet' },
                                    { value: 1, label: 'T1: Picker Base' },
                                    { value: 2, label: 'T2: Picker, Small Fleetsr' },
                                    { value: 3, label: 'T3: Providers Externos' }]
                            }
                        />
                    </Form.Item>
                </Form>
            </Card>
        </Modal>
    );
};
