import { Card } from 'antd';
import { useContext, useState } from 'react';
import { TiersConfigurationContext } from '../../context/tiersConfigurationContext';
import RegionsTable from './RegionsTable';
import SuperRegionTable from './SuperRegionTable';

const RegionsSections = () => {
    const { regions, superRegions } = useContext(TiersConfigurationContext);
    const [activeTabKey, setActiveTabKey] = useState('region');

    const onTabChange = (key: string) => {
        setActiveTabKey(key);
    };

    const tabList = [
        {
            key: 'region',
            tab: `Region (${regions.data.length})`,
        },
        {
            key: 'superRegion',
            tab: `Super Region (${superRegions.data.length})`,
        },
    ];

    const contentList: any = {
        region: <RegionsTable />,
        superRegion: <SuperRegionTable />,
    };
    return (
        <Card
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={activeTabKey}
            onTabChange={onTabChange}
        >
            {contentList[activeTabKey]}
        </Card>
    );
};
export default RegionsSections;
