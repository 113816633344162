import {
    Dropdown,
    Menu,
    Modal,
    Typography,
    Space,
    notification,
} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { IIssue } from '../../../interfaces/issue.interface';
import { deleteIssue } from '../../../api/issues';

const { ItemGroup } = Menu;
const { confirm } = Modal;
const { Text, Title } = Typography;

interface Props {
    item: IIssue;
    setItem: (item: IIssue) => void;
    fetchData: () => void;
}

const Actions = ({
    item, setItem, fetchData,
}: Props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const handleDelete = () => {
        confirm({
            width: 500,
            title: (
                <Title level={4}>
                    Are you sure to delete this issue?
                </Title>
            ),
            content: (
                <Space direction="vertical" size={8}>
                    <Text>
                        {`Description: ${item.description}`}
                    </Text>
                    <Text>
                        {`Status: ${item.status} - Assigned to: ${item?.responsableName || 'Unassigned'}`}
                    </Text>
                </Space>
            ),
            onCancel: () => {},
            onOk: () => {
                const issueID = item._id;
                deleteIssue(issueID)
                    .then(() => {
                        notification.success({
                            message: 'Issue deleted successfully',
                        });
                        fetchData();
                    })
                    .catch((error: any) => {
                        const description = error?.response?.data?.message || error.message;
                        notification.error({
                            message: 'Error',
                            description,
                        });
                    })
                    .finally(() => {});
            },
        });
    };

    const OPTIONS = [
        {
            children: 'View issue',
            onClick: () => {
                setItem(item);
                const searchParams = new URLSearchParams(location.search);
                navigate(`/v2/issues?${searchParams.toString()}&open=${item.issuePublicID}`);
            },
        },
        {
            children: 'Delete issue',
            type: 'danger',
            onClick: () => handleDelete(),
        },
    ];

    return (
        <Dropdown
            placement="bottomRight"
            trigger={['click']}
            overlay={(
                <Menu>
                    <ItemGroup
                        key="g"
                    >
                        {
                            OPTIONS.map((option, index) => (
                                <Menu.Item key={index} {...option} />
                            ))
                        }
                    </ItemGroup>
                </Menu>
            )}
        >
            <BiDotsVerticalRounded size={24} />
        </Dropdown>
    );
};

export default Actions;
