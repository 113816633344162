export enum ModalTypes {
  EditPriceBreakup = 'EDIT_PRICE_BREAKUP',
  EditFareSettings = 'EDIT_FARE_SETTINGS',
  CancelService = 'CANCEL_SERVICE',
  ReportIssue = 'REPORT_ISSUE',
  ManualRequest = 'MANUAL_REQUEST',
  ChangePaymentMethod = 'CHANGE_PAYMENT_METHOD',
  EditDeliveryProvider = 'EDIT_DELIVERY_PROVIDER',
  RegionActions = 'REGION_ACTIONS',
  EditDriver = 'EDIT_DRIVER',
  UpdateIssue = 'UPDATE_ISSUE',
  EditMaxDistance = 'EDIT_MAX_DISTANCE',
  CoverageEdit = 'COVERAGE_EDIT',
}
