/* eslint-disable prefer-promise-reject-errors */
import {
    Card, Form, Modal, notification, Input,
} from 'antd';
import { useState } from 'react';
import { Rule } from 'antd/lib/form';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../../actions/modals';
import { editMaxDistance } from '../../../api/workspaces';
import { ModalTypes } from '../../../types/modals';

export const EditMaxDistanceModal = () => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { modalData, activeModal, onSuccess } = useSelector((state: any) => state.modals);
    const isOpen = activeModal === ModalTypes.EditMaxDistance;

    const requiredRule: Rule = { required: true, message: 'Required' };
    const greaterThanZeroRule: Rule = {
        validator: (rule, value) => (value >= 0 ? Promise.resolve() : Promise.reject('Must be greater or equal to 0')),
    };

    const handleCancel = () => {
        form.resetFields();
        dispatch(closeModal());
    };

    const handleOk = (values: any) => {
        setLoading(true);
        editMaxDistance(modalData._id, values)
            .then(({ data }) => {
                notification.success({
                    message: data.message,
                });
                onSuccess && onSuccess();
                handleCancel();
            })
            .catch((err) => {
                console.log(err);
                notification.error({ message: 'oops! An error has occurred' });
            })
            .finally(() => {
                setLoading(false);
                handleCancel();
            });
    };

    return (
        <Modal
            title="Edit max distance My Fleet"
            visible={isOpen}
            onOk={() => form.submit()}
            onCancel={handleCancel}
            confirmLoading={loading}
            bodyStyle={{ padding: 0 }}
        >
            <Card
                bordered={false}
            >
                <Form
                    form={form}
                    onFinish={handleOk}
                    layout="vertical"
                    requiredMark={false}
                >
                    <Form.Item
                        rules={[requiredRule, greaterThanZeroRule]}
                        label="Max Distance My Fleet"
                        name="myFleetMaxDistance"
                        key="myFleetMaxDistance"
                        initialValue={modalData?.settings?.myFleetMaxDistance || 0}
                    >
                        <Input
                            placeholder="Max Distance"
                            type="number"
                            suffix="Km"
                            style={{ textAlign: 'center' }}
                            defaultValue={modalData?.settings?.myFleetMaxDistance || 0}
                        />
                    </Form.Item>
                </Form>
            </Card>
        </Modal>
    );
};
