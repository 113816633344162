import {
    Button,
    Divider,
    Input, Modal, Spin, Typography,
} from 'antd';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffect, useRef, useState } from 'react';
import { BsInfo } from 'react-icons/bs';
import { RootStateOrAny, useSelector } from 'react-redux';
import { socketInstance } from '../context/SocketClientContext';
import socketEvents from '../const/socketEvent';
import { OpenAIMessage } from '../interfaces/picker-chat.interface';
import { getMessages, getRun } from '../api/picker-chat';

const PickerChat = () => {
    const [value, setValue] = useState<string>('');
    const [messages, setMessages] = useState<OpenAIMessage[]>([]);
    const [loading, setLoading] = useState(false);
    const lastMessage = useRef('');
    const hasMore = useRef(true);

    const auth = useSelector((state: RootStateOrAny) => state.auth);

    const sendPrompt = async (payload: any) => {
        try {
            setLoading(true);
            setValue('');
            const url = process.env.REACT_APP_PICKER_CHAT;
            const name = `${auth?.name} ${auth?.lastName}`;
            const email = auth?.email;
            const metadata = { name, email };
            const result = await axios.post(url!, { prompt: payload, metadata });
            console.log({ result });
        } catch (error: any) {
            Modal.error({ title: 'Error', content: error.message });
            setLoading(false);
        }
    };

    const formatMessage = (message: string) => {
        // replace /n with empty string
        message = message.replace('\n', '');
        // replace **text** with <b>text</b>
        message = message.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
        return message;
    };

    const fetchMessages = async () => {
        if (!hasMore.current) return;
        try {
            const { data } = await getMessages(lastMessage.current);
            const newMessages = [...data.data.data];
            hasMore.current = data.data.has_more;
            lastMessage.current = newMessages[newMessages.length - 1].id;
            setMessages((messages) => [...messages, ...newMessages]);
        } catch (error: any) {
            Modal.error({ title: 'Error', content: error.message });
        }
    };
    useEffect(() => {
        fetchMessages();
        socketInstance.emit(socketEvents.PICKER_CHAT_JOIN_CHAT, 'picker-chat');
        socketInstance.on(socketEvents.PICKER_CHAT_NEW_MESSAGE, (payload: OpenAIMessage) => {
            setMessages((prevMessages) => [payload, ...prevMessages]);
            if (payload.role === 'assistant') setLoading(false);
        });
        return () => {
            socketInstance.emit(socketEvents.PICKER_CHAT_LEAVE_CHAT, 'picker-chat');
            socketInstance.off(socketEvents.PICKER_CHAT_NEW_MESSAGE);
        };
    }, []);

    const getName = (message: OpenAIMessage) => {
        if (message.role === 'assistant') return 'JARVIS';
        return message.metadata.name || message.metadata.email || 'User';
    };

    const showRunData = async (runId: string) => {
        try {
            const { data } = await getRun(runId);
            const createdAt = new Date(data.data.created_at * 1000);
            const startedAt = new Date(data.data.started_at * 1000);
            const completedAt = new Date(data.data.completed_at * 1000);
            Modal.info({
                title: 'Run Data',
                content: (
                    <div>
                        <p>
                            <b>Run Created At:</b>
                            {' '}
                            {createdAt.toLocaleString()}
                        </p>
                        <p>
                            <b>Run Started At:</b>
                            {' '}
                            {startedAt.toLocaleString()}
                        </p>
                        <p>
                            <b>Run Completed At:</b>
                            {' '}
                            {completedAt.toLocaleString()}
                        </p>
                        {Object.keys(data.data.metadata).map((key) => (
                            <p key={key}>
                                <b>
                                    {key}
                                    :
                                </b>
                                {' '}
                                {data.data.metadata[key]}
                            </p>
                        ))}
                    </div>
                ),
            });
        } catch (error: any) {
            Modal.error({ title: 'Error', content: error.message });
        }
    };

    return (
        <div className="screen screen-remaining picker-chat-screen">
            <h1>Chat</h1>
            <div
                id="messages"
                className="picker-chat-result-container"
                style={{
                    height: 600,
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                }}
            >
                <InfiniteScroll
                    dataLength={messages.length + 1}
                    next={fetchMessages}
                    hasMore
                    loader={loading && <Spin />}
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                    inverse
                    endMessage={
                        !loading && (
                            <Divider plain>
                                No more issues
                            </Divider>
                        )
                    }
                    scrollableTarget="messages"
                >
                    {messages.map((message) => (
                        <div key={message.id} id={message.id}>
                            <div className={`picker-chat-message-author picker-chat-message-author-${message.role}`}>
                                {message.role === 'assistant' && (
                                    <Button
                                        type="ghost"
                                        shape="circle"
                                        size="small"
                                        onClick={() => showRunData(message.run_id)}
                                    >
                                        <BsInfo />
                                    </Button>
                                )}
                                {getName(message)}
                            </div>
                            <div
                                className="picker-chat-message"
                                dangerouslySetInnerHTML={{ __html: formatMessage(message.content[0].text.value) }}
                            />
                        </div>
                    ))}
                </InfiniteScroll>
                {loading && (
                    <div className="picker-chat-loading">
                        <Spin />
                        <Typography.Text>Loading...</Typography.Text>
                    </div>
                )}
            </div>
            <Input.Search
                placeholder="input search text"
                allowClear
                onSearch={sendPrompt}
                className="picker-chat-input"
                onChange={(e) => setValue(e.target.value)}
                value={value}
                disabled={loading}
            />
            <p>JARVIS can make mistakes. Consider checking important information.</p>
        </div>
    );
};

export default PickerChat;
