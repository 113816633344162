import { Dispersion } from '../components/DispersionDetail';
import { DispersionProvider } from '../components/DispersionDetail/context/dispersionContext';

const DispersionDetail = () => (
    <div className="screen__dispersion-detail">
        <DispersionProvider>
            <Dispersion />
        </DispersionProvider>
    </div>
);

export default DispersionDetail;
