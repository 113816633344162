import {
    Avatar, Col, DatePicker, Dropdown, Input, Menu, notification, PageHeader, Row, Space, Table, Tag, Tooltip,
} from 'antd';
import moment from 'moment';
import { useEffect, useState, useMemo } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BookingType } from '../../types/booking';
import { STATUSES } from '../../const/booking';
import {
    openModal, setChangeStatus, setDriverManuallylId, setRestartSearchId, setRidesAddComment,
} from '../../actions/modals';
import { getBookings, SMR_URL, startSearch } from '../../api/booking';
import { endSearch } from '../../api/deliveryProviders';
import { ModalTypes } from '../../types/modals';
import {
    AssigningDriverManually, CancelService, ChangeStatusModal, RestartSearchModal, CommentModal,
} from '../Modals';

interface BookingMenuProps {
    booking: BookingType,
  }

const BookingMenu = ({ booking }: BookingMenuProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isOnHold = booking.statusText === STATUSES.ON_HOLD.TEXT;

    const menu = [
        {
            children: 'Assign Picker Manually',
            onClick: () => {
                dispatch(setDriverManuallylId(booking));
            },
        },
        {
            children: 'Change Status',
            onClick: () => {
                dispatch(setChangeStatus(booking));
            },
        },
        ...(isOnHold ? [{
            children: 'Start Search',
            onClick: () => {
                startSearch(booking._id)
                    .then((res: any) => notification.success({
                        message: 'Success!',
                        description: `${res.data.message}`,
                    }))
                    .catch((err: any) => {
                        const error = err.response?.data?.message;
                        notification.error({
                            message: 'Error',
                            description: error,
                        });
                    });
            },
        }] : []),
        {
            children: 'End Search',
            onClick: () => {
                endSearch(booking._id, { isAdmin: true })
                    .then((res: any) => notification.success({
                        message: 'Success!',
                        description: `${res.data.message}`,
                    }))
                    .catch((err) => notification.error({
                        message: 'Opps!',
                        description: 'An Error ocurred',
                    }));
            },
        },
        {
            children: 'Share My Ride',
            onClick: () => {
                window.open(`${SMR_URL}/#?token=${booking.smrToken}`);
            },
        },
        {
            children: 'Set Hack',
            onClick: () => {
                navigate(`/v2/booking?id=${booking._id}`);
            },
        },
        {
            children: 'Restart Search',
            onClick: () => {
                dispatch(setRestartSearchId(booking));
            },
        },
        {
            children: 'Cancel service',
            onClick: () => {
                dispatch(openModal(ModalTypes.CancelService, booking));
            },
        },
        {
            children: 'Report Issue',
            onClick: () => {
                dispatch(openModal(ModalTypes.ReportIssue, booking));
            },
        },
        {
            children: 'Add comment',
            onClick: () => {
                dispatch(setRidesAddComment(booking));
            },
        },
    ];

    return (
        <Menu>
            <Menu.ItemGroup key="g">
                {menu.map((o, i) => (
                    <Menu.Item key={i} {...o} />
                ))}
            </Menu.ItemGroup>
        </Menu>
    );
};

interface TableProps {
    initialColumns: any[];
    bookings: BookingType[]
}

export const TableBooking = ({ initialColumns, bookings }: TableProps) => {
    const [loading, setLoading] = useState(false);
    const [paginator, setPaginator] = useState({ limit: 10, skip: 0 });
    const [total, setTotal] = useState(0);

    const [bookingDetails, setBookingDetails] = useState<BookingType | null>(null);

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({ limit: pageSize, skip: pageSize * (page - 1) });
    };

    return (
        <div>
            <Table
                loading={loading}
                columns={initialColumns}
                dataSource={[...bookings]}
                rowClassName={(record: any, index) => (record.firstTimeOrder ? 'table-row-warning' : 'booking-row')}
                bordered
                scroll={{ x: 1500 }}
                pagination={{
                    total,
                    current: (paginator.skip / paginator.limit) + 1,
                    pageSize: paginator.limit,
                    onChange: onPageChange,
                    showSizeChanger: true,
                }}
                rowKey="_id"
                onRow={(record: any) => ({
                    onClick: () => {
                        setBookingDetails(record);
                    },
                })}
            />
            <AssigningDriverManually />
            <ChangeStatusModal />
            <CommentModal />
            <RestartSearchModal />
            <CancelService />
        </div>

    );
};
