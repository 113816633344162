import {
    useEffect, useState, createContext, FC, useMemo,
} from 'react';
import { getDriverTags, getDriverTracking, getRegions } from '../../../api/commandCenter';
import { IDriverTracking } from '../../../types/driver';
import { IRegion } from '../../../types/region';
import { checkDriverStatus, filterDriversFromFilterOptions } from '../utils/driver';

type TLocationMap = {
  center: {
    lat: number;
    lng: number;
  },
  zoom: number;
}

export type IDriversFilter = {
  deliveryProvider?: string[];
  tags?: string[];
  status?: string[]
};

type TBusiness = {
  id: string;
  name: string;
  coordeninates: {
    lat: number;
    lng: number;
  }
}

type IDriverTag = {
  text: string;
  value: string;
}

interface ICommandCenterContext {
  drivers: IDriverTracking[];
  driverTags: IDriverTag[];
  driverMarkerSelected: null | string;
  smallFleetSelected: boolean;
  myFleetSelected: boolean;
  business: TBusiness[];
  regions: IRegion[];
  showRegions: boolean;
  isLoadingCommandCenterContext: boolean;
  currentLocation: TLocationMap;
  updateLocation: (params: TLocationMap) => void;
  updatedDriverMarkerSelected: (params: null | string) => void;
  updateShowRegion: () => void;
  updateDriversFilter: (params: IDriversFilter) => void;
  updateBusiness: (params: TBusiness[]) => void
  updateDriverStatus: (params: string[]) => void
}

const initialContext = {
    drivers: [],
    driverTags: [],
    business: [],
    regions: [],
    smallFleetSelected: false,
    myFleetSelected: false,
    showRegions: false,
    driverMarkerSelected: null,
    isLoadingCommandCenterContext: false,
    currentLocation: {
        center: {
            lat: 0,
            lng: 0,
        },
        zoom: 0,
    },
    updateLocation: () => { },
    updatedDriverMarkerSelected: () => { },
    updateShowRegion: () => { },
    updateDriversFilter: () => { },
    updateBusiness: () => { },
    updateDriverStatus: () => {},
};

export const CommandCenterContext = createContext<ICommandCenterContext>(initialContext);

export const CommandCenterProvider: FC = ({ children }) => {
    const [drivers, setDrivers] = useState<IDriverTracking[]>([]);
    const [driverTags, setDriverTags] = useState<IDriverTag[]>([]);
    const [driversFilter, setDriversFilter] = useState<IDriversFilter>({
        deliveryProvider: [],
        tags: [],
        status: ['online'],
    });
    const [smallFleetSelected, setSmallFleetSelected] = useState(true);
    const [myFleetSelected, setMyFleetSelected] = useState(true);
    const [driverMarkerSelected, setDriverMarkerSelected] = useState<null | string>(null);
    const [business, setBusiness] = useState<TBusiness[]>([]);
    const [regions, setRegions] = useState([]);
    const [showRegions, setShowRegions] = useState(false);
    const [currentLocation, setCurrentLocation] = useState<TLocationMap>(
        {
            center: {
                lat: -2.2058400,
                lng: -79.9079500,
            },
            zoom: 12,
        },
    );
    const [isLoadingCommandCenterContext, setIsLoadingCommandCenterContext] = useState(false);

    const fetchDriverTracking = () => getDriverTracking()
        .then(({ data }: any) => {
            const currentDrivers = data.data.data.map((d: IDriverTracking) => ({
                ...d,
                statusText: checkDriverStatus(d.isBusy, d.onDutyFlag),
            }));

            setDrivers(currentDrivers);
        })
        .catch((error: any) => console.log(error));

    const fetchRegions = () => getRegions()
        .then(({ data }: any) => {
            setRegions(data.data.data);
        })
        .catch((error: any) => console.log(error));

    const fetchDriverTags = () => getDriverTags()
        .then(({ data }: any) => {
            setDriverTags(data.data.data);
        })
        .catch((error: any) => console.log(error));

    useEffect(() => {
        setIsLoadingCommandCenterContext(true);

        const callAllFetchs = async () => {
            setIsLoadingCommandCenterContext(true);

            await fetchDriverTracking();
            await fetchDriverTags();
            await fetchRegions();

            setIsLoadingCommandCenterContext(false);
        };

        callAllFetchs();
    }, []);

    const updateLocation = ({ center, zoom }: TLocationMap) => {
        setCurrentLocation({ center, zoom });
    };

    const updatedDriverMarkerSelected = (value: null | string) => {
        setDriverMarkerSelected(value);
    };

    const updateShowRegion = () => setShowRegions(!showRegions);

    const updateDriversFilter = ({ tags, deliveryProvider }: IDriversFilter) => {
        deliveryProvider && setSmallFleetSelected(!deliveryProvider?.includes('SMALL_FLEETS'));

        deliveryProvider && setMyFleetSelected(!deliveryProvider?.includes('THIRD_PARTY_DRIVER'));

        deliveryProvider && setDriversFilter({ ...driversFilter, deliveryProvider });

        tags && setDriversFilter({ ...driversFilter, tags });
    };

    const updateBusiness = (businessUpdated: TBusiness[]) => {
        setBusiness([...businessUpdated]);
    };

    const updateDriverStatus = (status: string[]) => {
        setDriversFilter({ ...driversFilter, status });
    };

    const driversFilters = useMemo(() => filterDriversFromFilterOptions(drivers, driversFilter), [drivers, driversFilter]);

    return (
        <CommandCenterContext.Provider
            value={{
                drivers: driversFilters,
                driverTags,
                driverMarkerSelected,
                smallFleetSelected,
                myFleetSelected,
                business,
                regions,
                showRegions,
                currentLocation,
                isLoadingCommandCenterContext,

                updateLocation,
                updatedDriverMarkerSelected,
                updateShowRegion,
                updateDriversFilter,
                updateBusiness,
                updateDriverStatus,
            }}
        >
            {children}
        </CommandCenterContext.Provider>
    );
};
