const getPathsFromPolygon = (polygon: google.maps.Polygon) => {
    const paths = polygon.getPaths().getArray();
    const newPolygon: number[][] = [];
    paths.forEach((path: google.maps.MVCArray<google.maps.LatLng>) => {
        const pathArr = path.getArray();
        pathArr.forEach(({ lat, lng }: google.maps.LatLng) => {
            newPolygon.push([lng(), lat()]);
        });
    });
    return [...newPolygon, newPolygon[0]];
};

export {
    getPathsFromPolygon,
};
