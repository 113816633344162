import { Form, Select } from 'antd';
import { IWhatsappCertification } from '../context/certifications.interface';

interface Props {
    form: any;
    item: IWhatsappCertification;
}

const ChangeStatus = ({ form, item }: Props) => {
    const STATUS = [
        { label: 'Pending', value: 'PENDING' },
        { label: 'Approved', value: 'APPROVED' },
        { label: 'Rejected', value: 'REJECTED' },
    ];

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={() => {}}
            initialValues={{ status: item.status }}
        >
            <Form.Item
                name="status"
                label="Select the new status of the certification"
                rules={[{ required: true, message: 'Please select the status' }]}
            >
                <Select
                    style={{ width: '100%' }}
                    placeholder="Select status"
                    options={STATUS}
                    onChange={(value: string) => {}}
                />
            </Form.Item>
        </Form>
    );
};

export default ChangeStatus;
