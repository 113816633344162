import {
    Alert, Button, Checkbox, Col, Form, InputNumber, Modal, notification, Row, Space,
} from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setRestartSearchId } from '../../../actions/modals';
import { getOneBusiness } from '../../../api/business';
import { getProvidersByCoordinates, getResetDeliveryProviders } from '../../../api/deliveryProviders';

export const RestartSearchModal = () => {
    const [loading, setLoading] = useState(false);
    const [deliveryProviders, setDeliveryProviders] = useState<any[]>([]);
    const { deliveries } = useSelector((state: any) => state.deliveries);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { restartSearchId: booking } = useSelector((state: any) => state.modals);
    const PERCENTAGE = 100;

    const onFinish = (values: any) => {
        setLoading(true);
        const payload: any = {
            bookingID: booking._id,
            preSelectedProviders: values.preSelectedProviders,
            isAdmin: true,
        };
        if (values.applyTip) {
            const fee = booking.sumOrderAndDelivery ? (booking.priceBreakUp.baseFare + booking.priceBreakUp.perKmCharge) : booking.deliveryFee;
            const tip = fee * (values.tip / PERCENTAGE);
            payload.tip = tip;
        }
        getResetDeliveryProviders(payload)
            .then((res: any) => notification.success({
                message: 'Success!',
                description: `${res.data.message}`,
            }))
            .catch((error: any) => notification.error({
                message: 'Opps!',
                description: `${error.response.data.message}`,
            }))
            .finally(() => {
                dispatch(setRestartSearchId(null));
                form.resetFields();
                setLoading(false);
            });
    };
    const onCancel = () => {
        dispatch(setRestartSearchId(null));
    };

    const getActiveProviders = async () => {
        try {
            const { data: { data: business } } = await getOneBusiness(booking?.business);
            const providers = business?.externalAPIConfiguration?.deliveryProviders;
            const activeProviders = providers?.filter((provider: any) => provider.isActive);
            const activeProvidersNames = activeProviders?.map((provider: any) => provider.keyName);
            setDeliveryProviders((deliveryProviders) => deliveryProviders.map(
                (provider: any) => ({ ...provider, hasBusinessActive: activeProvidersNames.includes(provider.keyName) }),
            ));
        } catch (error: any) {
            console.log(error);
            notification.error({
                message: 'Could not get active providers',
                description: `${error.response.data.message}`,
            });
        }
    };

    useEffect(() => {
        const coordinates = booking?.pickupCoordinates?.coordinates;
        if (!coordinates) return;
        getProvidersByCoordinates(booking?.pickupCoordinates?.coordinates)
            .then(({ data }: any) => {
                const keyNames = data.data;
                const providers = deliveries.filter((delivery: any) => keyNames.includes(delivery.keyName));
                setDeliveryProviders(providers);
                getActiveProviders();
            })
            .catch(console.log);
        return () => {
            setDeliveryProviders([]);
        };
    }, [booking]);

    const toggleSelection = (value: boolean) => {
        const providers = deliveryProviders.map((provider: any) => provider.keyName);
        form.setFieldsValue({ preSelectedProviders: value ? providers : [] });
    };

    return (
        <Modal
            visible={booking}
            title="Select providers"
            onCancel={onCancel}
            onOk={() => form.submit()}
            confirmLoading={loading}
        >
            <Form
                form={form}
                onFinish={onFinish}
                initialValues={{
                    applyTip: false,
                    tip: 15,
                }}
            >
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert
                        message="✔ - The business has this provider active"
                    />
                    <Row gutter={50} justify="center">
                        <Col>
                            <Button type="link" onClick={() => toggleSelection(true)}>
                                Select all
                            </Button>
                        </Col>
                        <Col>
                            <Button type="link" onClick={() => toggleSelection(false)}>
                                Deselect all
                            </Button>
                        </Col>
                    </Row>
                    <Form.Item name="preSelectedProviders">
                        <Checkbox.Group>
                            {
                                deliveryProviders.map((delivery: any, _: any) => (
                                    <Row key={_}>
                                        <Col span={24}>
                                            <Checkbox value={delivery.keyName}>
                                                {delivery.name}
                                                {delivery.hasBusinessActive && (
                                                    <span style={{ color: 'green', marginLeft: 5 }}>✔</span>
                                                )}
                                            </Checkbox>
                                        </Col>
                                    </Row>
                                ))
                            }
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item name="applyTip" valuePropName="checked">
                        <Checkbox>Include tip</Checkbox>
                    </Form.Item>
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) => prevValues.applyTip !== currentValues.applyTip}
                    >
                        {({ getFieldValue }) => (getFieldValue('applyTip') ? (
                            <Form.Item
                                name="tip"
                                label="Tip amount"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter the tip amount',
                                    },
                                ]}
                            >
                                <InputNumber
                                    min={0}
                                    max={50}
                                    formatter={(value) => `${value}%`}
                                />
                            </Form.Item>
                        ) : null)}
                    </Form.Item>
                </Space>
            </Form>
        </Modal>
    );
};
