/* eslint-disable prefer-promise-reject-errors */
import {
    Button, Card, Col, Form, Input, Modal, notification, Row, Space, Select, Typography, Divider,
} from 'antd';
import { Rule } from 'antd/lib/form';
import { useEffect, useState } from 'react';
import { BsPlusCircle, BsTrash } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from '../../../actions/modals';
import { editProviderFareSettings } from '../../../api/deliveryProviders';
import { editFareSettings } from '../../../api/workspaces';
import { ModalTypes } from '../../../types/modals';

const requiredRule: Rule = { required: true, message: 'Required' };
const greaterThanZeroRule: Rule = {
    validator: (rule, value) => (value >= 0 ? Promise.resolve() : Promise.reject('Must be greater or equal to 0')),
};

export const EditFareSettingsModal = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { activeModal, modalData, onSuccess } = useSelector((state: any) => state.modals);
    const isOpen = activeModal === ModalTypes.EditFareSettings;
    const handleCancel = () => {
        form.resetFields();
        dispatch(closeModal());
    };

    const onFinish = async (values: any) => {
        setLoading(true);

        let { fareSettings } = values;

        fareSettings = fareSettings.map((fare: any) => {
            delete fare.formName;
            fare.paymentMethod = fare.paymentMethod.toUpperCase();
            fare.carType = 0;
            return fare;
        });

        try {
            const fareSettingReq = modalData.type === 'deliveryProvider' ? editProviderFareSettings : editFareSettings;
            await fareSettingReq(modalData._id, values);
            notification.success({ message: 'Success', description: 'Fare Settings updated successfully' });
            onSuccess && onSuccess();
            handleCancel();
        } catch (error: any) {
            console.log({ error });
            const message = error.response?.data?.message;
            notification.error({
                message,
            });
        } finally {
            setLoading(false);
        }
    };

    const INPUTS = [
        {
            label: 'Payment Method', name: 'paymentMethod', type: 'text', hidden: true,
        },
        { label: 'Base Fare', name: 'baseFare', type: 'number' },
        { label: 'Per Min Charge', name: 'perMinuteCharge', type: 'number' },
        { label: 'Base Distance', name: 'baseDistance', type: 'number' },
        { label: 'Distance Range', name: 'distanceRange', type: 'number' },
        {
            label: 'Per Km Charge', name: 'perKmCharge', type: 'number', hidden: true,
        },
        {
            label: 'Free time', name: 'freeTime', type: 'number', hidden: true,
        },
        { label: 'Average Time', name: 'averageTime', type: 'number' },
        {
            label: 'Car Type', name: 'carType', type: 'number', hidden: true,
        },
        {
            label: 'Cancellation Policy', name: 'cancellationPolicy', type: 'text', hidden: true,
        },
        {
            label: 'Canc. Free Time In Sec.', name: 'cancellationFreeTimeInSecond', type: 'number', hidden: true,
        },
        {
            label: 'Mini. Cancellation Fee', name: 'minimumCancellationFee', type: 'number', hidden: true,
        },
        {
            label: 'Commission Charge', name: 'commissionCharge', type: 'number', hidden: true,
        },
    ];

    const KM_RANGES = [
        {
            label: 'Minimum', name: 'min', type: 'number', suffix: 'Km',
        },
        {
            label: 'Maximum', name: 'max', type: 'number', suffix: 'Km',
        },
        {
            label: 'Amount', name: 'amount', type: 'number', suffix: '$',
        },
    ];

    const DEFAULT_VALUES = {
        averageTime: 0,
        baseDistance: 0,
        baseFare: 0,
        cancellationFreeTimeInSecond: 0,
        cancellationPolicy: 'a',
        carType: 0,
        commissionCharge: 0,
        distanceRange: 0,
        freeTime: 0,
        kmRanges: [
            {
                amount: 0,
                max: 0,
                min: 0,
            },
        ],
        minimumCancellationFee: 0,
        perKmCharge: 0,
        perMinuteCharge: 0,
    };

    const FORMS = [
        {
            formName: 'LITE_CARD',
            carName: 'LITE',
            paymentMethod: 'card',
            ...DEFAULT_VALUES,
        },
        {
            formName: 'LITE_CASH',
            carName: 'LITE',
            paymentMethod: 'cash',
            ...DEFAULT_VALUES,
        },
        {
            formName: 'BIKE_CARD',
            carName: 'BIKE',
            paymentMethod: 'card',
            ...DEFAULT_VALUES,
        },
        {
            formName: 'BIKE_CASH',
            carName: 'BIKE',
            paymentMethod: 'cash',
            ...DEFAULT_VALUES,
        },
    ];

    useEffect(() => {
        if (!isOpen) return;

        form.setFieldsValue({ fareSettings: FORMS });

        let initialFareSettings = modalData?.fareSettings || [];
        const hasFareSettings = initialFareSettings.length > 0;

        if (hasFareSettings) {
            initialFareSettings = initialFareSettings.map((fareSettings: any) => {
                const { carName, paymentMethod } = fareSettings;
                const formName = `${carName}_${paymentMethod}`.toUpperCase();
                return {
                    ...fareSettings,
                    formName,
                };
            });

            initialFareSettings.forEach((fareSetting: any) => {
                const { formName, ...rest } = fareSetting;

                const formFareSettings = form.getFieldsValue().fareSettings;

                formFareSettings.forEach((values: any) => {
                    if (values.formName === formName) {
                        form.setFieldsValue({
                            fareSettings: formFareSettings.map((values: any) => {
                                if (values.formName === formName) {
                                    return { ...values, ...rest };
                                }
                                return values;
                            }),
                        });
                    }
                });
            });
        }
    }, [modalData]);

    return (
        <Modal
            title="Edit Fare Settings"
            visible={isOpen}
            onOk={() => form.submit()}
            onCancel={handleCancel}
            confirmLoading={loading}
            okText="Update"
            width={1000}
            centered
        >
            <Form
                form={form}
                onFinish={onFinish}
                requiredMark={false}
                layout="vertical"
                initialValues={{ fareSettings: FORMS }}
                style={{ maxHeight: 600, overflowY: 'scroll' }}
            >
                <Form.List name="fareSettings">
                    {(fields) => (
                        fields.map((field) => {
                            const formName = form.getFieldValue(['fareSettings', field.name, 'formName']).split('_');
                            const cardName = `${formName[0]} ${formName[1]}`;
                            return (
                                <Card key={field.name}>
                                    <Row gutter={12}>
                                        <Col span={24}>
                                            <Form.Item {...field} name={[field.name, 'formName']} noStyle>
                                                <Typography.Title level={3} style={{ textAlign: 'center', textTransform: 'capitalize' }}>
                                                    {cardName.toLowerCase()}
                                                </Typography.Title>
                                            </Form.Item>
                                            <Divider />
                                        </Col>

                                        {INPUTS.map((input) => (
                                            !input.hidden && (
                                                <Col xs={24} md={12} key={input.name}>
                                                    <Form.Item
                                                        {...field}
                                                        label={input.label}
                                                        name={[field.name, input.name]}
                                                        rules={[requiredRule, ...(input.type === 'number' ? [greaterThanZeroRule] : [])]}
                                                        key={input.name}
                                                        initialValue={input.type === 'number' ? 0 : ''}
                                                    >
                                                        <Input placeholder={input.label} type={input.type} />
                                                    </Form.Item>
                                                </Col>
                                            )
                                        ))}

                                        <Col span={24}>
                                            <Form.List
                                                name={[field.name, 'kmRanges']}
                                                initialValue={[]}
                                                rules={[{
                                                    validator(rule, value, callback) {
                                                        if (value.length === 0) {
                                                            callback('At least one km range is required');
                                                        } else {
                                                            callback();
                                                        }
                                                    },
                                                }]}
                                            >
                                                {(kmRanges, { add: addKmRange, remove }, { errors }) => (
                                                    <>
                                                        <Typography.Title level={4}>
                                                            Km Ranges
                                                        </Typography.Title>
                                                        <Divider />
                                                        {kmRanges.map((kmRange) => (
                                                            <div key={kmRange.key}>
                                                                <Space align="center" style={{ width: '100%' }}>
                                                                    {KM_RANGES.map((kmRangeInput) => (
                                                                        <Form.Item
                                                                            {...kmRange}
                                                                            label={kmRangeInput.label}
                                                                            name={[kmRange.name, kmRangeInput.name]}
                                                                            rules={[requiredRule, greaterThanZeroRule]}
                                                                            key={kmRangeInput.name}
                                                                        >
                                                                            <Input
                                                                                placeholder={kmRangeInput.label}
                                                                                type={kmRangeInput.type}
                                                                                suffix={kmRangeInput.suffix}
                                                                            />
                                                                        </Form.Item>
                                                                    ))}
                                                                    <Button danger onClick={() => remove(kmRange.name)}>
                                                                        <span className="anticon">
                                                                            <BsTrash size={16} />
                                                                        </span>
                                                                    </Button>
                                                                </Space>
                                                            </div>
                                                        ))}
                                                        {errors.length > 0 && (
                                                            <div style={{ color: 'red' }}>{errors[0]}</div>
                                                        )}

                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => addKmRange()}
                                                                block
                                                                icon={<span className="anticon"><BsPlusCircle /></span>}
                                                            >
                                                                Add Km Range
                                                            </Button>
                                                        </Form.Item>
                                                    </>
                                                )}
                                            </Form.List>
                                        </Col>
                                    </Row>
                                </Card>
                            );
                        })
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
};
