import React, { useState } from 'react';
import {
    Dropdown, Menu, Modal, notification, Table,
} from 'antd';
import { BsThreeDots } from 'react-icons/bs';
import { resendUserInvitation, transferOwnership } from '../../api/workspaces';
import { RemoveUserModal } from '../Modals';

interface WorkspaceUsersTableProps {
  id?: string;
  setShowAddUserModal: React.Dispatch<React.SetStateAction<any>>
  users: {
    data: any[];
    count: number;
    loading: boolean;
    paginator:{
      limit: number;
      skip: number;
    }
  }
  fetchUsers: (params: any) => void
}

interface WorkspaceUsersMenuProps {
    user: any;
    id?: string | any;
    setRemoveUser: React.Dispatch<React.SetStateAction<any>>;
    setShowAddUserModal: React.Dispatch<React.SetStateAction<any>>;
}

const WorkspaceUsersMenu = ({
    user, id, setRemoveUser, setShowAddUserModal,
}: WorkspaceUsersMenuProps) => {
    const menu = [
        {
            children: 'Edit User',
            onClick: () => {
                setShowAddUserModal({
                    visible: true, type: 'Edit', userID: user.userID, user,
                });
            },
        },
        {
            children: 'Remove From Workspaces',
            onClick: () => {
                setRemoveUser({ ...user, userID: user._id, workspaceID: id });
            },
        },
        {
            children: 'Transfer ownership',
            onClick: () => {
                Modal.confirm({
                    title: 'Are you sure you want to transfer ownership?',
                    content: 'This action cannot be undone',
                    okText: 'Yes',
                    cancelText: 'No',
                    onOk: () => {
                        transferOwnership(id, user._id, user.email)
                            .then(({ data }) => {
                                const { message } = data;
                                notification.success({
                                    message,
                                });
                            })
                            .catch((error) => {
                                const message = error.response?.data?.message;
                                notification.error({
                                    message,
                                });
                                console.log(error);
                            });
                    },
                });
            },
        },
    ];

    return (
        <Menu>
            <Menu.ItemGroup key="g">
                {menu.map((o, i) => (
                    <Menu.Item key={i} {...o} />
                ))}
            </Menu.ItemGroup>
        </Menu>
    );
};

export const WorkspaceUsersTable = ({
    id, setShowAddUserModal, users, fetchUsers,
}: WorkspaceUsersTableProps) => {
    const [removeUser, setRemoveUser] = useState<any>(null);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone',
            dataIndex: 'mobile',
            key: 'mobile',
            render: (mobile: string, user: any) => (
                <span>
                    { `${user?.countryCode} ${mobile}` || 'Not available' }
                </span>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'statusText',
            key: 'actions',
            width: 100,
            fixed: 'right' as 'right',
            // eslint-disable-next-line react/display-name
            render: (_statusText: any, record: any) => (
                <Dropdown
                    placement="bottomRight"
                    overlay={(
                        <WorkspaceUsersMenu
                            user={record}
                            id={id}
                            setRemoveUser={setRemoveUser}
                            setShowAddUserModal={setShowAddUserModal}
                        />
                    )}
                    // @ts-ignore
                    onClick={(e: any, a: any) => { e.stopPropagation(); }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            <BsThreeDots size={25} />
                        </a>
                    </div>
                </Dropdown>
            ),
        },
    ];

    const onPageChange = (page: number, pageSize: number) => {
        fetchUsers({ limit: pageSize, skip: page });
    };

    return (
        <div>
            <Table
                dataSource={users.data}
                columns={columns}
                loading={users.loading}
                rowKey="userID"
                scroll={{ x: 800 }}
                pagination={{
                    total: users.count,
                    current: users.paginator.skip || 1,
                    pageSize: users.paginator.limit,
                    onChange: onPageChange,
                    showSizeChanger: true,
                }}
            />
            <RemoveUserModal
                removeUser={removeUser}
                setRemoveUser={setRemoveUser}
                setReload={() => fetchUsers(users.paginator)}
            />
        </div>
    );
};
