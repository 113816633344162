import cs from 'classnames';
import {
    Row, Col, Typography, Spin, Space, Button,
} from 'antd';
import { FaRegEdit } from 'react-icons/fa';
import { useState } from 'react';
import { IAlert, IWorkspaceAlert } from '../../../interfaces/workspace-alert';
import CardDataBase from '../../CardDataBase';
import WorkspacesList from './Workspaces';
import UpdateAlert from './Update';

interface Props {
    alert?: IAlert | null;
    loadingWorkspaceAlerts: boolean;
    loadingReadAlert: boolean;
    workspaceAlerts: IWorkspaceAlert[];
    markAsRead: (wsAlertID: string, isRead: boolean) => void;
    updateAlert: (alert: any) => void;
}

const { Text } = Typography;

const AlertData = ({
    alert, workspaceAlerts, loadingWorkspaceAlerts, markAsRead, loadingReadAlert, updateAlert,
}: Props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [update, setUpdate] = useState<boolean>(false);

    const CARD_CONTENT = [
        {
            title: 'Workspaces notified',
            content: (
                loadingWorkspaceAlerts
                    ? <Spin size="small" />
                    : (
                        <Space size={8}>
                            <Text>
                                {workspaceAlerts.length || 0}
                            </Text>
                            <Button
                                type="link"
                                disabled={workspaceAlerts.length === 0}
                                onClick={() => setOpen(true)}
                            >
                                View all
                            </Button>
                        </Space>
                    )
            ),
        },
        {
            title: 'English message',
            content: alert?.description.en,
        },
        {
            title: 'Spanish message',
            content: alert?.description.es,
        },
        {
            title: 'Alert type',
            content: (
                <Text className={cs(alert?.type, 'status')}>
                    {alert?.type}
                </Text>
            ),
        },
        {
            title: 'CTA Text',
            content: alert?.CTAText || '-',
        },
        {
            title: 'CTA URL',
            content: alert?.CTAUrl || '-',
        },
        {
            title: 'Closable',
            content: alert?.isClosable ? 'Yes' : 'No',
        },
    ];

    return (
        <CardDataBase
            title="Alert Detail"
            action={[
                <Button
                    type="link"
                    icon={(
                        <FaRegEdit
                            style={{ verticalAlign: 'middle' }}
                            size={16}
                            onClick={() => setUpdate(true)}
                        />
                    )}
                />,
            ]}
        >
            { open && (
                <WorkspacesList
                    open={open}
                    workspaceAlerts={workspaceAlerts}
                    setOpen={setOpen}
                    loadingReadAlert={loadingReadAlert}
                    markAsRead={markAsRead}
                />
            )}
            {
                update && (
                    <UpdateAlert
                        alertData={alert}
                        visible={update}
                        setVisible={setUpdate}
                        updateAlert={updateAlert}
                    />
                )
            }
            {
                CARD_CONTENT.map((data) => (
                    <Row align="middle" justify="space-between" style={{ margin: '16px 0' }}>
                        <Col>
                            <Text strong>
                                {data.title}
                                :
                            </Text>
                        </Col>
                        <Col>{data.content}</Col>
                    </Row>
                ))
            }
        </CardDataBase>
    );
};

export default AlertData;
