import { useContext, useEffect, useState } from 'react';
import { Button, PageHeader, Space } from 'antd';
import { Filters, InvoicesTable } from './components';
import { InvoicesContext } from './context/invoices.context';
import PaymentTransactions from '../Modals/PaymentTransactions/PaymentTransactions';
import { IInvoice } from './context/invoices.interface';
import { TransactionsModal } from './components/TransactionsModal';

const Invoices = ({ params }: { params: URLSearchParams }) => {
    const {
        fetchData,
        loading,
        loadingAction,
        data,
        item,
        total,
        paginator,
        onPageChange,
        searchParams,
        date,
        selectedRowKeys,
        setItem,
        setData,
        handleDate,
        handleSearch,
        handleInvoiceId,
        handleFrequency,
        handleGateway,
        handleStatus,
        setSelectedRowKeys,
    } = useContext(InvoicesContext);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetchData();
    }, [params, paginator]);

    const updateInvoice = (invoice: IInvoice) => {
        setData((invoices) => {
            const data = invoices.map((i) => {
                if (i._id === invoice._id) {
                    return invoice;
                }
                return i;
            });
            return data;
        });
    };

    return (
        <PageHeader
            title="Invoices"
            style={{ padding: '32px 24px 24px 0' }}
            extra={(
                <Button
                    type="primary"
                    onClick={() => setShowModal(true)}
                >
                    Bulk Transactions
                </Button>
            )}
            footer={(
                <Space direction="vertical" size={24} style={{ width: '100%' }}>
                    <PaymentTransactions
                        payment={item}
                        onClose={(invoice: IInvoice | null) => {
                            if (invoice) {
                                updateInvoice(invoice);
                            }
                            setItem(null);
                        }}
                    />

                    <Filters
                        searchParams={searchParams}
                        date={date}
                        handleDate={handleDate}
                        handleStatus={handleStatus}
                        handleSearch={handleSearch}
                        handleFrequency={handleFrequency}
                        handleGateway={handleGateway}
                        handleInvoiceId={handleInvoiceId}
                    />

                    <InvoicesTable
                        loading={loading}
                        data={data}
                        total={total}
                        paginator={paginator}
                        onPageChange={onPageChange}
                        setSelectedRowKeys={setSelectedRowKeys}
                        selectedRowKeys={selectedRowKeys}
                    />
                    <TransactionsModal visible={showModal} onCancel={() => setShowModal(false)} />
                </Space>
            )}
        />
    );
};

export default Invoices;
