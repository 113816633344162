import GoogleMapReact from 'google-map-react';
import { FC, useContext } from 'react';
import { CommandCenterContext } from '../../context/commandCenterContext';
import { googleMapsStyles } from './mapStyle';

type TCommandCenterMap = {
    children: any;
    setMapsLoaded: React.Dispatch<any>
}

const CommandCenterMap: FC<TCommandCenterMap> = ({
    children,
    setMapsLoaded,
}) => {
    const { currentLocation } = useContext(CommandCenterContext);

    const createMapOptions = (maps: any) => ({
        panControl: false,
        mapTypeControl: false,
        scrollwheel: true,
        styles: googleMapsStyles,
    });

    return (
        <div style={{ height: '68vh', width: '100%' }}>
            <GoogleMapReact
                options={createMapOptions}
                center={currentLocation.center}
                zoom={currentLocation.zoom}
                onGoogleApiLoaded={({ map, maps }: any) => {
                    setMapsLoaded({ map, maps });
                }}
            >
                {children}
            </GoogleMapReact>
        </div>
    );
};

export default CommandCenterMap;
