/* eslint-disable default-case */
import { last } from 'lodash';
import moment from 'moment';
import { BookingStatus } from '../types/booking';

export const getStatusChipClass = (status: string) => {
    switch (status) {
    case 'PENDING':
        return {
            color: 'lightgray',
            style: {
                color: 'black',
            },
        };
    case 'ACCEPTED':
    case 'ON_HOLD':
    case 'ON_DECK':
    case 'READY_FOR_PICKUP':
        return {
            color: 'lightblue',
            style: {
                color: 'blue',
            },
        };
    case 'ARRIVED_AT_PICKUP':
    case 'RETURNED_TO_PICKUP':
        return {
            color: 'orange',
            style: {
                color: 'orange',
            },
        };
    case 'WAY_TO_DELIVER':
    case 'ARRIVED_AT_DELIVERY':
    case 'RETURNING':
        return {
            color: '#ff4081',
            style: {
                color: 'white',
            },
        };
    case 'COMPLETED':
        return {
            color: '#57d9a3',
            style: {
                color: 'darkgreen',
            },
        };
    case 'CANCELLED_BY_ADMIN':
    case 'CANCELLED':
    case 'RETURNED':
        return {
            color: '#ff8f73',
            style: {
                color: 'black',
            },
        };
    }
};

export const getProofTitle = (status: string) => {
    switch (status) {
    case 'PHOTO':
        return 'Requires photo proof';
    case 'CODE':
        return 'Requires code proof';
    default:
        return 'No evidence required';
    }
};

export const getProofColor = (isAprroved: boolean) => {
    switch (isAprroved) {
    case true:
        return '#08d108';
    default:
        return '#FFFF00';
    }
};

export const millisToMinutesAndSeconds = (millis: number) => {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return `${minutes}:${parseInt(seconds, 10) < 10 ? '0' : ''}${seconds}`;
};

export const getElapsedTime = (booking: any) => {
    let elapsedTime = 0;
    switch (booking.currentStatus) {
    case BookingStatus.PENDING:
    case BookingStatus.ACCEPTED:
    case BookingStatus.ARRIVED_AT_PICKUP:
    case BookingStatus.WAY_TO_DELIVER:
    case BookingStatus.ARRIVED_AT_DELIVERY:
    case BookingStatus.ON_HOLD:
    case BookingStatus.ON_DECK:
    case BookingStatus.READY_FOR_PICKUP:
        elapsedTime = moment().diff(booking.createdAt, 'ms');
        break;
    case BookingStatus.COMPLETED:
        elapsedTime = moment(booking.bookingCompletionDateTime).diff(booking.createdAt, 'ms');
        break;

    case BookingStatus.CANCELLED:
    case BookingStatus.CANCELLED_BY_ADMIN:
    case BookingStatus.CANCELLED_BY_DRIVER:
    case BookingStatus.CANCELLED_REQUEST:
    case BookingStatus.REJECTED_BY_BUSINESS:
    case BookingStatus.REJECTED_BY_ADMIN:
    case BookingStatus.CANCELLED_BY_BUSINESS:
    case BookingStatus.BUSINESS_NOT_FOUND:
    case BookingStatus.NOT_SHOW:
        elapsedTime = moment(booking.cancellationDate).diff(booking.createdAt, 'ms');
        break;
    case BookingStatus.NO_PICKERS_IN_AREA:
    case BookingStatus.PAYMENT_FAILED:
        const lastStatus: any = last(booking.statusUpdates);
        if (lastStatus) {
            moment(lastStatus.timeStamp).diff(booking.createdAt, 'ms');
        }
        break;
    default:
        break;
    }
    return elapsedTime;
};

export const snapshotWarningServiceRequestData = ({
    bookingFromSocket,
    bookings,
    skip = 0,
}: any) => {
    if (!bookingFromSocket) {
        return bookings;
    }
    if (bookingFromSocket.newBooking && skip === 0) {
        const newBooking = {
            ...bookingFromSocket.booking,
            elapsedTime: getElapsedTime(bookingFromSocket.booking),
            distancePointA: 0,
            distancePointB: 0,
        };
        const currentData: any[] = bookings;
        currentData.unshift(newBooking);
        return currentData;
    }

    const updatedBookings = bookings.map((booking: any) => {
        if (booking._id === bookingFromSocket.booking._id) {
            const updatedBooking = {
                ...booking,
                ...bookingFromSocket.booking,
            };

            return updatedBooking;
        }
        return booking;
    });
    return updatedBookings;
};
