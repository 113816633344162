import {
    Modal, Form, Input, Select, Button,
} from 'antd';
import { useState, useEffect } from 'react';
import { addVariable, updateVariable } from '../../../api/payments';

interface IVariableModal {
  visible: boolean;
  variable: any;
  onCancel: (variable: any) => void;
}

const VariableModal = ({
    visible,
    variable,
    onCancel,
}: IVariableModal) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const handleOk = () => {
        setLoading(true);
        const values = form.getFieldsValue();
        if (variable._id) {
            updateVariable(variable._id, { ...values, planID: variable.planID })
                .then(({ data }) => {
                    form.resetFields();
                    onCancel(data.data);
                })
                .catch()
                .finally(() => setLoading(false));
        } else {
            addVariable({ ...values, planID: variable.planID })
                .then(({ data }) => {
                    form.resetFields();
                    onCancel(data.data);
                })
                .catch()
                .finally(() => setLoading(false));
        }
    };

    const handleCancel = () => {
        form.resetFields();
        onCancel(null);
    };

    useEffect(() => {
        if (!variable) return;
        form.setFieldsValue(variable);
    }, [variable, form]);

    return (
        <Modal
            title={variable?.name || 'Create Variable'}
            visible={visible}
            width={1000}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                    Crear
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item label="Name" name="name">
                    <Input placeholder="Variable" />
                </Form.Item>
                <Form.Item label="Type" name="type">
                    <Select placeholder="Select a type">
                        <Select.Option value="PERCENTAGE">Percentage</Select.Option>
                        <Select.Option value="FIXED">Fixed</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="Value" name="value">
                    <Input placeholder="10.0" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default VariableModal;
