import axios from 'axios';
import { BASE_URL } from '.';

export const getRegions = () => {
    const query = {
        limit: '100',
        skip: '0',
        isBlocked: 'false',
        isDeleted: 'false',
    };
    const queryParam = new URLSearchParams(query);
    return axios.get(`${BASE_URL}/region/listAllRegion?${queryParam.toString()}`);
};

export const addRegion = (data: any) => (
    axios.post(`${BASE_URL}/region/addRegion`, data)
);

export const editRegion = (data: any) => axios.put(`${BASE_URL}/region/editRegion`, data);
