import { types } from '../types/types';
import * as APILogin from '../api/login';

export const setUser = (user: any) => ({
    type: types.login,
    payload: user,
});

export const startLoadUser = (token:string) => async (dispatch: any) => {
    try {
        const { data: { data } } = await APILogin.loginWithToken(token);
        dispatch(setUser(data));
    } catch (error) {
        console.log(error);
    }
};
