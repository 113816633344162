import {
    Col, Modal, notification, Radio, Row, Table,
} from 'antd';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { BsFillPinFill } from 'react-icons/bs';
import { IoLocationSharp } from 'react-icons/io5';
import { RiBattery2ChargeFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from '../../../actions/modals';
import { assignManualRequest, getAllAvailableDriver } from '../../../api/drivers';
import { IDriverManualRequest } from '../../../types/driver';
import { ModalTypes } from '../../../types/modals';
import { getColorDriverConnection } from '../../../utils/driver';

const ManualRequest = () => {
    const dispatch = useDispatch();
    const { activeModal, modalData } = useSelector((state: any) => state.modals);

    const [loading, seLoading] = useState<boolean>(false);
    const [paginator, setPaginator] = useState({ limit: 10, skip: 0 });
    const [total, setTotal] = useState(0);
    const [userID, setUserID] = useState<null | string>(null);
    const [drivers, setDrivers] = useState<IDriverManualRequest[]>([]);

    const isOpen = activeModal === ModalTypes.ManualRequest;

    const fetchGetAllAvailableDriver = useCallback(() => {
        seLoading(true);

        getAllAvailableDriver({
            limit: paginator.limit,
            skip: paginator.skip,
            carType: modalData.carType,
            latitude: modalData?.pickupCoordinates?.coordinates[1],
            longitude: modalData?.pickupCoordinates?.coordinates[0],
            bookingID: modalData._id,
        })
            .then(({ data }: any) => {
                setTotal(data.count);
                setDrivers(data.data.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => seLoading(false));
    }, [modalData, paginator]);

    const handleCancel = () => {
        dispatch(closeModal());
        setUserID(null);
        setDrivers([]);
    };

    const onChange = (e: any) => {
        setUserID(e.target.value);
    };

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({ limit: pageSize, skip: pageSize * (page - 1) });
    };

    const handleOk = async () => {
        if (userID) {
            try {
                seLoading(true);
                await assignManualRequest({
                    bookingID: modalData._id,
                    userID,
                })
                    .then((res) => {
                        notification.success({
                            message: 'Notification sent to the driver',
                        });
                        handleCancel();
                    });
            } catch (error) {
                console.log(error);
                notification.error({
                    message: 'An error has occurred in the manual request',
                });
            } finally {
                seLoading(false);
            }
        }
    };

    useEffect(() => {
        if (!isOpen || !modalData) return;
        fetchGetAllAvailableDriver();
    }, [fetchGetAllAvailableDriver]);

    const columns = [
        {
            title: 'Select',
            dataIndex: 'userID',
            key: '_id',
            width: 100,
            fixed: 'left' as 'left',
            render: (_id: any) => (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Radio
                        onChange={onChange}
                        value={_id}
                        checked={userID === _id}
                    />
                </div>
            ),
        },
        {
            title: 'Driver Name',
            dataIndex: 'user',
            width: 150,
            fixed: 'left' as 'left',
            key: 'name',
            render: (user: any) => `${user.name} ${user.lastName}`,
        },
        {
            title: 'Email',
            dataIndex: 'user',
            width: 200,
            key: 'email',
            render: (user: any) => user.email,
        },
        {
            title: 'Phone',
            dataIndex: 'user',
            width: 200,
            key: 'mobile',
            render: (user: any) => `${user.countryCode}-${user.mobile}`,
        },
        {
            title: 'Battery Percentage',
            dataIndex: 'batteryPercentage',
            width: 200,
            key: 'batteryPercentage',
            render: (batteryPercentage: number) => (batteryPercentage ? `${batteryPercentage}%` : 'N/A'),
        },
        {
            title: 'Work Balance',
            dataIndex: 'workBalance',
            key: 'workBalance',
            width: 100,
            render: (batteryStatus: number) => batteryStatus,
        },
        {
            title: 'Vehicle',
            dataIndex: 'carType',
            width: 100,
            key: 'carType',
            render: (carType: number) => carType,
        },
        {
            title: 'Tag',
            dataIndex: 'tag',
            width: 150,
            key: 'tag',
            render: (tag: number) => tag,
        },
        {
            title: 'Last Update',
            dataIndex: 'user',
            width: 200,
            key: 'updatedAt',
            render: (user: any) => moment(user.updatedAt).format('HH:mm - DD MMM YYYY'),
        },
        {
            title: 'Connection',
            dataIndex: '',
            key: '_',
            width: 120,
            render: (driver: any) => (
                <Row justify="center" align="middle">
                    {driver
                        ? (
                            <>
                                <Col><RiBattery2ChargeFill color={getColorDriverConnection(driver, 'savingBattery')} size={18} /></Col>
                                <Col><BsFillPinFill color={getColorDriverConnection(driver, 'locationForeground')} size={18} /></Col>
                                <Col>
                                    {' '}
                                    <IoLocationSharp color={getColorDriverConnection(driver, 'locationBackground')} size={18} />
                                </Col>
                            </>
                        )
                        : <>N/A</>}
                </Row>
            ),
        },
    ];

    return (
        <Modal
            title="Manual Request"
            visible={isOpen}
            width="70%"
            onOk={handleOk}
            onCancel={handleCancel}
            confirmLoading={loading}
            okText="Ok"
            okButtonProps={{ disabled: !userID }}
        >
            <Table
                loading={loading}
                columns={columns}
                dataSource={drivers}
                rowClassName="booking-row"
                bordered
                scroll={{ x: 240, y: 300 }}
                rowKey="_id"
                pagination={{
                    total,
                    current: (paginator.skip / paginator.limit) + 1,
                    pageSize: paginator.limit,
                    onChange: onPageChange,
                    showSizeChanger: true,
                }}
            />
        </Modal>
    );
};

export default ManualRequest;
