import moment from 'moment';
import {
    Row,
    Col,
    DatePicker,
    Input,
    Select,
    Switch,
    Space,
    Typography,
} from 'antd';
import { IContext } from '../context/issues.interface';

const { RangePicker }: any = DatePicker;
const { Search } = Input;
const { Text } = Typography;

const Filters = ({
    searchParams,
    handleDate,
    handleWorkspace,
    handleBooking,
    handleStatus,
    handleIssueId,
    handleUnRead,
}: Partial<IContext>) => {
    const {
        issueId,
        status,
        workspaceId,
        numericId,
        hasNewMessages,
    } = searchParams;

    const STATUS = [
        { label: 'PENDING', value: 'PENDING' },
        { label: 'ACTIVE', value: 'ACTIVE' },
        { label: 'SUCCESS', value: 'SUCCESS' },
        { label: 'EXPIRED', value: 'EXPIRED' },
    ];

    return (
        <Row justify="start" gutter={[16, 24]}>
            <Col>
                {
                    handleDate && (
                        <RangePicker
                            onChange={(date: any = []) => {
                                if (date) {
                                    const [startDate, endDate] = date;
                                    handleDate([
                                        startDate.startOf('day').toISOString(),
                                        endDate.endOf('day').toISOString(),
                                    ]);
                                } else {
                                    handleDate([]);
                                }
                            }}
                        />
                    )
                }
            </Col>
            <Col>
                <Select
                    defaultValue={status}
                    mode="multiple"
                    style={{ width: '160px' }}
                    placeholder="Status"
                    onChange={handleStatus}
                    options={STATUS}
                    maxTagCount="responsive"
                />
            </Col>
            <Col>
                <Search
                    allowClear
                    enterButton
                    style={{ width: '350px' }}
                    placeholder="Search by workspace Unique ID"
                    defaultValue={workspaceId}
                    onSearch={handleWorkspace}
                />
            </Col>
            <Col>
                <Search
                    allowClear
                    enterButton
                    style={{ width: '350px' }}
                    placeholder="Search by booking numeric Id"
                    defaultValue={numericId}
                    onSearch={handleBooking}
                />
            </Col>
            <Col>
                <Search
                    allowClear
                    enterButton
                    placeholder="Issue ID"
                    defaultValue={issueId}
                    onSearch={handleIssueId}
                />
            </Col>
            <Col>
                <Space size={8} align="center">
                    <Text>New Messages</Text>
                    <Switch
                        defaultChecked={hasNewMessages}
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        onChange={handleUnRead}
                    />
                </Space>
            </Col>
        </Row>
    );
};

export default Filters;
