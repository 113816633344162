import { AlertsProvider } from '../components/Alerts/context/alerts.context';
import Alerts from '../components/Alerts';

const AlertsScreen = () => (
    <div style={{ margin: '24px 4px' }}>
        <AlertsProvider>
            <Alerts />
        </AlertsProvider>
    </div>
);

export default AlertsScreen;
