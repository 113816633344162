import {
    Col, Form, InputNumber, Modal, notification, Row,
} from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from '../../../actions/modals';
import { setActive } from '../../../actions/ride';
import { updatePriceBreakup } from '../../../api/booking';
import { ModalTypes } from '../../../types/modals';

const requiredRule = { required: true, message: 'Required' };

export const EditPriceBreakupModal = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { activeModal } = useSelector((state: any) => state.modals);
    const { active } = useSelector((state: any) => state.ride);
    const isOpen = activeModal === ModalTypes.EditPriceBreakup;
    const handleCancel = () => dispatch(closeModal());

    const onFinish = async (values: any) => {
        setLoading(true);
        try {
            const { data } = await updatePriceBreakup(active._id, values);
            notification.success({ message: 'Success', description: 'Price Breakup updated successfully' });
            dispatch(setActive(data.data));
            handleCancel();
        } catch (error: any) {
            console.log({ error });
            const message = error.response?.data?.message;
            notification.error({
                message,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!active) return;
        form.setFieldsValue({
            ...active.priceBreakUp,
        });
    }, [active]);

    const INPUTS = [
        { label: 'Referral Discount', name: 'referralDiscount' },
        { label: 'Base Fare', name: 'baseFare' },
        { label: 'Per Km Charge', name: 'perKmCharge' },
        { label: 'Per Min Charge', name: 'perMinCharge' },
        { label: 'Promo Discount', name: 'promoDiscount' },
        { label: 'Order Amount', name: 'orderAmount' },
        { label: 'Comission', name: 'comission' },
    ];
    return (
        <Modal
            title="Edit Price Breakup"
            visible={isOpen}
            onOk={() => form.submit()}
            onCancel={handleCancel}
            confirmLoading={loading}
        >
            <Form
                form={form}
                onFinish={onFinish}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={12}>
                    {INPUTS.map((input) => (
                        <Col key={input.name} xs={24} md={12}>
                            <Form.Item label={input.label} name={input.name} rules={[requiredRule]} key={input.name}>
                                <InputNumber placeholder={input.label} />
                            </Form.Item>
                        </Col>
                    ))}
                </Row>
            </Form>
        </Modal>
    );
};
