import {
    Card, Form, Input, Modal, notification, Select,
} from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setEditWorkspace } from '../../../actions/modals';
import { updateWorkspaceSettings } from '../../../api/workspaces';

export const ChangePaymentWorkspacesModal = ({ reload, setReload }: any) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { workspace } = useSelector((state: any) => state.modals);

    const OPTIONS = [
        { value: 'CASH', label: 'Cash' },
        { value: 'CARD', label: 'Card' },
        { value: 'TERMINAL', label: 'Terminal' },
    ];

    const handleOk = (values: any) => {
        setLoading(true);
        updateWorkspaceSettings(workspace._id, values)
            .then(({ data }) => {
                notification.success({
                    message: data.message,
                });
            })
            .catch((err) => {
                console.log(err);
                notification.error({ message: 'oops! An error has occurred' });
            })
            .finally(() => {
                setLoading(false);
                dispatch(setEditWorkspace(null));
                setReload(!reload);
            });
    };

    const handleCancel = () => {
        dispatch(setEditWorkspace(null));
    };

    useEffect(() => {
        if (!workspace) return;
        form.setFieldsValue({
            name: workspace.name,
            paymentMethod: workspace.payment?.paymentMethod,
        });
    }, [workspace]);

    return (
        <Modal
            title="Edit Workspace"
            visible={workspace}
            onOk={() => form.submit()}
            onCancel={handleCancel}
            confirmLoading={loading}
            bodyStyle={{ padding: 0 }}
        >
            <Card bordered={false}>
                <Form
                    form={form}
                    onFinish={handleOk}
                    layout="vertical"
                    requiredMark={false}
                >
                    <Form.Item label="Avaible Methods" name="availableMethods">
                        <Select
                            defaultValue={workspace?.settings?.availableMethods || []}
                            style={{ width: '100%' }}
                            mode="multiple"
                            options={OPTIONS}
                        />
                    </Form.Item>
                </Form>
            </Card>
        </Modal>
    );
};
