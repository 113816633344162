const socketEvents = {
    BOOKING_SEND_TO_ADMIN: 'booking_send_to_admin',
    DISCONNECT: 'disconnect',
    CONNECT: 'connect',
    ADMIN_JOIN: 'event_join_admin',
    SEND_MESSAGE: 'SEND_MESSAGE',
    READ_MESSAGE: 'READ_MESSAGE',
    NOTIFICATION: 'NOTIFICATION',
    PICKER_CHAT_NEW_MESSAGE: 'picker-chat-new_message',
    PICKER_CHAT_JOIN_CHAT: 'picker-chat-join',
    PICKER_CHAT_LEAVE_CHAT: 'picker-chat-leave',
    NOTIFICATION_SEND_TO_ADMIN: 'notification_send_to_admin',
};

export default socketEvents;
