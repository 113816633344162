import * as plansAPI from '../api/plans';
import { types } from '../types/types';

export const setPlans = (plans: any) => ({
    type: types.plansLoad,
    payload: [...plans.data],
});

export const startLoading = () => ({
    type: types.plansStartLoading,
});

export const finishLoading = () => ({
    type: types.plansFinishLoading,
});

export const startLoadPlans = () => async (dispatch: any) => {
    try {
        dispatch(startLoading());
        const { data } = await plansAPI.getPlans();
        const plans = data.data;
        dispatch(setPlans(plans));
        dispatch(finishLoading());
    } catch (error) {
        console.log(error);
    }
};
