import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { useEffect } from 'react';
import { useQuery } from '../hooks/useQuery';
import { startLoadRide } from '../actions/ride';
import { BookingType } from '../types/booking';
import { BookingContainer } from '../components/BookingDetails/Container';

export const Booking = () => {
    const query = useQuery();
    const bookingId = query.get('id');
    const dispatch = useDispatch();
    const { active, events } = useSelector((state: RootStateOrAny) => state.ride);
    const { loading } = useSelector((state: RootStateOrAny) => state.ui);

    useEffect(() => {
        if (!bookingId) return;
        dispatch(startLoadRide(bookingId));
    }, [bookingId, dispatch]);

    if (loading) {
        return (
            <div style={{ margin: '50px auto', width: 100 }}>
                <Spin size="large" />
            </div>
        );
    }
    if (!active) return null;
    const booking = new BookingType(active);
    return (
        <BookingContainer booking={booking} events={events} />
    );
};
