import { Space, Table } from 'antd';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IObjection } from '../../../interfaces/objection.interface';
import { ObjectionsContext } from '../context/objectionsContext';
import { InitialColumns } from './inititalColumns';
import ObjectionDetail from './Detail';
import Filters from './Filters';

interface Props {
    params: any;
}

const ObjectedRequests = ({ params }: Props) => {
    const INITIAL_COLUMNS: any = InitialColumns();

    const {
        loading,
        total,
        paginator,
        searchParams,
        statusString,
        objections,
        objectionSelected,
        onPageChange,
        handleStatus,
        handleWorkspace,
        handleObjection,
        setShowDetails,
        setObjectionSelected,
        fetchObjections,
    } = useContext(ObjectionsContext);

    const { limit, page } = paginator;
    const { workspaceUniqueID, objectionPublicID } = searchParams;

    const { id } = useParams();

    const defaultWorkspaceUniqueID = params.get('workspaceUniqueID');
    const defaultObjectionPublicID = params.get('objectionPublicID');

    useEffect(() => {
        fetchObjections();
    }, [paginator, statusString, defaultWorkspaceUniqueID, workspaceUniqueID, defaultObjectionPublicID, objectionPublicID, searchParams]);

    useEffect(() => {
        if (id) {
            const objection = objections.find((obj) => obj.objectionPublicID === id);
            if (objection) {
                setObjectionSelected(objection);
                setShowDetails(true);
            }
        }
    }, [id, objections, setObjectionSelected, setShowDetails]);

    return (
        <Space direction="vertical" size={24} style={{ width: '100%' }}>
            <ObjectionDetail />
            <Filters
                defaultWorkspaceUniqueID={defaultWorkspaceUniqueID}
                defaultObjectionPublicID={defaultObjectionPublicID}
                searchParams={searchParams}
                handleStatus={handleStatus}
                handleWorkspace={handleWorkspace}
                handleObjection={handleObjection}
            />
            <Table
                loading={loading}
                columns={INITIAL_COLUMNS}
                dataSource={objections}
                pagination={{
                    total,
                    current: page,
                    pageSize: limit,
                    onChange: onPageChange,
                    showSizeChanger: true,
                }}
                bordered
                scroll={{ x: 1500 }}
                onRow={(record: IObjection) => ({
                    onClick: () => {
                        setObjectionSelected(record);
                        setShowDetails(true);
                    },
                })}
                rowClassName={(record: IObjection) => (record?._id === objectionSelected?._id ? 'table-row-warning' : '')}
            />
        </Space>
    );
};

export default ObjectedRequests;
