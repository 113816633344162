import cs from 'classnames';
import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { IContext } from '../context/summary.interface';
import { ESearchType } from '../context/summary.enum';

interface Props {
    setResults: IContext['setResults'];
    searchParams: URLSearchParams;
    setSearchParams: (params: any) => void;
}

const { Title, Text } = Typography;

const SearchType = ({ ...props }: Props) => {
    const navigate = useNavigate();
    const { setResults, searchParams, setSearchParams } = props;

    const searchType = searchParams.get('searchType') || ESearchType.WORKSPACE;
    const search = searchParams.get('search') || '';

    const options: string[] = [
        ESearchType.WORKSPACE,
        ESearchType.BOOKING,
    ];

    return (
        <div className="flex-column center-column-center">
            <Title className="text-logo no__margin" level={1}>
                Picker
            </Title>
            <div
                style={{ margin: '40px 0', gap: 32 }}
                className="flex-row center-row-center full-width"
            >
                {
                    options.map((item) => (
                        <div
                            key={item}
                            className={cs('main-search__type', { active: searchType === item })}
                            onClick={() => {
                                setResults([]);
                                setSearchParams({
                                    search,
                                    searchType: item,
                                });
                            }}
                        >
                            <Text>
                                {item}
                            </Text>
                        </div>
                    ))
                }
                <div
                    className="main-search__type"
                    onClick={() => navigate('/v2/chat')}
                >
                    <Text>
                        JARVIS
                    </Text>
                </div>
            </div>
        </div>
    );
};

export default SearchType;
