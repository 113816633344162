import {
    Button, Card, Col, Form, Input, notification, Row, Select,
} from 'antd';
import { useEffect, useState } from 'react';

import { createAlert, getWorkspacesV2 } from '../api/workspaces';

const { TextArea } = Input;

export const WorkspacesNotifications = () => {
    const [workspaces, setWorkspaces] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getWorkspacesV2()
            .then((d) => setWorkspaces(d.data.body))
            .catch((e) => console.log(e))
            .finally(() => setLoading(false));
    }, []);

    const handleSubmit = async (values: any) => {
        setLoading(true);
        try {
            const { data }: any = await createAlert({
                ...values,
                type: 'ACCOUNT',
            });
            notification.success({
                message: data.message,
            });
        } catch (error: any) {
            const message = error.response?.data?.message;
            notification.error({
                message,
            });
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Row justify="center" style={{ marginTop: 40 }}>
            <Col>
                <Card>
                    <Form onFinish={handleSubmit} onFinishFailed={(e) => console.log(e)} layout="vertical">
                        <Form.Item name="workspaceID" label="Workspace">
                            <Select
                                size="large"
                                style={{ width: '100%' }}
                                showSearch
                                filterOption={(input, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                loading={loading}
                                placeholder="Workspace de Pizza Hut"
                            >
                                {workspaces.map((workspace: any) => (
                                    <Select.Option key={workspace._id} value={workspace._id} label={workspace.name}>
                                        {workspace.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name="subType" label="Tipo" initialValue="PAYMENT_METHOD">
                            <Select
                                size="large"
                                style={{ width: '100%' }}
                                options={[
                                    { value: 'PAYMENT_METHOD', label: 'Método de pago' },
                                    { value: 'BILLING_DATA', label: 'Facturación' },
                                ]}
                            />
                        </Form.Item>
                        <Form.Item name="message" label="Mensaje">
                            <TextArea
                                size="large"
                                placeholder="Por condiciones climaticas es posible que los pedidos tengan retraso..."
                            />
                        </Form.Item>
                        <Row gutter={20}>
                            <Col xs={24} sm={12}>
                                <Form.Item name="CTAText" label="Acción">
                                    <Input
                                        size="large"
                                        placeholder="Ir a pagar"
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12}>
                                <Form.Item name="CTAUrl" label="URL">
                                    <Input
                                        size="large"
                                        placeholder="picker.com/pagar-plan"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {/* <Form.Item name="isClosable" valuePropName="checked">
              <Checkbox>¿Debe realizar alguna acción para que desaparezca la notificación?</Checkbox>
            </Form.Item> */}
                        <Row justify="end">
                            <Col>
                                <Form.Item>
                                    <Button htmlType="submit" type="primary" size="large" loading={loading}>
                                        Enviar notificación
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};
