import axios from 'axios';
import { BASE_URL } from './booking';

export const login = (payload: any) => axios.post(`${BASE_URL}/user/login`, payload);

export const loginWithToken = (token: string) => axios.post(
    `${BASE_URL}/user/accessTokenLogin`,
    { deviceType: 'WEB' },
    {
        headers: {
            authorization: `Bearer ${token}`,
        },
    },
);
