import { Menu } from 'antd';
import { useContext } from 'react';
import { TDeliveryProviders, TTier } from '../../../../../types/faresWorker';
import { TiersConfigurationContext } from '../../../context/tiersConfigurationContext';

interface MenuActionsProps {
  from: 'tier' | 'deliveryProvider'
  data: TTier | TDeliveryProviders
}

const MenuActions = ({ from, data }: MenuActionsProps) => {
    const { updateActionModal } = useContext(TiersConfigurationContext);

    const menuElement = () => {
        const menu = [
            {
                children: 'Edit Tier',
                onClick: () => {
                    updateActionModal({
                        active: true,
                        data: {
                            action: 'edit',
                            type: from,
                            content: data,
                        },
                    });
                },
            },
        ];

        if (from === 'tier') {
            menu.push({
                children: 'Delete Tier',
                onClick: () => {
                    updateActionModal({
                        active: true,
                        data: {
                            action: 'delete',
                            type: from,
                            content: data,
                        },
                    });
                },
            });
        }

        return menu;
    };

    return (
        <Menu>
            <Menu.ItemGroup key="g">
                {menuElement().map((o, i) => (
                    <Menu.Item key={i} {...o} />
                ))}
            </Menu.ItemGroup>
        </Menu>
    );
};

export default MenuActions;
