/* eslint-disable react/destructuring-assignment */
import {
    Avatar, Col, Dropdown, Menu, notification, PageHeader, Row, Table, Tag, Tooltip, Select, Space,
} from 'antd';
import {
    useContext, useEffect, useMemo, useState,
} from 'react';
import {
    BsCash, BsCheck2Circle, BsCreditCard2Back, BsThreeDots,
} from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import { isEqual, isUndefined } from 'lodash';
import moment from 'moment';

import {
    openModal, setChangeStatus, setDriverManuallylId, setRestartSearchId, setRidesAddComment,
} from '../actions/modals';
import { getWarningBookings, SMR_URL, assignResponsable } from '../api/booking';
import { endSearch } from '../api/deliveryProviders';
import {
    AssigningDriverManually, CancelService, ChangeStatusModal, RestartSearchModal, CommentModal,
} from '../components/Modals';
import { PROVIDERS } from '../const/providers';
import { getStatusChipClass, snapshotWarningServiceRequestData } from '../utils/bookings';
import { ModalTypes } from '../types/modals';
import { ChangePaymentMethod } from '../components/Modals/ChangePaymentMethod';
import { getAllAdmin } from '../api/admin';
import { Responsable } from '../types/responsable';
import { SocketClientContext } from '../context/SocketClientContext';
import socketEvents from '../const/socketEvent';
import { ECurrency } from '../types/payment';
import { IMAGES } from '../const/images';
import { BookingType } from '../types/booking';
import { BookingDetailsDrawer } from '../components/BookingDetails/BookingDetailsDrawer';
import { DropDownButtonRefresh } from '../components/DropDownButtonRefresh';
import { TableBooking } from '../components/TableBooking';

const { Option } = Select;

const WarningServiceRequestsMenu = ({
    booking,
    loading,
    setLoading,
    fetchGetWarningBookings,
}: any) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleAssingResponsable = async () => {
        const accessToken = window.localStorage.getItem('token');
        if (!accessToken) return notification.error({ message: 'You need to log in to perform this action' });

        const decode: any = jwt_decode(accessToken);
        if (!decode.userID) return null;

        try {
            setLoading(true);
            await assignResponsable(booking._id, decode.userID);
            notification.success({
                message: 'Success assined responsable',
            });
            fetchGetWarningBookings();
        } catch (error) {
            console.log(error);
            notification.error({
                message: 'Opps!',
                description: 'An Error ocurred',
            });
        } finally {
            setLoading(false);
        }

        return null;
    };

    const isChangePaymentMethod = [
        'ACCEPTED',
        'ENROUTE_TO_PICKUP',
        'PICKED_UP',
        'ENROUTE_TO_DELIVERY',
        'DELIVERED',
        'COMPLETED',
    ];

    const menu = [
        {
            children: 'Assign Picker Manually',
            onClick: () => {
                dispatch(setDriverManuallylId(booking));
            },
        },
        {
            children: 'Change Status',
            onClick: () => {
                dispatch(setChangeStatus(booking));
            },
        },
        {
            children: 'Add comment',
            onClick: () => {
                dispatch(setRidesAddComment(booking));
            },
        },
        {
            children: 'End Search',
            onClick: () => {
                endSearch(booking._id, { isAdmin: true })
                    .then((res: any) => notification.success({
                        message: 'Success!',
                        description: `${res.data.message}`,
                    }))
                    .catch(() => notification.error({
                        message: 'Opps!',
                        description: 'An Error ocurred',
                    }));
            },
        },
        {
            children: 'Share My Ride',
            onClick: () => {
                window.open(`${SMR_URL}/#?token=${booking.smrToken}`);
            },
        },
        {
            children: 'Set Hack',
            onClick: () => {
                navigate(`/v2/booking?id=${booking._id}`);
            },
        },
        {
            children: 'Restart Search',
            onClick: () => {
                dispatch(setRestartSearchId(booking));
            },
        },

        {
            children: 'Assign responsable',
            onClick: () => handleAssingResponsable(),
        },
        {
            children: 'Manual request',
            onClick: () => {
                dispatch(openModal(ModalTypes.ManualRequest, booking));
            },
        },
        {
            children: 'Cancel service',
            onClick: () => {
                dispatch(openModal(ModalTypes.CancelService, booking));
            },
        },
        {
            children: 'Change payment method',
            onClick: () => {
                dispatch(openModal(ModalTypes.ChangePaymentMethod, booking));
            },
            disabled: !isChangePaymentMethod.includes(booking.statusText),
        },
        {
            children: 'Report issue',
            onClick: () => {
                dispatch(openModal(ModalTypes.ReportIssue, booking));
            },
        },
    ];

    return (
        <Menu disabled={loading}>
            <Menu.ItemGroup key="g">
                {menu.map((o, i) => (
                    <Menu.Item key={i} {...o} />
                ))}
            </Menu.ItemGroup>
        </Menu>
    );
};

export const WarningServiceRequests = () => {
    const socketInstance = useContext(SocketClientContext);
    const [warninBookings, setwarninBookings] = useState<any>([]);
    const [bookingFromSocket, setBookingFromSocket] = useState<any>();
    const [total, setTotal] = useState(0);
    const [paginator, setPaginator] = useState({ limit: 10, skip: 0 });
    const [loading, setloading] = useState(false);
    const [responsables, setResponsables] = useState<Responsable[]>([]);
    const [responsableId, setResponsableId] = useState<string>();
    const [currency, setCurrency] = useState<ECurrency>();
    const [bookingDetails, setBookingDetails] = useState<BookingType | null>(null);
    const closeDrawer = () => setBookingDetails(null);
    const fetchGetWarningBookings = () => {
        setloading(true);

        const { limit, skip } = paginator;
        const query = {
            limit,
            skip,
            ...(responsableId ? { adminResponsableID: responsableId } : {}),
            ...(currency ? { currency } : {}),
            startDate: moment().startOf('day').format(),
            endDate: moment().endOf('day').format(),
        };

        getWarningBookings(query)
            .then(({ data }) => {
                setwarninBookings(data.data.bookingData);
                setTotal(data.data.dataCount);
            })
            .catch((error) => console.log(error))
            .finally(() => setloading(false));
    };

    useEffect(() => {
        fetchGetWarningBookings();
    }, [paginator, responsableId, currency]);

    useEffect(() => {
        socketInstance.on(socketEvents.BOOKING_SEND_TO_ADMIN, (payload) => {
            setBookingFromSocket(payload);
        });

        return () => {
            socketInstance.off(socketEvents.BOOKING_SEND_TO_ADMIN);
        };
    }, []);

    const bookings = useMemo(() => {
        const snapshot = snapshotWarningServiceRequestData({
            bookingFromSocket,
            bookings: warninBookings,
        });

        if (isEqual(snapshot, warninBookings)) return warninBookings;

        setwarninBookings(snapshot);

        return snapshot;
    }, [warninBookings, bookingFromSocket]);

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({ limit: pageSize, skip: pageSize * (page - 1) });
    };

    const MILISECONDS_TO_MINUTE = 60000;

    const columns: any = [
        {
            title: 'Responsable',
            dataIndex: 'responsable',
            key: 'responsable',
            width: 100,
            ellipsis: true,
        },
        {
            title: 'Service ID',
            dataIndex: 'bookingNumericId',
            key: 'bookingNumericId',
            width: 125,
            render: (bookingNumericId: string, booking: any) => (
                <Link style={{ color: '#08d108' }} to={`/v2/booking?id=${booking._id}`}>
                    {bookingNumericId}
                </Link>
            ),
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
            ellipsis: true,
            width: 125,
        },
        {
            title: 'Driver Name',
            dataIndex: 'driverName',
            key: 'driverName',
            ellipsis: true,
            width: 125,
            render: (driverName: string, booking: any) => {
                const isOnBatch = !isUndefined(booking.isActiveOnBatch) && !booking.isActiveOnBatch;
                return (
                    <Space size={5}>
                        {isOnBatch && (
                            <Tooltip title="Driver has another booking in progress">
                                <Avatar
                                    src={IMAGES.icons.batch}
                                    shape="circle"
                                    size={20}
                                />
                            </Tooltip>
                        )}
                        {!booking.driver ? driverName : (
                            <Link to={`/v2/driver-details/${booking.driver._id}`}>{driverName}</Link>
                        )}
                    </Space>
                );
            },
        },
        {
            title: 'DP',
            key: 'deliveryProvider',
            dataIndex: 'deliveryProvider',
            width: 65,
            render: (provider: string) => (
                <Tooltip title={(PROVIDERS as any)[provider]?.label || ''}>
                    <Avatar src={(PROVIDERS as any)[provider]?.image || ''} />
                </Tooltip>
            ),
        },
        {
            title: 'Hacked',
            dataIndex: 'isWithHack',
            key: 'isWithHack',
            width: 90,
            render: (isWithHack: boolean) => (
                isWithHack ? (
                    <div style={{ textAlign: 'center' }}>
                        <BsCheck2Circle size={28} color="green" />
                    </div>
                ) : (null)
            ),
        },
        {
            title: 'Business Name',
            dataIndex: 'businessName',
            key: 'businessName',
            ellipsis: true,
            width: 125,
        },
        {
            title: 'Region',
            dataIndex: 'regionName',
            key: 'regionName',
            ellipsis: true,
            width: 125,
        },
        {
            title: 'Distance to',
            children: [
                {
                    title: 'A',
                    dataIndex: 'distancePointA',
                    key: 'distancePointA',
                    width: 70,
                    render: (distance: number) => (
                        <div style={{ fontSize: 12, textAlign: 'center' }}>
                            {`${distance.toFixed(2)} km`}
                        </div>
                    ),
                },
                {
                    title: 'B',
                    dataIndex: 'distancePointB',
                    key: 'distancePointB',
                    width: 70,
                    render: (distance: number) => (
                        <div style={{ fontSize: 12, textAlign: 'center' }}>
                            {`${distance.toFixed(2)} km`}
                        </div>
                    ),
                },
            ],
        },
        {
            title: 'Time',
            dataIndex: 'elapsedTime',
            key: 'elapsedTime',
            width: 100,
            ellipsis: true,
            render: (time: number, booking: any) => {
                const minutes = Math.ceil(time / MILISECONDS_TO_MINUTE);
                if (booking.statusText === 'ON_HOLD') {
                    return (
                        <div>
                            {`${(booking.cookTime / MILISECONDS_TO_MINUTE) - minutes} min`}
                        </div>
                    );
                }
                const isGreen = minutes < 6 || booking.statusText === 'COMPLETED';
                const color = isGreen ? '#08d108' : minutes < 11 ? 'orange' : 'red';
                return (
                    <div style={{ color }}>
                        {`${Math.ceil(time / MILISECONDS_TO_MINUTE)} min`}
                    </div>
                );
            },
        },
        {
            title: 'Business',
            dataIndex: 'businessName',
            key: 'businessName',
            ellipsis: true,
        },
        {
            title: 'Order',
            dataIndex: 'orderAmount',
            key: 'orderAmount',
            width: 120,
            render: (amount: number) => `$${amount.toFixed(2)}`,
        },
        {
            title: 'Curr',
            dataIndex: 'currency',
            key: 'currency',
            width: 70,
        },
        {
            title: 'Paym.',
            dataIndex: 'paymentMethod',
            key: 'paymentMethod',
            width: 80,
            render: (paymentMethod: string) => (
                <Tooltip placement="topLeft" title={paymentMethod === 'CASH' ? 'Efectivo' : 'Tarjeta'}>
                    {paymentMethod === 'CASH' ? <BsCash color="#08d108" size={20} /> : <BsCreditCard2Back color="#00beff" size={20} />}
                </Tooltip>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'statusText',
            key: 'statusText',
            width: 200,
            render: (status: string) => (
                <Tag {...getStatusChipClass(status)}>{status}</Tag>
            ),
        },
        {
            title: 'Tag',
            dataIndex: 'tag',
            key: 'tag',
            ellipsis: true,
            width: 80,
            render: () => (
                <div style={{ textAlign: 'center' }}>
                    N/A
                </div>
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'statusText',
            key: 'actions',
            width: 100,
            fixed: 'right' as 'right',
            // eslint-disable-next-line react/display-name
            render: (_statusText: any, record: any) => (
                <Dropdown
                    placement="bottomRight"
                    overlay={(
                        <WarningServiceRequestsMenu
                            booking={record}
                            loading={loading}
                            setLoading={setloading}
                            fetchGetWarningBookings={fetchGetWarningBookings}
                        />
                    )}
                    // @ts-ignore
                    onClick={(e: any) => { e.stopPropagation(); }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            <BsThreeDots size={25} />
                        </a>
                    </div>
                </Dropdown>
            ),
        },
    ];

    const onChangeCurrency = (currency: ECurrency) => {
        setCurrency(currency);
    };

    const onChangeResponsable = (responsableId: string) => {
        setResponsableId(responsableId);
    };

    const fetchGetAdmin = () => {
        getAllAdmin()
            .then(({ data }) => {
                setResponsables(data.data.users);
            })
            .catch((error) => console.log(error));
    };

    useEffect(() => {
        fetchGetAdmin();
    }, []);
    const options = responsables?.map((responsable: Responsable) => (
        <Option key={responsable._id} value={responsable._id}>
            {responsable.name}
        </Option>
    ));

    return (
        <div>
            <BookingDetailsDrawer booking={bookingDetails} close={closeDrawer} />
            <PageHeader
                title="Warning Service Requests"
            />
            <Row justify="end" gutter={16}>
                <Col>
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Currency"
                        optionFilterProp="children"
                        onChange={onChangeCurrency}
                        filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().startsWith(input)}
                        allowClear
                    >
                        {Object.keys(ECurrency).map((currency: string) => (
                            <Option key={currency} value={currency}>
                                {currency}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col>

                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Responsable"
                        optionFilterProp="children"
                        onChange={onChangeResponsable}
                        filterOption={(input, option) => (option!.children as unknown as string || '').toLowerCase().startsWith(input)}
                        allowClear
                    >
                        {options}
                    </Select>
                </Col>
                <Col>
                    <DropDownButtonRefresh getBookingsData={fetchGetWarningBookings} />
                </Col>
            </Row>
            <br />
            <TableBooking initialColumns={columns} bookings={bookings} />
            <ChangePaymentMethod onSucess={fetchGetWarningBookings} />
        </div>
    );
};
