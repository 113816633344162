import { Card, Image, Typography } from 'antd';
import { AiOutlineCloudDownload } from 'react-icons/ai';

interface Props {
    url: string;
    index: number;
}

const { Text } = Typography;

const Attachment = ({ url, index }: Props) => {
    const fileName = `File ${index}`;

    const downloadImage = (url: string, filename: string) => {
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.setAttribute('download', filename);
        a.click();
    };

    return (
        <Card
            hoverable
            bordered
            cover={(
                <Image
                    src={url}
                    preview={{ src: url }}
                />
            )}
        >
            <Card.Meta
                title={(
                    <Text>
                        {fileName}
                    </Text>
                )}
                description={(
                    <AiOutlineCloudDownload
                        size={22}
                        color="#00B2E3"
                        onClick={() => downloadImage(url, fileName)}
                    />
                )}
            />
        </Card>
    );
};

export default Attachment;
