import cs from 'classnames';
import moment from 'moment';
import { Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { IoMdOpen } from 'react-icons/io';
import { IObjection } from '../../../interfaces/objection.interface';

const { Text } = Typography;

export const InitialColumns = () => {
    const navigate = useNavigate();

    const handleBooking = (bookingNumericId: string) => bookingNumericId && navigate(`/v2/booking?id=${bookingNumericId}`);

    const handleWorkspace = (workspaceID: string) => workspaceID && navigate(`/v2/workspaces/${workspaceID}`);

    const INITIAL_COLUMNS = [
        {
            title: 'Date & Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string) => {
                const date = moment(createdAt).format('dddd, D MMM');
                const time = moment(createdAt).format('h:mm a');
                return (
                    <Space direction="vertical" size={4}>
                        <Text>
                            {date}
                        </Text>
                        <Text>
                            {time}
                        </Text>
                    </Space>
                );
            },
        },
        {
            title: 'Objection ID',
            dataIndex: 'objectionPublicID',
            key: 'objectionPublicID',
        },
        {
            title: 'Booking Associated',
            dataIndex: 'bookingNumericID',
            key: 'bookingNumericID',
            render: (_: any, objection: IObjection) => (
                <Space
                    size={8}
                    direction="horizontal"
                    onClick={() => handleBooking(objection?.issueID?.bookingID)}
                    style={{ cursor: 'pointer' }}
                >
                    <Text style={{ color: '#00B2E3' }}>
                        {`# ${objection?.issueID?.bookingNumericId}` || 'Not available'}
                    </Text>
                    <IoMdOpen
                        color="#00B2E3"
                        size={16}
                        style={{ verticalAlign: 'middle' }}
                    />
                </Space>
            ),
        },
        {
            title: 'Booking Creation Date',
            dataIndex: 'bookingCreationDate',
            key: 'bookingCreationDate',
            render: (bookingCreationDate: string) => {
                const date = moment(bookingCreationDate).format('dddd, D MMM');
                const time = moment(bookingCreationDate).format('h:mm a');
                return (
                    <Space direction="vertical" size={4}>
                        <Text>
                            {date}
                        </Text>
                        <Text>
                            {time}
                        </Text>
                    </Space>
                );
            },
        },
        {
            title: 'Workspace ID',
            dataIndex: 'workspaceUniqueID',
            key: 'workspaceUniqueID',
            render: (_: any, objection: IObjection) => (
                <Space
                    size={8}
                    direction="horizontal"
                    onClick={() => handleWorkspace(objection?.issueID?.workspaceID)}
                    style={{ cursor: 'pointer' }}
                >
                    <Text style={{ color: '#00B2E3' }}>
                        {objection?.issueID?.workspaceUniqueID || 'Not available'}
                    </Text>
                    <IoMdOpen
                        color="#00B2E3"
                        size={16}
                        style={{ verticalAlign: 'middle' }}
                    />
                </Space>
            ),
        },
        {
            title: 'Issue Associated',
            dataIndex: 'issuePublicID',
            key: 'issuePublicID',
            render: (_: any, objection: IObjection) => (
                <Space
                    size={8}
                    direction="horizontal"
                >
                    <Text>
                        {objection?.issueID?.issuePublicID || 'Not available'}
                    </Text>
                </Space>
            ),
        },
        {
            title: 'Objection Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => (
                <Text className={cs(status, 'status')}>
                    {status}
                </Text>
            ),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (amount: number) => (
                <Text>
                    {`${amount.toFixed(2)}`}
                </Text>
            ),
        },
    ];

    return INITIAL_COLUMNS;
};
