import axios from 'axios';
import { OCTOPUS_BASE_URL } from '.';
import { TRegion, TSuperRegion } from '../types/faresWorker';

const getGlobalTiers = () => axios.get(`${OCTOPUS_BASE_URL}/providers-configuration/global-tiers`);

const getAllRegions = () => axios.get(`${OCTOPUS_BASE_URL}/fares-worker/region`);

const getAllSuperRegions = () => axios.get(`${OCTOPUS_BASE_URL}/fares-worker/super-region`);

const updateRegion = (data: TRegion) => axios.put(`${OCTOPUS_BASE_URL}/fares-worker/region/${data._id}`, data);

const updateSuperRegion = (data: TSuperRegion) => axios.put(`${OCTOPUS_BASE_URL}/fares-worker/super-region/${data._id}`, data);

export {
    getGlobalTiers,
    getAllRegions,
    getAllSuperRegions,
    updateRegion,
    updateSuperRegion,
};
