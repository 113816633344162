/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-no-useless-fragment */
import { Popover } from 'antd';
import { AiFillShop } from 'react-icons/ai';

type TBusinessMarker = {
    lat: number;
    lng: number;
    name: string;
}

const BusinesMarker = ({
    name,
}:TBusinessMarker) => (
    <Popover content={<>{name}</>}>
        <AiFillShop
            color="#10a4cd"
            size={35}
        />
    </Popover>
);

export default BusinesMarker;
