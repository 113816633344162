import {
    Card as AntdCard, Col, Row, Space,
} from 'antd';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { RootStateOrAny, useSelector } from 'react-redux';

import { DeliveryType } from '../../../types/delivery';
import { TierTime } from './TierTime';

export interface DPConfiguration {
  name: string;
  tier: number;
}

export interface TierConfiguration {
  isActive: boolean;
  index: number;
  time: number;
}

interface TiersProvidersProps {
    deliveries: DeliveryType[];
    dpConfiguration?: DPConfiguration[];
    tiersConfiguration?: TierConfiguration[];
    handleTierTimeChange: (tier: number, time: number) => void;
    updateDPConfiguration: (deliveryProviders: any) => Promise<void>;
}

const TiersProviders = ({
    deliveries, dpConfiguration, tiersConfiguration, updateDPConfiguration, handleTierTimeChange,
}: TiersProvidersProps) => {
    const [tiers, setTiers] = useState<any>([]);
    const { deliveries: allDeliveries } = useSelector((state: RootStateOrAny) => state.deliveries);

    useEffect(() => {
        if (!dpConfiguration || deliveries.length === 0) return;
        const newTiers: any = {};
        deliveries.forEach((dp) => {
            const dpConfig = dpConfiguration.find((d) => d.name === dp.keyName);
            let tier = 0;
            if (dpConfig) {
                tier = dpConfig.tier;
            } else {
                const deliveryPartner = allDeliveries.find((d: any) => d.keyName === dp.keyName);
                tier = deliveryPartner.tier || 0;
            }
            newTiers[tier] = [...(newTiers[tier] || []), dp];
        });
        setTiers(Object.values(newTiers));
    }, [dpConfiguration, deliveries, tiersConfiguration]);

    const onDragEnd = async (result: any) => {
        const { destination } = result;
        const { source } = result;

        if (
            (!destination || result.reason === 'CANCEL')
            || destination.droppableId === source.droppableId
        ) return;

        const newTiers = [...tiers];
        const [removed] = newTiers[source.droppableId].splice(source.index, 1);
        newTiers[destination.droppableId].splice(destination.index, 0, removed);
        const dpFormatted: any = [];
        newTiers.forEach((tier: any, i: number) => {
            tier.forEach((dp: any) => {
                dpFormatted.push({
                    name: dp.keyName,
                    tier: i,
                });
            });
        });
        await updateDPConfiguration(dpFormatted);
        setTiers(newTiers);
    };

    const addTier = () => {
        setTiers((tiers: any) => [...tiers, []]);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Row style={{ width: '100%' }} gutter={[10, 10]}>
                {tiers.map((tier: any, i: number) => {
                    const time = tiersConfiguration?.find((t) => t.index === i)?.time;
                    return (
                        <Col span={12} key={i}>
                            <AntdCard
                                title={`Tier ${i + 1}`}
                                extra={<TierTime time={time || 0} tier={i} setTime={handleTierTimeChange} />}
                                style={{ height: '100%' }}
                                bodyStyle={{ height: '100%' }}
                            >
                                <Droppable droppableId={i.toString()}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            style={{ height: '100%' }}
                                        >
                                            <Space direction="vertical">
                                                {tier.map((d: any, index: number) => (
                                                    <Draggable
                                                        key={d._id}
                                                        draggableId={d._id}
                                                        index={index}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                <div key={d._id}>
                                                                    {d.name}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            </Space>
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </AntdCard>
                        </Col>
                    );
                })}
                <Col xs={24} md={12} lg={24} xxl={12}>
                    <AntdCard
                        // className={cs(styles.card, styles.addTier)}
                        onClick={addTier}
                    >
                        + Add Tier
                    </AntdCard>
                </Col>
            </Row>
        </DragDropContext>
    );
};

export default TiersProviders;
