import { Col, Input, Row } from 'antd';
import { OctopusPaginatorQuery } from '../../../types/paginator';

interface Props {
    search: string;
    paginator: OctopusPaginatorQuery;
    handlePaginator: (newPaginator: OctopusPaginatorQuery) => void;
}

const { Search } = Input;

const Filters = ({
    search, paginator, handlePaginator,
}: Props) => (
    <Row justify="end" gutter={16} style={{ width: '100%' }}>
        <Col span={8}>
            <Search
                placeholder="Search by name / lastName / email / unique ID"
                onSearch={(search) => {
                    handlePaginator({ ...paginator, page: 1, search });
                }}
                defaultValue={search}
                enterButton
            />
        </Col>
    </Row>
);

export default Filters;
