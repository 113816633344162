import { notification, PageHeader, Table } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { getMicroFleet } from '../api/microFleet';
import { countDriversConnected } from '../utils/driver';

export const SmallFleet = () => {
    const [fleet, setFleet] = useState();
    const [loading, setLoading] = useState(false);
    const [paginator, setPaginator] = useState({ limit: 10, page: 1 });
    const [total, setTotal] = useState(0);

    const countConnected = async (smallFleets: any) => {
        const driversConnected = await Promise.all(smallFleets.map((smallFleet: any) => {
            if (smallFleet.drivers) {
                return countDriversConnected(smallFleet.drivers);
            }
            return Promise.resolve(0);
        }));

        const driversConnectedMapped = smallFleets.map((smallFleet: any, index: number) => {
            const connectedMapped: any = driversConnected[index] || 0;
            return {
                ...smallFleet,
                driverStatus: connectedMapped,
            };
        });
        setFleet(driversConnectedMapped);
    };

    useEffect(() => {
        setLoading(true);
        getMicroFleet(paginator)
            .then(({ data }) => {
                setTotal(data.body.pages * paginator.limit);
                countConnected(data.body.smallFleets);
            })
            .catch((err) => {
                console.log(err);
                notification.error({ message: err.response?.data?.message });
            })
            .finally(() => setLoading(false));
    }, [paginator]);

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({ limit: pageSize, page });
    };

    const colums = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'key Name',
            dataIndex: 'keyName',
            key: 'keyName',
        },
        {
            title: 'Total Drivers',
            dataIndex: 'drivers',
            key: 'drivers',
            render: (driver: any[]) => (
                <div>
                    {driver.length}
                </div>
            ),
        },
        {
            title: 'Connected Drivers',
            dataIndex: 'driverStatus',
            key: 'driverStatus',
            render: (driverStatus: any) => (
                <div>
                    {driverStatus.connected}
                </div>
            ),
        },
        {
            title: 'Busy Drivers',
            dataIndex: 'driverStatus',
            key: 'driverStatus',
            render: (driverStatus: any) => (
                <div>
                    {driverStatus.busy}
                </div>
            ),
        },
        {
            title: '',
            dataIndex: '',
            key: 'actions',
            width: 100,
            fixed: 'right' as 'right',
            // eslint-disable-next-line react/display-name
            render: (driver: any) => (
                <Link
                    to={`/v2/small-fleet/${driver._id}?name=${driver.name}`}
                >
                    Details
                </Link>
            ),
        },
    ];

    return (
        <div>
            <PageHeader title="Small Fleet" />
            <Table
                dataSource={fleet}
                columns={colums}
                loading={loading}
                pagination={{
                    total,
                    current: paginator.page,
                    pageSize: paginator.limit,
                    onChange: onPageChange,
                    showSizeChanger: true,
                }}
                rowKey="_id"
            />
        </div>
    );
};
