import { union } from 'lodash';
import { IDriverTracking } from '../../../types/driver';
import { IDriversFilter } from '../context/commandCenterContext';

const checkDriverStatus = (isBusy: boolean, onDutyFlag: boolean) => {
    let status = 'offline';
    if (!isBusy && onDutyFlag) status = 'online';
    if (isBusy) status = 'busy';

    return status;
};

const checkBatteryPercentage = (percentage: number) => {
    let statusColor = '#6e7b9c';

    if (percentage > 20 && percentage < 80) statusColor = '#ffd875';
    if (percentage > 80) statusColor = '#00de9b';

    return statusColor;
};

const checkAmount = (mount: number) => {
    let statusColor = '#fff';

    if (mount === 0) statusColor = '#ff426f';

    return statusColor;
};

const checkGPSStatus = (locationBackground: boolean, locationForeground: boolean) => {
    let statusColor = '#ffd875';
    if (locationBackground && locationForeground) statusColor = '#00de9b';
    if (!locationBackground && !locationForeground) statusColor = '#ff426f';
    return statusColor;
};

const filterDriversFromValue = (value: string, drivers: IDriverTracking[], driverId: string | null) => {
    if (driverId) {
        const onlyDriver = drivers.filter((driver) => driver.driverID === driverId);
        return onlyDriver;
    }

    const valueLC = value.toLocaleLowerCase();

    const driversFiltered = value.length > 0 ? drivers.filter(
        (driver) => `${driver.name} ${driver.lastName}`.toLocaleLowerCase().includes(valueLC)
            || driver.email.toLocaleLowerCase().includes(valueLC)
            || driver.mobile.toLocaleLowerCase().includes(valueLC),

    ) : drivers;

    return driversFiltered;
};

const checkIsClickedDriverMarker = (
    driversContentRef: React.MutableRefObject<HTMLInputElement | null>,
    event: any,
): boolean => driversContentRef?.current?.parentElement?.contains(event.target)
        || event.target.parentElement.id.includes('driver-marker')
        || document.getElementById('dropdown-driver-card-options')?.contains(event.target);

const filterDriversFromFilterOptions = (
    drivers: IDriverTracking[],
    filterOptions: IDriversFilter,
): IDriverTracking[] => {
    const currentDrivers = filterOptions.status
     && filterOptions.status?.length > 0 ? drivers.filter((d) => filterOptions.status?.includes(d.statusText)) : drivers;

    let dataPicker: IDriverTracking[] = [];
    let dataPickerBase: IDriverTracking[] = [];
    let dataSF: IDriverTracking[] = [];
    let dataMyFleet: IDriverTracking[] = [];

    const selectedPicker = filterOptions.deliveryProvider?.includes('PICKER');
    const selectedPickerBase = filterOptions.deliveryProvider?.includes('PICKER_BASE');
    const selectedSF = filterOptions.deliveryProvider?.includes('SMALL_FLEETS');
    const selectedMyFleet = filterOptions.deliveryProvider?.includes('THIRD_PARTY_DRIVER');

    if (
        !selectedPicker
        && !selectedSF
        && !selectedPickerBase
        && !selectedMyFleet
        && filterOptions.tags?.length === 0
    ) return currentDrivers;

    const filterFromTag = currentDrivers.filter((d) => filterOptions.tags?.includes(d.details[0].tag));

    if (selectedPicker) {
        dataPicker = currentDrivers.filter((d) => !Object.keys(d.details[0]).includes('isSmallFleetsDriver')
            || d.details[0].isSmallFleetsDriver === false);
    }

    if (selectedPickerBase) {
        dataPickerBase = currentDrivers.filter((d) => d.details[0].tag === 'PICKER_BASE');
    }

    if (selectedSF) {
        dataSF = !selectedPicker
            ? currentDrivers.filter((d) => Object.keys(d.details[0]).includes('isSmallFleetsDriver') && d.details[0].isSmallFleetsDriver === true)
            : currentDrivers;
    }

    if (selectedMyFleet) {
        dataMyFleet = currentDrivers.filter((d) => d.details[0].tag === 'THIRD_PARTY_DRIVER');
    }

    return union(dataPicker, dataSF, dataPickerBase, dataMyFleet, filterFromTag);
};

export {
    checkDriverStatus,
    checkBatteryPercentage,
    checkAmount,
    checkGPSStatus,
    filterDriversFromValue,
    checkIsClickedDriverMarker,
    filterDriversFromFilterOptions,
};
