import { useContext, useState } from 'react';
import {
    Button, Card, Col, Modal, PageHeader, Row,
} from 'antd';
import {
    AlertData, AlertsList, PreviewAlert,
} from './components';
import { AlertsContext } from './context/alerts.context';
import Send from './components/Send';

const Alerts = () => {
    const {
        alert, form, workspaceAlerts, loadingSendAlert, loadingWorkspaceAlerts, sendAlert, sendProgrammedAlert, markAsRead, loadingReadAlert, updateAlert,
    } = useContext(AlertsContext);

    const [showPreview, setShowPreview] = useState<boolean>(false);

    const extra = [
        <Button
            type="ghost"
            onClick={() => setShowPreview(true)}
        >
            Alert Preview
        </Button>,
        <Button
            type="primary"
            loading={loadingSendAlert}
            onClick={() => form.submit()}
        >
            Send alert
        </Button>,
    ];

    return (
        <Row justify="space-between" align="top" gutter={[24, 32]}>
            {
                showPreview && (
                    <Modal
                        open={showPreview}
                        onCancel={() => setShowPreview(false)}
                        footer={null}
                        width={800}
                        centered
                    >
                        <PreviewAlert
                            alert={alert}
                        />
                    </Modal>
                )
            }

            <Col xs={24} xl={8}>
                <AlertsList />
            </Col>
            <Col xs={24} xl={16}>
                <Card bordered>
                    <PageHeader
                        title=""
                        style={{ paddingRight: 12 }}
                        extra={extra}
                    />
                    <div
                        style={{
                            paddingRight: 12,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignContent: 'center',
                            height: '75vh',
                            overflowY: 'scroll',
                        }}
                    >
                        <Row justify="space-between" align="top" gutter={[24, 24]}>
                            <Col xs={24}>
                                <Send
                                    form={form}
                                    alert={alert}
                                    loadingSendAlert={loadingSendAlert}
                                    sendAlert={sendAlert}
                                    sendProgrammedAlert={sendProgrammedAlert}
                                />
                            </Col>
                            <Col xs={24}>
                                <AlertData
                                    alert={alert}
                                    markAsRead={markAsRead}
                                    loadingReadAlert={loadingReadAlert}
                                    loadingWorkspaceAlerts={loadingWorkspaceAlerts}
                                    workspaceAlerts={workspaceAlerts}
                                    updateAlert={updateAlert}
                                />
                            </Col>
                        </Row>
                    </div>
                </Card>
            </Col>
        </Row>
    );
};

export default Alerts;
