/* eslint-disable no-unused-vars */
import {
    Row, Col, Input, Select, DatePicker,
    Button,
    Space,
} from 'antd';

interface Props {
    defaultDispersionUniqueID: any;
    defaultSearch: any;
    searchParams: any;
    hasSelected?: boolean;
    handleDispersionUniqueID: (value: string) => void;
    handleSearch: (value: string) => void;
    handleStatus: (value: string) => void;
    handleCurrency: (value: any) => void;
    handlePayMultiple: () => void;
    handleDownload: () => void;
}

const { Search } = Input;

const Filters = ({
    defaultSearch,
    defaultDispersionUniqueID,
    searchParams,
    hasSelected,
    handleDispersionUniqueID,
    handleSearch,
    handleStatus,
    handleCurrency,
    handlePayMultiple,
    handleDownload,
}: Props) => {
    const {
        dispersionUniqueID, workspaceName, status, currency,
    } = searchParams;

    const OPTIONS = [
        { label: 'PENDING', value: 'PENDING' },
        { label: 'PAID', value: 'PAID' },
        { label: 'ACTIVE', value: 'ACTIVE' },
    ];

    const OPTIONS_CURRENCY = [
        { label: 'USD', value: 'USD' },
        { label: 'MXN', value: 'MXN' },
        { label: 'PEN', value: 'PEN' },
        { label: 'DOP', value: 'DOP' },
        { label: 'COP', value: 'COP' },
    ];

    return (
        <Row justify="start" gutter={[16, 24]}>
            <Col>
                <Search
                    allowClear
                    enterButton
                    defaultValue={dispersionUniqueID || defaultDispersionUniqueID}
                    onSearch={handleDispersionUniqueID}
                    placeholder="Dispersion ID"
                />
            </Col>
            <Col>
                <Search
                    allowClear
                    enterButton
                    defaultValue={workspaceName || defaultSearch}
                    onSearch={handleSearch}
                    placeholder="WS Name / ID"
                />
            </Col>
            <Col>
                <Select
                    defaultValue={status}
                    mode="multiple"
                    style={{ width: '200px' }}
                    placeholder="Dispersion status"
                    onChange={handleStatus}
                    options={OPTIONS}
                    maxTagCount="responsive"
                />
            </Col>
            <Col>
                <Select
                    defaultValue={currency}
                    mode="multiple"
                    style={{ width: '150px' }}
                    placeholder="Currency"
                    onChange={handleCurrency}
                    options={OPTIONS_CURRENCY}
                    maxTagCount="responsive"
                />
            </Col>
            {
                hasSelected && (
                    <Space size={0}>
                        <Col>
                            <Button
                                type="primary"
                                onClick={handleDownload}
                            >
                                Download
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                onClick={handlePayMultiple}
                            >
                                Pay Multiple
                            </Button>
                        </Col>
                    </Space>
                )
            }
        </Row>
    );
};

export default Filters;
