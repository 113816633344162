import { PageHeader } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { ObjectedRequests } from '../components/Objections';
import { ObjectionProvider } from '../components/Objections/context/objectionsContext';

const Objections = () => {
    const [searchParams] = useSearchParams();
    return (
        <div>
            <PageHeader title="Objections" style={{ padding: '32px 24px 24px 0' }} />
            <ObjectionProvider>
                <ObjectedRequests params={searchParams} />
            </ObjectionProvider>
        </div>
    );
};

export default Objections;
