import { useContext } from 'react';
import moment from 'moment';
import {
    Row,
    Col,
    DatePicker,
    Input,
    Select,
    Button,
} from 'antd';
import { IContext } from '../context/invoices.interface';
import { InvoicesContext } from '../context/invoices.context';
import { InvoicesStatus } from '../../../const/invoices';

const { RangePicker }: any = DatePicker;
const { Search } = Input;

const Filters = ({
    searchParams,
    handleDate,
    handleSearch,
    handleStatus,
    handleInvoiceId,
    handleFrequency,
    handleGateway,
}: Partial<IContext>) => {
    const { downloadInvoices, selectedRowKeys, date } = useContext(InvoicesContext);
    const hasSelected = selectedRowKeys && selectedRowKeys.length > 0;

    const {
        search,
        invoiceId,
        status,
        frequency,
        gateway,
    } = searchParams;

    const STATUS = Object.entries(InvoicesStatus).map(([key, value]) => ({
        label: value,
        value: key,
    }));

    const GATEWAYS = [
        { label: 'PAYMENTEZ', value: 'PAYMENTEZ' },
        { label: 'STRIPE', value: 'STRIPE' },
        { label: 'EPAYCO', value: 'EPAYCO' },
    ];

    const FREQUENCIES = [
        { label: 'WEEKLY', value: 'WEEKLY' },
        { label: 'FORTNIGHTLY', value: 'FORTNIGHTLY' },
        { label: 'MONTHLY', value: 'MONTHLY' },
    ];

    return (
        <Row justify="start" gutter={[16, 24]}>
            <Col>
                <DatePicker value={date ? moment(date) : undefined} onChange={handleDate} />
            </Col>
            <Col>
                <Select
                    defaultValue={status}
                    mode="multiple"
                    style={{ width: '160px' }}
                    placeholder="Status"
                    onChange={handleStatus}
                    options={STATUS}
                    maxTagCount="responsive"
                />
            </Col>
            <Col>
                <Select
                    defaultValue={gateway}
                    mode="multiple"
                    style={{ width: '175px' }}
                    placeholder="Payment Gateways"
                    onChange={handleGateway}
                    options={GATEWAYS}
                    maxTagCount="responsive"
                />
            </Col>
            <Col>
                <Select
                    defaultValue={frequency}
                    mode="multiple"
                    style={{ width: '160px' }}
                    placeholder="Frequency"
                    onChange={handleFrequency}
                    options={FREQUENCIES}
                    maxTagCount="responsive"
                />
            </Col>
            <Col>
                <Search
                    allowClear
                    enterButton
                    style={{ width: '350px' }}
                    placeholder="Search by workspace, plan or workspaceUniqueID"
                    defaultValue={search}
                    onSearch={handleSearch}
                />
            </Col>
            <Col>
                <Search
                    allowClear
                    enterButton
                    placeholder="Invoice number"
                    defaultValue={invoiceId}
                    onSearch={handleInvoiceId}
                />
            </Col>
            {
                hasSelected && (
                    <Col>
                        <Button
                            type="primary"
                            onClick={downloadInvoices}
                        >
                            Download
                        </Button>
                    </Col>
                )
            }
        </Row>
    );
};

export default Filters;
