import cs from 'classnames';
import moment from 'moment';
import {
    Dropdown, Space, Tooltip, Typography,
} from 'antd';
import { BsThreeDots } from 'react-icons/bs';
import { BiHelpCircle } from 'react-icons/bi';
import { IDispersion } from '../../../interfaces/dispersion.interface';
import DispersionMenu from './Menu';

const { Text } = Typography;

export const InitialColumns = () => {
    const INITIAL_COLUMNS = [
        {
            title: 'Dispersion ID',
            dataIndex: 'dispersionUniqueID',
            key: 'dispersionUniqueID',
        },
        {
            title: (
                <Tooltip title="Ej. Week 40 of 2023" placement="right" showArrow>
                    <Space size={4}>
                        Dispersion Week
                        <BiHelpCircle size={16} style={{ verticalAlign: 'middle' }} />
                    </Space>
                </Tooltip>
            ),
            dataIndex: 'week',
            key: 'week',
        },
        {
            title: 'Workspace ID',
            dataIndex: 'workspaceUniqueID',
            key: 'workspaceUniqueID',
        },
        {
            title: 'Workspace Name',
            dataIndex: 'workspaceName',
            key: 'workspaceName',
        },
        {
            title: 'Date & Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text: string) => {
                const date = moment(text).format('dddd, D MMM');
                const time = moment(text).format('h:mm a');
                return (
                    <Space direction="vertical" size={4}>
                        <Text>
                            {date}
                        </Text>
                        <Text>
                            {time}
                        </Text>
                    </Space>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: string) => (
                <Text className={cs(text, 'status')}>
                    {text}
                </Text>
            ),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text: number, record: IDispersion) => (
                <Text>
                    {`${text.toFixed(2)} ${record.currency}`}
                </Text>
            ),
        },
        {
            title: 'Transactions',
            dataIndex: 'transactions',
            key: 'transactions',
            render: (text: string) => (
                <Text>
                    {text}
                </Text>
            ),
        },
        {
            title: '',
            dataIndex: 'status',
            key: 'actions',
            fixed: 'right',
            width: 60,
            render: (text: string, record: IDispersion) => (
                <Dropdown
                    placement="bottomRight"
                    overlay={(
                        <DispersionMenu
                            dispersion={record}
                        />
                    )}
                    // @ts-ignore
                    onClick={(e: any) => { e.stopPropagation(); }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            <BsThreeDots size={25} style={{ verticalAlign: 'middle' }} />
                        </a>
                    </div>
                </Dropdown>
            ),
        },
    ];

    return INITIAL_COLUMNS;
};
