import { Typography } from 'antd';
import { AiFillQuestionCircle } from 'react-icons/ai';
import HelperIconTooltip from '../../../../components/HelperIconTooltip';

const { Text } = Typography;

const colorBold = 'black';

interface IHelperMessages {
    type: 'activeTier' | 'screen' | 'tiersDefault'
}

const HelperMessages = ({
    type,
}: IHelperMessages) => {
    const TYPES_MESSAGES: any = {
        activeTier: (
            <Text style={{ color: colorBold }}>
                <Text strong style={{ color: colorBold }}>Activates</Text>
                {' '}
                or
                <Text strong style={{ color: colorBold }}>Deactivates</Text>
                {' '}
                the tier of the selected region/super region.
            </Text>
        ),
        screen: (
            <Text style={{ color: colorBold }}>
                <Text strong style={{ color: colorBold }}>Tiers</Text>
                {' '}
                and
                <Text strong style={{ color: colorBold }}>Delivery Provider Tiers</Text>
                {' '}
                configurations of the Regions and Super Regions.
                <br />
                <br />
                Select a
                {' '}
                <Text strong style={{ color: colorBold }}>Region</Text>
                {' '}
                or
                {' '}
                <Text strong style={{ color: colorBold }}>Super Region</Text>
                {' '}
                to see their Tiers and Delvery Provider Tiers
            </Text>
        ),
        tiersDefault: (
            <Text style={{ color: colorBold }}>
                By not having custom tiers, the
                {' '}
                <Text strong style={{ color: colorBold }}>global tiers</Text>
                {' '}
                will be shown
            </Text>
        ),
    };

    return (
        <HelperIconTooltip icon={<AiFillQuestionCircle size={16} />} text={TYPES_MESSAGES[type]} />
    );
};

export default HelperMessages;
