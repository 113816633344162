import moment from 'moment';
import {
    Avatar, Space, Table, Typography,
} from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

interface IWorkspaceBusinessesTableProps {
    uniqueID: string | undefined;
    businesses: {
        data: any[];
        loading: boolean;
    }
}

const { Text } = Typography;

export const WorkspaceBusinessesTable = ({ uniqueID, businesses }: IWorkspaceBusinessesTableProps) => {
    const { deliveries, loading: dpLoading } = useSelector((state: any) => state.deliveries);

    const columns = [
        {
            title: 'Business ID',
            dataIndex: 'userUniqueID',
            key: 'userUniqueID',
            render: (userUniqueID: string, business: any) => (
                <Link style={{ color: '#08d108' }} to={`/v2/business/${business._id}`}>
                    {userUniqueID}
                </Link>
            ),
        },
        {
            title: 'Creation Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string, business: any) => (
                <Space size={4}>
                    <Text>
                        {moment(createdAt).format('DD MMM')}
                    </Text>
                    <Text type="secondary">
                        at
                    </Text>
                    <Text>
                        {moment(createdAt).format('HH:mm')}
                    </Text>
                </Space>
            ),
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName',
        },
        {
            title: 'Contact Name',
            dataIndex: '',
            key: 'contactName',
            render: (data: any) => `${data.name} ${data.lastName}`,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Delivery Providers',
            dataIndex: 'externalAPIConfiguration',
            key: 'externalAPIConfiguration',
            render: (externalAPIConfiguration: any) => {
                const { deliveryProviders } = externalAPIConfiguration;
                const activeDeliveryProviders = deliveryProviders.filter((dp: any) => dp.isActive);
                return (
                    <Avatar.Group>
                        {activeDeliveryProviders.map((dp: any) => {
                            const keyName = (dp.keyName || dp.name);
                            const delivery = dpLoading ? {} : deliveries.find((delivery: any) => delivery.keyName === keyName);
                            return (
                                <Avatar
                                    key={keyName}
                                    style={{ borderColor: '#212845', backgroundColor: '#1a1f36' }}
                                    src={delivery?.image}
                                />
                            );
                        })}
                    </Avatar.Group>
                );
            },
        },
    ];

    return (
        <div>
            <Table
                dataSource={businesses.data}
                columns={columns}
                loading={businesses.loading}
                rowKey="userID"
                scroll={{ x: 800 }}
            />
        </div>
    );
};
