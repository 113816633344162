export const STATUSES_TEXT = {
    PENDING: 'PENDING',
    ON_DECK: 'ON_DECK',
    READY_FOR_PICKUP: 'READY_FOR_PICKUP',
    ARRIVED_AT_PICKUP: 'ARRIVED_AT_PICKUP',
    WAY_TO_DELIVER: 'WAY_TO_DELIVER',
    ARRIVED_AT_DELIVERY: 'ARRIVED_AT_DELIVERY',
    ACCEPTED: 'ACCEPTED',
    ENROUTE_TO_PICKUP: 'ENROUTE_TO_PICKUP',
    PICKED_UP: 'PICKED_UP',
    ENROUTE_TO_DELIVERY: 'ENROUTE_TO_DELIVERY',
    DELIVERED: 'DELIVERED',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
    CANCELLED_BY_DRIVER: 'CANCELLED_BY_DRIVER',
    CANCELLED_BY_CUSTOMER: 'CANCELLED_BY_CUSTOMER',
    CANCELLED_BY_SERVICE_PROVIDER: 'CANCELLED_BY_SERVICE_PROVIDER',
    CANCELLED_BY_ADMIN: 'CANCELLED_BY_ADMIN',
    PROVIDER_NOT_FOUND: 'PROVIDER_NOT_FOUND',
    EXPIRED: 'EXPIRED',
    ON_HOLD: 'ON_HOLD',
    REJECTED_BY_DRIVER: 'REJECTED_BY_DRIVER',
    REJECTED_BY_CUSTOMER: 'REJECTED_BY_CUSTOMER',
    REJECTED_BY_BUSINESS: 'REJECTED_BY_BUSINESS',
    CANCELLED_REQUEST: 'CANCELLED_REQUEST',
    CANCELLED_BY_BUSINESS: 'CANCELLED_BY_BUSINESS',
    PAYMENT_FAILED: 'PAYMENT_FAILED',
    TIME_PASSED: 'Booking time is already passed',
    NOT_DELIVERED: 'NOT_DELIVERED',
    CANCELLED_BY_DELIVERY_PROVIDER: 'CANCELLED_BY_DELIVERY_PROVIDER',
    RETURNED_TO_PICKUP: 'RETURNED_TO_PICKUP',
    RETURNING: 'RETURNING',
    RETURNED: 'RETURNED',
};

export const STATUSES = {
    PENDING: {
        INDEX: 0,
        TEXT: 'PENDING',
    },
    ACCEPTED: {
        INDEX: 1,
        TEXT: 'ACCEPTED',
    },
    ARRIVED_AT_PICKUP: {
        INDEX: 2,
        TEXT: 'ARRIVED_AT_PICKUP',
    },
    WAY_TO_DELIVER: {
        INDEX: 3,
        TEXT: 'WAY_TO_DELIVER',
    },
    ARRIVED_AT_DELIVERY: {
        INDEX: 4,
        TEXT: 'ARRIVED_AT_DELIVERY',
    },
    COMPLETED: {
        INDEX: 5,
        TEXT: 'COMPLETED',
    },
    ON_HOLD: {
        INDEX: 20,
        TEXT: 'ON_HOLD',
    },
    ON_DECK: {
        INDEX: 21,
        TEXT: 'ON_DECK',
    },
    READY_FOR_PICKUP: {
        INDEX: 22,
        TEXT: 'READY_FOR_PICKUP',
    },
    NOT_DELIVERED: {
        INDEX: 11,
        TEXT: 'NOT_DELIVERED',
    },
    RETURNING: {
        INDEX: 32,
        TEXT: 'RETURNING',
    },
    RETURNED: {
        INDEX: 33,
        TEXT: 'RETURNED',
    },
    RETURNED_TO_PICKUP: {
        INDEX: 34,
        TEXT: 'RETURNED_TO_PICKUP',
    },
    PROVIDER_NOT_FOUND: {
        INDEX: 31,
        TEXT: 'PROVIDER_NOT_FOUND',
    },
};

export const CANCELLED_STATUSES = [
    'CANCELLED',
    'CANCELLED_BY_ADMIN',
    'CANCELLED_BY_DRIVER',
    'CANCELLED_BY_CUSTOMER',
    'CANCELLED_BY_BUSINESS',
    'REJECTED_BY_BUSINESS',
    'DRIVER_NOT_FOUND',
    'NO_PICKERS_IN_AREA',
    'PAYMENT_FAILED',
    'CANCELLED_REQUEST',
];

export const FINISHED_STATUSES = [
    STATUSES_TEXT.COMPLETED,
    STATUSES_TEXT.CANCELLED_BY_ADMIN,
    STATUSES_TEXT.CANCELLED,
    STATUSES_TEXT.CANCELLED_BY_DRIVER,
    STATUSES_TEXT.REJECTED_BY_DRIVER,
    STATUSES_TEXT.REJECTED_BY_CUSTOMER,
    STATUSES_TEXT.REJECTED_BY_BUSINESS,
    STATUSES_TEXT.CANCELLED_REQUEST,
    STATUSES_TEXT.CANCELLED_BY_BUSINESS,
    STATUSES_TEXT.PAYMENT_FAILED,
    STATUSES_TEXT.NOT_DELIVERED,
    STATUSES_TEXT.CANCELLED_BY_DELIVERY_PROVIDER,
    STATUSES_TEXT.RETURNED,
    STATUSES_TEXT.PROVIDER_NOT_FOUND,
];

export const NOTIFICATIONS = {
    WHATSAPP: {
        INDEX: 0,
        TEXT: 'whatsapp',
    },
    EMAIL: {
        INDEX: 1,
        TEXT: 'email',
    },
    SMS: {
        INDEX: 2,
        TEXT: 'sms',
    },
};
