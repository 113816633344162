export const types = {

    uiSetInfo: '[UI] Set Info',
    uiRemoveInfo: '[UI] Remove Info',
    uiSetError: '[UI] Set Error',
    uiRemoveError: '[UI] Remove Error',
    uiStartLoading: '[UI] Start loading',
    uiFinishLoading: '[UI] Finish loading',

    login: '[Auth] Login',

    ridesLoad: '[RIDE] Load Rides',
    ridesSetActive: '[RIDE] Set active Ride',
    ridesSetActiveEvents: '[RIDE] Set active Ride events',
    ridesUpdateProviderRide: '[RIDE] Update Provider Ride',
    ridesUpdateSmallFleetProviderRide: '[RIDE] Update Small Fleet Provider Ride',
    ridesStartLoading: '[RIDE] Start loading Ride',
    ridesFinishLoading: '[RIDE] Finish loading Ride',

    ridesRestartSearchId: '[RIDE] Restart Search Id Ride',
    ridesCancelSearchId: '[RIDE] Cancel Search Id Ride',
    ridesChangeStatus: '[RIDE] Change Status Modal',
    ridesDriverManuallyModal: '[RIDE] Driver Manually Modal',
    ridesReportIssue: '[RIDED] Report Issue Manually Modal',
    ridesAddComment: '[RIDE] Add comment Modal',

    workspaceEdit: '[Workspaces] Workspace Edit Modal',
    workspaceRemove: '[Workspaces] Remove Workspace Modal',
    workspaceDeactivate: '[Workspaces] Deactivate Workspace Modal',
    settingsTiers: '[Workspaces] Settings Tiers Workspace Modal',
    workspaceEditMaxDistance: '[Workspaces] Edit max distance my fleet Modal',

    deliveryProviderDeactivate: '[DeliveryProvider] Deactivate Delivery Proviver Modal',
    deliveryProviderChangeTier: '[DeliveryProvider] Delivery Provider Change Tier Modal',

    bussinesSettingsTiers: '[Workspaces] Settings Tiers Bussines Modal',

    openModal: '[Modal] Open Modal',
    closeModal: '[Modal] Close Modal',

    deliveriesStartLoading: '[DP] Start loading DPs',
    deliveriesLoad: '[DP] Load DPs',
    deliveriesFinishLoading: '[DP] Finish loading DPs',

    plansStartLoading: '[Plan] Start loading plans',
    plansFinishLoading: '[Plan] Finish loading plans',
    plansLoad: '[Plan] Plans loaded',

    updateIssue: '[Issue] UpdateIssue',
};
