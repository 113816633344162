import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    Button, Form, message, Upload, UploadFile, UploadProps, Typography,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AiFillWarning } from 'react-icons/ai';
import { MdAttachFile } from 'react-icons/md';
import { uploadFile } from '../../../../api/issues';
import socketEvents from '../../../../const/socketEvent';
import { SocketChatContext } from '../../../../context/SocketChatContext';
import { EChatUserRole, EMessageType } from '../../../../enums/chat.enum';
import { IMessage } from '../../../../types/chat';
import { ChatContext } from '../../context/chatContext';
import { ShieldContext } from '../../context/shieldContext';
import { ISSUE_CLOSED } from '../../../../const/issue';

const { Text } = Typography;

const SendMessage = () => {
    const {
        chat,
        messages,
        addedMessage,
        setReadMessages,
    } = useContext(ChatContext);
    const { issue } = useContext(ShieldContext);
    const { socketInstance } = useContext(SocketChatContext);
    const [form] = Form.useForm();

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [sending, setSending] = useState<boolean>(false);
    const [valueEditor, setValueEditor] = useState<string>('');
    const [isChatClosed, setIsChatClosed] = useState<boolean>(false);

    const onChange = (value: any) => setValueEditor(value);

    const returnTypingMessage = (file: any) => {
        let type = EMessageType.TEXT;
        if (file.type.includes('image')) {
            type = EMessageType.IMAGE;
        } else if (file.type.includes('video')) {
            type = EMessageType.VIDEO;
        } else if (file.type.includes('audio')) {
            type = EMessageType.AUDIO;
        }
        return type;
    };

    const handleSendMessage = async () => {
        if (!chat?._id) {
            message.warning('Chat not found');
            return;
        }

        if (!issue?.responsableID || !issue?.responsableName) {
            message.warning('Issue not assigned responsable');
            return;
        }

        if ((!valueEditor || valueEditor === '') && fileList.length === 0) {
            message.warning('Message not be empty');
            return;
        }

        const payload: any = {
            chatID: chat?._id,
            roleType: EChatUserRole.SUPPORT,
            user: {
                userID: issue?.responsableID,
                name: issue?.responsableName,
            },
            readBy: [EChatUserRole.SUPPORT],
        };

        const messageData = {
            action: socketEvents.SEND_MESSAGE,
            payload,
        };

        if (fileList.length > 0) {
            if (fileList.length === 1) {
                const { data: { data } } = await uploadFile(fileList[0], 'business');
                payload.data = data.original;
                payload.type = returnTypingMessage(fileList[0]);
                console.log(`👀 Send message with one attachment ${payload}`);
                socketInstance.send(JSON.stringify(messageData));
            } else {
                const files = await Promise.all(fileList.map(async (file) => {
                    const { data: { data } } = await uploadFile(file, 'business');
                    return {
                        content: data.original,
                        type: returnTypingMessage(file),
                    };
                }));

                files.forEach((file) => {
                    payload.data = file.content;
                    payload.type = file.type;
                    console.log(`👀 Send message with any attachment ${payload}`);
                    socketInstance.send(JSON.stringify(messageData));
                });
            }
        } else {
            payload.data = valueEditor;
            payload.type = EMessageType.TEXT;
            console.log(`👀 Send message without attachment ${payload}`);
            socketInstance.send(JSON.stringify(messageData));
        }

        form.resetFields();
        setValueEditor('');
        setFileList([]);
        setSending(false);
    };

    const props: UploadProps = {
        onRemove: (file: any) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file: any) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    };

    const MessageFinalizeChat = () => (
        <div className="settings__subheader--help">
            <AiFillWarning size={16} style={{ verticalAlign: 'middle', color: '#2f2e2e' }} />
            <Text style={{ fontWeight: '400', color: '#080808' }}>
                The chat has been closed, you cannot send or receive messages. To solve a new problem please open a new ticket.
            </Text>
        </div>
    );

    useEffect(() => {
        setIsChatClosed(ISSUE_CLOSED.includes(issue.status));
    }, []);

    useEffect(() => {
        if (!socketInstance) return;
        socketInstance.onmessage = (event: any) => {
            const parsedData = JSON.parse(event.data);
            if (parsedData.action === socketEvents.SEND_MESSAGE) {
                parsedData.payload._id = parsedData.payload.messageID;
                addedMessage(parsedData.payload);
                const messageData = {
                    chatID: parsedData.payload.chatID,
                    messageIds: [parsedData.payload.messageID],
                    readBy: EChatUserRole.SUPPORT,
                };
                const readMessageData = {
                    action: socketEvents.READ_MESSAGE,
                    payload: messageData,
                };
                socketInstance.send(JSON.stringify(readMessageData));
            } else if (parsedData.action === socketEvents.READ_MESSAGE) {
                const { messageIds, readBy } = parsedData.payload;
                setReadMessages(messageIds, readBy);
            }
        };

        return () => {
            socketInstance.onmessage = null;
        };
    }, [socketInstance]);

    useEffect(() => {
        if (socketInstance && socketInstance.readyState !== 1) return;
        const messageIds = messages.map((message: IMessage) => !message.readBy.includes(EChatUserRole.SUPPORT) && message._id).filter((id) => id !== false);
        if (messageIds.length > 0) {
            const messageData = {
                chatID: chat._id,
                messageIds,
                readBy: EChatUserRole.SUPPORT,
            };
            const readMessageData = {
                action: socketEvents.READ_MESSAGE,
                payload: messageData,
            };
            socketInstance.send(JSON.stringify(readMessageData));
        }
    }, [messages]);

    return (
        <>
            {isChatClosed && <MessageFinalizeChat />}
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSendMessage}
            >
                <ReactQuill
                    className="chat__quill"
                    theme="snow"
                    value={valueEditor}
                    onChange={onChange}
                    readOnly={isChatClosed}
                />

                <div className="chat__attachments">
                    <Upload {...props} disabled={isChatClosed}>
                        <Button
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            disabled={isChatClosed}
                            icon={(
                                <MdAttachFile
                                    size={20}
                                    color={isChatClosed ? '#838383' : '#00B2E3'}
                                />
                            )}
                        />
                    </Upload>

                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={sending}
                        disabled={isChatClosed}
                    >
                        Send
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default SendMessage;
