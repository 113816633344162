import { io } from 'socket.io-client';
import { MULTIVERSE_URL } from '../const/envs';
import socketEvents from '../const/socketEvent';

let socket: any;

const configSocket: any = {
    reconnection: true,
    forceNew: true,
    transports: ['websocket'],
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
};

export const initiateSocket = () => {
    socket = io(MULTIVERSE_URL, configSocket);
    if (socket) {
        socket.emit(socketEvents.ADMIN_JOIN);
    }
    return socket;
};

export const disconnectSocket = () => {
    if (socket) socket.disconnect();
    console.log('Socket disconnected');
};

export const checkSocketConnection = () => {
    if (socket) return socket;
};
