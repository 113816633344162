import moment from 'moment';
import { Col, Row, Typography } from 'antd';
import CardDataBase from '../../CardDataBase';

const { Text } = Typography;

const Information = ({ user }: { user: any }) => {
    const CONTENT = [
        {
            title: 'Names',
            content: `${user.name} ${user.lastName}`,
        },
        {
            title: 'Email',
            content: user.email,
        },
        {
            title: 'Mobile',
            content: `${user.countryCode}${user.mobile}`,
        },
        {
            title: 'Created at',
            content: moment(user.createdAt).format('DD MMM YYYY'),
        },
    ];

    return (
        <CardDataBase title="General Information">
            <Row
                align="top"
                gutter={[24, 28]}
                justify="space-between"
                style={{ width: '100%' }}
            >
                <Col xs={24} xl={12}>
                    {
                        CONTENT.map((data) => (
                            <Row
                                align="top"
                                justify="space-between"
                                style={{ marginBottom: 8 }}
                            >
                                <Col>
                                    <Text strong>
                                        {`${data.title}:`}
                                    </Text>
                                </Col>
                                <Col>{data.content}</Col>
                            </Row>
                        ))
                    }
                </Col>
            </Row>
        </CardDataBase>
    );
};

export default Information;
