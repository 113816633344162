import {
    Divider,
    PageHeader,
    Space,
    Typography,
} from 'antd';
import { IGeoPoint } from '../../../interfaces/express-delivery.interface';
import ItemData from './Item';

const { Title } = Typography;

interface Props {
    description: string;
    volume: string;
    weight: number;
    isFragile: boolean;
    customerCountryCode: string;
    customerMobile: string;
    deliveryLocation: {
        address: string,
        coordinates: IGeoPoint,
        references?: string | null,
    },
    pickupLocation: {
        address: string,
        coordinates: IGeoPoint,
        references?: string | null,
    },
    header?: string;
}

const DeliveryData = ({
    description,
    volume,
    weight,
    isFragile,
    deliveryLocation,
    pickupLocation,
    customerCountryCode,
    customerMobile,
    header,
}: Props) => (
    <Space direction="vertical" size={12} style={{ width: '100%' }}>
        {
            header && (
                <PageHeader
                    title={(
                        <Title level={5} style={{ marginBottom: 0 }}>
                            {header || 'Delivery Data'}
                        </Title>
                    )}
                    style={{ padding: 0 }}
                    footer={<Divider style={{ margin: '12px 0' }} />}
                />
            )
        }
        <ItemData
            label="Description"
            value={description || '-'}
        />
        <ItemData
            label="Large"
            value={volume || '-'}
        />
        <ItemData
            label="Weight"
            value={`${weight}lbs` || '-'}
        />
        <ItemData
            label="Fragile"
            value={isFragile ? 'Yes' : 'No'}
        />

        <ItemData
            label="Customer mobile"
            value={`${customerCountryCode} ${customerMobile}`}
        />
        <ItemData
            label="Pickup location"
            value={(
                <Space size={2} direction="vertical">
                    {pickupLocation.address}
                    {(!pickupLocation.references || pickupLocation.references === 'N/A') ? 'No references' : pickupLocation.references}
                </Space>
            )}
        />
        <ItemData
            label="Delivery location"
            value={(
                <Space size={2} direction="vertical">
                    {deliveryLocation.address}
                    {(!deliveryLocation.references || deliveryLocation.references === 'N/A') ? 'No references' : deliveryLocation.references}
                </Space>
            )}
        />
    </Space>
);

export default DeliveryData;
