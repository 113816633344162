import {
    Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import {
    Alert, Col, Form, Input, InputNumber, Modal, notification, Row, Select, Tabs, Transfer,
} from 'antd';

import { addDriver, editDriver, getDriverById } from '../../../api/drivers';
import { getAssociatedBusiness } from '../../../api/workspaces';
import { ECarName } from '../../../types/driver';
import { DriverPaymentMethod } from '../../../const/driver';

interface showAddDriverModal {
  visible: boolean;
  type: string;
  user?: any
}

interface AddDriverModalProps {
  id: string;
  showAddDriverModal: showAddDriverModal
  setShowAddDriverModal: Dispatch<SetStateAction<any>>
  setReload: () => void
}

export const AddDriverModal = ({
    id, showAddDriverModal, setShowAddDriverModal, setReload,
}: AddDriverModalProps) => {
    const [loading, setLoading] = useState(false);
    const [business, setBusiness] = useState<any>([]);
    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [form] = Form.useForm();

    const onChange = (nextTargetKeys: string[]) => {
        setTargetKeys(nextTargetKeys);
    };

    const { TabPane } = Tabs;
    const options = [
        { value: ECarName.LITE, label: 'Car' },
        { value: ECarName.BIKE, label: 'Bike' },
    ];

    const paymentMethods = [
        { value: DriverPaymentMethod.CASH, label: 'Cash' },
        { value: DriverPaymentMethod.CARD, label: 'Card' },
        { value: DriverPaymentMethod.TERMINAL, label: 'Terminal' },
    ];

    const AVAILABLE_METHODS = [
        DriverPaymentMethod.CASH,
        DriverPaymentMethod.CARD,
        DriverPaymentMethod.TERMINAL,
    ];

    const requiredRule = { required: true, message: 'Required' };
    const isDisable = showAddDriverModal.type === 'Edit';

    useEffect(() => {
        if (!id) return;
        if (showAddDriverModal.type === 'Edit') {
            if (!showAddDriverModal.user) return;
            const hasPaymenthMethods = showAddDriverModal.user.availablePaymentMethods.length > 0;
            const availablePaymentMethods = hasPaymenthMethods ? showAddDriverModal.user.availablePaymentMethods : AVAILABLE_METHODS;
            getDriverById(showAddDriverModal.user._id)
                .then(({ data }) => {
                    form.setFieldsValue({
                        ...data.data,
                        ...data.data.driverID,
                        availablePaymentMethods,
                    });
                    setTargetKeys(showAddDriverModal.user.business);
                })
                .catch((err) => console.log(err));
        }

        getAssociatedBusiness(id)
            .then(({ data }) => {
                const business = data.body.data.map((business: any) => ({
                    key: business.businessID._id,
                    companyName: business.businessID.companyName,
                }));
                setBusiness(business);
            })
            .catch((error) => {
                const message = error.response?.data?.message;
                notification.error({
                    message,
                });
                console.log(error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id, showAddDriverModal]);

    const onFinish = (values: any) => {
        setLoading(true);
        if (showAddDriverModal.type === 'Add') {
            const hasAllBusiness = targetKeys?.length === business.length;
            if (hasAllBusiness) {
                delete values.business;
            }
            addDriver({
                ...values, countryCode: `+${values.countryCode}`, workspaceID: id, hasAllBusiness,
            })
                .then((res) => {
                    const { message } = res.data;
                    notification.success({
                        message,
                    });
                    setReload();
                })
                .catch((error) => {
                    const message = error.response?.data?.message;
                    notification.error({
                        message,
                    });
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                    setShowAddDriverModal({ visible: false });
                    form.resetFields();
                });
        } else if (showAddDriverModal.type === 'Edit') {
            editDriver({ ...values, workspaceID: id, userID: showAddDriverModal.user._id })
                .then(({ data }) => {
                    notification.success({
                        message: data.message,
                    });
                    setReload();
                })
                .catch((error) => {
                    notification.error({
                        message: error.response?.data?.message,
                    });
                    console.log(error);
                })
                .finally(() => {
                    setLoading(false);
                    setShowAddDriverModal({ visible: false });
                    form.resetFields();
                });
        }
    };

    const handleCancel = () => {
        setShowAddDriverModal({ visible: false, type: showAddDriverModal.type });
        form.resetFields();
    };

    return (
        <Modal
            title={`${showAddDriverModal.type} Driver`}
            visible={showAddDriverModal.visible}
            onOk={() => form.submit()}
            onCancel={handleCancel}
            bodyStyle={{ padding: '0px 24px' }}
            confirmLoading={loading}
        >
            <br />
            {showAddDriverModal.type === 'Add' && (
                <Alert
                    message="Picker will send a request to the established email, and the person must accept it in order to be part of the workspace"
                    type="warning"
                    showIcon
                    closable
                />
            )}
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
                requiredMark={false}
            >
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Driver Information" key="1">
                        <Row gutter={10}>
                            <Col span={12}>
                                <Form.Item label="Name" name="name" rules={[requiredRule]}>
                                    <Input placeholder="First Name" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Last Name" name="lastName" rules={[requiredRule]}>
                                    <Input placeholder="Last Name" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Country Code" name="countryCode" rules={[requiredRule]}>
                                    <Input maxLength={4} addonBefore="+" placeholder="Country Code" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Mobile" name="mobile">
                                    <Input maxLength={10} style={{ width: '100%' }} placeholder="Mobile" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Email" name="email" rules={[requiredRule]}>
                                    <Input placeholder="Email" disabled={isDisable} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Payment Methods" name="availablePaymentMethods">
                                    <Select placeholder="Cash" options={paymentMethods} mode="multiple" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Vehicle Information" key="2">
                        <Row gutter={12}>
                            <Col span={8}>
                                <Form.Item label="Vehicle Type" name="vehicleType">
                                    <Select placeholder="Vehicle Type" options={options} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Vehicle Model" name="vehicleModelName" rules={[requiredRule]}>
                                    <Input placeholder="Vehicle Model" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="MC Number" name="MCNumber" rules={[requiredRule]}>
                                    <Input placeholder="MC Number" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Vehicle Color" name="vehicleColor">
                                    <Input style={{ width: '100%' }} placeholder="Vehicle Color" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Vehicle Year" name="vehicleYear" rules={[requiredRule]}>
                                    <InputNumber style={{ width: '100%' }} placeholder="Vehicle Year" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tab="Business Access" key="3">
                        <Form.Item name="business">
                            <Transfer
                                style={{ display: 'flex', justifyContent: 'center' }}
                                dataSource={business}
                                titles={['Businesses', 'Selected']}
                                showSelectAll
                                targetKeys={targetKeys}
                                onChange={onChange}
                                render={(item: any) => item.companyName}
                                oneWay
                            />
                        </Form.Item>
                    </TabPane>
                </Tabs>
            </Form>
        </Modal>
    );
};
