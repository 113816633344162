import { Menu, notification } from 'antd';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import {
    AssigningDriverManually,
    CancelService,
    ChangeStatusModal,
    CommentModal,
    RestartSearchModal,
} from '../Modals';
import { ReportIssue } from '../Modals/ReportIssue/ReportIssue';
import {
    openModal,
    setChangeStatus,
    setDriverManuallylId,
    setRestartSearchId,
    setRidesAddComment,
} from '../../actions/modals';
import { endSearch } from '../../api/deliveryProviders';
import { SMR_URL } from '../../api';
import { ModalTypes } from '../../types/modals';
import { startLoadRide } from '../../actions/ride';

const Actions = () => {
    const dispatch = useDispatch();

    const { active: booking } = useSelector((state: RootStateOrAny) => state.ride);

    const menu = [
        {
            children: 'Assign Picker Manually',
            onClick: () => {
                dispatch(setDriverManuallylId(booking));
            },
        },
        {
            children: 'Change Status',
            onClick: () => {
                dispatch(setChangeStatus(booking));
            },
        },
        {
            children: 'End Search',
            onClick: () => {
                endSearch(booking._id, { isAdmin: true })
                    .then((res: any) => {
                        notification.success({
                            message: 'Success!',
                            description: `${res.data.message}`,
                        });
                    })
                    .catch(() => notification.error({
                        message: 'Opps!',
                        description: 'An Error ocurred',
                    }));
            },
        },
        {
            children: 'Share My Ride',
            onClick: () => {
                window.open(`${SMR_URL}/#?token=${booking.smrToken}`);
            },
        },
        {
            children: 'Restart Search',
            onClick: () => {
                dispatch(setRestartSearchId(booking));
            },
        },
        {
            children: 'Cancel service',
            onClick: () => {
                dispatch(openModal(ModalTypes.CancelService, booking));
            },
        },
        {
            children: 'Report Issue',
            onClick: () => {
                dispatch(openModal(ModalTypes.ReportIssue, booking));
            },
        },
        {
            children: 'Add comment',
            onClick: () => {
                dispatch(setRidesAddComment(booking));
            },
        },
    ];

    if (!booking) return null;

    return (
        <>
            <AssigningDriverManually />
            <ChangeStatusModal />
            <CommentModal />
            <RestartSearchModal />
            <CancelService />
            <ReportIssue />
            <Menu>
                <Menu.ItemGroup key="g">
                    {menu.map((o, i) => (
                        <Menu.Item
                            key={i}
                            {...o}
                        />
                    ))}
                </Menu.ItemGroup>
            </Menu>
        </>
    );
};

export default Actions;
