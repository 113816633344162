/* eslint-disable prefer-destructuring */
import { notification } from 'antd';
import {
    FC, createContext, useEffect, useState,
} from 'react';
import { get } from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { IContext, IWhatsappCertification } from './certifications.interface';
import { initialContext } from './certifications.initial';
import { getCertification, getCertifications } from '../../../api/whatsapp-certifications';

export const CertificationsContext = createContext<IContext>(initialContext);

export const CertificationsProvider: FC = ({ children }) => {
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [loadingAction, setLoadingAction] = useState<boolean>(initialContext.loadingAction);
    const [data, setData] = useState<IWhatsappCertification[]>(initialContext.data);
    const [item, setItem] = useState<IWhatsappCertification | null>(initialContext.item);
    const [total, setTotal] = useState<number>(initialContext.total);
    const [paginator, setPaginator] = useState(initialContext.paginator);

    const [searchParams, setSearchParams] = useSearchParams();

    const statusString = searchParams.get('status') || '[]';
    const status = JSON.parse(statusString);
    const startDate = searchParams.get('startDate') || null;
    const endDate = searchParams.get('endDate') || null;
    const search = searchParams.get('search') || '';

    const getParams = () => {
        const params: any = {};
        (status && status.length) && (params.status = status);
        startDate && (params.startDate = startDate);
        endDate && (params.endDate = endDate);
        search && (params.search = search);
        return params;
    };

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({
            limit: pageSize,
            page,
        });
    };

    const handleStatus = (value: any) => {
        const { status, ...params } = getParams();
        value && value.length !== '' && (params.status = JSON.stringify(value));
        setSearchParams(params);
    };

    const handleDate = (date: any) => {
        const { startDate, endDate, ...params } = getParams();
        if (!date.length) {
            delete params.startDate;
            delete params.endDate;
            setSearchParams(params);
            return;
        }
        (params.startDate = date[0]);
        (params.endDate = date[1]);
        setSearchParams(params);
    };

    const handleSearch = (value: string) => {
        setSearchParams({ ...getParams(), search: value });
    };

    const fetchData = () => {
        setLoading(true);

        const params: any = {
            ...paginator,
            ...getParams(),
        };

        getCertifications(params)
            .then(({ data: { data: response } }: any) => {
                const { data, total } = response;
                setData(data);
                setTotal(total);
            })
            .catch((error: any) => {
                const description = get(error, 'response.data.message', '');
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoading(false));
    };

    const fetchItem = (id: string) => {
        setLoadingAction(true);

        getCertification(id)
            .then(({ data: { data: response } }: any) => {
                setItem({
                    ...item,
                    ...response,
                });
            })
            .catch((error: any) => {
                const description = get(error, 'response.data.message', '');
                notification.error({
                    message: 'Error',
                    description,
                });
            })
            .finally(() => setLoadingAction(false));
    };

    useEffect(() => {
        if (!item) return;
        fetchItem(item?._id || '');
    }, [item?._id]);

    return (
        <CertificationsContext.Provider
            value={{
                loading,
                loadingAction,
                data,
                item,
                total,
                paginator,
                startDate,
                endDate,
                searchParams,
                setLoading,
                setItem,
                setData,
                fetchData,
                fetchItem,
                onPageChange,
                handleStatus,
                handleDate,
                handleSearch,
            }}
        >
            {children}
        </CertificationsContext.Provider>
    );
};
