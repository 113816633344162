import {
    Form, Modal, Input, Space, notification,
} from 'antd';
import { useState } from 'react';
import WorkspaceInputSearch from '../../WorkspaceInputSearch';
import { createPayment } from '../../../api/payments';

interface IProps {
    visible: boolean;
    onCancel: (payment: any) => void;
}

const CreatePayment = ({
    visible, onCancel,
}: IProps) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values: any) => {
        setLoading(true);
        createPayment({ workspaceUniqueID: values.id })
            .then(({ data }) => {
                notification.success({
                    message: 'Payment added successfully',
                });
                onCancel(data.data);
            })
            .catch((error) => {
                const description = error.response?.data?.message;
                notification.error({
                    message: 'Error adding payment',
                    description,
                });
            })
            .finally(() => {
                setLoading(false);
                form.resetFields();
            });
    };

    const workspaceSelectedData = ({ id, name }: { id: string, name: string }) => {
        form.setFieldsValue({ id, name });
    };

    return (
        <Modal
            visible={visible}
            title="Create Payment"
            onCancel={onCancel}
            onOk={() => form.submit()}
            okText="Create"
            confirmLoading={loading}
        >
            <Space direction="vertical" size={24}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Workspace Id"
                        name="id"
                        rules={[{ required: true, message: 'Missing Workspace Id' }]}
                    >
                        <Input placeholder="WS123" />
                    </Form.Item>
                    <Form.Item
                        label="Workspace Name"
                        name="name"
                        rules={[{ required: true, message: 'Missing name' }]}
                    >
                        <WorkspaceInputSearch
                            placeholder="Picker Workspace"
                            field=""
                            workspaceSelectedData={workspaceSelectedData}
                        />
                    </Form.Item>
                </Form>
            </Space>
        </Modal>
    );
};

export default CreatePayment;
