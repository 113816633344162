/* eslint-disable no-use-before-define */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState } from 'react';
import {
    Avatar, Card, Checkbox, Col, Descriptions, notification, PageHeader, Row, Space, Spin, Switch, Table, Tag, Tooltip,
} from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { get, isUndefined } from 'lodash';
import { AiOutlineCopy } from 'react-icons/ai';

import { useSelector } from 'react-redux';
import { BsInfoCircle } from 'react-icons/bs';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { getOneBusiness, updateDeliveryProviderBusinessStatus, updateIgnoreAPIAddress } from '../api/business';
import { IBusiness, IBusinessID } from '../types/bussiness';
import Map, { Marker } from '../components/Map';
import { IMAGES } from '../const/images';
import { getProvidersByCoordinates } from '../api/deliveryProviders';
import { copyText } from '../utils/general';

const styles = {
    loadingStyles: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh',
    },
};

export const BusinessDetails = () => {
    const [loading, setLoading] = useState(false);
    const [userBusiness, setUserBusiness] = useState<IBusiness>();
    const [loadingAction, setLoadingAction] = useState<boolean>(false);
    const business = userBusiness?.businessID;
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!id) return;
        setLoading(true);
        getOneBusiness(id)
            .then(({ data }) => {
                setUserBusiness(data.data);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    }, [id]);

    const onBackHandle = () => {
        if (location.key !== 'default') navigate(-1);
        else navigate('/v2/business');
    };

    const onChange = (e: CheckboxChangeEvent) => {
        setLoadingAction(true);
        const ignoreAPIAddress = e.target.checked;

        if (isUndefined(userBusiness)) return;

        updateIgnoreAPIAddress(userBusiness._id, ignoreAPIAddress)
            .then(() => {
                notification.success({
                    message: 'Success',
                    description: 'Ignore API Address updated successfully',
                });
            })
            .catch((error: any) => {
                notification.error({
                    message: 'Error',
                    description: get(error, 'response.data.message', 'Error'),
                });
            })
            .finally(() => setLoadingAction(false));
    };

    return (
        <div>
            <PageHeader
                onBack={onBackHandle}
                title={`Business: ${business?.companyName || ''}`}
            />
            {!loading && !business && <h1>Business not found</h1>}
            {loading ? (
                <div style={styles.loadingStyles}>
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    {userBusiness && business && (
                        <Row gutter={[20, 20]}>
                            <Col span={24}>
                                <Card>
                                    {DetailsHeaders(userBusiness, loadingAction, onChange)}
                                </Card>
                            </Col>
                            <Col xs={24} lg={12}>
                                <BusinessLocation business={business} />
                            </Col>
                            <Col xs={24} lg={12}>
                                <BusinessProviders userBusiness={userBusiness} />
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </div>
    );
};

const apiKey = (apiKey: string) => (
    <div>
        {apiKey}
        <AiOutlineCopy
            style={{ marginLeft: 10 }}
            color="#C9D6E8"
            size={20}
            onClick={() => copyText({ value: apiKey, messageSuccess: 'Api Key copied!' })}
            cursor="pointer"
        />
    </div>
);

const DetailsHeaders = (
    userBusiness: IBusiness,
    loadingAction: boolean,
    onChange: (e: CheckboxChangeEvent) => void,
) => {
    const business = userBusiness?.businessID;
    const items = [
        { label: 'Unique Id', value: userBusiness.userUniqueID },
        { label: 'Deleted', value: userBusiness.isDeleted ? 'Yes' : 'No' },
        { label: 'Email', value: userBusiness.email },
        { label: 'Country', value: business.country },
        { label: 'Currency', value: business.currency },
        { label: 'Api Key', value: apiKey(userBusiness.apiKey) },
    ];

    return (
        <Descriptions title={business.companyName}>
            {items.map(({ label, value }) => (
                <Descriptions.Item key={label} label={label}>{value}</Descriptions.Item>
            ))}

            <Descriptions.Item label="Address">
                <Space direction="vertical">
                    {business.fullAddress}
                    <div>
                        {business.addressNumber && (
                            <>
                                <b>Number:</b>
                                {' '}
                                {business.addressNumber}
                                {' '}
                            </>
                        )}
                        {business.building && (
                            <>
                                <b>Building:</b>
                                {' '}
                                {business.building}
                                {' '}
                            </>
                        )}
                    </div>
                    <div>
                        {business.internal && `Internal: ${business.internal} `}
                        {business.external && `External: ${business.external} `}
                    </div>
                    <div>
                        <b>Reference:</b>
                        {' '}
                        {business.addressReference}
                    </div>
                </Space>
            </Descriptions.Item>

            <Descriptions.Item label="API Address">
                <Checkbox
                    onChange={onChange}
                    defaultChecked={userBusiness?.externalAPIConfiguration?.ignoreAPIAddress}
                    disabled={loadingAction}
                >
                    {loadingAction ? <Spin /> : 'Ignore'}
                </Checkbox>
            </Descriptions.Item>
        </Descriptions>
    );
};

const BusinessLocation = ({ business }: { business: IBusinessID }) => {
    const businessLocation = { lat: business.latitude, lng: business.longitude };

    return (
        <Card title="Location">
            <div style={{ height: 300 }}>
                <Map pos1={businessLocation}>
                    <Marker {...businessLocation}>
                        <img
                            style={{ margin: '-30px 0 0 -15px' }}
                            alt="marker"
                            src={IMAGES.icons.store_location}
                        />
                    </Marker>
                </Map>
            </div>
        </Card>
    );
};

const BusinessProviders = ({ userBusiness }: { userBusiness: IBusiness }) => {
    const [loading, setLoading] = useState(false);
    const [deliveryProviders, setDeliveryProviders] = useState<any>([]);
    const { deliveries } = useSelector((state: any) => state.deliveries);

    useEffect(() => {
        setLoading(true);
        getProvidersByCoordinates([
            userBusiness.businessID.longitude.toString(),
            userBusiness.businessID.latitude.toString(),
        ])
            .then(({ data }: any) => {
                const deliveryProviders = data.data;
                const businessDeliveries = get(userBusiness.externalAPIConfiguration, 'deliveryProviders') || [];

                const deliveriesByName: any = businessDeliveries.reduce((accDeliveries, delivery) => ({
                    ...accDeliveries,
                    [delivery.keyName || delivery.name]: delivery,
                }), {});

                const providers = deliveryProviders.reduce(
                    (accDeliveries: any, deliveryKeyName: string) => {
                        const delivery = deliveries.find((d: any) => d.keyName === deliveryKeyName) || {};
                        const isVisible = isUndefined(delivery.isVisible) || delivery.isVisible;
                        const isActive = deliveriesByName[delivery.keyName]?.isActive || deliveriesByName[delivery.name]?.isActive;

                        if (!isVisible && !isActive) return accDeliveries;
                        return [
                            ...accDeliveries,
                            {
                                ...delivery,
                                ...(deliveriesByName[delivery.keyName] || deliveriesByName[delivery.name]),
                                name: delivery.name,
                                isActive,
                            },
                        ];
                    },
                    [],
                );
                setDeliveryProviders(providers);
            })
            .catch((error: any) => {
                console.log({ error });
                const message = get(error, 'response.data.message');
                notification.error({ message });
            })
            .finally(() => setLoading(false));
    }, []);

    const handleDeliveryChange = async (deliveryKey: string, status: boolean) => {
        try {
            setLoading(true);
            const { data } = await updateDeliveryProviderBusinessStatus(userBusiness._id, deliveryKey, status);
            const deliveryUpdated = data.data;
            const deliveryProvidersUpdated = deliveryProviders.map((delivery: any) => {
                if (delivery.keyName === deliveryKey) {
                    return {
                        ...delivery,
                        isActive: deliveryUpdated.isActive,
                        storeStatus: deliveryUpdated.storeStatus,
                    };
                }
                return delivery;
            });
            setDeliveryProviders(deliveryProvidersUpdated);
        } catch (error) {
            console.log(error);
            const message = get(error, 'response.data.message');
            notification.error({ message });
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: '',
            dataIndex: 'image',
            key: 'image',
            width: 50,
            render: (image: string) => <Avatar src={image} />,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name: string, delivery: any) => (
                <>
                    {name}
                    {delivery.storeStatus === 'Rejected' && (
                        <Tag color="red" style={{ marginLeft: 10 }}>
                            Rejected
                        </Tag>
                    )}
                    {delivery.storeStatus === 'PENDING' && (
                        <Tag color="orange" style={{ marginLeft: 10 }}>
                            Pending
                        </Tag>
                    )}
                    {delivery.storeStatus === 'Approved' && (
                        <Tag color="green" style={{ marginLeft: 10 }}>
                            Accepted
                        </Tag>
                    )}
                </>
            ),
        },
        {
            title: 'isActive',
            dataIndex: 'isActive',
            key: 'isActive',
            render: (isActive: boolean, delivery: any) => (
                <Switch
                    loading={loading}
                    checked={isActive}
                    onChange={(status) => handleDeliveryChange(delivery.keyName, status)}
                    style={{ backgroundColor: delivery.storeStatus === 'PENDING' ? 'orange' : '' }}
                />
            ),
        },

    ];

    return (
        <Card title="Providers">
            <Table
                columns={columns}
                dataSource={deliveryProviders}
                rowKey="_id"
                pagination={false}
            />
        </Card>
    );
};
