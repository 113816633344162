/* eslint-disable react/jsx-no-useless-fragment */
const WorkspaceStatusCircle = ({ status }: { status: string }) => {
    switch (status) {
    case 'active': {
        return (
            <path xmlns="http://www.w3.org/2000/svg" d="M26.9119 21.0468C26.9119 22.7854 26.2212 24.4529 24.9918 25.6823C23.7624 26.9117 22.095 27.6024 20.3563 27.6024C18.6177 27.6024 16.9503 26.9117 15.7209 25.6823C14.4915 24.4529 13.8008 22.7854 13.8008 21.0468C13.8008 19.3082 14.4915 17.6407 15.7209 16.4113C16.9503 15.1819 18.6177 14.4912 20.3563 14.4912C22.095 14.4912 23.7624 15.1819 24.9918 16.4113C26.2212 17.6407 26.9119 19.3082 26.9119 21.0468ZM23.7856 18.3459C23.718 18.2781 23.6376 18.2243 23.5491 18.1875C23.4606 18.1508 23.3657 18.1319 23.2699 18.1319C23.1741 18.1319 23.0792 18.1508 22.9907 18.1875C22.9023 18.2243 22.8219 18.2781 22.7542 18.3459L18.8995 22.202L17.9585 21.2595C17.8217 21.1227 17.6362 21.0459 17.4428 21.0459C17.2493 21.0459 17.0638 21.1227 16.9271 21.2595C16.7903 21.3963 16.7134 21.5818 16.7134 21.7752C16.7134 21.9686 16.7903 22.1541 16.9271 22.2909L18.3838 23.7477C18.4515 23.8155 18.5319 23.8693 18.6204 23.9061C18.7089 23.9428 18.8037 23.9617 18.8995 23.9617C18.9954 23.9617 19.0902 23.9428 19.1787 23.9061C19.2672 23.8693 19.3476 23.8155 19.4152 23.7477L23.7856 19.3773C23.8535 19.3097 23.9073 19.2293 23.944 19.1408C23.9807 19.0523 23.9996 18.9574 23.9996 18.8616C23.9996 18.7658 23.9807 18.6709 23.944 18.5824C23.9073 18.4939 23.8535 18.4136 23.7856 18.3459Z" fill="#01EA74" />
        );
    }
    case 'deleted': {
        return (
            <path xmlns="http://www.w3.org/2000/svg" d="M20.6993 27.6023C19.7932 27.6033 18.8957 27.4254 18.0583 27.0789C17.2209 26.7325 16.4601 26.2243 15.8193 25.5833C15.1785 24.9424 14.6704 24.1814 14.3241 23.3438C13.9777 22.5062 13.7999 21.6085 13.8008 20.7021V20.5641C13.8284 19.2112 14.2528 17.8963 15.0214 16.7827C15.79 15.6692 16.8687 14.8061 18.1237 14.3007C19.3786 13.7954 20.7543 13.67 22.0799 13.9403C23.4054 14.2105 24.6223 14.8645 25.5794 15.8208C26.545 16.7858 27.2027 18.0157 27.4692 19.3548C27.7357 20.6938 27.5991 22.0819 27.0766 23.3432C26.5541 24.6046 25.6693 25.6826 24.5341 26.4407C23.3989 27.1989 22.0643 27.6031 20.6993 27.6023V27.6023ZM20.6993 21.675L22.4861 23.4622L23.4588 22.4892L21.672 20.7021L23.4588 18.9149L22.4861 17.942L20.6993 19.7291L18.9126 17.942L17.9399 18.9149L19.7266 20.7021L17.9399 22.4892L18.9126 23.4622L20.6993 21.6757V21.675Z" fill="#FF426F" />
        );
    }
    case 'suspended': {
        return (
            <>
                <path xmlns="http://www.w3.org/2000/svg" d="M17.252 19.3217H24.1525V22.0819H17.252V19.3217Z" fill="#FFD875" />
                <path xmlns="http://www.w3.org/2000/svg" d="M20.7023 13.8012C16.8974 13.8012 13.8018 16.8968 13.8018 20.7018C13.8018 24.5068 16.8974 27.6024 20.7023 27.6024C24.5073 27.6024 27.6029 24.5068 27.6029 20.7018C27.6029 16.8968 24.5073 13.8012 20.7023 13.8012ZM20.7023 26.2223C17.6585 26.2223 15.1819 23.7456 15.1819 20.7018C15.1819 17.6579 17.6585 15.1813 20.7023 15.1813C23.7462 15.1813 26.2228 17.6579 26.2228 20.7018C26.2228 23.7456 23.7462 26.2223 20.7023 26.2223Z" fill="#FFD875" />
            </>

        );
    }

    default: {
        return (<></>);
    }
    }
};

export default WorkspaceStatusCircle;
