import {
    Avatar, Button, Card, Checkbox, Col, Collapse, DatePicker, Dropdown, Form, Input, Menu, notification, Row, Select, Space,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getDeliveryProviders } from '../api/deliveryProviders';
import {
    getDriverSearchReport,
    getReport,
    getTotalOrderReport,
    getTotalOrderReportNew,
    getDriverConnectionReport,
    getManualRequestReport,
    getOperationsSummaryReport,
    getOctopusReport,
} from '../api/reports';
import { IGetTotalOrderReportProps } from '../interfaces/report.interface';
import { DeliveryType } from '../types/delivery';
import { FileType } from '../types/reports';
import { formatServicesRequestReport } from '../utils/formatServicesRequestReport';
import { formatManualRequestReport } from '../utils/reportManualRequest';
import { formatSearchRequestReport } from '../utils/reportSearchService';
import { HEADERS, parseData } from '../utils/userReport';

const { RangePicker }: any = DatePicker;
const { Panel } = Collapse;
const { Option } = Select;

const REPORT_CHOICE = [
    { value: 'inspectorReport', label: 'Inspector Report' },
    { value: 'operationsSummaryReport', label: 'Operations Summary Report' },
    { value: 'workspacesReport', label: 'User\'s Workspace' },
    { value: 'totalOrderRequest', label: 'Total Order Request' },
    { value: 'totalOrderRequestNew', label: 'Total Order Request New' },
    { value: 'driverSearchReport', label: 'Driver Search Report' },
    { value: 'businessDeliveryProvidersReport', label: 'Business Delivery Provider Report' },
    { value: 'totalDriverConnections', label: 'Total Driver Connections Report' },
    { value: 'manualAssignReport', label: 'Manual Assign Report' },
];

const STATUSES = [
    { value: 'PENDING', label: 'Pending' },
    { value: 'ON_DECK', label: 'On deck' },
    { value: 'READY_FOR_PICKUP', label: 'Ready for Pickup' },
    { value: 'ARRIVED_AT_PICKUP', label: 'Arrived at Pickup' },
    { value: 'WAY_TO_DELIVER', label: 'Way to Deliver' },
    { value: 'ARRIVED_AT_DELIVERY', label: 'Arrived at Delivery' },
    { value: 'ACCEPTED', label: 'Accepted' },
    { value: 'ENROUTE_TO_PICKUP', label: 'Enroute to Pickup' },
    { value: 'PICKED_UP', label: 'Picked up' },
    { value: 'ENROUTE_TO_DELIVERY', label: 'Enroute to Delivery' },
    { value: 'DELIVERED', label: 'Delivered' },
    { value: 'COMPLETED', label: 'Completed' },
    { value: 'CANCELLED', label: 'Cancelled' },
    { value: 'CANCELLED_BY_DRIVER', label: 'Cancelled by Driver' },
    { value: 'CANCELLED_BY_CUSTOMER', label: 'Cancelled by Customer' },
    { value: 'CANCELLED_BY_SERVICE_PROVIDER', label: 'Cancel by Service Provider' },
    { value: 'CANCELLED_BY_ADMIN', label: 'Cancelled by Admin' },
    { value: 'EXPIRED', label: 'Expired' },
    { value: 'ON_HOLD', label: 'On hold' },
    { value: 'REJECTED_BY_DRIVER', label: 'Rejected by driver' },
    { value: 'REJECTED_BY_CUSTOMER', label: 'Rejected by customer' },
    { value: 'REJECTED_BY_BUSINESS', label: 'Rejected by business' },
    { value: 'CANCELLED_REQUEST', label: 'Cancelled request' },
    { value: 'CANCELLED_BY_BUSINESS', label: 'Cancelled by business' },
    { value: 'PAYMENT_FAILED', label: 'Payment failed' },
    { value: 'TIME_PASSED', label: 'Time passed' },
    { value: 'NOT_DELIVERED', label: 'Not delivered' },
    { value: 'FAILED', label: 'Failed' },
    { value: 'RETURNING', label: 'ReturnING' },
    { value: 'RETURNED', label: 'Returned' },
    { value: 'RETURNED_TO_PICKUP', label: 'Returned to pickup' },
];

interface ColapsableProps {
  form: any;
}

const Colapsable = ({ form }: ColapsableProps) => (
    <Collapse defaultActiveKey={['1']}>
        <Panel header="Colums in reports" key="1">
            <Form.Item
                shouldUpdate={
                    (prev, current) => prev.report !== current.report
                }
            >
                {() => {
                    const { report = 'workspacesReport' } = form.getFieldsValue();
                    const columns = HEADERS[report];
                    return (
                        <Form.Item name="selectedRows" initialValue={Object.keys(columns)}>
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row>
                                    {Object.keys(columns).map((key: any) => (
                                        <Col key={key} span={8}>
                                            <Checkbox value={key}>{columns[key].label}</Checkbox>
                                        </Col>
                                    ))}
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    );
                }}
            </Form.Item>
        </Panel>
    </Collapse>
);

export const Reports = () => {
    const [loading, setLoading] = useState(false);
    const [fileType, setFileType] = useState<FileType>(FileType.CSV);
    const [deliveries, setDeliveries] = useState<DeliveryType[]>([]);
    const [form] = Form.useForm();

    useEffect(() => {
        getDeliveryProviders()
            .then(({ data }: any) => setDeliveries(data.deliveryProviders))
            .catch(console.log);
    }, []);

    const handleSubmit = async (values: any) => {
        setLoading(true);
        try {
            const utc = (moment().utcOffset() / 60).toString();
            const {
                dateRange, report, fileName, selectedRows, bookingNumericId, providerName, status,
            } = values;
            const [startDate, endDate] = dateRange;
            const formattedStartDate = moment(startDate).startOf('day');
            const formattedEndDate = moment(endDate).endOf('day');
            let rows = [];
            if (report === 'totalOrderRequest') {
                const { data } = await getTotalOrderReport(formattedStartDate.format(), formattedEndDate.format(), providerName, bookingNumericId, status);
                rows = data.data.CSVData;
            } else if (report === 'totalOrderRequestNew') {
                const TotalOrderReportNewProps: IGetTotalOrderReportProps = {
                    startDate: formattedStartDate.format(),
                    endDate: formattedEndDate.format(),
                    providerName,
                    bookingNumericId,
                    status,
                    utc,
                };
                const { data } = await getTotalOrderReportNew(TotalOrderReportNewProps);
                rows = data.data;
            } else if (report === 'newServiceRequestReport') {
                const { data } = await getReport(formattedStartDate.format(), formattedEndDate.format(), report);
                rows = formatServicesRequestReport(data.data.CSVData);
            } else if (report === 'driverSearchReport') {
                const { data } = await getDriverSearchReport(formattedStartDate.format(), formattedEndDate.format());
                rows = formatSearchRequestReport(data.data.data);
            } else if (report === 'manualAssignReport') {
                const { data } = await getManualRequestReport(formattedStartDate.format(), formattedEndDate.format());
                rows = formatManualRequestReport(data.data.data);
            } else if (report === 'totalDriverConnections') {
                const { data } = await getDriverConnectionReport(formattedStartDate.format(), formattedEndDate.format(), utc);
                rows = data.data;
            } else if (report === 'operationsSummaryReport') {
                const { data } = await getOperationsSummaryReport(formattedStartDate.format(), formattedEndDate.format(), utc);
                rows = data;
            } else if (report === 'inspectorReport') {
                const { data } = await getOctopusReport(formattedStartDate.format(), formattedEndDate.format(), report, utc);
                rows = data;
            } else {
                const { data } = await getReport(formattedStartDate.format(), formattedEndDate.format(), report);
                rows = data.data.CSVData;
            }

            parseData({
                rows, fileName, selectedRows, headersType: report, fileType,
            });
            notification.info({
                message: 'Tu reporte se está descargando',
            });
        } catch (error: any) {
            const message = error.response?.data?.message;
            notification.error({
                message,
            });
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const requiredRule = { required: true, message: 'Requerido' };

    const handleChangeFileType = (type: any) => {
        setFileType(type.key);
    };

    const menu = (
        <Menu onClick={handleChangeFileType}>
            <Menu.Item key={FileType.CSV}>CSV</Menu.Item>
            <Menu.Item key={FileType.XLSX}>XLSX</Menu.Item>
        </Menu>
    );

    const toggleSelection = (value: boolean) => {
        const { report } = form.getFieldsValue();
        form.setFieldsValue({ selectedRows: value ? Object.keys(HEADERS[report]) : [] });
    };
    return (
        <div style={{ marginTop: 40 }}>
            <Card>
                <Form
                    onFinish={handleSubmit}
                    layout="vertical"
                    requiredMark={false}
                    size="large"
                    form={form}
                    onValuesChange={(changedValues, allValues) => {
                        if (changedValues.report) {
                            form.setFieldsValue({ selectedRows: Object.keys(HEADERS[changedValues.report]) });
                        }
                    }}
                >
                    <Form.Item
                        style={{ display: 'inline-block', width: 'calc(35% - 8px)' }}
                        name="dateRange"
                        label="Fecha"
                        rules={[requiredRule]}
                    >
                        <RangePicker style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        style={{ display: 'inline-block', width: 'calc(30% - 8px)', margin: '0 8px' }}
                        initialValue="workspacesReport"
                        name="report"
                        label="Reporte"
                        rules={[requiredRule]}
                    >
                        <Select options={REPORT_CHOICE} />
                    </Form.Item>
                    <Form.Item
                        style={{ display: 'inline-block', width: 'calc(35% - 8px)' }}
                        initialValue="PickerReports"
                        name="fileName"
                        label="File"
                        rules={[requiredRule]}
                    >
                        <Input placeholder="fileName" />
                    </Form.Item>

                    <Form.Item
                        shouldUpdate={
                            (prev, current) => prev.report !== current.report
                        }
                        noStyle
                    >
                        {() => {
                            const { report } = form.getFieldsValue();
                            if (!report || (report !== 'totalOrderRequest' && report !== 'totalOrderRequestNew')) return null;
                            return (
                                <>
                                    <Form.Item
                                        name="providerName"
                                        label="Provider"
                                        style={{ display: 'inline-block', width: 'calc(35% - 8px)' }}
                                    >
                                        <Select allowClear>
                                            {deliveries.map((delivery) => (
                                                <Option key={delivery.keyName} value={delivery.keyName} label={delivery.name}>
                                                    <Space>
                                                        <Avatar size="small" src={delivery.image} />
                                                        {delivery.name}
                                                    </Space>
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name="bookingNumericId"
                                        label="Booking Numeric Id"
                                        style={{ display: 'inline-block', width: 'calc(30% - 8px)', margin: '0 8px' }}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name="status"
                                        label="Status"
                                        style={{ display: 'inline-block', width: 'calc(30% - 8px)', margin: '0 8px' }}
                                    >
                                        <Select allowClear options={STATUSES} />
                                    </Form.Item>
                                </>
                            );
                        }}
                    </Form.Item>
                    <Colapsable form={form} />
                    <Row gutter={50} justify="center">
                        <Col>
                            <Button type="link" onClick={() => toggleSelection(true)}>
                                Select all
                            </Button>
                        </Col>
                        <Col>
                            <Button type="link" onClick={() => toggleSelection(false)}>
                                Deselect all
                            </Button>
                        </Col>
                    </Row>
                    <Form.Item style={{ marginTop: '15px' }}>
                        <Dropdown.Button
                            overlay={menu}
                            trigger={['click']}
                            type="primary"
                            buttonsRender={([leftButton, rightButton]) => [
                                React.cloneElement(leftButton as React.ReactElement<any, string>, { loading, htmlType: 'submit' }),
                                rightButton,
                            ]}
                        >
                            Download
                            {' '}
                            {fileType}
                        </Dropdown.Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};
