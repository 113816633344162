import {
    Card, Col, Menu, PageHeader, Row, Select, Typography,
} from 'antd';

interface Props {
    handleAlertType: (value: string) => void;
    handleStatus: (value: string) => void;
    setShowFilters: (show: boolean) => void;
    resetFilters: () => void;
}

const { Title } = Typography;

const Filters = ({ ...props }: Props) => {
    const OPTIONS_TYPE = [
        {
            value: 'info',
            label: 'Informative',
        },
        {
            value: 'success',
            label: 'Success',
        },
        {
            value: 'warning',
            label: 'Warning',
        },
        {
            value: 'error',
            label: 'Error',
        },
    ];

    const OPTIONS_IS_ACTIVE = [
        { label: 'Active', value: 'ACTIVE' },
        { label: 'Inactive', value: 'INACTIVE' },
    ];

    const ITEMS = [
        {
            key: '1',
            label: (
                <Card
                    bordered
                    className="card-filters"
                >
                    <PageHeader
                        title={(
                            <Title level={5} style={{ margin: 0 }}>
                                Filters
                            </Title>
                        )}
                        style={{
                            padding: 0,
                            width: '100%',
                            marginBottom: 12,
                        }}
                        extra={[
                            <span
                                style={{ cursor: 'pointer', fontSize: 12 }}
                                onClick={() => props.resetFilters()}
                            >
                                Reset
                            </span>,
                            <span
                                style={{ cursor: 'pointer', fontSize: 12 }}
                                onClick={() => props.setShowFilters(false)}
                            >
                                Close
                            </span>,
                        ]}
                    />

                    <Row justify="start" align="top" gutter={[12, 12]}>
                        <Col span={24}>
                            <h4>Alert Type</h4>
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Select alert type"
                                options={OPTIONS_TYPE}
                                onChange={(value: string) => props.handleAlertType(value)}
                            />
                        </Col>
                        <Col span={24}>
                            <h4>State alert</h4>
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Select state alert"
                                options={OPTIONS_IS_ACTIVE}
                                onChange={(value: string) => props.handleStatus(value)}
                            />
                        </Col>
                    </Row>
                </Card>
            ),
        },
    ];

    return (
        <Menu items={ITEMS} />
    );
};

export default Filters;
