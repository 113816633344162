import {
    Form, Modal, Input, Tooltip, Space, Typography, notification,
} from 'antd';
import { useContext, useState } from 'react';
import { BsLightbulb } from 'react-icons/bs';
import { addTransaction } from '../../../api/dispersions';
import { IDispersion } from '../../../interfaces/dispersion.interface';
import { DispersionContext } from '../../DispersionDetail/context/dispersionContext';

const { TextArea } = Input;
const { Text } = Typography;

interface IProps {
    dispersion: IDispersion
    visible: boolean;
    onCancel: () => void;
}

export const NewTransaction = ({
    visible, onCancel, dispersion,
}: IProps) => {
    const { fetchDispersion } = useContext(DispersionContext);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values: any) => {
        setLoading(true);
        const payload = {
            ...values,
            dispersionID: dispersion._id,
        };

        !values.bookingID && delete payload.bookingID;

        addTransaction(payload)
            .then(() => {
                notification.success({
                    message: 'Transaction added successfully',
                });
                fetchDispersion();
            })
            .catch((error) => {
                const description = error.response?.data?.message;
                notification.error({
                    message: 'Error adding transaction',
                    description,
                });
            })
            .finally(() => {
                setLoading(false);
                form.resetFields();
                onCancel();
            });
    };

    const RULES = [
        {
            required: true,
            message: 'Please input amount!',
        },
    ];

    return (
        <Modal
            visible={visible}
            title="Added new transaction"
            onCancel={onCancel}
            onOk={() => form.submit()}
            okText="Add"
            confirmLoading={loading}
        >
            <Space direction="vertical" size={24}>
                <Space direction="horizontal" size={8}>
                    <BsLightbulb color="#FFF" size={14} />
                    <Text>
                        Remember to be clear in your comments as it will make it easier to understand for future reference.
                    </Text>
                </Space>

                <Text>
                    <Text strong>Dispersion ID:</Text>
                    {' '}
                    {dispersion._id}
                </Text>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    initialValues={{
                        currency: dispersion.currency,
                    }}
                >
                    <Form.Item hidden name="currency">
                        <Input />
                    </Form.Item>

                    <Tooltip
                        title="You can set bookingID if you want to pay for a specific booking"
                        showArrow
                        placement="topLeft"
                    >
                        <Form.Item name="bookingID" label="BookingID (optional)">
                            <Input />
                        </Form.Item>
                    </Tooltip>

                    <Form.Item
                        name="amount"
                        label="Amount"
                        rules={RULES}
                    >
                        <Input type="number" />
                    </Form.Item>

                    <Form.Item
                        name="comment"
                        label="Comment"
                        rules={RULES}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                </Form>
            </Space>
        </Modal>
    );
};
