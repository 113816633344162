import { Modal } from 'antd';
import { useContext, useMemo } from 'react';
import { TiersConfigurationContext } from '../../context/tiersConfigurationContext';
import AddContent from './AddContent';
import DeleteContent from './DeleteContent';
import EditContent from './EditContent';

const ModalAction = () => {
    const { modalData, elementSelected, updateActionModal } = useContext(TiersConfigurationContext);

    const handleCancel = () => updateActionModal(null);

    const modalContent: any = useMemo(() => {
        if (elementSelected && modalData) {
            const title = ` ${elementSelected.type} ${modalData.data.type === 'deliveryProvider' ? 'Delivery Provider' : ''}`;
            return {
                edit: {
                    title: `Edit ${title} tier: ${elementSelected.name}`,
                    component: <EditContent />,
                },
                delete: {
                    title: `Delete ${title} tier: ${elementSelected.name}`,
                    component: <DeleteContent />,
                },
                add: {
                    title: `Add ${title} tier`,
                    component: <AddContent />,
                },
            };
        }
    }, [elementSelected, modalData]);

    return (
        modalData
        && (
            <Modal
                title={modalContent[modalData.data.action].title}
                visible={modalData.active}
                width="30%"
                footer={false}
                onCancel={handleCancel}
            >
                {modalContent[modalData.data.action].component}
            </Modal>
        )
    );
};

export default ModalAction;
