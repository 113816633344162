import {
    Card,
    Dropdown, Menu, notification, Table,
} from 'antd';
import { BsThreeDots } from 'react-icons/bs';

import { DASHBOARD_BASE_URL } from '../../const/envs';
import { resendUserInvitation } from '../../api/workspaces';

interface WorkspaceInvitationTableProps {
  id?: string;
  invitations: {
    data: any[];
    loading: boolean;
    paginator:{
      limit: number;
      skip: number;
  }
 }
 fetchInvitations: (params: any) => void
}

interface WorkspaceInvitationsMenuProps {
    invitation: any;
    id?: string | any;

}

const WorkspaceInvitationsMenu = ({
    invitation, id,
}: WorkspaceInvitationsMenuProps) => {
    const copyInvitationLink = async (invitedEmail: string) => {
        const linkToCopy = `${DASHBOARD_BASE_URL}/user-invited?email=${invitedEmail}`;
        try {
            await navigator.clipboard.writeText(linkToCopy);
            notification.success({
                message: ('Invitation link copied successfully'),
            });
        } catch (error) {
            notification.success({
                message: ('Invitation link not copied'),
            });
        }
    };

    const resendEmailInvitation = (invitedEmail: string) => {
        resendUserInvitation(invitedEmail, id)
            .then(() => {
                notification.success({
                    message: 'Invitation successfully resent',
                });
            })
            .catch((error: any) => {
                const description = error.response.data.message;
                notification.error({
                    message: 'Failed to resend invitation',
                    description,
                });
            });
    };

    const menu = [
        {
            children: 'Resend Invitation',
            onClick: () => resendEmailInvitation(invitation.invitedEmail),
        },
        {
            children: 'Copy Invitation',
            onClick: () => copyInvitationLink(invitation.invitedEmail),
        },
    ];

    return (
        <Menu>
            <Menu.ItemGroup key="g">
                {menu.map((o, i) => (
                    <Menu.Item key={i} {...o} />
                ))}
            </Menu.ItemGroup>
        </Menu>
    );
};

export const WorkspaceInvitationsTable = ({
    id, invitations, fetchInvitations,
}: WorkspaceInvitationTableProps) => {
    const columns = [
        {
            title: 'Email',
            dataIndex: 'invitedEmail',
            key: 'invitedEmail',
        },
        {
            title: 'User role',
            dataIndex: 'roleID',
            key: 'roleID',
            render: (roleID: any) => (
                roleID.keyName
            ),
        },
        {
            title: 'Accesses',
            dataIndex: 'roleID',
            key: 'roleID',
            render: (roleID: any) => {
                const total = roleID.permissions.length - 1;
                return (
                    <span>
                        {`${roleID.permissions[0]}, +${total}` }
                    </span>
                );
            },
        },
        {
            title: 'Stores',
            dataIndex: 'business',
            key: 'stores',
            render: (business: string[]) => {
                const total = business.length;
                return (
                    <span>
                        {
                            total === 0
                                ? 'All stores'
                                : `${total} ${total === 1 ? 'store' : 'stores'}`
                        }
                    </span>
                );
            },
        },
        {
            title: 'Actions',
            dataIndex: 'statusText',
            key: 'actions',
            width: 100,
            fixed: 'right' as 'right',
            render: (_statusText: any, record: any) => (
                <Dropdown
                    placement="bottomRight"
                    overlay={(
                        <WorkspaceInvitationsMenu
                            invitation={record}
                            id={id}
                        />
                    )}
                    // @ts-ignore
                    onClick={(e: any, a: any) => { e.stopPropagation(); }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            <BsThreeDots size={25} />
                        </a>
                    </div>
                </Dropdown>
            ),
        },
    ];

    const onPageChange = (page: number, pageSize: number) => {
        fetchInvitations({ limit: pageSize, skip: page });
    };

    return (
        <div>
            <Card
                style={{ width: '100%' }}
            >
                <Table
                    dataSource={invitations.data}
                    columns={columns}
                    loading={invitations.loading}
                    rowKey="userID"
                    scroll={{ x: 800 }}
                    pagination={{
                        current: invitations.paginator.skip || 1,
                        pageSize: invitations.paginator.limit,
                        onChange: onPageChange,
                        showSizeChanger: true,
                    }}
                />
            </Card>
        </div>
    );
};
