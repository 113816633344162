import { useContext } from 'react';
import { Drawer, Space } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import Information from './Information';
import { ExpressDeliveryContext } from '../context/express-delivery.context';
import DeliveryData from './DeliveryData';

const DeliveryDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { expressSelected: expressDelivery, setExpressSelected } = useContext(ExpressDeliveryContext);

    if (!expressDelivery) return null;

    return (
        <Drawer
            title="Express Delivery Detail"
            open={!!expressDelivery}
            onClose={() => {
                setExpressSelected(null);
                const query = new URLSearchParams(location.search);
                query.delete('expressDeliveryId');
                navigate(`/v2/express-delivery?${query.toString()}`);
            }}
            width={640}
        >
            <Space direction="vertical" size={32} style={{ width: '100%' }}>
                <Information
                    createdAt={expressDelivery.createdAt}
                    customerName={expressDelivery.customerName}
                    customerLastName={expressDelivery.customerLastName}
                    businessName={expressDelivery.businessName}
                    businessID={expressDelivery.businessID}
                    workspaceUniqueID={expressDelivery.workspaceUniqueID}
                    workspaceID={expressDelivery.workspaceID}
                    workspaceName={expressDelivery.workspaceName}
                />

                <DeliveryData
                    description={expressDelivery.description}
                    customerCountryCode={expressDelivery.customerCountryCode}
                    customerMobile={expressDelivery.customerMobile}
                    weight={expressDelivery.weight}
                    isFragile={expressDelivery.isFragile}
                    volume={expressDelivery.volume}
                    deliveryLocation={{
                        address: expressDelivery.deliveryAddress,
                        coordinates: expressDelivery.deliveryCoordinates,
                        references: 'N/A',
                    }}
                    pickupLocation={{
                        address: expressDelivery.pickupAddress,
                        coordinates: expressDelivery.pickupCoordinates,
                        references: 'N/A',
                    }}
                />
            </Space>
        </Drawer>
    );
};

export default DeliveryDetail;
