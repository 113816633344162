import axios from 'axios';
import { BASE_URL } from './booking';

const getDriverTracking = () => axios.get(`${BASE_URL}/driver/getDriverTracking?carType=3&status=3`);

const getRegions = () => axios.get(`${BASE_URL}/region/listAllRegion?limit=100&skip=0&isBlocked=false&isDeleted=false`);

const getDriverTags = () => axios.get(`${BASE_URL}/admin/getAllDriverTags`);

const getBusiness = (search: string) => {
    const query = {
        searchUser: search,
        limit: '12',
        skip: '0',
        orderBy: 'createdAt',
        sort: 'DESC',
        status: 'EXISTING',
    };
    const urlParams = new URLSearchParams(query);
    return axios.get(`${BASE_URL}/admin/getAllBusinesses?${urlParams.toString()}`);
};

export {
    getDriverTracking,
    getRegions,
    getDriverTags,
    getBusiness,
};
