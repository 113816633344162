import moment from 'moment';

export const formatManualRequestReport = (data: any) => {
    const report = [];
    for (let i = 0; i < data.length; ++i) {
        const actual = data[i];

        report.push({
            bookingID: actual.bookingID,
            bookingNumericId: actual.bookingNumericId,
            driverID: actual.driverID,
            driverName: actual.driverName,
            lastDriverID: actual.lastDriver || '',
            lastDriverName: actual.lastDriverName || '',
            dateOfAssignment: moment(actual.createdAt).format('YYYY-MM-DD'),
        });
    }
    return report;
};
