import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import IssuesScreen from '../screens/Issues';
import { IssuesProvider } from '../components/Issues/context/issues.context';
import { ChatProvider } from '../components/Chat/context/chatContext';

const IssuesRouter: FC = () => (
    <Routes>
        <Route
            path=""
            element={(
                <IssuesProvider>
                    <ChatProvider>
                        <IssuesScreen />
                    </ChatProvider>
                </IssuesProvider>
            )}
        />
    </Routes>
);

export default IssuesRouter;
