import cs from 'classnames';
import {
    Card, Typography, Row, Col, Space, Spin, PageHeader, Button, Modal, notification, Dropdown, Drawer,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import { BiLinkExternal } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import IssueTimeline from './Timeline';
import CardDataBase from '../../CardDataBase';
import Attachment from './Attachment';
import { ShieldContext } from '../context/shieldContext';
import { deleteIssue } from '../../../api/issues';
import ChangeStatus from './ChangeStatus';
import AssignedResponsable from './AssignedResponsable';
import ContainerChat from './chat/Container';
import { markNotificationsAsRead } from '../../../api/workspaces';

const { Title, Text } = Typography;
const { confirm } = Modal;

const IssueData = () => {
    const {
        loadingDetail, issueSelected, issue, fetchIssueDetail, removeIssue, setChatOpen, handleReadNotification,
    } = useContext(ShieldContext);
    const hasAttachments = issue?.attachments?.length > 0;

    const WORKSPACE_DATA = [
        {
            title: 'Workspace Name',
            content: issue?.workspaceName,
        },
        {
            title: 'Workspace Unique ID',
            content: (
                <Link to={`/v2/workspaces/${issue?.workspaceUniqueID}`}>
                    <Space
                        size={8}
                        direction="horizontal"
                        style={{ cursor: 'pointer' }}
                    >
                        <Text style={{ color: '#00B2E3' }}>
                            {`# ${issue?.workspaceUniqueID}`}
                        </Text>
                        <BiLinkExternal color="#00B2E3" style={{ verticalAlign: 'middle' }} size={16} />
                    </Space>
                </Link>
            ),
        },
    ];
    const ISSUE_DATA = [
        {
            title: 'Issue ID',
            content: issue?.issuePublicID,
        },
        {
            title: 'Start Date',
            content: moment(issue?.createdAt).format('dddd, D MMM'),
        },
        {
            title: 'Assigned to',
            content: <AssignedResponsable />,
        },
        {
            title: 'Status',
            content: (
                <Dropdown overlay={<ChangeStatus />} trigger={['click']}>
                    <a onClick={(e) => e.preventDefault()} style={{ color: 'white' }}>
                        <Space>
                            <Text className={cs(issue?.status)}>
                                {issue?.status}
                            </Text>
                            <DownOutlined />
                        </Space>
                    </a>
                </Dropdown>
            ),
        },
        {
            title: 'Issue objection',
            content: (
                <Space size={8} direction="horizontal" style={{ cursor: 'pointer' }}>
                    <Text>
                        {issue?.objectionPublicID ? (
                            <Link
                                to={`/v2/objected-requests/${issue?.objectionPublicID}`}
                                style={{ color: '#00B2E3' }}
                            >
                                {issue?.objectionPublicID}
                                <BiLinkExternal
                                    color="#00B2E3"
                                    style={{ verticalAlign: 'middle', paddingLeft: '5px' }}
                                    size={16}
                                />
                            </Link>
                        ) : (
                            'No objection'
                        )}
                    </Text>
                </Space>
            ),
        },
    ];

    const BOOKING_DATA = [
        {
            title: 'Booking Numeric ID',
            content: (
                <Link to={`/v2/booking?id=${issue?.bookingData?._id}`}>
                    <Space
                        size={8}
                        direction="horizontal"
                        style={{ cursor: 'pointer' }}
                    >
                        <Text style={{ color: '#00B2E3' }}>
                            {`# ${issue?.bookingData?.bookingNumericId}`}
                        </Text>
                        <BiLinkExternal color="#00B2E3" style={{ verticalAlign: 'middle' }} size={16} />
                    </Space>
                </Link>
            ),
        },
        {
            title: 'Current Status',
            content: issue?.bookingData?.statusText,
        },
        {
            title: 'Payment Method',
            content: issue?.bookingData?.paymentMethod,
        },
        {
            title: 'Customer',
            content: issue?.bookingData?.customerName,
        },

    ];

    const BUSINESS_DATA = [
        {
            title: 'Business Name',
            content: issue?.bookingData?.businessName,
        },
        {
            title: 'Business Unique ID',
            content: (
                <Link to={`/v2/business/${issue?.bookingData?.business}`}>
                    <Space
                        size={8}
                        direction="horizontal"
                        style={{ cursor: 'pointer' }}
                    >
                        <Text style={{ color: '#00B2E3' }}>
                            {`# ${issue?.bookingData?.businessUniqueID}`}
                        </Text>
                        <BiLinkExternal color="#00B2E3" style={{ verticalAlign: 'middle' }} size={16} />
                    </Space>
                </Link>
            ),
        },
    ];

    useEffect(() => {
        fetchIssueDetail(issueSelected);
    }, [issueSelected]);

    if (loadingDetail) {
        return (
            <Space
                direction="vertical"
                size={8}
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Spin />
                <Text>
                    Getting issue data...
                </Text>
            </Space>
        );
    }

    const handleDeleteIssue = () => {
        confirm({
            width: 500,
            title: (
                <Title level={4}>
                    Are you sure to delete this issue?
                </Title>
            ),
            content: (
                <Space direction="vertical" size={8}>
                    <Text>
                        {`Description: ${issue.description}`}
                    </Text>
                    <Text>
                        {`Status: ${issue.status} - Assigned to: ${issue?.responsableName || 'Unassigned'}`}
                    </Text>
                </Space>
            ),
            onCancel: () => {},
            onOk: () => {
                const issueID = issue._id;
                deleteIssue(issueID)
                    .then(() => {
                        notification.success({
                            message: 'Issue deleted successfully',
                        });
                        removeIssue(issueID);
                    })
                    .catch((error: any) => {
                        const descritpion = error?.response?.data?.message || error.message;
                        notification.error({
                            message: 'Error',
                            description: descritpion,
                        });
                    })
                    .finally(() => {});
            },
        });
    };

    return (
        <div>
            <ContainerChat />
            <PageHeader
                title="Issue Detail"
                style={{ padding: 0 }}
                extra={(
                    <Space size={16}>
                        <Button
                            type="primary"
                            onClick={() => {
                                handleReadNotification(issue._id);
                                setChatOpen(true);
                            }}
                        >
                            Open Chat
                        </Button>
                        <Button
                            danger
                            type="primary"
                            onClick={() => handleDeleteIssue()}
                        >
                            Delete Issue
                        </Button>
                    </Space>
                )}
                footer={(
                    <Row gutter={[24, 32]} justify="start" align="top">
                        <Col xs={24} lg={12}>
                            <Space direction="vertical" size={24} style={{ width: '100%' }}>
                                <CardDataBase title="Issue Data" style={{ minHeight: 200 }}>
                                    {
                                        ISSUE_DATA.map((data, index) => (
                                            <Row align="middle" justify="space-between" style={{ margin: '16px 0' }} key={index}>
                                                <Col>
                                                    <Text strong>
                                                        {data.title}
                                                        :
                                                    </Text>
                                                </Col>
                                                <Col>{data.content}</Col>
                                            </Row>
                                        ))
                                    }
                                </CardDataBase>

                                <CardDataBase title="Workspace Data" style={{ minHeight: 180 }}>
                                    {
                                        WORKSPACE_DATA.map((data, index) => (
                                            <Row align="middle" justify="space-between" style={{ margin: '16px 0' }} key={index}>
                                                <Col>
                                                    <Text strong>
                                                        {data.title}
                                                        :
                                                    </Text>
                                                </Col>
                                                <Col>{data.content}</Col>
                                            </Row>
                                        ))
                                    }
                                </CardDataBase>

                                <CardDataBase title="Business Data" style={{ minHeight: 180 }}>
                                    {
                                        BUSINESS_DATA.map((data, index) => (
                                            <Row align="middle" justify="space-between" style={{ margin: '16px 0' }} key={index}>
                                                <Col>
                                                    <Text strong>
                                                        {data.title}
                                                        :
                                                    </Text>
                                                </Col>
                                                <Col>{data.content}</Col>
                                            </Row>
                                        ))
                                    }
                                </CardDataBase>

                                <CardDataBase title="Associated Order" style={{ minHeight: 100 }}>
                                    {
                                        BOOKING_DATA.map((data, index) => (
                                            <Row align="middle" justify="space-between" style={{ margin: '16px 0' }} key={index}>
                                                <Col>
                                                    <Text strong>
                                                        {data.title}
                                                        :
                                                    </Text>
                                                </Col>
                                                <Col>{data.content}</Col>
                                            </Row>
                                        ))
                                    }
                                </CardDataBase>
                            </Space>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Space direction="vertical" size={24} style={{ width: '100%' }}>
                                <Card bordered>
                                    <Title level={4}>Description</Title>
                                    <Text>
                                        {' '}
                                        {issue?.description}
                                        {' '}
                                    </Text>
                                    <Title level={4}>
                                        {`Attachments (${issue?.attachments?.length || 0})`}
                                    </Title>

                                    <Row gutter={{
                                        xs: 8, sm: 16, md: 24, lg: 32,
                                    }}
                                    >
                                        {
                                            hasAttachments
                                                ? (
                                                    issue?.attachments?.map((attachment, index) => (
                                                        <Col
                                                            key={index}
                                                            span={10}
                                                            style={{ marginBottom: 16 }}
                                                            className="attachment"
                                                        >
                                                            <Attachment
                                                                url={attachment}
                                                                index={index + 1}
                                                            />
                                                        </Col>
                                                    ))
                                                ) : (
                                                    <Text style={{ paddingLeft: 18 }}>
                                                        No attachments available
                                                    </Text>
                                                )
                                        }
                                    </Row>
                                </Card>

                                <Card bordered>
                                    <Title level={4}>
                                        Timeline Issue
                                    </Title>
                                    <IssueTimeline statusUpdate={issue?.statusUpdate} />
                                </Card>
                            </Space>
                        </Col>
                    </Row>
                )}
            />
        </div>
    );
};

export default IssueData;
