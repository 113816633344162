import {
    Dropdown, Menu, Table, Tag,
} from 'antd';
import { Dispatch, SetStateAction, useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { checkDriverStatus } from '../../screens/CommandCenter/utils/driver';

import { IUser } from '../../types/bussiness';
import { RemoveDriverModal } from '../Modals';

interface WorkspaceDriversTableProps {
  id?: string;
  setShowAddDriverModal: Dispatch<SetStateAction<any>>;
  drivers: {
    data: any[],
    count: number;
    loading: boolean;
    paginator:{
      limit: number;
      skip: number;
    }
  }
  fetchDrivers: (params: any) => void
}
interface WorkspaceDriversMenuProps {
    id?: string
    user: IUser
    setRemoveDriver: Dispatch<SetStateAction<any>>
    setShowAddDriverModal: Dispatch<SetStateAction<any>>
  }

const WorkspaceDriversMenu = ({
    user, id, setRemoveDriver, setShowAddDriverModal,
}: WorkspaceDriversMenuProps) => {
    const menu = [
        {
            children: 'Edit Driver',
            onClick: () => {
                setShowAddDriverModal({ visible: true, type: 'Edit', user });
            },
        },
        {
            children: 'Remove From Workspaces',
            onClick: () => {
                setRemoveDriver({ ...user, id });
            },
        },
    ];

    return (
        <Menu>
            <Menu.ItemGroup key="g">
                {menu.map((o, i) => (
                    <Menu.Item key={i} {...o} />
                ))}
            </Menu.ItemGroup>
        </Menu>
    );
};

const DRIVER_STATUS_COLOR = {
    BUSY: 'gold',
    ONLINE: 'green',
    OFFLINE: 'default',
};

export const WorkspaceDriversTable = ({
    id, setShowAddDriverModal, drivers, fetchDrivers,
}: WorkspaceDriversTableProps) => {
    const [removeDriver, setRemoveDriver] = useState(null);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 200,
            ellipsis: true,
            render: (name: any, driver: any) => `${name} ${driver.lastName}`,
        },
        {
            title: 'Active',
            dataIndex: 'onDutyFlag',
            key: 'onDutyFlag',
            width: 100,
            render: (onDutyFlag: boolean, driver: any) => {
                const status = checkDriverStatus(driver.isBusy, onDutyFlag).toUpperCase();
                return (
                    <Tag color={(DRIVER_STATUS_COLOR as any)[status]}>
                        {status}
                    </Tag>
                );
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone',
            dataIndex: 'fullMobile',
            key: 'fullMobile',
            width: 200,
            ellipsis: true,
        },
        {
            title: 'Business',
            dataIndex: 'business',
            key: 'business',
            width: 100,
            render: (business: string[], driver: any) => (driver.hasAllBusiness ? 'All' : business.length),
        },
        {
            title: 'Methods',
            dataIndex: 'availablePaymentMethods',
            key: 'availablePaymentMethods',
            width: 200,
            render: (availablePaymentMethods: string[]) => {
                const hasAll = availablePaymentMethods.length === 3 || availablePaymentMethods.length === 0;
                return (
                    <div>
                        {hasAll ? 'All' : (
                            availablePaymentMethods.join(', ')
                        )}
                    </div>
                );
            },
        },
        {
            title: 'Actions',
            dataIndex: 'statusText',
            key: 'actions',
            width: 100,
            fixed: 'right' as 'right',
            // eslint-disable-next-line react/display-name
            render: (_statusText: any, record: any) => (
                <Dropdown
                    placement="bottomRight"
                    overlay={(
                        <WorkspaceDriversMenu
                            user={record}
                            id={id}
                            setRemoveDriver={setRemoveDriver}
                            setShowAddDriverModal={setShowAddDriverModal}
                        />
                    )}
                    // @ts-ignore
                    onClick={(e: any, a: any) => { e.stopPropagation(); }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            <BsThreeDots size={25} />
                        </a>
                    </div>
                </Dropdown>
            ),
        },
    ];

    const onPageChange = (page: number, pageSize: number) => {
        fetchDrivers({ limit: pageSize, skip: page });
    };

    return (
        <div>
            <Table
                dataSource={drivers.data}
                columns={columns}
                loading={drivers.loading}
                rowKey="userID"
                scroll={{ x: 800 }}
                pagination={{
                    total: drivers.count,
                    current: drivers.paginator.skip || 1,
                    pageSize: drivers.paginator.limit,
                    onChange: onPageChange,
                    showSizeChanger: true,
                }}
            />
            <RemoveDriverModal setRemoveDriver={setRemoveDriver} removeDriver={removeDriver} setReload={() => fetchDrivers(drivers.paginator)} />
        </div>
    );
};
