import moment from 'moment';
import { Drawer, Spin, Typography } from 'antd';
import { useContext, useEffect } from 'react';
import { ChatContext } from '../../context/chatContext';
import { ShieldContext } from '../../context/shieldContext';
import Chat from './Chat';
import CreateChat from './CreateChat';
import MessageInfo from './MessageInfo';

const { Text, Paragraph } = Typography;

const ContainerChat = () => {
    const {
        loading, selectedMessage, chat, fetchChat,
    } = useContext(ChatContext);
    const {
        issue, informationOpen, setInformationOpen, chatOpen, setChatOpen,
    } = useContext(ShieldContext);

    useEffect(() => {
        if (!issue) return;
        fetchChat(issue?.issuePublicID);
    }, []);

    return (
        <Drawer
            width="60%"
            title="Support Chat"
            visible={chatOpen}
            placement="right"
            onClose={() => setChatOpen(false)}
        >
            <Drawer
                width="20%"
                title="Information Message"
                visible={informationOpen}
                placement="right"
                onClose={() => setInformationOpen(false)}
            >
                {
                    selectedMessage ? (
                        <MessageInfo />
                    ) : (
                        <div className="chat__information">
                            <Paragraph style={{ textAlign: 'center' }}>
                                No message selected
                            </Paragraph>
                        </div>
                    )
                }
            </Drawer>
            {
                loading ? (
                    <div className="chat__loading">
                        <Spin />
                        <Paragraph style={{ textAlign: 'center' }}>
                            Loading chat
                        </Paragraph>
                    </div>
                ) : chat && chat._id ? (
                    <Chat />
                ) : (
                    <CreateChat />
                )
            }
        </Drawer>
    );
};

export default ContainerChat;
