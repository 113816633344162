import { types } from '../types/types';

interface ActionProps {
  type: string;
  payload: any;
}

export const planReducer = (state = {}, action: ActionProps) => {
    switch (action.type) {
    case types.plansStartLoading:
        return {
            ...state,
            loading: true,
        };

    case types.plansFinishLoading:
        return {
            ...state,
            loading: false,
        };

    case types.plansLoad:
        return {
            ...state,
            plans: [...action.payload],
        };

    default:
        return state;
    }
};
