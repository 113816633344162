import { Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { IGuide } from '../../../interfaces/express-delivery.interface';

interface Props {
    item: IGuide;
    setItemSelected: (item: IGuide) => void;
}

const TableMenu = ({ item, setItemSelected }: Props) => {
    const navigate = useNavigate();

    const OPTIONS = [
        {
            children: 'View detail',
            onClick: () => {
                setItemSelected(item);
                navigate(`/v2/express-delivery/${item._id}`);
            },
        },
    ];

    return (
        <Menu>
            <Menu.ItemGroup
                key="g"
                title="Actions"
            >
                {
                    OPTIONS.map((option, index) => (
                        <Menu.Item key={index} {...option} />
                    ))
                }
            </Menu.ItemGroup>
        </Menu>
    );
};

export default TableMenu;
