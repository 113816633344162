/* eslint-disable max-classes-per-file */
import moment from 'moment';
import { CANCELLED_STATUSES, FINISHED_STATUSES, STATUSES } from '../const/booking';
import { PaymentMethods } from '../enums/paymentMethods';
import { diffTimeInMinutes } from '../utils';
import { millisToMinutesAndSeconds } from '../utils/bookings';

export interface Image {
  original: null;
  thumbnail: null;
}
export interface Payment {
  maxTries: number;
  actualTries: number;
}

export interface Billing {
  isMRR: boolean;
  method: string;
}

export interface DeliveryProvider {
  clientId: null;
  clientSecret: null;
  isActive: boolean;
  storeStatus: null | string;
  index: number;
  storeToken: null;
  companyToken: null;
  name: string;
}
export interface ExternalAPIConfiguration {
  payment: Payment;
  billing: Billing;
  apiPermissions: string[];
  deliveryProviders: DeliveryProvider[];
  externalIntegration: boolean;
  usePickerDrivers: boolean;
  deactivatedBy: null;
  maxDeliveryDistance: number;
  sumOrderAndDelivery: boolean;
}

export interface Contact {
  mobile: string;
  mobileOTP: number;
  countryCode: string;
  otpUpdatedAt: Date;
  _id: string;
  role: string;
  isPrimary: boolean;
  isVerified: boolean;
}
export interface Customer {
  _id: string;
  updatedAt: Date;
  createdAt: Date;
  countryCode: string;
  mobile: string;
  deviceToken: string;
  fullMobile: string;
  status: string;
  favoriteBusinesses: any[];
  favoriteProducts: any[];
  favoriteDrivers: any[];
  online: boolean;
  isBusy: boolean;
  onDutyFlag: boolean;
  promoAdded: null;
  isPhoneVerified: boolean;
  isEmailVerified: boolean;
  isAdminVerified: boolean;
  isDeleted: boolean;
  isBlocked: boolean;
  image: Image;
  socialAccounts: any[];
  businessAddressID: null;
  driverAddressID: null;
  serviceProviderAddressID: null;
  customerAddressID: string;
  businessID: null;
  driverID: null;
  serviceProviderID: null;
  customerID: string;
  role: string[];
  totalCreatedUsers: number;
  assignedTo: null;
  assignedBy: null;
  createdBy: null;
  ratedByUserCount: number;
  totalRatingPoints: number;
  passwordResetToken: null;
  cronHardDeleteCount: number;
  rememberMe: boolean;
  identityNumber: string;
  dateOfBirth: string;
  lastName: string;
  name: null;
  contacts: Contact[];
  __v: number;
  externalAPIConfiguration: ExternalAPIConfiguration;
}

export interface DeliveryCoordinates {
  coordinates: number[];
  type: string;
}

export interface DistanceBreakUp {
  extraDistance: number;
  baseDistance: number;
  billedDistance: number;
  realDistance: number;
  exactDistance?: number;
}

export interface PriceBreakUp {
  rushHourIncreaseType: string;
  rainCharge: number;
  rushHourCharge: number;
  rushHourIncrementAmount: number;
  rushHourIncreaseCoefficient: number;
  companyPaymentDriver: number;
  companyPaymentBusiness: number;
  driverExtraCommission: number;
  notShowCharge: number;
  shopOrderRecharge: number;
  workBalance: number;
  taxCharges: number;
  tip: number;
  donation: number;
  comission: number;
  referralDiscount: number;
  promoDiscount: number;
  receivedByBusiness: number;
  receivedByDriver: number;
  businessExtraPayment: number;
  extraPayment: number;
  businessPayment: number;
  operationBonus: number;
  companyPayment: number;
  driverPayment: number;
  orderAmount: number;
  perMinCharge: number;
  perKmCharge: number;
  baseFare: number;
  notificationCharge: number;
  businessOrderAmount?: number;
  businessDeliveryFee: number;
}
export interface KMRange {
  amount: number;
  max: number;
  min: number;
}
export interface PricingDetails {
  baseDistance: number;
  freeTime: number;
  perMinuteCharge: number;
  perKmCharge: number;
  baseFare: number;
  averageTime: number;
  rushHourIncrementAmount: number;
  rushHourIncreaseCoefficient: number;
  specialFeesActive: any[];
  rainCharge: number;
  minimumCancellationFee: number;
  cancellationFreeTimeInSecond: number;
  cancellationPolicy: string;
  notShowDriverCommission: number;
  notShowCustomerCharge: number;
  notShowTimeLimit: number;
  kmRanges: KMRange[];
}

export enum BookingStatus {
  PENDING = 0,
  ACCEPTED = 1,
  ARRIVED_AT_PICKUP = 2,
  WAY_TO_DELIVER = 3,
  ARRIVED_AT_DELIVERY = 4,
  COMPLETED = 5,
  CANCELLED_BY_ADMIN = 6,
  CANCELLED = 7,
  CANCELLED_BY_DRIVER = 8,
  REJECTED_BY_DRIVER = 9,
  CANCELLED_REQUEST = 10,
  NOT_DELIVERED = 11,
  DRIVER_NOT_FOUND = 12,
  NO_PICKERS_IN_AREA = 13,
  EXPIRED = 14,
  ADMIN_NOTIFICATION = 15,
  CANCEL_BOOKING = 16,
  RE_ASSIGN_DRIVER = 17,
  RE_ASSIGN_BOOKING = 18,
  OUT_OF_REGIONS = 19,
  ON_HOLD = 20,
  ON_DECK = 21,
  READY_FOR_PICKUP = 22,
  CANCELLED_BY_BUSINESS = 23,
  BUSINESS_NOT_FOUND = 24,
  NOT_SHOW = 25,
  REJECTED_BY_BUSINESS = 26,
  REJECTED_BY_ADMIN = 27,
  PAYMENT_FAILED = 28,
  RETURNING = 32,
  RETURNED = 33,
  RETURNED_TO_PICKUP = 34,
}

export interface TransactionDetails {
  tipCharge: null;
  donationCharge: null;
  cancellationCharge: null;
  deliveryCharge: null;
  itemCharge: null;
}

export interface RegisteredByEvent {
  _id: string;
  name: string;
  lastName?: string;
  email?: string;
}

export interface BookingOrderStatus {
  _id: string;
  updatedAt: Date;
  createdAt: Date;
  externalId: string;
  externalStatus: string;
  externalSupportId?: string;
  isActive: boolean;
  providerFee: number;
  providerName: string;
  rideId: string;
  status: string;
  errorMessage: string;
  currency: string;
}

export class BookingEvent {
  _id: string;

  description: string;

  origin?: string;

  bookingID: string;

  type: string;

  updatedAt: Date;

  createdAt: Date;

  registeredBy: RegisteredByEvent;

  isAdmin?: boolean;

  isEmulated?: boolean;

  constructor(partial: BookingEvent) {
      Object.assign(this, partial);
  }

  get date() {
      return moment(this.createdAt).format('HH:mm:ss:SSS');
  }

  get registeredByText() {
      return this.registeredBy ? `${this.registeredBy.name} ${this.registeredBy.lastName || ''}` : '';
  }
}

export class BookingType {
  _id: string;

  updatedAt: Date;

  createdAt: Date;

  cancellationDate: Date;

  bookingCompletionDateTime: Date;

  bookingNumericId: number;

  customerName: string;

  customerMobile: string;

  paymentMethod: PaymentMethods;

  bookingType: number;

  typeText: string;

  business: string;

  businessName: string;

  businessUniqueID: string;

  customer: Customer;

  completedCustomerBookings: number;

  customerDeviceType: string;

  paymentMethodCode: number;

  corporateID: string;

  carType: number;

  carName: string;

  pricingDetails: PricingDetails;

  bookingAutoAccepted: boolean;

  chatType: string;

  refunds: any[];

  corporateChargeStatus: string;

  sendInvoice: boolean;

  minimumDriverCash: number;

  productReferencesImages: any[];

  products: any[];

  realPosition: DeliveryCoordinates;

  isArrivingSoon: boolean;

  transactionDetails: TransactionDetails;

  isRated: boolean;

  cancelReasonByServiceProvider: null;

  maximumCancellationDate: Date;

  orderUpdates: any[];

  isLaterBooking: boolean;

  statusUpdates: any[];

  statusText: string;

  currentStatus: number;

  totalTime: number;

  isTimeStarted: boolean;

  bidResponded: boolean;

  bidProcessing: boolean;

  bidAccepted: boolean;

  bidTotal: number;

  recentlyRequestedAmount: number;

  requestedAmount: number;

  orderReceived: boolean;

  moneyAccepted: boolean;

  moneyRequested: boolean;

  otherUserID: null;

  superRegionID: string;

  regionID: string;

  distanceBreakUp: DistanceBreakUp;

  distanceTravelled: number;

  totalAmount: number;

  totalPay: number;

  ServiceAmount: number;

  orderAmount: number;

  deliveryFee: number;

  pendingAmount: number;

  priceBreakUp: PriceBreakUp;

  bookingNotes: null;

  bookingDate: Date;

  subtype: string;

  vehicleColor: null;

  pickupCoordinates: DeliveryCoordinates;

  pickupAddress: string;

  pickupReferences: string;

  pickupZipCode: null;

  pickupState: null;

  pickupCity: null;

  deliveryCoordinates: DeliveryCoordinates;

  deliveryAddress: string;

  deliveryReferences: string;

  deliveryZipCode: string;

  deliveryState: string;

  deliveryCity: string;

  driverPickerPoints: null;

  distancePointA: number;

  distancePointB: number;

  driverTag: null;

  driverEmail: string;

  driverImage: Image;

  driverMobile: string;

  driverName: string;

  driver: null;

  elapsedTime: number;

  customerSegment: null;

  customerEmail: string;

  customerImage: Image;

  businessTag: null;

  businessMobile: string;

  businessImage: Image;

  smrToken: string;

  deliveryProvider: string;

  isProofOfDeliveryApproved: boolean;

  proofOfDelivery: string;

  proofOfDeliveryType: string;

  requiresProofOfDelivery: boolean;

  proofValidationCode: string;

  isActiveOnBatch?: boolean;

  userBookingCreator: string;

  workspaceName: string;

  workspaceID: string;

  externalBookingId: string;

  tip?: number

  currency: string;

  rating: number;

  review: string;

  deliveryZone?: string;

  constructor(partial: BookingType) {
      Object.assign(this, partial);
  }

  get isCompleted() {
      return this.statusText === STATUSES.COMPLETED.TEXT;
  }

  get isCancelled() {
      return CANCELLED_STATUSES.includes(this.statusText);
  }

  getBookingDate() {
      return this.bookingDate ? new Date(this.bookingDate).toDateString() : 'N/A';
  }

  getCancelledAt() {
      return moment(this.cancellationDate).local().format('HH:mm:ss');
  }

  getCompletedAt() {
      return moment(this.bookingCompletionDateTime).local().format('HH:mm:ss');
  }

  getRequestTotalTime() {
      const startDate = this.createdAt;
      let endDate;
      if (CANCELLED_STATUSES.includes(this.statusText)) {
          endDate = this.cancellationDate;
      } else if (this.statusText === STATUSES.COMPLETED.TEXT) {
          endDate = this.bookingCompletionDateTime;
      } else {
          return 'N/A';
      }
      const diff = Math.abs(new Date(endDate).getTime() - new Date(startDate).getTime());
      return millisToMinutesAndSeconds(diff);
  }

  getTotalBilledTime() {
      return millisToMinutesAndSeconds(this.totalTime * 1000);
  }

  getPromoDiscount() {
      return this.priceBreakUp.promoDiscount === 0 ? '-' : this.priceBreakUp.promoDiscount.toFixed(2);
  }

  getReferralDiscount() {
      return this.priceBreakUp.referralDiscount === 0 ? '-' : this.priceBreakUp.referralDiscount.toFixed(2);
  }

  getTotalDiscount() {
      return (this.priceBreakUp.promoDiscount + this.priceBreakUp.referralDiscount).toFixed(2);
  }

  getDeliveryFee() {
      const {
          baseFare,
          perMinCharge,
          perKmCharge,
      } = this.priceBreakUp;
      return (
          baseFare
        + perMinCharge
        + perKmCharge
      );
  }

  getTotalPay() {
      const { promoDiscount, referralDiscount, orderAmount } = this.priceBreakUp;
      const deliveryFee = this.getDeliveryFee();
      return deliveryFee + orderAmount - promoDiscount - referralDiscount;
  }

  getMinutes() {
      let end;
      const isCompleted = this.statusText === STATUSES.COMPLETED.TEXT;
      if (
          (isCompleted && !this.bookingCompletionDateTime)
      || (!isCompleted && FINISHED_STATUSES.includes(this.statusText))
      ) {
          end = this.updatedAt;
      } else {
          end = this.bookingCompletionDateTime || moment().toDate();
      }
      return diffTimeInMinutes(this.createdAt, end);
  }
}
