import cs from 'classnames';
import {
    Col, Row, Space, Tooltip, Typography,
} from 'antd';
import { BsFillInfoSquareFill } from 'react-icons/bs';

interface ItemProps {
    label: string;
    value: string | number | React.ReactNode;
    info?: boolean;
    infoLabel?: string;
    actionRequired?: boolean;
    actionLabel?: string;
    actionIcon?: React.ReactNode;
    action?: () => void;
}

const { Text } = Typography;

const ItemData = ({
    label, value, info = false, infoLabel = '', actionRequired = false, actionLabel = '', actionIcon = null, action = () => {},
}: ItemProps) => (
    <Row justify="space-between" align="middle" style={{ width: '100%', marginBottom: 8 }}>
        <Col span={10}>
            <Space size={8}>
                <Text className="card-express__text-label">
                    {label}
                </Text>
                {
                    info && (
                        <Tooltip placement="top" title={infoLabel}>
                            <BsFillInfoSquareFill
                                size={12}
                                color="#687385"
                                style={{ verticalAlign: 'middle' }}
                            />
                        </Tooltip>
                    )
                }
            </Space>
        </Col>
        <Col span={14} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Text
                className={cs('card-express__text-value', { link: actionRequired })}
                onClick={actionRequired ? action : () => {}}
            >
                {value}
            </Text>
            {
                actionRequired && (
                    <Tooltip placement="right" title={actionLabel}>
                        <Text onClick={action}>
                            {actionIcon}
                        </Text>
                    </Tooltip>
                )
            }
        </Col>
    </Row>
);

export default ItemData;
