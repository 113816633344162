import moment from 'moment';
import { Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { OctopusPaginatorQuery } from '../../../types/paginator';

interface Props {
    users: any[];
    loading: boolean;
    paginator: OctopusPaginatorQuery;
    total: number;
    setPaginator: (paginator: OctopusPaginatorQuery) => void;
}

const { Text } = Typography;

const UsersTable = ({
    users, loading, paginator, total, setPaginator,
}: Props) => {
    const COLUMNS = [
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string) => (
                <Text>
                    {moment(createdAt).format('DD MMM YY')}
                </Text>
            ),
        },
        {
            title: 'User Unique ID',
            dataIndex: 'userUniqueID',
            key: 'userUniqueID',
            render: (userUniqueID: string, _: any) => (
                <Link style={{ color: '#08d108' }} to={`/v2/users/${_._id}`}>
                    {userUniqueID}
                </Link>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Last name',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile',
            render: (mobile: string, _: any) => (
                <Text>
                    {`${_.countryCode}${mobile}`}
                </Text>
            ),
        },
    ];

    return (
        <Table
            columns={COLUMNS}
            dataSource={users}
            loading={loading}
            rowKey="_id"
            rowClassName={(record: any) => (record.isDeleted ? 'table-row-warning' : 'booking-row')}
            pagination={{
                total,
                current: paginator.page,
                pageSize: paginator.limit,
                onChange: (page: number, pageSize: number) => setPaginator({ limit: pageSize, page }),
                showSizeChanger: true,
            }}
        />
    );
};

export default UsersTable;
