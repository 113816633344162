import {
    Button, PageHeader, Row, Space, Typography, Col, Spin, Steps, Card, Modal,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { BsLayoutSidebarInset, BsSearch } from 'react-icons/bs';
import { TPDMonitorContext } from './context/monitor.context';
import BookingPreview from '../BookingPreview';
import { BookingEvent, BookingOrderStatus } from '../../types/booking';
import { IMonitorData } from './context/monitor.interface';
import CardEvent from './components/Event';
import CardOrder from './components/Order';
import { validateTypeEvent } from './utils';
import CardLog from './components/Log';
import { IBookingLog } from '../../interfaces/booking-log.interface';
import CardCloudWatch from './components/CloudWatch';
import AsyncButton from '../AsyncButton/AsyncButton';
import { toggleActivateMonitor } from '../../api/monitor';
import { startLoadRide } from '../../actions/ride';

const { Title, Text } = Typography;

const TPDMonitor = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const {
        data, loading, showBookingDrawer, setShowBookingDrawer, fetchMonitorData,
    } = useContext(TPDMonitorContext);
    const { active } = useSelector((state: RootStateOrAny) => state.ride);

    const [eventSelected, setEventSelected] = useState<IMonitorData>();

    const getLastEventDescription = (event: any) => {
        if (!event) return '';
        const registeredByName = `${event?.registeredBy?.name} ${event?.registeredBy?.lastName}`;
        const adminSuffix = event?.isAdmin ? ' from Octopus Admin' : '';

        return (
            <Text>
                {event.description}
                {event?.registeredBy && ` by ${registeredByName} `}
                {event?.isAdmin && adminSuffix}
            </Text>
        );
    };

    const handleActivateMonitoring = async () => {
        try {
            const { data } = await toggleActivateMonitor();
            Modal.success({
                title: 'Monitoring changed',
                content: data.message,
            });
        } catch (error) {
            console.error(error);
        }
    };

    const getBookingId = () => {
        const regex = /\/booking\/(.*?)\/monitor/;
        const match = location.pathname.match(regex);

        if (match && match.length > 1) {
            return match[1];
        }
    };

    useEffect(() => {
        if (!active?._id) {
            const bookingId = getBookingId();
            bookingId && dispatch(startLoadRide(bookingId));
            return;
        }
        fetchMonitorData(active?._id, active?.bookingNumericId, active?.workspaceName);
    }, [active?._id]);

    return (
        <Space direction="vertical" size={24} style={{ width: '100%' }}>

            {showBookingDrawer && (
                <BookingPreview
                    booking={active}
                    open={showBookingDrawer}
                    onClose={() => setShowBookingDrawer(false)}
                />
            )}

            <PageHeader
                onBack={() => navigate(-1)}
                title={`3PD Monitor | Nº ${active?.bookingNumericId}`}
                extra={[
                    <AsyncButton
                        icon={(
                            <BsSearch style={{
                                verticalAlign: 'middle',
                                marginRight: 8,
                            }}
                            />
                        )}
                        onClick={handleActivateMonitoring}
                    >
                        Activate Monitoring
                    </AsyncButton>,
                    <Button
                        type="primary"
                        icon={(
                            <BsLayoutSidebarInset style={{
                                verticalAlign: 'middle',
                                marginRight: 8,
                            }}
                            />
                        )}
                        onClick={() => setShowBookingDrawer(true)}
                    >
                        Booking
                    </Button>,
                ]}
                style={{ margin: '24px 0', padding: 0 }}
            />

            <Title level={4}>
                All Activity
            </Title>

            {
                loading ? (
                    <Space size={8}>
                        <Spin size="small" />
                        <Text>
                            Loading...
                        </Text>
                    </Space>
                ) : (
                    <Row style={{ width: '100%' }} gutter={24} justify="space-between" align="top">
                        <Col span={10}>
                            <div className="timeline__list">
                                <Steps
                                    progressDot
                                    size="small"
                                    direction="vertical"
                                    current={data.length}
                                    items={
                                        data.map((item: IMonitorData) => ({
                                            title: (
                                                <div className="timeline__list--item">
                                                    {
                                                        item.type === 'EVENT' && (
                                                            <CardEvent
                                                                type={validateTypeEvent(item.data?.type as 'SUCCESS' | 'FAILED' | 'RESOLVING')}
                                                                showDetail={false}
                                                                booking={active}
                                                                bookingEvent={item.data as BookingEvent}
                                                                bookingEventSelected={item.data === eventSelected?.data}
                                                            />
                                                        )
                                                    }
                                                    {
                                                        item.type === 'ORDER' && (
                                                            <CardOrder
                                                                showDetail={false}
                                                                booking={active}
                                                                bookingOrder={item.data as BookingOrderStatus}
                                                                bookingItemSelected={item.data === eventSelected?.data}
                                                            />
                                                        )
                                                    }
                                                    {
                                                        item.type === 'LOG' && (
                                                            <CardLog
                                                                showDetail={false}
                                                                booking={active}
                                                                bookingLog={item.data as IBookingLog}
                                                                bookingItemSelected={item.data === eventSelected?.data}
                                                            />
                                                        )
                                                    }
                                                    {
                                                        item.type === 'CLOUDWATCH' && (
                                                            <CardCloudWatch
                                                                showDetail={false}
                                                                bookingLog={item.data as any}
                                                                bookingItemSelected={item.data === eventSelected?.data}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            ),
                                            onClick: () => setEventSelected(item),
                                        }))
                                    }
                                />
                            </div>
                        </Col>
                        <Col span={14}>
                            <Card
                                bordered
                                style={{
                                    borderColor: '#303030',
                                    backgroundColor: 'transparent',
                                    borderRadius: 8,
                                }}
                            >
                                {
                                    eventSelected?.type === 'EVENT' && (
                                        <CardEvent
                                            showDetail
                                            booking={active}
                                            bookingEvent={eventSelected?.data as BookingEvent}
                                            bookingEventSelected={false}
                                        />
                                    )
                                }
                                {
                                    eventSelected?.type === 'ORDER' && (
                                        <CardOrder
                                            showDetail
                                            booking={active}
                                            bookingOrder={eventSelected?.data as BookingOrderStatus}
                                            bookingItemSelected={false}
                                        />
                                    )
                                }
                                {
                                    eventSelected?.type === 'LOG' && (
                                        <CardLog
                                            showDetail
                                            booking={active}
                                            bookingLog={eventSelected?.data as IBookingLog}
                                            bookingItemSelected={false}
                                        />
                                    )
                                }
                                {
                                    eventSelected?.type === 'CLOUDWATCH' && (
                                        <CardCloudWatch
                                            showDetail
                                            bookingLog={eventSelected?.data as any}
                                            bookingItemSelected={false}
                                        />
                                    )
                                }
                            </Card>
                        </Col>
                    </Row>
                )
            }
        </Space>
    );
};

export default TPDMonitor;
