// Component only for debugging
import { useContext, useEffect, useState } from 'react';
import { Button, Modal, Space } from 'antd';
import { RootStateOrAny, useSelector } from 'react-redux';
import moment from 'moment';
import { TPDMonitorContext } from './context/monitor.context';
import { IMonitorData } from './context/monitor.interface';
import { getBookingSummary, getSummary, sendVote } from '../../api/picker-chat';
import { socketInstance } from '../../context/SocketClientContext';
import socketEvents from '../../const/socketEvent';
import { OpenAIMessage } from '../../interfaces/picker-chat.interface';

const PDSummary = () => {
    const [prompt, setPrompt] = useState<string>('');
    const [answer, setAnswer] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [voteResult, setVoteResult] = useState<boolean | null>(null);
    const {
        data,
        workspaceName,
        bookingNumericId,
    } = useContext(TPDMonitorContext);
    const auth = useSelector((state: RootStateOrAny) => state.auth);

    const filter = (item: IMonitorData): { title: string, createdAt: string } | null => {
        if (item.type === 'EVENT') {
            if (item.data.type === 'NEW_BOOKING') {
                return {
                    title: 'Envío creado',
                    createdAt: item.data.createdAt,
                };
            }
        } else if (item.type === 'LOG') {
            // creation order
            if (item.data.description?.includes('to create request with')) {
                const providerText = item.data.description.split('to create request with ')[1];
                const provider = providerText.split(' ')[0];
                const status = item.data.description.includes('Succeeded') ? 'exitosamente' : 'con error';
                let errorMessage = '';
                if (status === 'con error') {
                    // eslint-disable-next-line no-useless-escape
                    const errorMessageRegex = /errorMessage":"(.*)"}/;
                    const errorMessageMatch = item.data.additionalInfo.match(errorMessageRegex);
                    console.log(errorMessageMatch);
                    errorMessage = errorMessageMatch ? `: ${errorMessageMatch[1]}` : '';
                }
                return {
                    title: `Orden creada con ${provider} ${status}${errorMessage}`,
                    createdAt: item.data.createdAt,
                };
            }
            // cancel order
            if (item.data.description?.includes('to cancel request with')) {
                const providerText = item.data.description.split('to cancel request with ')[1];
                const provider = providerText.split(' ')[0];
                const status = item.data.description.includes('Succeeded') ? 'exitosamente' : 'con error';
                let errorMessage = '';
                if (status === 'con error') {
                    // eslint-disable-next-line no-useless-escape
                    const errorMessageRegex = /errorMessage":(.*)}/;
                    const errorMessageMatch = item.data.additionalInfo.match(errorMessageRegex);
                    console.log(errorMessageMatch);
                    errorMessage = errorMessageMatch ? `: ${errorMessageMatch[1].replaceAll('"', '')}` : '';
                }
                return {
                    title: `Orden cancelada con ${provider} ${status}${errorMessage}`,
                    createdAt: item.data.createdAt,
                };
            }
        } else if (item.type === 'CLOUDWATCH') {
            // const regex = /Request received from (.*) changing to (.*)/;
            const regex = /Update status from (.*) changing to (.*)/;
            const match = item.data.description.match(regex);
            if (match) {
                const [_, provider, status] = match;
                if (!status || status === 'undefined') return null;
                return {
                    title: `${provider} cambió a ${status}`,
                    createdAt: item.data.createdAt,
                };
            }
        }
        return null;
    };
    useEffect(() => {
        if (data.length === 0) return;
        const summary = data.map(filter).filter((item) => item !== null);
        const firstItemDate = moment(summary[0]!.createdAt);
        const eventLine = summary.map((item) => `-${item!.title} a las ${moment(item!.createdAt).format('HH:mm:ss:SSS')}`).join('\n');
        const prompt = `En base a estos eventos del envío ${bookingNumericId} del workspace ${workspaceName} del día ${moment(firstItemDate).format('DD/MM/YYYY')}:\n${eventLine}\nDime en puntos específicos lo que no está bien`;
        setPrompt(prompt);
        getBookingSummary(bookingNumericId)
            .then(({ data: response }: any) => {
                if (response.data) {
                    setAnswer(response.data.message);
                    setVoteResult(response.data.vote);
                    setLoading(false);
                } else {
                    setLoading(true);
                    const name = `${auth?.name} ${auth?.lastName}`;
                    const email = auth?.email;
                    const metadata = {
                        bookingId: bookingNumericId,
                        name,
                        email,
                    };
                    getSummary(prompt, metadata)
                        .catch((error) => {
                            console.error(error);
                            setLoading(false);
                        });
                }
            })
            .catch((error: any) => console.log(error));
    }, [data]);

    useEffect(() => {
        socketInstance.emit(socketEvents.PICKER_CHAT_JOIN_CHAT, 'picker-chat');
        socketInstance.on(socketEvents.PICKER_CHAT_NEW_MESSAGE, (payload: OpenAIMessage) => {
            setAnswer(payload.content[0].text.value);
            setLoading(false);
        });
        return () => {
            socketInstance.emit(socketEvents.PICKER_CHAT_LEAVE_CHAT, 'picker-chat');
            socketInstance.off(socketEvents.PICKER_CHAT_NEW_MESSAGE);
        };
    }, []);

    const vote = async (vote: boolean) => {
        if (voteResult !== null) return;
        await sendVote(bookingNumericId.toString(), vote);
        setVoteResult(vote);
    };

    const seeEvents = () => {
        Modal.info({
            width: 800,
            content: (
                <div style={{ whiteSpace: 'pre-line' }}>
                    {prompt}
                </div>
            ),
            onOk() { },
        });
    };

    return (
        <div>
            <Button onClick={seeEvents}>See Events</Button>
            {loading && <div style={{ marginTop: 10 }}>Loading summary...</div>}
            <div style={{ whiteSpace: 'pre-line' }}>
                {answer}
            </div>
            {answer && (
                <Space>
                    <Button
                        onClick={() => vote(true)}
                        ghost={voteResult !== true}
                        type="primary"
                    >
                        Good
                    </Button>
                    <Button
                        onClick={() => vote(false)}
                        ghost={voteResult !== false}
                        type="primary"
                        danger
                    >
                        Bad
                    </Button>
                </Space>
            )}
        </div>
    );
};

export default PDSummary;
