import {
    Button, Modal, notification, Select,
} from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { closeModal } from '../../../actions/modals';
import { changePaymentMethod } from '../../../api/booking';
import { ModalTypes } from '../../../types/modals';

const { Option } = Select;

interface IChangePaymentMethod {
    onSucess?: () => void
}

export const ChangePaymentMethod = ({ onSucess }: IChangePaymentMethod) => {
    const [loading, setLoading] = useState(false);

    const { activeModal, modalData } = useSelector((state: any) => state.modals);
    const [selected, setSelected] = useState<string | null>();
    const isOpen = activeModal === ModalTypes.ChangePaymentMethod;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onFinish = async () => {
        if (!selected) return;
        setLoading(true);

        try {
            await changePaymentMethod(modalData._id, selected);

            notification.success({
                message: 'Payment method changed successfully!',
                description: 'See booking detail',
                onClick: () => {
                    navigate(`/v2/booking?id=${modalData._id}`);
                },
            });

            dispatch(closeModal());
            setSelected(null);
            onSucess && onSucess();
        } catch (error) {
            console.log(error);
            notification.error({
                message: 'An error has occurred when canceling the service',
            });
        } finally {
            setLoading(false);
        }
    };

    const onSelect = (value: string) => {
        setSelected(value);
    };

    const onCancel = () => {
        dispatch(closeModal());
        setSelected(null);
    };

    const PAYMENT_METHODS = [
        // {
        //    title: "Billing Transfer",
        //    value: "BILLING_TRANSFER"
        // },
        {
            title: 'Card',
            value: 'CARD',
        },
        {
            title: 'Cash',
            value: 'CASH',
        },
    ];

    return (
        <Modal
            width="30%"
            visible={isOpen}
            title={`Change Payment Method: ${modalData?.bookingNumericId}`}
            onCancel={onCancel}
            onOk={onFinish}
            confirmLoading={loading}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" disabled={!selected} type="primary" loading={loading} onClick={onFinish}>
                    Ok
                </Button>,
            ]}
        >
            <Select
                style={{ width: '100%' }}
                placeholder="Select Payment Methods"
                optionFilterProp="children"
                onSelect={onSelect}
            >
                {PAYMENT_METHODS.map((payment) => (modalData?.paymentMethod === payment.value ? null : <Option value={payment.value}>{payment.title}</Option>))}
            </Select>
        </Modal>
    );
};
