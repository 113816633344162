import { createContext, useEffect, useState } from 'react';

interface Props extends React.PropsWithChildren<{}> {
    socketURL: string;
}

export const SocketChatContext = createContext<any>(null);

export const SocketChatProvider = ({ children, socketURL }: Props) => {
    const [socketInstance, setSocketInstance] = useState<WebSocket | null>(null);

    useEffect(() => {
        if (!socketURL) return;
        setSocketInstance(new WebSocket(socketURL));
        return () => {
            socketInstance?.close();
        };
    }, [socketURL]);

    return (
        <SocketChatContext.Provider
            value={{ socketInstance }}
        >
            {children}
        </SocketChatContext.Provider>
    );
};
