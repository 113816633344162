import moment from 'moment';
import cs from 'classnames';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Space, Tooltip, Typography } from 'antd';
import { IssuesContext } from '../context/issues.context';
import { IIssue } from '../../../interfaces/issue.interface';
import Actions from './Actions';

export interface ColumnProps {
    title: string;
    dataIndex: string;
    key: string;
    align?: 'center' | 'left' | 'right';
    render?: (value: any, record: any) => React.ReactNode;
}

const { Text } = Typography;

const InitialColumns: () => ColumnProps[] = () => {
    const { setItem, fetchData } = useContext(IssuesContext);

    const INITIAL_COLUMNS = [
        {
            title: 'Issue ID',
            dataIndex: 'issuePublicID',
            key: 'issuePublicID',
            render: (text: string) => (
                <Text>
                    {text}
                </Text>
            ),
        },
        {
            title: 'Date & Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center' as 'center',
            render: (text: string) => {
                const date = moment(text).format('dddd, D MMM');
                const time = moment(text).format('h:mm a');
                return (
                    <Space size={4} direction="vertical">
                        <Text>
                            {date}
                        </Text>
                        <Text>
                            {time}
                        </Text>
                    </Space>
                );
            },
        },
        {
            title: 'Booking associated',
            dataIndex: 'bookingNumericId',
            key: 'bookingNumericId',
            align: 'center' as 'center',
            render: (text: string, item: IIssue) => (
                <Link to={`/v2/booking?id=${item.bookingID}`}>
                    <Space direction="vertical">
                        <Text style={{ color: '#177ddc' }}>
                            {text}
                        </Text>
                    </Space>
                </Link>
            ),
        },
        {
            title: 'Workspace',
            dataIndex: 'workspaceName',
            key: 'workspaceID',
            align: 'center' as 'center',
            render: (text: string, item: IIssue) => (
                <Link to={`/v2/workspaces/${item.workspaceID}`}>
                    <Space direction="vertical">
                        <Text style={{ color: '#177ddc' }}>
                            {text}
                        </Text>
                        <Text type="secondary">
                            {item?.workspaceUniqueID || ''}
                        </Text>
                    </Space>
                </Link>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center' as 'center',
            render: (text: string) => (
                <Text className={cs(text, 'status')}>
                    {text}
                </Text>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: (text: string) => (
                <Tooltip title={text}>
                    <Text
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {text.length > 30 ? `${text.slice(0, 30)}...` : text}
                    </Text>
                </Tooltip>
            ),
        },
        {
            title: 'Actions',
            dataIndex: '_id',
            key: 'actions',
            align: 'center' as 'center',
            render: (_id: string, record: IIssue) => (
                <Actions
                    item={record}
                    setItem={setItem}
                    fetchData={fetchData}
                />
            ),
        },
    ];

    return INITIAL_COLUMNS as ColumnProps[];
};

export default InitialColumns;
