import {
    TDeliveryProviders, TModalData, TRegion, TSuperRegion, TTier,
} from '../../../types/faresWorker';

type TGenerateBaseData = {
    elementSelected:TRegion | TSuperRegion,
    modalData: TModalData,
}

type TGenerateDataToUpdate = TGenerateBaseData & {
    values: any
}

type TGenerateDataToDelete = TGenerateBaseData;

type TGenerateDataToAdd = {
    elementSelected:TRegion | TSuperRegion,
    values: any
};

const generateDataToUpdate = ({
    elementSelected,
    modalData,
    values,
}: TGenerateDataToUpdate) => {
    const currentElement = elementSelected;

    if (elementSelected.tiers && modalData?.data.type === 'tier') {
        const newTiers = elementSelected.tiers.map((tier) => {
            if (tier.index === (modalData?.data.content as TTier).index) {
                return {
                    ...tier,
                    time: values.time,
                    isActive: values.isActive,
                    index: values.index,
                };
            }
            return tier;
        });

        currentElement.tiers = newTiers;
        return currentElement;
    }

    if (modalData?.data.type === 'deliveryProvider') {
        if (!elementSelected.deliveryProviders) {
            currentElement.deliveryProviders = [
                {
                    ...(modalData?.data.content as TDeliveryProviders),
                    tier: values.tier,
                },
            ];
            return currentElement;
        }

        const checkExistDP = elementSelected.deliveryProviders.find(
            (dp) => dp.keyName === (modalData?.data.content as TDeliveryProviders).keyName,
        );

        if (!checkExistDP) {
            currentElement.deliveryProviders = [
                ...elementSelected.deliveryProviders,
                {
                    ...(modalData?.data.content as TDeliveryProviders),
                    tier: values.tier,
                },
            ];
            return currentElement;
        }

        const newDP = elementSelected.deliveryProviders.map((dp) => {
            if (dp.keyName === (modalData?.data.content as TDeliveryProviders).keyName) {
                return {
                    ...dp,
                    tier: values.tier,
                };
            }
            return dp;
        });
        currentElement.deliveryProviders = newDP;
        return currentElement;
    }

    return currentElement;
};

const generateDataToDelete = ({
    elementSelected,
    modalData,
}: TGenerateDataToDelete) => {
    const currentElement = elementSelected;

    if (!currentElement.tiers) return currentElement;

    const newTiers = currentElement.tiers.filter((tier) => tier.index !== (modalData?.data.content as TTier).index);

    currentElement.tiers = newTiers;

    return currentElement;
};

const generateDataToAdd = ({
    elementSelected,
    values,
}: TGenerateDataToAdd) => {
    const currentElement = elementSelected;

    if (!currentElement.tiers) {
        currentElement.tiers = [{ ...values }];
        return currentElement;
    }

    currentElement.tiers = [...currentElement.tiers, { ...values }];
    return currentElement;
};

export {
    generateDataToUpdate,
    generateDataToDelete,
    generateDataToAdd,
};
