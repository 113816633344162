import {
    Layout as LayoutAntd, Row, Col, Typography,
} from 'antd';
import { AiOutlineMenu } from 'react-icons/ai';
import Actions from './ActionsHeader';

interface Props {
    openMenu: () => void;
    isMenuOpen: boolean;
    goHome: () => void;
}

const { Header: HeaderAntd } = LayoutAntd;
const { Text } = Typography;

const Header = ({ openMenu, isMenuOpen, goHome }: Props) => (
    <HeaderAntd style={{ padding: '0 20px' }}>
        <Row justify="space-between" gutter={[24, 24]}>
            <Col>
                <div
                    className="full__height flex-row center-row-center"
                    style={{ cursor: 'pointer' }}
                >
                    {
                        isMenuOpen
                            ? null
                            : (
                                <AiOutlineMenu
                                    size={24}
                                    color="#00B2E3"
                                    className="trigger"
                                    style={{ verticalAlign: 'middle' }}
                                    onClick={() => openMenu()}
                                />
                            )
                    }
                    <Text className="text-logo" onClick={goHome}>
                        Picker
                    </Text>
                </div>
            </Col>
            <Col className="flex-row center-row-center" />
        </Row>
    </HeaderAntd>
);

export default Header;
