import { Select } from 'antd';
import { useEffect } from 'react';

interface Props {
    value: string[];
    setSelectedColumns: any;
    options: {
        label: string;
        value: string;
    }[];
}

const SelectColumns = ({
    value,
    setSelectedColumns,
    options,
}: Props) => {
    const handleOnChange = (values: string[]) => {
        setSelectedColumns(values);
        localStorage.setItem('order-columns', JSON.stringify(values));
    };

    useEffect(() => {
        if (localStorage.getItem('order-columns')) return;
        const columns = options.map((option) => option.value);
        localStorage.setItem('order-columns', JSON.stringify(columns));
    }, []);

    return (
        <Select
            style={{ width: '350px' }}
            mode="multiple"
            placeholder="Select Columns"
            onChange={handleOnChange}
            options={options}
            maxTagCount="responsive"
            value={value}
        />
    );
};

export default SelectColumns;
