import { Dropdown, Table, Typography } from 'antd';
import { useContext, useMemo } from 'react';
import { AiFillCheckCircle, AiOutlineSelect } from 'react-icons/ai';
import { BsThreeDots } from 'react-icons/bs';
import { MdCancel } from 'react-icons/md';
import { TTier } from '../../../../../types/faresWorker';
import { TiersConfigurationContext } from '../../../context/tiersConfigurationContext';
import MenuActions from '../MenuActions';

const TiersRegionsTable = () => {
    const { elementSelected, regions, globalTiers } = useContext(TiersConfigurationContext);

    const { Title } = Typography;

    const renderColumns = () => {
        const columns: any = [
            {
                title: 'Index',
                dataIndex: 'index',
                key: 'index',
            },
            {
                title: 'Time',
                dataIndex: 'time',
                key: 'time',
            },
            {
                title: 'Active',
                dataIndex: 'isActive',
                key: 'isActive',
                render: (data: boolean) => (
                    data ? <AiFillCheckCircle color="green" size={25} /> : <MdCancel color="red" size={25} />
                ),
            },
        ];

        if (elementSelected && elementSelected.tiers && elementSelected.tiers.length > 0) {
            columns.push(
                {
                    title: 'Actions',
                    dataIndex: '',
                    key: 'actions',
                    width: 100,
                    fixed: 'right' as 'right',
                    // eslint-disable-next-line react/display-name
                    render: (data: TTier) => (
                        <Dropdown
                            placement="bottomRight"
                            // @ts-ignore
                            onClick={(e: any) => { e.stopPropagation(); }}
                            overlay={<MenuActions data={data} from="tier" />}
                        >
                            <div style={{ textAlign: 'center' }}>
                                <BsThreeDots size={25} cursor="pointer" />
                            </div>
                        </Dropdown>
                    ),
                },
            );
        }

        return columns;
    };

    const tableLoading = {
        spinning: !(elementSelected && !regions.loading),
        indicator: (
            <>
                <Title level={5} style={{ color: 'white' }}>Select an element</Title>
                <AiOutlineSelect color="white" size={35} />
            </>
        ),
    };

    const data = useMemo(() => {
        if (!elementSelected) return [];

        if (elementSelected.tiers && elementSelected.tiers.length > 0) {
            return elementSelected.tiers;
        }
        return globalTiers.data;
    }, [elementSelected, regions]);

    return (
        <div>
            <Table
                dataSource={data}
                columns={renderColumns()}
                loading={tableLoading}
                rowKey="userID"
                scroll={{ x: 800 }}
            />
        </div>
    );
};

export default TiersRegionsTable;
