import { useState } from 'react';
import {
    Card, Input, List, Typography,
} from 'antd';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Plan } from '../../../../interfaces/plan';

interface Props {
    changePlan: (plan: Plan) => void;
    handleActive: () => void;
}

const { Search } = Input;

const Plans = ({ changePlan, handleActive }: Props) => {
    const { plans } = useSelector((state: RootStateOrAny) => state.plans);

    const [list, setList] = useState<Plan[]>(plans);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toLowerCase();
        const filteredList = plans.filter((plan: Plan) => plan.name.toLowerCase().includes(value));
        setList(filteredList);
    };

    return (
        <Card
            bordered
            style={{ minWidth: 220, maxWidth: 220 }}
            headStyle={{ padding: '0 8px' }}
            bodyStyle={{
                padding: '4px 8px',
                maxHeight: 280,
                overflowY: 'scroll',
            }}
            title={(
                <Search
                    allowClear
                    onChange={handleSearch}
                    placeholder="Search plan"
                />
            )}
        >
            <List
                dataSource={list}
                renderItem={(plan: Plan) => (
                    <List.Item
                        key={plan._id}
                        onClick={() => {
                            changePlan(plan);
                            handleActive();
                        }}
                    >
                        <Typography.Text>{plan.name}</Typography.Text>
                    </List.Item>
                )}
            />
        </Card>
    );
};

export default Plans;
