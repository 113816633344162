import {
    PageHeader, Space, Spin, Tabs, Typography,
} from 'antd';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UsersContext } from './context/users.context';
import { Information, WorkspaceInvitations, WorkspacesRelated } from './components';

const { Text } = Typography;

const UserDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const userID = location.pathname.split('/').pop() || '';

    const {
        user, loading, loadingTab, fetchUser, activeTab, setActiveTab, fetchWorkspacesRelated, fetchWorkspaceInvitations,
    } = useContext(UsersContext);

    const items = [
        {
            key: '1',
            label: 'Workspaces',
            children: (
                <WorkspacesRelated
                    user={user}
                    loadingTab={loadingTab}
                    fetchWorkspacesRelated={fetchWorkspacesRelated}
                />
            ),
        },
        {
            key: '2',
            label: 'Invitations',
            children: (
                activeTab === '2' && (
                    <WorkspaceInvitations
                        user={user}
                        loadingTab={loadingTab}
                        fetchWorkspaceInvitations={fetchWorkspaceInvitations}
                    />
                )
            ),
        },
    ];

    useEffect(() => {
        if (!userID) return;
        fetchUser(userID);
    }, [userID]);

    return (
        loading ? (
            <Space size={16}>
                <Spin size="small" />
                <Text>
                    Getting data
                </Text>
            </Space>
        ) : (
            <Space direction="vertical" size={24} style={{ width: '100%' }}>
                <PageHeader
                    onBack={() => navigate(-1)}
                    title={`User | ${user.userUniqueID}`}
                    style={{ padding: 0, paddingTop: 24 }}
                />

                <Information user={user} />

                <Tabs
                    defaultActiveKey="1"
                    items={items}
                    onChange={(key) => setActiveTab(key)}
                />
            </Space>
        )
    );
};

export default UserDetail;
