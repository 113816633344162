import {
    Card, List, Space, Typography,
} from 'antd';

const { Text } = Typography;

interface DriverVehicleProps {
  fleet: {
    driverID: {
      vehicleType: string
      vehicleYear: number
      vehicleColor: string,
      MCNumber: number
    }
  }
}

export const DriverVehicleDetails = ({ fleet }: DriverVehicleProps | any) => {
    const vehicleType = fleet?.driverID?.vehicleType || 'N/A';
    const vehicleYear = fleet?.driverID?.vehicleYear || 'N/A';
    const vehicleColor = fleet?.driverID?.vehicleColor || 'N/A';
    const MCNumber = fleet?.driverID?.MCNumber || 'N/A';

    return (
        <Card
            style={{ height: '600px' }}
            title="Vehicle Information"
            bordered
        >
            <List>
                <List.Item>
                    <List.Item.Meta
                        title={
                            <Text style={{ fontSize: 13 }}>Type:</Text>
                        }
                    />
                    <Text type="secondary" style={{ fontSize: 12 }}>
                        {vehicleType}
                    </Text>
                </List.Item>
                <div />
            </List>
            <List>
                <List.Item>
                    <List.Item.Meta
                        title={
                            <Text style={{ fontSize: 13 }}>Model Name:</Text>
                        }
                    />
                    <Text type="secondary" style={{ fontSize: 12 }}>
                        {vehicleType}
                    </Text>
                </List.Item>
                <div />
            </List>
            <List>
                <List.Item>
                    <List.Item.Meta
                        title={
                            <Text style={{ fontSize: 13 }}>Year:</Text>
                        }
                    />
                    <Text type="secondary" style={{ fontSize: 12 }}>
                        {vehicleYear}
                    </Text>
                </List.Item>
                <div />
            </List>
            <List>
                <List.Item>
                    <List.Item.Meta
                        title={
                            <Text style={{ fontSize: 13 }}>Color:</Text>
                        }
                    />
                    <Space>
                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                            <Text type="secondary" style={{ fontSize: 12 }}>
                                {vehicleColor}
                            </Text>
                        </Space>
                    </Space>
                </List.Item>
                <div />
            </List>
            <List>
                <List.Item>
                    <List.Item.Meta
                        title={
                            <Text style={{ fontSize: 13 }}>MC Number:</Text>
                        }
                    />
                    <Space>
                        <Space direction="vertical" size={0} style={{ textAlign: 'right' }}>
                            <Text type="secondary" style={{ fontSize: 12 }}>
                                {MCNumber}
                            </Text>
                        </Space>
                    </Space>
                </List.Item>
                <div />
            </List>
        </Card>
    );
};
