import { DrawingManager, GoogleMap, Polygon } from '@react-google-maps/api';
import {
    Col, Form, Input, Radio, Row, Select, InputNumber,
} from 'antd';
import { useMemo } from 'react';

import {
    CarType, PaymentType, Region, RegionStatus, RideType, SpecialFeesActive,
} from '../../../../const/region';
import { checkRegionStatus } from '../../../../screens/CommandCenter/utils/region';
import { googleMapsStyles } from '../../../Map/googleMapStyles';

const { Option } = Select;

const isEditRegionColors = {
    fillColor: '#00b2e3',
    strokeColor: '#0092bb',
};

const mapContainerStyle = {
    height: '400px',
    width: '100%',
};

const mapOptions = {
    styles: googleMapsStyles,
};

interface IShowContent {
    region: Region;
    edit: boolean;
    allRegions: Region[];
    onPolygonComplete: (params: google.maps.Polygon) => void
}

const ShowContent = ({
    region,
    edit,
    allRegions,
    onPolygonComplete,
}: IShowContent) => {
    const { center, paths, status } = useMemo(() => {
        const { geoLongLat, regionStatus } = region;

        const center = {
            lat: geoLongLat.coordinates[0][0][1],
            lng: geoLongLat.coordinates[0][0][0],
        };

        const paths = geoLongLat.coordinates[0].map((coordinate: number[]) => (
            { lat: coordinate[1], lng: coordinate[0] }
        ));

        const { fill: fillColor, stroke: strokeColor } = checkRegionStatus(regionStatus);

        return {
            center,
            paths,
            status: {
                fillColor: edit ? isEditRegionColors.fillColor : fillColor,
                strokeColor: edit ? isEditRegionColors.strokeColor : strokeColor,
            },
        };
    }, [region, edit]);

    return (
        <Row>
            {edit
                ? (
                    <>
                        <Row>
                            <Form.Item name="regionName" label="Area name" rules={[{ required: true }]} initialValue={region.regionName}>
                                <Input placeholder="Region 1" />
                            </Form.Item>
                        </Row>
                        <Row style={{ width: '100%' }} gutter={16}>
                            <Col span={8}>
                                <Form.Item label="Special Fee Active" name="specialFeesActive" initialValue={region.specialFeesActive}>
                                    <Select
                                        mode="multiple"
                                        showSearch
                                        placeholder="Select a person"
                                        optionFilterProp="children"
                                        filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                                    >
                                        <Option value={SpecialFeesActive.RAIN_FEE}>Rain Fee</Option>
                                        <Option value={SpecialFeesActive.RUSH_HOUR_FEE}>Rush Hour Fee</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="Region Status" name="regionStatus" initialValue={region.regionStatus}>
                                    <Select
                                        showSearch
                                        placeholder="Select a person"
                                        optionFilterProp="children"
                                        defaultValue={region.regionStatus}
                                        filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                                    >
                                        <Option value={RegionStatus.Green}>Green</Option>
                                        <Option value={RegionStatus.Yellow}>Yellow</Option>
                                        <Option value={RegionStatus.Red}>Red</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="highDemandThreshold" label="High Demand Threshold" initialValue={region.highDemandThreshold || 0}>
                                    <InputNumber placeholder="Demand Threshold" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )
                : null}
            <Row style={{ width: '100%' }}>
                <Col span={8}>
                    <Form.Item label="Ride type" name="rideType" initialValue={region.rideType}>
                        <Radio.Group disabled={!edit} defaultValue={region.rideType}>
                            <Radio value={RideType.RIDE_NOW}>Ride Now</Radio>
                            <br />
                            <Radio value={RideType.RIDE_LATER}>Ride Later</Radio>
                            <br />
                            <Radio value={RideType.BOTH}>Both</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="Car type" name="carType" initialValue={region.carType}>
                        <Radio.Group disabled={!edit} defaultValue={region.carType}>
                            <Radio value={CarType.BIKE}>Bike</Radio>
                            <br />
                            <Radio value={CarType.LITE}>Lite</Radio>
                            <br />
                            <Radio value={CarType.BOTH}>Both</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="Payment type" name="paymentType" initialValue={region.paymentType}>
                        <Radio.Group disabled={!edit} defaultValue={region.paymentType}>
                            <Radio value={PaymentType.CARD}>Card</Radio>
                            <br />
                            <Radio value={PaymentType.CASH}>Cash</Radio>
                            <br />
                            <Radio value={PaymentType.BOTH}>Both</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{ width: '100%' }}>
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={12}
                    center={center}
                    options={mapOptions}
                >
                    <DrawingManager
                        onPolygonComplete={onPolygonComplete}
                        options={{
                            drawingControl: edit,
                            drawingControlOptions: {
                                drawingModes: ['polygon' as any],
                            },
                        }}
                    />
                    {edit && allRegions.map((reg: any) => {
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        if (reg._id === region._id) return <></>;

                        const paths = reg.geoLongLat.coordinates[0].map((coordinate: number[]) => (
                            { lat: coordinate[1], lng: coordinate[0] }
                        ));

                        const { fill: fillColor, stroke: strokeColor } = checkRegionStatus(reg.regionStatus);
                        return (
                            <Polygon
                                key={reg._id}
                                paths={paths}
                                options={{
                                    fillColor,
                                    strokeColor,
                                }}
                            />

                        );
                    })}
                    <Polygon
                        key={region._id}
                        paths={paths}
                        options={status}
                    />
                </GoogleMap>
            </Row>
        </Row>
    );
};

export default ShowContent;
