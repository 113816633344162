import { Spin, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { TbMoodEmpty } from 'react-icons/tb';
import { CHAT_URL } from '../../../../const/envs';
import { SocketChatProvider } from '../../../../context/SocketChatContext';
import { ChatContext } from '../../context/chatContext';
import Message from './Message';
import SendMessage from './SendMessage';

const { Title, Paragraph } = Typography;

const Chat = () => {
    const {
        chat,
        messages,
        loadingMessages,
        fetchMessages,
    } = useContext(ChatContext);

    const [socketURL, setSocketURL] = useState<string>('');

    useEffect(() => {
        if (!chat?._id) return;
        const params = new URLSearchParams();
        params.append('chatId', chat?._id);
        const url = `${CHAT_URL}?${params.toString()}`;
        setSocketURL(url);
    }, [chat?._id]);

    useEffect(() => {
        if (!chat?._id) return;
        fetchMessages(chat?._id);
    }, [chat?._id]);

    return (
        <SocketChatProvider socketURL={socketURL}>
            <div className="chat__header">
                <Title level={4}>
                    Chat:
                    {' '}
                    {chat?.name}
                </Title>
            </div>
            <div className="chat__list">
                {
                    loadingMessages ? (
                        <div className="chat__loading">
                            <Spin />
                            <Paragraph style={{ textAlign: 'center' }}>
                                Loading messages
                            </Paragraph>
                        </div>
                    ) : messages?.length === 0 ? (
                        <div className="chat__empty">
                            <TbMoodEmpty size={32} />
                            <Paragraph style={{ textAlign: 'center' }}>
                                No sended messages yet
                            </Paragraph>
                        </div>
                    ) : (
                        messages?.map((message, index) => (
                            <Message key={index} message={message} />
                        ))
                    )
                }
            </div>
            <div className="chat__send">
                <SendMessage />
            </div>
        </SocketChatProvider>
    );
};

export default Chat;
