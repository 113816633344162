import {
    Modal, Form, Input, Select, Button, Space, Row, Col, Descriptions, Table,
} from 'antd';
import { useState, useEffect } from 'react';
import { AiFillCheckCircle } from 'react-icons/ai';
import { MdCancel } from 'react-icons/md';
import { testPlan } from '../../../api/payments';

const { Option } = Select;

interface IPlanEvalModal {
  visible: boolean;
  plan: any;
  onCancel: () => void;
}

const PlanEvalModal = ({
    visible,
    plan,
    onCancel,
}: IPlanEvalModal) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [planEval, setPlanEval] = useState<any>([]);
    const [total, setTotal] = useState<any>(0);

    const handleTest = (values: any) => {
        setLoading(true);
        testPlan(plan._id, values)
            .then(({ data }) => {
                const total = data.data.reduce((acc: number, curr: any) => acc + curr.total, 0);
                setTotal(Math.round(total * 100) / 100);
                setPlanEval(data.data);
            })
            .catch()
            .finally(() => setLoading(false));
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Amount',
            dataIndex: 'total',
            key: 'total',
            render: (total: number) => total.toFixed(2),
        },
        {
            title: 'Processed',
            dataIndex: 'toProcess',
            key: 'toProcess',
            render: (data: boolean) => (
                data ? <AiFillCheckCircle color="green" size={25} /> : <MdCancel color="red" size={25} />
            ),
        },
    ];

    const handleCancel = () => {
        setPlanEval(undefined);
        form.resetFields();
        onCancel();
    };

    return (
        <Modal
            title={`Test Plan: ${plan?.name}`}
            visible={visible}
            width={1000}
            onCancel={handleCancel}
        >
            <Form form={form} layout="vertical" onFinish={handleTest}>
                <Row gutter={3}>
                    <Col span={20}>
                        <Form.Item label="Booking" name="bookingId">
                            <Input placeholder="888888888" />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="&nbsp;">
                            <Button type="primary" block htmlType="submit" loading={loading}>
                                Test
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table dataSource={planEval} columns={columns} loading={loading} />
            <Descriptions title="Operations" bordered column={1}>
                <Descriptions.Item label="Total">{total}</Descriptions.Item>
            </Descriptions>
        </Modal>
    );
};

export default PlanEvalModal;
