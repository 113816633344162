import {
    Card, Col, Drawer, Row,
} from 'antd';
import IssueData from './IssueData';
import IssuesList from './IssuesList';

const Issues = () => (
    <Row justify="space-between" align="top" gutter={[24, 32]}>
        <Col xs={24} lg={8}>
            <IssuesList />
        </Col>
        <Col xs={24} lg={16}>
            <Card bordered>
                <div
                    style={{
                        width: '100%',
                        overflowY: 'scroll',
                        height: '70vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignContent: 'center',
                    }}
                >
                    <IssueData />
                </div>
            </Card>
        </Col>
    </Row>
);

export default Issues;
