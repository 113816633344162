export const STATUSES_ISSUE = {
    PENDING: 'PENDING',
    ACTIVE: 'ACTIVE',
    SOLVED: 'SOLVED',
    NOT_SOLVED: 'NOT_SOLVED',
};

export const NOTIFICATION_DESCRIPTIONS = {
    NEW_MESSAGE: 'new message',
    NEW_ISSUE: 'New issue',
    UPDATE_ISSUE: 'updated',
};

export const ISSUE_CLOSED = [STATUSES_ISSUE.SOLVED.toString, STATUSES_ISSUE.SOLVED.toString()];
