import { Col, Row, Typography } from 'antd';
import CardDataBase from '../../../CardDataBase';

const { Text } = Typography;

interface IBankDetail {
    bank: string;
    accountType: string;
    accountNumber: string;
    accountHolderName: string;
    accountIdentification: string;
}

const BankDetail = ({
    bank = 'No information',
    accountType = 'No information',
    accountNumber = 'No information',
    accountHolderName = 'No information',
    accountIdentification = 'No information',
} : IBankDetail) => {
    const CARD_CONTENT = [
        {
            title: 'Bank',
            content: bank,
        },
        {
            title: 'Account Type',
            content: accountType,
        },
        {
            title: 'Account Number',
            content: accountNumber,
        },
        {
            title: 'Account Holder Name',
            content: accountHolderName,
        },
        {
            title: 'Account Identification',
            content: accountIdentification,
        },
    ];

    return (
        <CardDataBase title="Bank details" style={{ minHeight: 345 }}>
            {
                CARD_CONTENT.map((data) => (
                    <Row align="middle" justify="space-between" style={{ margin: '16px 0' }}>
                        <Col>
                            <Text strong>
                                {data.title}
                                :
                            </Text>
                        </Col>
                        <Col>{data.content}</Col>
                    </Row>
                ))
            }
        </CardDataBase>
    );
};

export default BankDetail;
