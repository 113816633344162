import moment from 'moment';
import cs from 'classnames';
import { Space, Typography } from 'antd';
import { BookingEvent, BookingType } from '../../../types/booking';

interface Props {
    showDetail?: boolean;
    type?: 'SUCCESS' | 'FAILED' | 'RESOLVING'
    booking: BookingType;
    bookingEvent?: BookingEvent;
    bookingEventSelected: boolean;
}

const { Title, Text } = Typography;

const CardEvent = ({
    showDetail = false, type = 'SUCCESS', booking, bookingEvent, bookingEventSelected,
}: Props) => {
    const date = moment(bookingEvent?.createdAt).format('DD MMM HH:mm:ss');

    const getCreatedData = (bookingEvent: BookingEvent) => (
        <Space>
            <Text>
                {
                    `Created by ${bookingEvent?.registeredBy.name} ${bookingEvent?.registeredBy.lastName || ''}
                    from ${bookingEvent?.origin === 'API' ? 'API' : 'Dashboard'}`
                }
            </Text>
        </Space>
    );

    return (
        !showDetail ? (
            <div className={cs('card__event', { active: bookingEventSelected })}>
                <Space size={8}>
                    <Text className="card__tag event">
                        Event
                    </Text>
                </Space>
                <Text>
                    {bookingEvent?.description}
                </Text>
                <Text style={{ fontSize: 12, fontStyle: 'italic' }}>
                    {date}
                </Text>
            </div>
        ) : (
            <Space direction="vertical" size={16}>
                <Title level={5} style={{ margin: 0 }}>
                    Event Detail
                </Title>
                <Space size={8}>
                    <Text className="card__tag event">
                        Event
                    </Text>
                    <Text className={cs('card__tag', type)}>
                        {type}
                    </Text>
                </Space>

                <Text>
                    {bookingEvent?.description}
                </Text>

                {
                    bookingEvent?.type === 'NEW_BOOKING' && (
                        getCreatedData(bookingEvent)
                    )
                }

                <Text style={{ fontSize: 12, fontStyle: 'italic' }}>
                    {date}
                </Text>
            </Space>
        )
    );
};

export default CardEvent;
