import {
    Modal, Form, Input, Select, Button,
} from 'antd';
import { TypesCharge } from '../../../const/wallet';

const { Option } = Select;

interface ICreateTransactionModal {
  visible: boolean;
  loading: boolean;
  onCancel: () => void;
  onSubmit: (values: any) => void;
}

const CreateTransactionModal = ({
    visible,
    loading,
    onCancel,
    onSubmit,
}: ICreateTransactionModal) => {
    const [form] = Form.useForm();

    const handleOk = () => {
        form.validateFields().then((values) => {
            onSubmit(values);
        });
    };

    const handleCancel = () => {
        form.resetFields();
        onCancel();
    };

    const typeOptions = Object.keys(TypesCharge).map((key) => (
        <Option key={key} value={key}>
            {TypesCharge[key as keyof typeof TypesCharge]}
        </Option>
    ));

    return (
        <Modal
            title="Create Transaction"
            visible={visible}
            onCancel={handleCancel}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                    Submit
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    label="Type"
                    name="type"
                    rules={[{ required: true, message: 'Please select a type' }]}
                >
                    <Select placeholder="Select a type">{typeOptions}</Select>
                </Form.Item>
                <Form.Item
                    label="Amount"
                    name="balance"
                    rules={[
                        { required: true, message: 'Please enter an amount' },
                    ]}
                >
                    <Input
                        type="number"
                        defaultValue="00.00"
                        addonBefore={<span>$</span>}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreateTransactionModal;
