import { Modal, Typography } from 'antd';
import CoverageActions from './Actions';
import CoverageMap from './CoverageMap';

interface Props {
    workspaceID: string;
    actionLoading: boolean;
    error: any;
    showArea: boolean;
    currentLocation: any;
    newPolygon: google.maps.Polygon | undefined;
    onCancel: () => void;
    onPolygonComplete: (polygon: google.maps.Polygon) => void;
    resetPolygon: () => void;
    saveCoverageArea: (workspaceID: string) => void;
}

const { Title, Paragraph } = Typography;

const CoverageArea = ({
    workspaceID,
    actionLoading,
    error,
    showArea,
    currentLocation,
    onCancel,
    onPolygonComplete,
    resetPolygon,
    newPolygon,
    saveCoverageArea,
}: Props) => (
    <Modal
        open={showArea}
        closeIcon={<div />}
        onCancel={onCancel}
        bodyStyle={{ padding: 16 }}
        width={800}
        onOk={() => saveCoverageArea(workspaceID)}
        okButtonProps={{ loading: actionLoading }}
    >
        <div>
            <Title level={4}>
                Coverage area settings
            </Title>

            <Paragraph strong>
                To create a coverage, select the point tool and click on the map to create a polygon. To finish, click again on the first point created.
            </Paragraph>

            <div style={{ position: 'relative' }}>
                <CoverageActions
                    resetPolygon={resetPolygon}
                />
                <CoverageMap
                    newPolygon={newPolygon}
                    currentLocation={currentLocation}
                    onPolygonComplete={onPolygonComplete}
                />
            </div>
        </div>
    </Modal>
);

export default CoverageArea;
