import { Button, Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from '../../../actions/modals';
import { setBookingIssue } from '../../../api/booking';
import { getBookingIssues } from '../../../api/bookingIssues';
import { TIssue } from '../../../types/issue';
import { ModalTypes } from '../../../types/modals';
import CreateIssue from './components/CreanteIssue';
import CreateReportIssue from './components/CreateReportIssue';

interface ICancelService {
    onSucess?: () => void
}

type TMode = 'CREATE_REPORT' | 'CREATE_ISSUE';

export const ReportIssue = ({ onSucess }: ICancelService) => {
    const [currentIssues, setCurrentIssues] = useState<TIssue[]>([]);
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState<TMode>('CREATE_REPORT');
    const [issueSelectedID, setIssueSelectedID] = useState<string | null>();

    const { activeModal, modalData } = useSelector((state: any) => state.modals);
    const isOpen = activeModal === ModalTypes.ReportIssue;

    const dispatch = useDispatch();

    const fetchIssues = async () => {
        setLoading(true);
        await getBookingIssues()
            .then(({ data }) => {
                setCurrentIssues(data.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const onFinish = async () => {
        setLoading(true);

        try {
            await setBookingIssue(modalData._id, [issueSelectedID]);

            notification.success({
                message: 'Issue reported successfully!',
            });

            onSucess && onSucess();
            dispatch(closeModal());
        } catch (error) {
            console.log(error);
            notification.error({
                message: 'An error has occurred when canceling the service',
            });
        } finally {
            setLoading(false);
        }
    };

    const onSuccessIssue = () => {
        fetchIssues();
        setMode('CREATE_REPORT');
    };

    const onCancel = () => {
        dispatch(closeModal());
        setMode('CREATE_REPORT');
    };

    useEffect(() => {
        fetchIssues();
    }, []);

    return (
        <Modal
            width="40%"
            visible={isOpen}
            title={`Report Issue booking: ${modalData?.bookingNumericId}`}
            onCancel={onCancel}
            confirmLoading={loading}
            footer={mode === 'CREATE_REPORT' && [
                <Button key="back" onClick={onCancel}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={onFinish}>
                    Ok
                </Button>,
            ]}
        >
            {
                mode === 'CREATE_REPORT'
                    ? (
                        <CreateReportIssue
                            setIssueSelectedID={setIssueSelectedID}
                            currentIssues={currentIssues}
                            setMode={setMode}
                        />
                    )
                    : <CreateIssue onSuccess={onSuccessIssue} setMode={setMode} />
            }
        </Modal>
    );
};
