import moment from 'moment';
import {
    Button,
    Col,
    PageHeader,
    Row,
    Space,
    Table,
    Tooltip,
    Typography,
} from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TbTestPipe } from 'react-icons/tb';
import { MdDelete } from 'react-icons/md';

import ConceptEvalModal from '../Modals/ConceptEvalModal';
import ConceptModal from '../Modals/ConceptModal';
import { ConceptContext } from './context/ConceptContext';
import { IConcept, IConceptCondition } from '../../interfaces/plan';

const { Text } = Typography;

export const ConceptTable = () => {
    const [concept, setConcept] = useState<IConcept>();
    const [showConcept, setShowConcept] = useState(false);
    const [testConcept, setTestConcept] = useState(false);
    const { id } = useParams();
    const {
        concepts, conceptsLoading, addConcept, fetchConcepts, removeConcept,
    } = useContext(ConceptContext);

    useEffect(() => {
        fetchConcepts();
    }, []);

    const handleTestConcept = (conceptId: string) => {
        setConcept(concepts.find((c: any) => c._id === conceptId));
        setTestConcept(true);
    };

    const handleDeleteConcept = (conceptId: string) => {
        removeConcept(id!, conceptId);
    };

    const menu = (conceptId: string) => (
        <Row justify="center" gutter={12}>
            <Col>
                <Tooltip title="Test">
                    <Button
                        icon={<TbTestPipe style={{ verticalAlign: 'middle', color: '#177DDC' }} />}
                        type="ghost"
                        onClick={() => handleTestConcept(conceptId)}
                    />
                </Tooltip>
            </Col>
            <Col>
                <Tooltip title="Delete">
                    <Button
                        icon={<MdDelete style={{ verticalAlign: 'middle', color: '#FF426F' }} />}
                        type="ghost"
                        onClick={() => handleDeleteConcept(conceptId)}
                    />
                </Tooltip>
            </Col>
        </Row>
    );

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (name: string, concept: any) => (
                <a
                    style={{ color: '#177DDC' }}
                    onClick={() => {
                        setConcept({ ...concept });
                        setShowConcept(true);
                    }}
                >
                    {name}
                </a>
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string) => (
                <Text>
                    {moment(createdAt).format('MMM DD MMM')}
                </Text>
            ),
        },
        {
            title: 'Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
            render: (frequency: string) => (
                <Text>
                    {frequency || 'Not available'}
                </Text>
            ),
        },
        {
            title: (
                <Space>
                    <Text style={{ color: '#FFF' }}>Conditions | </Text>
                    <Text style={{ color: '#e5a500' }}>Field</Text>
                    <Text style={{ color: '#FFF' }}>Operator</Text>
                    <Text style={{ color: '#6C8EEF' }}>Value</Text>
                </Space>
            ),
            dataIndex: 'conditions',
            key: 'conditions',
            with: 200,
            render: (conditions: IConceptCondition[]) => (
                conditions.map((condition, index) => (
                    <div style={{ display: 'flex', gap: 12 }}>
                        <Text style={{ color: '#e5a500' }}>
                            {condition.field}
                        </Text>
                        <Text style={{ color: '#FFF' }}>
                            {condition.operator}
                        </Text>
                        <Text style={{ color: '#6C8EEF' }}>
                            {condition.value}
                        </Text>
                    </div>

                ))
            ),
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            key: 'operation',
            render: (operation: string) => (
                <Tooltip title={operation}>
                    <span
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: 180,
                            display: 'inline-block',
                        }}
                    >
                        {operation || 'Not available'}
                    </span>
                </Tooltip>
            ),
        },
        {
            title: 'Actions',
            dataIndex: '_id',
            key: 'actions',
            fixed: 'right' as 'right',
            with: 60,
            render: (_id: string) => (menu(_id)),
        },
    ];

    const handleAddConcept = () => {
        setShowConcept(true);
        setConcept({
            _id: '', name: '', operation: '', conditions: [] as IConceptCondition[], planID: id as string,
        });
    };

    const handleCancelConcept = (concept: any) => {
        if (concept) {
            addConcept(concept);
        }
        setShowConcept(false);
    };

    return (
        <Row justify="space-between" gutter={[0, 20]}>
            <ConceptModal
                visible={showConcept}
                concept={concept}
                onCancel={handleCancelConcept}
            />
            <ConceptEvalModal
                visible={!!testConcept}
                concept={concept}
                onCancel={() => setTestConcept(false)}
            />
            <Col span={24}>
                <PageHeader
                    title="Concepts"
                    style={{ padding: 0 }}
                    extra={[
                        <Button type="primary" size="small" onClick={handleAddConcept}>
                            Add Concept
                        </Button>,
                    ]}
                />
            </Col>
            <Col span={24}>
                <Table
                    columns={columns}
                    dataSource={concepts}
                    loading={conceptsLoading}
                    pagination={false}
                    rowKey="_id"
                    locale={{ emptyText: 'No concepts' }}
                />
            </Col>
        </Row>
    );
};
