import {
    Button, Card, Col, Dropdown, Menu, Row, Space, Table, Tag,
    Typography,
    message,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { FaBook, FaSync } from 'react-icons/fa';
import { MdContentCopy } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { BsThreeDots } from 'react-icons/bs';
import { getBookingOrderUpdates } from '../../api/booking';
import { BookingOrderStatus } from '../../types/booking';
import { getStatusChipClass } from '../../utils/bookings';
import { showOrderRequestModal, showOrderResponseModal } from './OrderModal';
import SelectColumns from './SelectColumns';

interface OrderUpdateTableProps {
    bookingId: string;
    hiddenColumns?: string[];
}

const { Text } = Typography;

export const OrderUpdateTable = ({
    bookingId,
    hiddenColumns = [],
}: OrderUpdateTableProps) => {
    const [loading, setLoading] = useState(false);
    const [updates, setUpdates] = useState<BookingOrderStatus[]>([]);
    const [selectedColumns, setSelectedColumns] = useState<any[]>([]);
    const [visibleColumns, setVisibleColumns] = useState<any[]>([]);

    const { currency } = useSelector((state: any) => state.ride.active);

    const fetchOrderUpdates = () => {
        setLoading(true);
        getBookingOrderUpdates(bookingId)
            .then(({ data }) => {
                setUpdates(data.data);
            })
            .catch((error) => console.log(error))
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!bookingId) return;
        fetchOrderUpdates();
    }, [bookingId]);

    const OrderMenu = (order: any) => {
        const { orderId } = order;
        const menu = [
            {
                children: 'See request',
                onClick: () => showOrderRequestModal(orderId),
            },
            {
                children: 'See response',
                onClick: () => showOrderResponseModal(orderId),
            },
        ];

        return (
            <Menu>
                <Menu.ItemGroup key="g">
                    {menu.map((o: any, i: any) => (
                        <Menu.Item key={i} {...o} />
                    ))}
                </Menu.ItemGroup>
            </Menu>
        );
    };

    const columns = [
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'date',
            render: (date: string) => moment(date).local().format('DD-MM-YYYY'),
        },
        {
            title: 'Hour',
            dataIndex: 'createdAt',
            key: 'hour',
            render: (date: string) => moment(date).local().format('HH:mm:ss'),
        },
        {
            title: 'Provider ID',
            dataIndex: 'externalId',
            key: 'externalId',
            render: (externalId: string, order: BookingOrderStatus) => (
                <Space>
                    <Text>
                        {order?.externalSupportId || externalId || 'Not available'}
                    </Text>
                    { (order?.externalSupportId || externalId) && (
                        <Button
                            type="link"
                            icon={(
                                <MdContentCopy style={{ verticalAlign: 'middle' }} />
                            )}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            onClick={() => {
                                navigator.clipboard.writeText(order?.externalSupportId || externalId);
                                message.success('External ID copied to clipboard');
                            }}
                        />
                    )}
                </Space>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) => (
                <Tag {...getStatusChipClass(status)}>{status}</Tag>
            ),
        },
        {
            title: 'Provider',
            dataIndex: 'providerName',
            key: 'providerName',
        },
        {
            title: 'Error Message',
            dataIndex: 'errorMessage',
            key: 'errorMessage',
        },
        {
            title: 'Tracking',
            dataIndex: 'trackingUrl',
            key: 'trackingUrl',
            render: (trackingUrl: string) => (
                <span>
                    {trackingUrl ? (
                        <a href={trackingUrl} target="_blank" rel="noopener noreferrer">
                            See tracking
                        </a>
                    ) : (
                        'Not available'
                    )}
                </span>
            ),
        },
        {
            title: 'Tip',
            dataIndex: 'tip',
            key: 'tipAmount',
            render: (tip: number, booking: any) => `$${(tip || 0).toFixed(2)} ${currency}`,
        },
        {
            title: 'Provider Fee',
            dataIndex: 'providerFee',
            key: 'providerFee',
            render: (providerFee: number) => `$${(providerFee || 0).toFixed(2)} ${currency}`,
        },
        {
            title: 'Actions',
            dataIndex: '_id',
            key: 'actions',
            render: (orderId: any) => (
                <Dropdown
                    placement="bottomRight"
                    overlay={<OrderMenu orderId={orderId} />}
                    // @ts-ignore
                    onClick={(e: any, a: any) => {
                        e.stopPropagation();
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            <BsThreeDots size={25} />
                        </a>
                    </div>
                </Dropdown>
            ),

        },
    ];

    const OPTIONS = [
        { value: 'date', label: 'Date' },
        { value: 'hour', label: 'Hour' },
        { value: 'externalId', label: 'Provider ID' },
        { value: 'providerName', label: 'Provider' },
        { value: 'status', label: 'Status' },
        { value: 'errorMessage', label: 'Error Message' },
        { value: 'trackingUrl', label: 'Tracking' },
        { value: 'tipAmount', label: 'Tip Amount' },
        { value: 'providerFee', label: 'Provider Fee' },
        { value: 'actions', label: 'Actions' },
    ];

    useEffect(() => {
        if (hiddenColumns.length === 0) return;
        setVisibleColumns(columns.filter((column) => !hiddenColumns.includes(column.key as string)));
    }, []);

    useEffect(() => {
        const localStorageColumns = localStorage.getItem('order-columns');
        if (localStorageColumns) {
            setSelectedColumns(JSON.parse(localStorageColumns));
            setVisibleColumns(columns.filter((column) => JSON.parse(localStorageColumns).includes(column.key as string)));
        }
    }, [localStorage.getItem('order-columns')]);

    return (

        <Card
            title={(
                <Space direction="horizontal">
                    <FaBook />
                    Order Updates
                    <Button type="link" onClick={fetchOrderUpdates}>
                        <FaSync />
                    </Button>
                </Space>
            )}
        >
            <Space
                size={12}
                direction="vertical"
                style={{ width: '100%' }}
            >
                <Row justify="start" gutter={16}>
                    <Col>
                        <SelectColumns
                            value={selectedColumns}
                            setSelectedColumns={setSelectedColumns}
                            options={OPTIONS}
                        />
                    </Col>
                </Row>
                <Table
                    columns={visibleColumns}
                    dataSource={updates}
                    pagination={false}
                    loading={loading}
                />
            </Space>
        </Card>
    );
};
