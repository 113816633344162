import { Dropdown, Table, Typography } from 'antd';
import { useContext, useMemo } from 'react';
import { AiOutlineSelect } from 'react-icons/ai';
import { BsThreeDots } from 'react-icons/bs';
import { TDeliveryProviders } from '../../../../../types/faresWorker';
import { TiersConfigurationContext } from '../../../context/tiersConfigurationContext';
import { filterDeliveryProvidersElement } from '../../../utils/deliveryProviders';
import MenuActions from '../MenuActions';

const TiersDeliveryProviderTable = () => {
    const { elementSelected, deliveryProviders, regions } = useContext(TiersConfigurationContext);

    const { Title } = Typography;

    const columns = [
        {
            title: 'Key name',
            dataIndex: 'keyName',
            key: 'keyName',
        },
        {
            title: 'Tier',
            dataIndex: 'tier',
            key: 'tier',
        },
        {
            title: 'Actions',
            dataIndex: '',
            key: 'actions',
            width: 100,
            fixed: 'right' as 'right',
            // eslint-disable-next-line react/display-name
            render: (data: TDeliveryProviders) => (
                <Dropdown
                    placement="bottomRight"
                    // @ts-ignore
                    onClick={(e: any) => { e.stopPropagation(); }}
                    overlay={<MenuActions data={data} from="deliveryProvider" />}
                >
                    <div style={{ textAlign: 'center' }}>
                        <BsThreeDots cursor="pointer" size={25} />
                    </div>
                </Dropdown>
            ),
        },
    ];

    const tableLoading = {
        spinning: !(elementSelected && !deliveryProviders.loading && !regions.loading),
        indicator: (
            <>
                <Title level={5} style={{ color: 'white' }}>Select an element</Title>
                <AiOutlineSelect color="white" size={35} />
            </>
        ),
    };

    const data = useMemo(
        () => filterDeliveryProvidersElement(elementSelected, deliveryProviders.data),
        [elementSelected, deliveryProviders, regions],
    );

    return (
        <div>
            <Table
                dataSource={data}
                columns={columns}
                loading={tableLoading}
                rowKey="userID"
                scroll={{ x: 800 }}
            />
        </div>
    );
};

export default TiersDeliveryProviderTable;
