import moment from 'moment';
import {
    Col, DatePicker, Input, Row, Select,
} from 'antd';

interface Props {
    searchParams: any;
    startDate: string;
    endDate: string;
    handleStatus: (value: string) => void;
    handleDate: (value: any) => void;
}

const { RangePicker }: any = DatePicker;
const { Search } = Input;

const Filters = ({
    searchParams,
    startDate,
    endDate,
    handleStatus,
    handleDate,
}: Props) => {
    const { status } = searchParams;

    const OPTIONS = [
        { label: 'PENDING', value: 'PENDING' },
        { label: 'ACCEPTED', value: 'ACCEPTED' },
        { label: 'ARRIVED_AT_PICKUP', value: 'ARRIVED_AT_PICKUP' },
        { label: 'ARRIVED_AT_DELIVERY', value: 'ARRIVED_AT_DELIVERY' },
        { label: 'COMPLETED', value: 'COMPLETED' },
        { label: 'READY_FOR_PICKUP', value: 'READY_FOR_PICKUP' },
        { label: 'NOT_DELIVERED', value: 'NOT_DELIVERED' },
        { label: 'RETURNING', value: 'RETURNING' },
        { label: 'RETURNED', value: 'RETURNED' },
        { label: 'RETURNED_TO_PICKUP', value: 'RETURNED_TO_PICKUP' },
    ];

    const initialDate = [
        moment().startOf('day').toISOString(),
        moment().endOf('day').toISOString(),
    ];

    return (
        <Row justify="start" gutter={[16, 24]}>
            <Col>
                <RangePicker
                    defaultValue={[moment(startDate), moment(endDate)]}
                    onChange={(date: any = []) => {
                        if (date) {
                            const [startDate, endDate] = date;
                            handleDate([
                                startDate.startOf('day').toISOString(),
                                endDate.endOf('day').toISOString(),
                            ]);
                        } else {
                            handleDate(initialDate);
                        }
                    }}
                />
            </Col>
            <Col>
                <Select
                    defaultValue={status}
                    mode="multiple"
                    style={{ width: '300px' }}
                    placeholder="Status"
                    onChange={handleStatus}
                    options={OPTIONS}
                    maxTagCount="responsive"
                />
            </Col>
        </Row>
    );
};

export default Filters;
