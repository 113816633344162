import { debounce, set } from 'lodash';
import {
    useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    Card, Divider, Input, List, Menu, Modal, PageHeader, Space, Spin, notification,
} from 'antd';
import { Responsable } from '../../../types/responsable';
import { getAdministrators } from '../../../api/admin';
import { updateIssue } from '../../../api/issues';
import { IssuesContext } from '../context/issues.context';

interface Props {
    handleActive: () => void;
    onChangeResponsable: (responsableID: any) => void;
}

const { Search } = Input;
const { confirm } = Modal;

const OperatorsList = ({
    handleActive,
    onChangeResponsable,
}: Props) => {
    const [operators, setOperators] = useState<Responsable[]>([]);
    const [maxOperators, setMaxOperators] = useState<number>(0);
    const [search, setSearch] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const {
        item, data, setData, setItem,
    } = useContext(IssuesContext);

    const fetchAdministrators = async (search?: string) => {
        if (loading) return;
        if (operators.length >= maxOperators) setOperators([]);
        setLoading(true);
        const LIMIT = 6;
        const PAGE = Math.ceil(operators.length / LIMIT);

        const params: any = {
            limit: LIMIT,
            skip: PAGE,
            role: 'admin',
            isBlocked: false,
            isDeleted: false,
            isAdminVerified: true,
        };

        if (search && search !== '') { params.search = search; }

        getAdministrators(params)
            .then(({ data: { data } }) => {
                setOperators([...operators, ...data.users]);
                setMaxOperators(data.count);
            })
            .catch((error) => console.log(error))
            .finally(() => setLoading(false));
    };

    const scrollableRef = useRef<HTMLDivElement | null>(null);
    const fetchDebounced = useCallback<any>(debounce(fetchAdministrators, 700), []);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setSearch(value);
        fetchDebounced(value);
    };

    const confirmAssigned = (responsable: any) => confirm({
        title: 'Assign issue',
        content: `Are you sure to assign this issue to ${responsable?.name} ${responsable?.lastName}?`,
        onOk: async () => {
            try {
                const payload = {
                    responsableID: responsable?._id,
                    responsableName: `${responsable?.name} ${responsable?.lastName}`,
                    status: item?.status,
                    description: item?.description,
                    companyName: item?.bookingData?.businessName,
                    userID: item?.userID,
                    bookingNumericId: item?.bookingNumericId,
                    issuePublicID: item?.issuePublicID,
                    role: 'business',
                };
                const issueID = item?._id;
                updateIssue(issueID || '', payload)
                    .then((response) => {
                        notification.success({
                            message: 'Issue assigned successfully',
                        });
                        const newData = data.map((item) => {
                            if (item._id === issueID) {
                                return {
                                    ...item,
                                    responsableID: responsable?._id,
                                    responsableName: `${responsable?.name} ${responsable?.lastName}`,
                                };
                            }
                            return item;
                        });
                        setData(newData);
                        setItem({
                            ...item,
                            ...response.data.data,
                        });
                        handleActive();
                    }).catch((error) => {
                        const description = error?.response?.data?.message || 'Something went wrong, please try again later';
                        notification.error({
                            message: 'Error',
                            description,
                        });
                    });
            } catch (error) {
                console.log(error);
                notification.error({
                    message: 'Error',
                    description: 'Something went wrong, please try again later',
                });
            }
        },
        onCancel: () => {
            if (!item?.responsableID) return;
            onChangeResponsable(item?.responsableID);
        },
        okText: 'Assign',
        cancelText: 'Cancel',
        zIndex: 9999,
    });

    const changeResponsable = (responsableID: String) => {
        onChangeResponsable(responsableID);
        const findResponsable = operators.find((responsable: Responsable) => responsable._id === responsableID);
        confirmAssigned(findResponsable);
    };

    useEffect(() => {
        fetchAdministrators();
    }, []);

    const ITEMS = [
        {
            key: '1',
            label: (
                <Card
                    bordered
                    style={{
                        minWidth: 300,
                        maxWidth: 300,
                    }}
                >
                    <PageHeader
                        title="Operators"
                        style={{
                            padding: 0,
                            paddingRight: 0,
                            width: '100%',
                            marginBottom: 12,
                        }}
                        extra={(
                            <span
                                style={{ cursor: 'pointer' }}
                                onClick={handleActive}
                            >
                                close
                            </span>
                        )}
                    />

                    <Space direction="vertical" size={12}>
                        <Search
                            allowClear
                            enterButton
                            onChange={handleSearch}
                            placeholder="Search by name"
                        />

                        <div
                            id="scrollableOperators"
                            ref={scrollableRef}
                            style={{
                                width: '100%',
                                overflow: 'auto',
                                height: '20vh',
                            }}
                        >
                            {
                                operators.length === 0 && (
                                    <div
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: 8,
                                        }}
                                    >
                                        <Spin />
                                        <span>
                                            Getting operators...
                                        </span>
                                    </div>
                                )
                            }
                            <InfiniteScroll
                                dataLength={operators.length}
                                next={fetchAdministrators}
                                hasMore={operators.length < maxOperators}
                                loader={loading && <Spin />}
                                endMessage={
                                    !loading && (
                                        <Divider plain>
                                            No more operators
                                        </Divider>
                                    )
                                }
                                scrollableTarget="scrollableOperators"
                            >
                                <List
                                    dataSource={operators}
                                    renderItem={(item: Responsable) => (
                                        item.name && (
                                            <List.Item
                                                id={item._id}
                                                key={item._id}
                                                onClick={() => changeResponsable(item._id)}
                                            >
                                                <List.Item.Meta
                                                    title={item.name}
                                                />
                                            </List.Item>
                                        )
                                    )}
                                />
                            </InfiniteScroll>
                        </div>

                    </Space>

                </Card>
            ),
        },
    ];

    return (
        <Menu items={ITEMS} />
    );
};

export default OperatorsList;
