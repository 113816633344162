import { BookingType } from '../types/booking';
import { types } from '../types/types';

const initialState = {
    emailExist: null,
    loading: false,
    active: null,
    events: [],
};

interface State {
  emailExist: null | boolean;
  loading: boolean;
  active: null | BookingType;
}

interface ActionProp {
  type: string;
  payload?: any;
}

export const rideReducer = (state: State = initialState, action: ActionProp) => {
    switch (action.type) {
    case types.ridesStartLoading:
        return {
            ...state,
            loading: true,
        };

    case types.ridesFinishLoading:
        return {
            ...state,
            loading: false,
        };

    case types.ridesSetActive:
        return {
            ...state,
            active: { ...action.payload },
        };

    case types.ridesSetActiveEvents:
        return {
            ...state,
            events: [...action.payload],
        };

    case types.ridesUpdateProviderRide:
        return {
            ...state,
            active: { ...state.active, deliveryProvider: action.payload },
        };

    case types.ridesUpdateSmallFleetProviderRide:
        return {
            ...state,
            active: { ...state.active, driverTag: action.payload },
        };

    default:
        return state;
    }
};
