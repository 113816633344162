import cs from 'classnames';
import styles from './styles.module.scss';

interface IStatusIndicator {
    fill: string;
    stroke: string;
    size?: number;
}

const StatusIndicator = ({
    fill,
    stroke,
    size,
}: IStatusIndicator) => {
    const customSize = size ? {
        width: size,
        height: size,
    } : {};

    return (
        <div
            style={{
                background: fill,
                border: `2px solid ${stroke}`,
                ...customSize,
            }}
            className={cs(styles.dot)}
        />
    );
};

export default StatusIndicator;
