import { Button, notification } from 'antd';
import { useState } from 'react';
import { BsCash, BsCheck } from 'react-icons/bs';

const PayButton = ({ form, field, addPayment }: any) => {
    const [status, setStatus] = useState('no-payed');
    const paying = status === 'paying';
    const payed = status === 'payed';

    const handleClick = async () => {
        setStatus('paying');
        try {
            await addPayment(field.name);
            setStatus('payed');
            notification.success({
                message: 'Payment made correctly.',
            });
        } catch (error: any) {
            console.log(error);
            setStatus('no-payed');
            notification.error({
                message: error?.response?.data.message || 'An error ocurred',
            });
        }
    };

    return payed ? (
        <Button>
            <span className="anticon">
                <BsCheck />
            </span>
        </Button>
    ) : (
        <Button loading={paying} type="primary" onClick={handleClick}>
            <span className="anticon">
                <BsCash size={17} />
            </span>
        </Button>
    );
};

export default PayButton;
