import {
    Button, Form, Input, notification,
} from 'antd';
import { useState } from 'react';

import { registerBookingIssue } from '../../../../../api/bookingIssues';

const { TextArea } = Input;

interface ICreateIssue {
    onSuccess?: () => void;
    setMode: any;
}

const CreateIssue = ({
    setMode,
    onSuccess,
}:ICreateIssue) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onSubmit = async (values: any) => {
        setLoading(true);

        try {
            const data = {
                ...values,
                key: values.name.toUpperCase().replace(/ /g, '_'),
            };

            await registerBookingIssue(data);

            notification.success({
                message: 'Issue created successfully!',
            });

            onSuccess && onSuccess();
        } catch (error) {
            console.log(error);
            notification.error({
                message: 'An error has occurred when canceling the service',
            });
        } finally {
            setLoading(true);
        }
    };

    return (
        <Form
            form={form}
            onFinish={onSubmit}
            layout="vertical"
        >
            <Form.Item name="name" label="New issue" rules={[{ required: true }]}>
                <Input disabled={loading} placeholder="Name..." />
            </Form.Item>
            <Form.Item name="details" label="Description" rules={[{ required: true }]}>
                <TextArea disabled={loading} placeholder="Some description..." rows={4} />
            </Form.Item>
            <Button key="back" onClick={() => setMode('CREATE_REPORT')}>
                Cancel
            </Button>
            <Button onClick={() => form.submit()} key="submit" type="primary" loading={loading}>
                Ok
            </Button>
        </Form>
    );
};

export default CreateIssue;
