import moment from 'moment';
import { Typography } from 'antd';
import { useContext } from 'react';
import { ChatContext } from '../../context/chatContext';
import { EMessageType } from '../../../../enums/chat.enum';

const { Text, Paragraph } = Typography;

const MessageInfo = () => {
    const { selectedMessage } = useContext(ChatContext);

    if (!selectedMessage) return null;

    const readByMessage = () => {
        if (selectedMessage.readBy?.length === 0) return null;

        const readBy = selectedMessage.readBy?.filter((user, index, self) => self.indexOf(user) === index);

        return (
            <Paragraph>
                <Text strong>
                    Read by:
                </Text>
                {' '}
                {
                    readBy.map((user, index) => {
                        if (index === readBy.length - 1) return user;
                        return `${user}, `;
                    })
                }
            </Paragraph>
        );
    };

    return (
        <div className="chat__information">
            <Paragraph>
                <Text strong>
                    Message:
                </Text>
                {' '}
                {selectedMessage.data}
            </Paragraph>
            <Paragraph>
                <Text strong>
                    Sent:
                </Text>
                {' '}
                {moment(selectedMessage.createdAt).format('DD/MM/YYYY HH:mm:ss')}
            </Paragraph>
            <Paragraph>
                <Text strong>
                    User:
                </Text>
                {' '}
                {selectedMessage.user?.name}
            </Paragraph>
            <Paragraph>
                <Text strong>
                    Role:
                </Text>
                {' '}
                {selectedMessage.roleType}
            </Paragraph>
            {readByMessage()}
        </div>
    );
};

export default MessageInfo;
