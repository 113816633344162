const getBillingDataFromWorkspace = (workspace: any) => {
    const { billingData, payment } = workspace;

    if (!billingData && !payment) return;

    if (billingData) {
        return {
            identification: billingData?.identification,
            name: billingData?.name,
            fullAddress: billingData?.address?.fullAddress,
            email: billingData?.email,
            mobile: billingData?.mobile,
            bank: billingData?.bank,
            accountType: billingData?.accountType,
            accountNumber: billingData?.accountNumber,
            accountHolderName: billingData?.accountHolderName,
            accountIdentification: billingData?.accountIdentification,
        };
    }

    if (payment?.manager) {
        const {
            email, name, lastName, mobile, countryCode,
        } = payment?.manager;

        return {
            name: name && lastName && `${name} ${lastName}`,
            fullAddress: billingData?.address?.fullAddress,
            email,
            mobile: countryCode && mobile && `${countryCode} ${mobile}`,
        };
    }
};

export {
    getBillingDataFromWorkspace,
};
