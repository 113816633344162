import moment from 'moment';
import {
    Card, Space, Timeline, Typography,
} from 'antd';
import { IIssueStatusUpdate } from '../../../interfaces/issue.interface';

interface Props {
    statusUpdate: IIssueStatusUpdate[];
}

const { Text } = Typography;

const IssueTimeline = ({ statusUpdate }: Props) => {
    const hasUpdates = statusUpdate?.length > 0;

    if (!hasUpdates) return <Text type="secondary">No updates</Text>;

    return (
        <Timeline mode="left">
            {
                statusUpdate.map((status, index) => {
                    if (!status) return null;
                    const {
                        responsableID, responsableName, currentStatus, timeStamp,
                    } = status;
                    const date = moment(timeStamp).local().format('DD-MM-YYYY');
                    const time = moment(timeStamp).local().format('HH:mm:ss:SSS');
                    return (
                        <Timeline.Item label={currentStatus} key={index}>
                            <Space direction="vertical" size={4}>
                                <Text>
                                    {date}
                                    {' '}
                                    -
                                    {time}
                                </Text>
                                <Text>
                                    {responsableID ? responsableName : 'System'}
                                </Text>
                            </Space>
                        </Timeline.Item>
                    );
                })
            }
        </Timeline>
    );
};

export default IssueTimeline;
