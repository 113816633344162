import moment from 'moment';
import {
    Button, List, Space, Spin, Typography,
} from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdOpenInNew } from 'react-icons/md';
import { IUser } from '../../../interfaces/user.interface';
import { IWorkspaceInvitation } from '../../../interfaces/workspace-invitations';

interface Props {
    user: IUser;
    loadingTab: boolean;
    fetchWorkspaceInvitations: (userID: string) => void;
}

const { Text } = Typography;

const WorkspaceInvitations = ({
    user, loadingTab, fetchWorkspaceInvitations,
}: Props) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!user._id) return;
        fetchWorkspaceInvitations(user._id);
    }, [user._id]);

    return (
        loadingTab ? (
            <Space size={16}>
                <Spin size="small" />
                <Text>
                    Getting data
                </Text>
            </Space>
        ) : (
            <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                <List
                    dataSource={user.invitations}
                    renderItem={(item: IWorkspaceInvitation) => (
                        item.workspaceID && (
                            <List.Item
                                key={item._id}
                                extra={[
                                    <Button
                                        key="1"
                                        type="link"
                                        icon={<MdOpenInNew />}
                                        onClick={() => navigate(`/v2/workspaces/${item.workspaceID?._id}`)}
                                    />,
                                ]}
                            >
                                <List.Item.Meta
                                    title={(
                                        <Text strong>
                                            {`${item.workspaceID?.name} (${item.workspaceID?.workspaceUniqueID})`}
                                            {' - '}
                                            {
                                                item.accepted ? (
                                                    <span style={{ color: '#01EA74' }}>
                                                        Accepted
                                                    </span>
                                                ) : (
                                                    <span style={{ color: '#FFEBA6' }}>
                                                        Pending
                                                    </span>
                                                )
                                            }
                                        </Text>
                                    )}
                                    description={(
                                        <Space size={16} direction="vertical">
                                            <Text>
                                                <b>{item.invitedEmail}</b>
                                                {' invited as '}
                                                <b>{item.roleID.keyName.split('_').join(' ')}</b>
                                            </Text>
                                            <Text style={{ fontSize: 12 }}>
                                                {'User invited at '}
                                                <b>{moment(item.createdAt).format('DD MMM YYYY - HH:mm')}</b>
                                            </Text>
                                        </Space>
                                    )}
                                />
                            </List.Item>
                        )
                    )}
                />
            </div>
        )
    );
};

export default WorkspaceInvitations;
