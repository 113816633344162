import { useSearchParams } from 'react-router-dom';
import Issues from '../components/Issues/Issues';

const IssuesScreen = () => {
    const [searchParams] = useSearchParams();
    return (
        <Issues params={searchParams} />
    );
};

export default IssuesScreen;
