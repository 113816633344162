import {
    useEffect, createContext, FC,
} from 'react';
import { io, Socket } from 'socket.io-client';
import { MULTIVERSE_URL } from '../const/envs';
import socketEvents from '../const/socketEvent';

const configSocket: any = {
    reconnection: true,
    forceNew: true,
    transports: ['websocket'],
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    reconnectionAttempts: Infinity,
};

export const socketInstance = io(MULTIVERSE_URL, configSocket);

export const SocketClientContext = createContext<Socket>(socketInstance);

export const SocketClientProvider: FC = ({ children }) => {
    useEffect(() => () => {
        Object.values(socketEvents)
            .map((socketEvent) => socketInstance.off(socketEvent));
    }, []);

    return (
        <SocketClientContext.Provider
            value={socketInstance}
        >
            {children}
        </SocketClientContext.Provider>
    );
};
