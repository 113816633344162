import { message } from 'antd';
import { createContext, FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getObjectionDetail, getObjections } from '../../../api/issues';
import { IObjection } from '../../../interfaces/objection.interface';
import { IObjectionsContext } from './context.interface';
import { initialContext } from './initial.context';

export const ObjectionsContext = createContext<IObjectionsContext>(initialContext);

export const ObjectionProvider: FC = ({ children }) => {
    const [objections, setObjections] = useState<IObjection[]>(initialContext.objections);
    const [loading, setLoading] = useState<boolean>(initialContext.loading);
    const [total, setTotal] = useState<number>(initialContext.total);
    const [paginator, setPaginator] = useState(initialContext.paginator);
    const [searchParams, setSearchParams] = useSearchParams();

    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [loadingDetail, setLoadingDetail] = useState<boolean>(false);
    const [objectionSelected, setObjectionSelected] = useState<IObjection | null>(null);

    const objectionPublicID = searchParams.get('objectionPublicID');
    const workspaceUniqueID = searchParams.get('workspaceUniqueID');
    const statusString = searchParams.get('status') || '[]';
    const status = JSON.parse(statusString);

    const getParams = () => {
        const params: any = {};
        status.length && (params.status = status);
        workspaceUniqueID && (params.workspaceUniqueID = workspaceUniqueID);
        objectionPublicID && (params.objectionPublicID = objectionPublicID);
        return params;
    };

    const onPageChange = (page: number, pageSize: number) => {
        setPaginator({
            limit: pageSize,
            page,
        });
    };

    const handleStatus = (value: any) => {
        const { status, ...params } = getParams();
        value.length !== '' && (params.status = JSON.stringify(value));
        setSearchParams(params);
    };

    const handleWorkspace = (workspaceUniqueID: string) => {
        setSearchParams({ ...getParams(), workspaceUniqueID });
    };

    const handleObjection = (objectionPublicID: string) => {
        setSearchParams({ ...getParams(), objectionPublicID });
    };

    const fetchObjections = async () => {
        setLoading(true);

        const params: any = {
            ...paginator,
            ...getParams(),
        };

        if (workspaceUniqueID) {
            params.workspaceUniqueID = workspaceUniqueID;
        }

        if (objectionPublicID) {
            params.objectionPublicID = objectionPublicID;
        }

        getObjections(params)
            .then(({ data: { data } }: any) => {
                setObjections(data.data);
                setTotal(data.total);
            })
            .catch((error: any) => {
                const description = error?.response?.data?.message || error?.message;
                message.error({ content: description });
            })
            .finally(() => setLoading(false));
    };

    const fetchObjectionDetail = async () => {
        setLoadingDetail(true);

        if (!objectionSelected) {
            return;
        }

        getObjectionDetail(objectionSelected._id)
            .then(({ data: { data } }: any) => {
                setObjectionSelected({
                    ...data.objection,
                    issueID: data.issue,
                });
            })
            .catch((error: any) => {
                const description = error?.response?.data?.message || error?.message;
                message.error({ content: description });
            })
            .finally(() => setLoadingDetail(false));
    };

    const updateStatus = (objectionID: string, status: string) => {
        const objection = objections.find((objection: IObjection) => objection._id === objectionID);
        if (objection) {
            objection.status = status;
            setObjections([...objections]);
        }
    };

    return (
        <ObjectionsContext.Provider
            value={{
                objections,
                objectionSelected,
                loading,
                loadingDetail,
                showDetails,
                total,
                paginator,
                searchParams,
                statusString,
                onPageChange,
                handleStatus,
                handleWorkspace,
                handleObjection,
                setShowDetails,
                setObjectionSelected,
                fetchObjections,
                fetchObjectionDetail,
                updateStatus,
            }}
        >
            {children}
        </ObjectionsContext.Provider>
    );
};
