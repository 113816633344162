import {
    Button, List, Modal, Space, Spin, Typography,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { FaCheckDouble } from 'react-icons/fa';
import { IWorkspaceAlert } from '../../../interfaces/workspace-alert';

interface Props {
    open: boolean;
    workspaceAlerts: IWorkspaceAlert[];
    loadingReadAlert: boolean;
    setOpen: (open: boolean) => void;
    markAsRead: (wsAlertID: string, isRead: boolean) => void;
}

const { Text } = Typography;

const WorkspacesList = ({
    open, workspaceAlerts, setOpen, markAsRead, loadingReadAlert,
}: Props) => {
    const navigate = useNavigate();

    return (
        <Modal
            title="Workspaces notified"
            open={open}
            footer={null}
            onCancel={() => setOpen(false)}
        >
            <Space direction="vertical" size={24} style={{ width: '100%' }}>
                <Text>
                    Press crtl/cmd + F and press Enter to search a workspace. Mark as read by clicking the check icon.
                </Text>
                <List
                    style={{
                        maxHeight: 600,
                        overflowY: 'scroll',
                    }}
                    dataSource={workspaceAlerts}
                    renderItem={(item: IWorkspaceAlert) => {
                        const { workspaceID } = item;
                        return (
                            <List.Item
                                key={workspaceID?._id}
                                style={{
                                    padding: '12px 0',
                                    paddingRight: 12,
                                }}
                                actions={[
                                    loadingReadAlert ? (
                                        <Spin size="small" />
                                    ) : (
                                        <Button
                                            type="ghost"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                            onClick={() => markAsRead(item?._id, !item?.isRead)}
                                            icon={(
                                                <FaCheckDouble
                                                    color={item.isRead ? '#177ddc' : '#7d7d7d'}
                                                    size={14}
                                                />
                                            )}
                                        />
                                    ),
                                ]}
                            >
                                <List.Item.Meta
                                    title={(
                                        <Space size={4}>
                                            <Text strong>
                                                {workspaceID?.name}
                                            </Text>
                                            <Button
                                                type="link"
                                                style={{ padding: 0 }}
                                                onClick={() => navigate(`/v2/workspaces/${workspaceID?._id}`)}
                                            >
                                                {`(${workspaceID?.workspaceUniqueID})`}
                                            </Button>
                                        </Space>
                                    )}
                                    description={(
                                        <Text>
                                            {workspaceID?.ownerEmail}
                                        </Text>
                                    )}
                                />
                            </List.Item>
                        );
                    }}
                />
            </Space>
        </Modal>
    );
};

export default WorkspacesList;
