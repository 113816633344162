import { Menu, message, Modal } from 'antd';
import { useContext } from 'react';
import { updateObjection } from '../../../api/issues';
import { ObjectionsContext } from '../context/objectionsContext';

const { Item } = Menu;
const { confirm } = Modal;

const ChangeStatus = () => {
    const { objectionSelected, updateStatus } = useContext(ObjectionsContext);

    const OPTIONS = [
        { label: 'PENDING', value: 'PENDING' },
        { label: 'ACTIVE', value: 'ACTIVE' },
        { label: 'COMPLETED', value: 'COMPLETED' },
        { label: 'CANCELLED', value: 'CANCELLED' },
    ];

    const confirmChangeStatus = (status: string) => confirm({
        title: 'Change status',
        content: 'Are you sure you want to change the status of this objected request?',
        onOk: async () => {
            if (!objectionSelected) return;
            try {
                const payload = {
                    status,
                };
                const objectionID = objectionSelected?.issueID?._id;
                await updateObjection(objectionID, payload);
                message.success({ content: 'Status changed successfully' });
                objectionSelected.status = status;
                updateStatus(objectionSelected?._id, status);
            } catch (error: any) {
                const desctiption = error?.response?.data?.message || 'Something went wrong, please try again later';
                message.error({ content: desctiption });
            }
        },
        okText: 'Change',
        cancelText: 'Cancel',
    });

    return (
        <Menu>
            {
                OPTIONS.map((option) => (
                    <Item
                        key={option.value}
                        onClick={() => confirmChangeStatus(option.value)}
                    >
                        {option.label}
                    </Item>
                ))
            }
        </Menu>
    );
};

export default ChangeStatus;
