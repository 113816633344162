import {
    Button, Divider, Drawer, Typography,
} from 'antd';
import { IoIosCloseCircle } from 'react-icons/io';
import { BookingType } from '../../types/booking';
import ItemData from '../../widgets/ItemData';

interface Props {
    booking: BookingType,
    open: boolean;
    onClose: () => void;
}

const { Title, Text } = Typography;

const BookingPreview = ({
    booking, open, onClose,
}: Props) => {
    console.log({ booking });

    const workspaceData = [
        {
            label: 'Workspace name',
            value: booking?.workspaceName,
        },
    ];

    const customerData = [
        {
            label: 'Names',
            value: booking?.customerName,
        },
        {
            label: 'Mobile',
            value: booking?.customerMobile,
        },
        {
            label: 'Email',
            value: booking?.customerEmail || '-',
        },
    ];

    const businessData = [
        {
            label: 'Name',
            value: booking?.businessName,
        },
        {
            label: 'Mobile',
            value: booking?.businessMobile,
        },
        {
            label: 'Unique ID',
            value: booking?.businessUniqueID || '-',
        },
    ];

    const deliveryData = [
        {
            label: 'Delivery Address',
            value: booking?.deliveryAddress,
        },
        {
            label: 'Reference',
            value: booking?.deliveryReferences,
        },
        {
            label: 'City',
            value: booking?.deliveryCity || '-',
        },
    ];

    return (
        <Drawer
            open={open}
            onClose={onClose}
            closable={false}
            placement="right"
            width={650}
            title={`Booking Nº ${booking?.bookingNumericId || '000000'}`}
            extra={[
                <Button
                    type="link"
                    icon={(
                        <IoIosCloseCircle
                            style={{ verticalAlign: 'middle' }}
                            size={16}
                        />
                    )}
                    onClick={() => onClose()}
                />,
            ]}
        >

            <Title level={4}>
                Workspace Information
            </Title>

            {
                workspaceData.map((item, index) => (
                    <ItemData
                        label={item.label}
                        value={item.value}
                        key={index}
                    />
                ))
            }

            <Divider />

            <Title level={4}>
                Customer Information
            </Title>

            {
                customerData.map((item, index) => (
                    <ItemData
                        label={item.label}
                        value={item.value}
                        key={index}
                    />
                ))
            }

            <Divider />

            <Title level={4}>
                Business Information
            </Title>

            {
                businessData.map((item, index) => (
                    <ItemData
                        label={item.label}
                        value={item.value}
                        key={index}
                    />
                ))
            }

            <Divider />

            <Title level={4}>
                Delivery Information
            </Title>

            {
                deliveryData.map((item, index) => (
                    <ItemData
                        label={item.label}
                        value={item.value}
                        key={index}
                    />
                ))
            }

        </Drawer>
    );
};

export default BookingPreview;
