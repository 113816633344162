import { types } from '../types/types';

export const setRestartSearchId = (rideId: any) => ({
    type: types.ridesRestartSearchId,
    payload: rideId,
});

export const setCancelSearchId = (rideId: any) => ({
    type: types.ridesCancelSearchId,
    payload: rideId,
});

export const setChangeStatus = (rideId: any) => ({
    type: types.ridesChangeStatus,
    payload: rideId,
});

export const setRidesAddComment = (rideId: any) => ({
    type: types.ridesAddComment,
    payload: rideId,
});

export const setDriverManuallylId = (rideId: any) => ({
    type: types.ridesDriverManuallyModal,
    payload: rideId,
});

export const setReportIssue = (rideId: any) => ({
    type: types.ridesReportIssue,
    payload: rideId,
});

export const setEditWorkspace = (workspace: any) => ({
    type: types.workspaceEdit,
    payload: workspace,
});

export const setDeleteWorkspace = (workspace: any) => ({
    type: types.workspaceRemove,
    payload: workspace,
});

export const setDeactivateWorkspace = (workspace: any) => ({
    type: types.workspaceDeactivate,
    payload: workspace,
});

export const openModal = (type: string, data?: any, onSuccess?: any) => ({
    type: types.openModal,
    payload: { type, data, onSuccess },
});

export const closeModal = () => ({
    type: types.closeModal,
});

export const setDeactivateDeliveryProvider = (deliveryProvider: any) => ({
    type: types.deliveryProviderDeactivate,
    payload: deliveryProvider,
});

export const setChangeTier = (deliveryProvider: any) => ({
    type: types.deliveryProviderChangeTier,
    payload: deliveryProvider,
});

export const setSettingsTiers = (workspaces: any, optionAction: string | null) => ({
    type: types.settingsTiers,
    payload: { workspaces, optionAction },
});

export const setSettingsTiersForBussines = (bussines?: any) => ({
    type: types.bussinesSettingsTiers,
    payload: bussines,
});

export const setUpdateIssue = (issue: any) => ({
    type: types.updateIssue,
    payload: issue,
});

export const setMaxDistanceMyFleet = (workspace: any, optionAction: string | null) => ({
    type: types.workspaceEditMaxDistance,
    payload: { workspace, optionAction },
});
