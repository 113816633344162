import { Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSettingsTiers } from '../../../actions/modals';
import {
    getBusinessWithProviders, updateWorkspaceDeliveryProviders, updateWorkspaceTiers,
} from '../../../api/workspaces';

import TiersProviders from './TiersProviders';
import { getCurrentDeliveries } from './util';

export const SettingsTiers = ({ reload, setReload }: any) => {
    const [deliveries, setDeliveries] = useState<any[]>([]);
    const [stores, setStores] = useState<any[]>([]);
    const active = useSelector((state: any) => state.modals.tiersWorkspace);
    const { deliveries: allDeliveries } = useSelector((state: any) => state.deliveries);
    const dispatch = useDispatch();

    const updateDeliveriesData = (allDeliveries: any[], initialStores: any[]) => setDeliveries(getCurrentDeliveries(allDeliveries, initialStores));

    const fetchBusinessWithProviders = () => {
        getBusinessWithProviders(active._id)
            .then(({ data }: any) => {
                const stores = data.body.sort((a: any, b: any) => a.companyName.localeCompare(b.companyName));
                setStores(stores);
            })
            .catch((error: any) => console.log(error.message));
    };

    useEffect(() => {
        if (!active) return;
        fetchBusinessWithProviders();
    }, [active]);

    useEffect(() => {
        if (stores.length === 0 || allDeliveries.length === 0) return;
        updateDeliveriesData(allDeliveries, stores);
    }, [stores, allDeliveries]);

    const handleCancel = () => {
        dispatch(setSettingsTiers(null, null));
    };

    const handleTierTimeChange = async (tierNumber: number, time: number) => {
        const tiers = active.tiers || [];
        const tierIndex = tiers.findIndex((tier: any) => tier.index === tierNumber);
        let tierUpdated: any = { index: tierNumber, time };
        // Check if exists
        if (tierIndex === -1) {
            tierUpdated.isActive = true;
            tiers.push(tierUpdated);
        } else {
            tierUpdated = { ...active.tiers![tierIndex], ...tierUpdated };
            tiers[tierIndex] = tierUpdated;
        }
        try {
            notification.info({
                message: "Updating tier's time",
                duration: 2,
            });
            const data: any = await updateWorkspaceTiers({ workspaceID: active._id, tiers });
            notification.success({ message: data.data.message });
            dispatch(setSettingsTiers(data.data.body, 'setSettingsTiers'));
        } catch (error) {
            console.log({ error });
            notification.error({ message: 'Error updating tier time' });
        }
    };

    const updateDPConfiguration = async (deliveryProviders: any[]) => {
        try {
            notification.info({
                message: 'Updating tiers',
                duration: 2,
            });
            const data: any = await updateWorkspaceDeliveryProviders({ workspaceID: active._id, deliveryProviders });
            setDeliveries(deliveries.map((delivery: any) => {
                const dp = deliveryProviders.find((dp: any) => dp.name === delivery.keyName);
                return { ...delivery, tier: dp.tier };
            }));
            dispatch(setSettingsTiers(data.data.body, 'setSettingsTiers'));
            notification.success({ message: data.data.message });
        } catch (error) {
            console.log({ error });
            notification.error({ message: 'Error updating tier' });
        }
    };

    return (
        <Modal
            title="Settings Workspaces Tiers "
            visible={active}
            onOk={handleCancel}
            onCancel={handleCancel}
            bodyStyle={{ padding: 10 }}
            width={1000}
        >
            <TiersProviders
                deliveries={deliveries}
                dpConfiguration={active?.deliveryProviders}
                tiersConfiguration={active?.tiers}
                handleTierTimeChange={handleTierTimeChange}
                updateDPConfiguration={updateDPConfiguration}
            />
        </Modal>
    );
};
