import {
    Form, Input, Modal, Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { setRidesAddComment } from '../../../actions/modals';
import { commentBooking } from '../../../api/deliveryProviders';

const { TextArea } = Input;

export const CommentModal = () => {
    const [form] = Form.useForm();
    const { addComment: booking } = useSelector((state: any) => state.modals);
    const dispatch = useDispatch();

    const onFinish = (values: any) => {
        commentBooking({
            bookingID: booking._id,
            ...values,
            isAdmin: true,
        })
            .then((res) => console.log(res))
            .catch((err) => console.log(err))
            .finally(() => dispatch(setRidesAddComment(null)));
    };
    const onCancel = () => {
        dispatch(setRidesAddComment(null));
    };

    return (
        <Modal
            open={booking}
            title="Add comment"
            onCancel={onCancel}
            onOk={() => form.submit()}
        >

            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item name="comment">
                    <TextArea rows={4} />
                </Form.Item>
            </Form>
        </Modal>
    );
};
