import { useContext } from 'react';
import { SummaryContext } from './context/summary.context';
import SearchType from './components/SearchType';
import Search from './components/Search';
import Results from './components/Results';

const MainSearch = () => {
    const props = useContext(SummaryContext);

    return (
        <div className="main-search">
            <SearchType {...props} />
            <Search {...props} />
            <Results {...props} />
        </div>
    );
};

export default MainSearch;
