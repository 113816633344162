import {
    Avatar,
    Col, Row, Space, Switch, Typography,
} from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import cs from 'classnames';
import { AiFillQuestionCircle, AiFillStop, AiOutlineCopy } from 'react-icons/ai';
import { BiCode } from 'react-icons/bi';
import { MdPhotoCamera } from 'react-icons/md';
import { copyText } from '../../../../utils/general';
import CardDataBase from '../../../CardDataBase';
import HelperIconTooltip from '../../../HelperIconTooltip';
import styles from '../../styles.module.scss';
import { editWorkspace } from '../../../../api/workspaces';

const { Title, Text } = Typography;

const colorTextHelp = '#000';

interface ISettingsDetail {
    workspaceId: string;
    masterKey: string;
    proofOfDelivery: string;
    preferredProvider?: string;
    requiresProofOfDelivery: boolean;
}

const SettingsDetail = ({
    workspaceId,
    masterKey,
    proofOfDelivery,
    preferredProvider,
    requiresProofOfDelivery,
}: ISettingsDetail) => {
    const [loading, setLoading] = useState(false);
    const [proofActive, setProofActive] = useState(requiresProofOfDelivery);
    const { deliveries, loading: dpLoading } = useSelector((state: any) => state.deliveries);
    const delivery = dpLoading ? {} : deliveries.find((delivery: any) => delivery.keyName === preferredProvider);

    const handleProofChange = async (value: boolean) => {
        setLoading(true);
        try {
            await editWorkspace(workspaceId, { requiresProofOfDelivery: value });
            setProofActive(value);
        } catch (error) {
            console.log('Error updating proof of delivery', error);
        } finally {
            setLoading(false);
        }
    };

    const HELP_TEXT = {
        masterKey: (
            <Text style={{ color: colorTextHelp }}>
                Your companies
                {' '}
                <Text strong style={{ color: colorTextHelp }}>Master Key</Text>
                {' '}
                will allow you to integrate with other platforms.
            </Text>
        ),
        proofOfDelivery: (
            <Text style={{ color: colorTextHelp }}>
                <Text strong style={{ color: colorTextHelp }}>Proof of delivery</Text>
                {' '}
                is a way to ensure that the shipment was delivered correctly to the customer.
            </Text>
        ),
        preferredProvider: (
            <Text style={{ color: colorTextHelp }}>
                <Text strong style={{ color: colorTextHelp }}>Preferred provider</Text>
                {' '}
                It is the provider with which the workspace was registered.
            </Text>
        ),
    };

    const PROOF_TYPES = [
        {
            index: 0,
            name: 'Photography',
            keyName: 'PHOTO',
            avatar: <MdPhotoCamera className={styles.avatar} size={24} />,
        },
        {
            index: 1,
            name: 'Code',
            keyName: 'CODE',
            avatar: <BiCode className={styles.avatar} size={24} />,
        },
        {
            index: 2,
            name: 'None',
            keyName: 'NONE',
            avatar: <AiFillStop className={styles.avatar} size={24} />,
        },
    ];

    return (
        <CardDataBase title="Settings" style={{ minHeight: 345 }}>
            <Space direction="vertical" size={8} style={{ width: '100%' }}>
                <Space align="center" direction="horizontal" size={8}>
                    <Title level={5}>Master Key</Title>
                    <HelperIconTooltip icon={<AiFillQuestionCircle size={16} />} text={HELP_TEXT.masterKey} />
                </Space>
                <Space align="center" direction="vertical" size={8}>
                    <Row align="middle">
                        <span className={cs(styles.text, styles.masterKey)}>
                            {masterKey}
                        </span>
                        <AiOutlineCopy
                            className={styles.iconCopy}
                            color="#C9D6E8"
                            size={24}
                            onClick={() => copyText({ value: masterKey, messageSuccess: 'Master key copied!' })}
                            cursor="pointer"
                        />
                    </Row>
                </Space>
                <Row align="middle" justify="space-between" style={{ margin: '16px 0' }}>
                    <Col span={8}>
                        <Space direction="horizontal">
                            <Text strong>
                                Proof of Delivery:
                            </Text>
                            <HelperIconTooltip icon={<AiFillQuestionCircle size={16} />} text={HELP_TEXT.proofOfDelivery} />
                        </Space>
                    </Col>
                    <Col>
                        <Switch
                            checked={proofActive}
                            onChange={handleProofChange}
                            loading={loading}
                        />
                    </Col>
                </Row>
                {delivery?.name && (
                    <>
                        <Space style={{ marginTop: 40 }} align="center" direction="horizontal" size={8}>
                            <Title level={5}>Preferred Provider</Title>
                            <HelperIconTooltip icon={<AiFillQuestionCircle size={16} />} text={HELP_TEXT.preferredProvider} />
                        </Space>
                        <Space align="center" direction="vertical" size={8}>
                            <Avatar
                                style={{ borderColor: '#212845', backgroundColor: '#1a1f36' }}
                                src={delivery.image}
                            />
                        </Space>
                    </>
                )}
            </Space>
        </CardDataBase>
    );
};

export default SettingsDetail;
