import axios from 'axios';
import { OCTOPUS_BASE_URL } from '.';
import { OctopusPaginatorQuery } from '../types/paginator';

interface RequestProps {
    paginator?: OctopusPaginatorQuery;
    search?: string;
    role?: string;
}

export const getUsers = ({ paginator, search, role }: RequestProps) => {
    const query: any = {
        ...paginator,
    };

    if (search && search.length > 0) {
        query.search = search;
    }

    if (role) {
        query.role = role;
    }
    const urlParams = new URLSearchParams(query);
    const url = `${OCTOPUS_BASE_URL}/users?${urlParams.toString()}`;

    return axios.get(url);
};

export const getUser = (id: string) => {
    const url = `${OCTOPUS_BASE_URL}/users/${id}`;
    return axios.get(url);
};

export const getWorkspacesRelated = (userID: string) => {
    const params = new URLSearchParams({ userID });

    const url = `${OCTOPUS_BASE_URL}/workspace-users`;

    return axios.get(url, { params });
};

export const getWorkspaceInvitations = (userID: string) => {
    const params = new URLSearchParams({ userID });

    const url = `${OCTOPUS_BASE_URL}/workspace-invitations`;

    return axios.get(url, { params });
};
