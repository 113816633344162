import {
    Button, Form, Input, Modal, notification, Select, Space, Switch, Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeModal } from '../../../actions/modals';
import { ModalTypes } from '../../../types/modals';
import { updateCoverage } from '../../../api/deliveryProviders';
import { Coverage } from '../../../const/coverages';
import { PAYMENT_METHODS } from '../../../const/paymentMethods';

const { Title } = Typography;

interface ICoverageEditModal {
    onSuccess?: (coverage: Coverage) => void
    editPolygon?: (coverageId: string) => void
    deleteCoverage?: (coverageId: string) => void
}

const OPTIONS = [
    { value: PAYMENT_METHODS.CASH, label: 'Cash' },
    { value: PAYMENT_METHODS.CARD, label: 'Card' },
    { value: PAYMENT_METHODS.BILLING_CARD, label: 'Billing Card' },
    { value: PAYMENT_METHODS.BILLING_TRANSFER, label: 'Billing Transfer' },
    { value: PAYMENT_METHODS.TERMINAL, label: 'Terminal' },
];

const CoverageEditModal = ({
    onSuccess,
    editPolygon,
    deleteCoverage,
}: ICoverageEditModal) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const { activeModal, modalData } = useSelector((state: any) => state.modals);
    const isOpen = activeModal === ModalTypes.CoverageEdit;

    const handleCancel = () => {
        dispatch(closeModal());
    };

    const onSubmit = async (values: any) => {
        const { _id, ...payload } = values;
        setLoading(true);
        try {
            const { data } = await updateCoverage(_id, payload);
            const coverage = data.data;

            notification.success({
                message: 'Region Edited',
                description: 'Region edited successfully',
            });

            onSuccess && onSuccess(coverage);
            handleCancel();
        } catch (error: any) {
            const { message } = error.response.data;
            notification.error({
                message: 'Error',
                description: message,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({ ...modalData?.coverage });
    }, [modalData]);

    const handleEditPolygon = () => {
        editPolygon && editPolygon(modalData?.coverage?._id);
        handleCancel();
    };

    const handleDelete = (coverageId: string) => {
        deleteCoverage && deleteCoverage(coverageId);
        handleCancel();
    };

    return (
        <Modal
            width="40%"
            visible={isOpen}
            title={(
                <Space align="center">
                    <Title style={{ margin: '0 8px 0 0' }} level={5}>
                        Coverage Detail:
                        {' '}
                        {modalData?.coverage?.name}
                    </Title>
                </Space>
            )}
            footer={([
                <Button onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button
                    type="primary"
                    onClick={() => form.submit()}
                    loading={loading}
                >
                    Save
                </Button>,
            ])}
            onCancel={handleCancel}
        >

            <Form
                disabled={loading}
                form={form}
                layout="vertical"
                onFinish={onSubmit}
                style={{ width: '100%' }}
            >
                <Form.Item name="_id" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="name" label="Name">
                    <Input placeholder="Coverage" />
                </Form.Item>
                <Form.Item name="paymentMethod" label="Payment Method">
                    <Select
                        mode="multiple"
                        placeholder="Please select"
                        options={OPTIONS}
                    />
                </Form.Item>
                <Form.Item name="isVisible" label="Visible" valuePropName="checked">
                    <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                    />
                </Form.Item>
                <Form.Item style={{ textAlign: 'end' }}>
                    <Button
                        onClick={() => handleDelete(modalData?.coverage?._id)}
                        type="primary"
                        danger
                        style={{ marginRight: 8 }}
                    >
                        Delete Polygon
                    </Button>
                    <Button
                        onClick={handleEditPolygon}
                        type="primary"
                    >
                        Edit Polygon
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CoverageEditModal;
