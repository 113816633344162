import { Button, Drawer, Space } from 'antd';
import { Link } from 'react-router-dom';
import { BookingType } from '../../types/booking';
import { Events } from './Events';
import { OrderUpdateTable } from './OrderUpdateTable';
import { StatusUpdates } from './StatusUpdates';

interface BookingDetailsDrawerProps {
    booking: BookingType | null;
    close: () => void;
}

export const BookingDetailsDrawer = ({ booking, close }: BookingDetailsDrawerProps) => {
    if (!booking) return null;

    return (
        <Drawer
            title={`Booking: ${booking?.bookingNumericId}`}
            onClose={close}
            visible
            width="50%"
            extra={<Link to={`/v2/booking?id=${booking._id}`}><Button type="primary">See all details</Button></Link>}
        >
            <Space direction="vertical" style={{ width: '100%' }} size={10}>
                <StatusUpdates statusUpdates={booking?.statusUpdates || []} />
                <Events bookingId={booking._id} />
                <OrderUpdateTable bookingId={booking._id} hiddenColumns={['date']} />
            </Space>
        </Drawer>
    );
};
