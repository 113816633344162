import { Tabs } from 'antd';
import { PlanProvider } from '../../components/Plans/context/PlanContext';
import { Plans } from '../../components/Plans/Plans';
import { VariableTable } from '../../components/Plans/VariableTable';
import { VariableProvider } from '../../components/Plans/context/VariableContext';

const { TabPane } = Tabs;

const PlansScreen = () => (
    <Tabs defaultActiveKey="1">
        <TabPane tab="Plans" key="1">
            <PlanProvider>
                <Plans />
            </PlanProvider>
        </TabPane>
        <TabPane tab="Variables" key="2">
            <VariableProvider>
                <VariableTable />
            </VariableProvider>
        </TabPane>
    </Tabs>
);

export default PlansScreen;
