import axios from 'axios';
import { BASE_URL } from '.';

const getBookingIssues = () => axios.get(`${BASE_URL}/bookingIssues/getBookingIssues`);

const registerBookingIssue = (data: any) => axios.post(`${BASE_URL}/bookingIssues/registerBookingIssue`, data);

export {
    getBookingIssues,
    registerBookingIssue,
};
