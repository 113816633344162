import { Row, Col, DatePicker } from 'antd';

interface Props {
    handleDate: (value: any) => void;
}

const { RangePicker }: any = DatePicker;

const Filters = ({
    handleDate,
}: Props) => {
    console.log('Filters');
    return (
        <Row justify="start" gutter={16}>
            <Col>
                <RangePicker
                    onChange={(date: any = []) => {
                        if (date) {
                            const [startDate, endDate] = date;
                            handleDate([
                                startDate.startOf('day').toISOString(),
                                endDate.endOf('day').toISOString(),
                            ]);
                        } else {
                            handleDate(null);
                        }
                    }}
                />
            </Col>
        </Row>
    );
};

export default Filters;
