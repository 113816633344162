import cs from 'classnames';
import moment from 'moment';
import {
    Button, Col, Drawer, Dropdown, Row, Space, Spin, Typography,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useContext, useEffect } from 'react';
import CardDataBase from '../../CardDataBase';
import { ObjectionsContext } from '../context/objectionsContext';
import ChangeStatus from './ChangeStatus';

const { Text } = Typography;

const ObjectionDetail = () => {
    const {
        showDetails,
        loadingDetail,
        objectionSelected,
        setShowDetails,
        setObjectionSelected,
        fetchObjectionDetail,
    } = useContext(ObjectionsContext);

    const handleClose = () => {
        setObjectionSelected(null);
        setShowDetails(false);
    };

    const OBJECTION_DATA = [
        {
            title: 'Objection ID',
            content: objectionSelected?.objectionPublicID,
        },
        {
            title: 'Created At',
            content: (
                <Text>
                    {objectionSelected?.createdAt && moment(objectionSelected?.createdAt).format('dddd, D MMM - HH:mm a')}
                </Text>
            ),
        },
        {
            title: 'Amount',
            content: (
                <Text className="status">
                    {`${objectionSelected?.amount.toFixed(2)}`}
                </Text>
            ),
        },
        {
            title: 'Status',
            content: (
                <Dropdown overlay={<ChangeStatus />} trigger={['click']}>
                    <a onClick={(e) => e.preventDefault()} style={{ color: 'white' }}>
                        <Space>
                            <Text className={cs(objectionSelected?.status)}>
                                {objectionSelected?.status}
                            </Text>
                            <DownOutlined />
                        </Space>
                    </a>
                </Dropdown>
            ),
        },
    ];

    const ISSUE_RELATED = [
        {
            title: 'Issue Public ID',
            content: objectionSelected?.issueID?.issuePublicID,
        },
        {
            title: 'Workspace Name',
            content: objectionSelected?.issueID?.workspaceName,
        },
        {
            title: 'Workspace Unique ID',
            content: objectionSelected?.issueID?.workspaceUniqueID,
        },
        {
            title: 'Booking Numeric ID',
            content: `#${objectionSelected?.issueID?.bookingNumericId}`,
        },
        {
            title: 'Assigned To',
            content: (
                objectionSelected?.issueID?.responsableID
                    ? objectionSelected?.issueID?.responsableName
                    : 'Not assigned'
            ),
        },
        {
            title: 'Issue Status',
            content: (
                <Text className={cs(objectionSelected?.issueID?.status, 'status')}>
                    {objectionSelected?.issueID?.status}
                </Text>
            ),
        },
    ];

    useEffect(() => {
        fetchObjectionDetail();
    }, [objectionSelected?._id]);

    return (
        <Drawer
            visible={showDetails}
            closable={false}
            width={720}
            title="Objection Detail"
            placement="right"
            maskClosable={!loadingDetail}
            extra={[
                <Button
                    type="link"
                    key="close"
                    onClick={handleClose}
                    disabled={loadingDetail}
                >
                    Close
                </Button>,
            ]}
        >
            {
                loadingDetail ? (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '20vw',
                        }}
                    >
                        <Spin />
                        <Text>
                            Loading objection detail
                        </Text>
                    </div>
                ) : (
                    <Space direction="vertical" size={24} style={{ width: '100%' }}>
                        <CardDataBase title="Objection Data" style={{ minHeight: 250 }}>
                            {
                                OBJECTION_DATA.map((data) => (
                                    <Row align="middle" justify="space-between" style={{ margin: '16px 0' }}>
                                        <Col>
                                            <Text strong>
                                                {data.title}
                                                :
                                            </Text>
                                        </Col>
                                        <Col>{data.content}</Col>
                                    </Row>
                                ))
                            }
                        </CardDataBase>
                        <CardDataBase title="Issue Related" style={{ minHeight: 250 }}>
                            {
                                !objectionSelected?.issueID?._id
                                    ? (
                                        <Text>
                                            This objection is not related to any issue
                                        </Text>
                                    ) : (
                                        ISSUE_RELATED.map((data) => (
                                            <Row align="middle" justify="space-between" style={{ margin: '16px 0' }}>
                                                <Col>
                                                    <Text strong>
                                                        {data.title}
                                                        :
                                                    </Text>
                                                </Col>
                                                <Col>{data.content}</Col>
                                            </Row>
                                        ))
                                    )
                            }
                        </CardDataBase>
                    </Space>
                )
            }
        </Drawer>
    );
};

export default ObjectionDetail;
