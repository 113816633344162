import { Table } from 'antd';
import InitialColumns, { ColumnProps } from './initialColumn';
import { IContext } from '../context/issues.interface';

const IssuesTable = ({
    loading,
    data,
    total,
    paginator,
    onPageChange,
}: Partial<IContext>) => {
    const INITIAL_COLUMNS: ColumnProps[] = InitialColumns();

    const { limit, page } = paginator || { limit: 0, skip: 0 };

    return (
        <Table
            loading={loading}
            columns={INITIAL_COLUMNS}
            dataSource={data}
            pagination={{
                total,
                current: page,
                pageSize: limit,
                onChange: onPageChange,
                showSizeChanger: true,
            }}
            bordered
            scroll={{ x: 1500 }}
            rowKey="_id"
        />
    );
};

export default IssuesTable;
